import { authHeader, get, patch, post, del } from './api'

import { ITemplate, TemplateProps, templates } from '@touchpoints/requests'

export const fetchTemplates = templates.makeFetchTemplates(get, authHeader)

export async function getTemplate(organizationId: string, templateId: string) {
	const headers = await authHeader()
	return get<{ template: ITemplate }>(
		`/organizations/${organizationId}/templates/${templateId}`,
		undefined,
		headers
	)
}

export async function addTemplate(organizationId: string, template: TemplateProps) {
	const headers = await authHeader()
	return post<{ template: ITemplate }>(
		`/organizations/${organizationId}/templates`,
		{
			...template,
		},
		headers
	)
}

export async function updateTemplate(
	organizationId: string,
	templateId: string,
	data: Partial<TemplateProps>
) {
	const headers = await authHeader()
	return patch<{ template: ITemplate }>(
		`/organizations/${organizationId}/templates/${templateId}`,
		{
			...data,
		},
		headers
	)
}

export async function deleteTemplate(organizationId: string, templateId: string) {
	const headers = await authHeader()
	return del(`/organizations/${organizationId}/templates/${templateId}`, headers)
}

export const previewTemplate = templates.makeProcessTemplate(post, authHeader)

type SendPreviewOptions = {
	candidateId?: string
	positionId?: string
	data?: Record<string, any>
}
export async function sendPreviewTemplate(
	organizationId: string,
	templateId: string,
	options?: SendPreviewOptions
) {
	const headers = await authHeader()
	return post(
		`/organizations/${organizationId}/templates/${templateId}/send-preview`,
		{
			...options,
		},
		headers
	)
}
