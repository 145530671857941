import type { IResourceComment, ITask } from '@touchpoints/requests'
import { authHeader, del, get, patch, post } from './api'

export async function getTasks(organizationId: string, assignedUserId?: string) {
	const headers = await authHeader()
	return get<{ tasks: ITask[] }>(
		`/organizations/${organizationId}/tasks`,
		{ assignee: assignedUserId },
		headers
	)
}

export async function getFinishedTasks(organizationId: string) {
	const headers = await authHeader()
	return get<{ tasks: ITask[] }>(
		`/organizations/${organizationId}/tasks/finished`,
		undefined,
		headers
	)
}

export async function getFinishedTasksForCandidate(organizationId: string, candidateId: string) {
	const headers = await authHeader()
	return get<{ tasks: ITask[] }>(
		`/organizations/${organizationId}/tasks/candidates/${candidateId}/finished`,
		undefined,
		headers
	)
}

export async function getTaskById(organizationId: string, taskId: string) {
	const headers = await authHeader()
	return get<{ task: ITask }>(
		`/organizations/${organizationId}/tasks/${taskId}`,
		undefined,
		headers
	)
}

export async function assignTask(organizationId: string, taskId: string, userId: string) {
	const headers = await authHeader()
	return patch<{ task: ITask }>(
		`/organizations/${organizationId}/tasks/${taskId}/assign/${userId}`,
		undefined,
		headers
	)
}

export async function changeTaskDueDate(organizationId: string, taskId: string, dueBy: number) {
	const headers = await authHeader()
	return patch<{ task: ITask }>(
		`/organizations/${organizationId}/tasks/${taskId}`,
		{ dueBy },
		headers
	)
}

export async function changeTaskPriority(organizationId: string, taskId: string, priority: number) {
	const headers = await authHeader()
	return patch<{ task: ITask }>(
		`/organizations/${organizationId}/tasks/${taskId}`,
		{ priority },
		headers
	)
}

export async function completeTask(organizationId: string, taskId: string) {
	const headers = await authHeader()
	return patch(`/organizations/${organizationId}/tasks/${taskId}/complete`, undefined, headers)
}

export async function closeTask(organizationId: string, taskId: string, reason?: string) {
	const headers = await authHeader()
	return patch(`/organizations/${organizationId}/tasks/${taskId}/close`, { reason }, headers)
}

export async function addComment(organizationId: string, taskId: string, comment: string) {
	const headers = await authHeader()
	return post<{ comment: IResourceComment }>(
		`/organizations/${organizationId}/tasks/${taskId}/comments`,
		{ comment },
		headers
	)
}

export async function removeComment(organizationId: string, taskId: string, commentId: string) {
	const headers = await authHeader()
	return del(
		`/organizations/${organizationId}/tasks/${taskId}/comments/${commentId}`,
		undefined,
		headers
	)
}

export async function createGenericTask(
	organizationId: string,
	taskData: {
		assignedUserId: string
		priority: number
		dueDate?: number
		payload: {
			title: string
			html: string
			candidateId?: string
			positionId?: string
			accountId?: string
		}
	},
	isPrivate?: boolean
) {
	const headers = await authHeader()
	return post<{ task: ITask }>(
		`/organizations/${organizationId}/tasks/generic`,
		{
			assignedUserId: taskData.assignedUserId,
			priority: taskData.priority,
			dueDate: taskData.dueDate,
			payload: taskData.payload,
			isPrivate,
		},
		headers
	)
}

export async function previewTaskFields(
	organizationId: string,
	inputs: {
		title?: string
		subject?: string
		html?: string
	},
	options: { candidateId: string; positionId: string }
) {
	const headers = await authHeader()
	return post<{ fields: { title: string; subject: string; html: string }; context: any }>(
		`/organizations/${organizationId}/tasks/preview`,
		{ ...inputs, ...options },
		headers
	)
}
