import { styled } from '@stitches/react'
import { Button as FRButton } from 'flowbite-react'
import { useRouter } from 'next/router'
import { observer } from 'mobx-react-lite'
import { HiMenu } from 'react-icons/hi'
import capitalize from 'lodash/capitalize'
import { useEffect, useMemo, useState } from 'react'

import { Flex, Select, createSelectOnChange } from '@touchpoints/ui'

import { rootStore } from '@store'
import { useReaction } from '@touchpoints/mobx-hooks'

const TopbarContainer = styled('div')

const BreadcrumbView = observer(function ({ sectionName }: { sectionName: string }) {
	const router = useRouter()
	const [parts, setParts] = useState<string[]>([])

	const sequence = rootStore.sequences.activeSequence
	const favoriteBoard = rootStore.favoriteBoards.activeFavoriteBoard
	const position = rootStore.positions.activePosition

	useEffect(() => {
		switch (sectionName.toLowerCase()) {
			case 'sequences': {
				if (!sequence) {
					setParts([])
				} else {
					setParts([sequence.name])
				}
				break
			}

			case 'favorites': {
				if (!favoriteBoard) {
					setParts([])
				} else {
					setParts([favoriteBoard.name])
				}
				break
			}

			case 'sourcing': {
				if (position) {
					setParts([
						`${position.referenceName} - ${position.name} (${position.location})`,
					])
				} else {
					setParts([])
				}
				break
			}

			case 'call scripts': {
				const pathParts = router.pathname.split('/')
				const p = pathParts[2]
				if (p === 'questions') {
					setParts(['Questions'])
				} else {
					setParts([])
				}
				break
			}

			default:
				// if nothing then we should always clear
				setParts([])
				break
		}
	}, [sectionName, sequence, favoriteBoard, position, router])

	return (
		<div className="flex items-center">
			{parts.map((part) => {
				return (
					<div className="flex items-center" key={part}>
						<p className="text-2xl font-bold">&nbsp;/&nbsp;{part}</p>
					</div>
				)
			})}
		</div>
	)
})

function AccountsSwitcher() {
	const router = useRouter()

	const options = useReaction(() =>
		rootStore.accounts.list.map((account) => ({ value: account.id, label: account.name }))
	)
	return (
		<div className="ml-4 w-52">
			<Select
				value={options.find((o) => o.value === rootStore.accounts.activeAccountId)}
				options={options}
				fullWidth
				onChange={createSelectOnChange((v) => {
					router.push(`/accounts/${v}`)
				})}
			/>
		</div>
	)
}

export default observer(function Topbar() {
	const router = useRouter()

	const name = useMemo(() => {
		const text = router.pathname.split('/')[1] ?? ''

		switch (text) {
			case 'call-scripts': {
				return 'Call Scripts'
			}
		}

		return capitalize(text)
	}, [router.pathname])

	return (
		<TopbarContainer className="flex justify-between items-center px-[20px] w-full min-h-[61px] max-h-[61px] border-b dark:bg-gray-800 dark:border-gray-600 dark:text-white">
			<div className="flex">
				<Flex>
					<FRButton
						className="md:hidden"
						outline
						size="sm"
						onClick={() => {
							rootStore.ui.sidebar.toggle()
						}}
					>
						<HiMenu className="w-5 h-5" />
					</FRButton>
					<p className="text-2xl font-bold">{name}</p>
					{!!rootStore.accounts.activeAccountId && <AccountsSwitcher />}
					<BreadcrumbView sectionName={name} />
				</Flex>
			</div>
		</TopbarContainer>
	)
})
