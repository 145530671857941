export function SmartFormAction({
	size = 20,
	color = '#6E56CF',
}: {
	size?: number
	color?: string
}) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M15.834 9.16667V2.5H3.33398V17.5H8.75065M6.66732 5.83333H12.5007M6.66732 9.16667H9.16732"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<g clipPath="url(#clip0_2259_27238)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M15.3458 11.039C15.5207 11.0941 15.6396 11.2563 15.6396 11.4396V13.54L17.3199 13.54C17.4765 13.54 17.6202 13.6272 17.6925 13.7661C17.7648 13.905 17.7539 14.0727 17.664 14.201L14.7235 18.4018C14.6184 18.552 14.4279 18.6167 14.2531 18.5615C14.0782 18.5064 13.9593 18.3442 13.9593 18.1609L13.9593 16.0605H12.279C12.1223 16.0605 11.9787 15.9734 11.9064 15.8344C11.834 15.6955 11.845 15.5278 11.9348 15.3995L14.8754 11.1987C14.9805 11.0485 15.1709 10.9839 15.3458 11.039Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_2259_27238">
					<rect
						width="8.40156"
						height="8.40156"
						fill="white"
						transform="translate(10.5986 10.5977)"
					/>
				</clipPath>
			</defs>
		</svg>
	)
}
