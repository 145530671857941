import * as RadixTabs from '@radix-ui/react-tabs'
import { styled } from '@stitches/react'

import type { PropsWithChildren } from 'react'

const Root = styled(RadixTabs.Root, {
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
})

const Bar = styled(RadixTabs.List, {
	flexShrink: 0,
	display: 'flex',
	borderBottom: `1px solid #eaeaea`,
})

const StyledItem = styled(RadixTabs.Trigger, {
	all: 'unset',
	fontFamily: 'inherit',
	backgroundColor: 'white',
	padding: '0',
	margin: '0 10px',
	height: 30,
	flex: 1,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	fontSize: '0.875rem',
	fontWeight: 500,
	lineHeight: '150%',
	color: '#687076',
	cursor: 'pointer',
	userSelect: 'none',
	'&:first-child': { borderTopLeftRadius: 6, marginLeft: 0 },
	'&:last-child': { borderTopRightRadius: 6 },
	'&:hover': { color: '#1868b8' },
	'&[data-state="active"]': {
		color: '#11181C',
		boxShadow: 'inset 0 -1px 0 0 currentColor, 0 1px 0 0 currentColor',
	},
})

const Content = styled(RadixTabs.Content, {
	flexGrow: 1,
	padding: 20,
	backgroundColor: 'white',
	borderBottomLeftRadius: 6,
	borderBottomRightRadius: 6,
	outline: 'none',
})

type ItemProps = {
	fullWidth?: boolean
	isDisabled?: boolean
}

function Item({
	children,
	fullWidth = false,
	disabled = false,
	...props
}: PropsWithChildren<RadixTabs.TabsTriggerProps & ItemProps>) {
	return (
		<StyledItem
			{...props}
			css={{
				flexGrow: fullWidth ? 1 : 0,
				pointerEvents: disabled ? 'none' : 'auto',
				opacity: disabled ? 0.5 : 1,
			}}
		>
			{children}
		</StyledItem>
	)
}

export function Tabs({ children, ...props }: PropsWithChildren<RadixTabs.TabsProps>) {
	return <Root {...props}>{children}</Root>
}

Tabs.Bar = Bar
Tabs.Item = Item
Tabs.Content = Content
