import { authHeader, get, patch, post, del } from './api'

import type { ITeam } from '@touchpoints/requests'

export async function fetchTeams(organizationId: string) {
	const headers = await authHeader()
	return get<{ teams: ITeam[] }>(`/organizations/${organizationId}/teams`, undefined, headers)
}

export async function getTeam(organizationId: string, teamId: string) {
	const headers = await authHeader()
	return get<{ team: ITeam }>(
		`/organizations/${organizationId}/teams/${teamId}`,
		undefined,
		headers
	)
}

export async function addTeam(organizationId: string, name: string, memberIds: string[] = []) {
	const headers = await authHeader()
	return post<{ team: ITeam }>(
		`/organizations/${organizationId}/teams`,
		{
			name,
			memberIds,
		},
		headers
	)
}

export async function updateTeam(
	organizationId: string,
	teamId: string,
	data: Partial<Pick<ITeam, 'name' | 'memberIds'>>
) {
	const headers = await authHeader()
	return patch<{ team: ITeam }>(
		`/organizations/${organizationId}/teams/${teamId}`,
		{
			...data,
		},
		headers
	)
}

export async function removeTeam(organizationId: string, teamId: string) {
	const headers = await authHeader()
	return del(`/organizations/${organizationId}/teams/${teamId}`, headers)
}
