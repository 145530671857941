import { observer } from 'mobx-react-lite'
import { PropsWithChildren, useEffect, useState } from 'react'

import { rootStore } from '@store'

import type { ITask } from '@touchpoints/requests'
import { Switch, toast } from '@touchpoints/ui'
import {
	Button,
	FlowbiteButtonTheme,
	FlowbiteModalTheme,
	Modal,
	TextInput,
	Textarea,
} from 'flowbite-react'

import { Loading } from '@nextui-org/react'
import { LockClosedIcon } from '@touchpoints/icons'
import { DeepPartial } from 'flowbite-react'
import { FlowbiteModalBodyTheme } from 'flowbite-react/lib/esm/components/Modal/ModalBody'
import { makeAutoObservable } from 'mobx'
import { TaskAssignee } from './TaskAssignee'
import { TaskCandidate } from './TaskCandidate'
import { TaskPosition } from './TaskPosition'
import { TaskPriority } from './TaskPriority'
import { TaskDueBy, TaskDueIn } from './TasksFooter'
import { DESC_THEME, TITLE_THEME } from './themes'
import { TaskCreated } from './TaskCreatedToastContent'
import { fromUnitToMs } from '@services/time'

export const createTaskModal = makeAutoObservable({
	candidateId: '',
	isOpen: false,
	open(candidateId?: string) {
		this.isOpen = true
		this.candidateId = candidateId ?? ''
	},
	close() {
		this.isOpen = false
		this.candidateId = ''
	},
})

type Props = {
	onCreated?: (task: ITask) => void
}

const MODAL_THEME: DeepPartial<FlowbiteModalTheme> = {
	content: {
		base: 'relative h-full w-full p-4 md:h-auto lg:mt-[-300px]',
	},

	root: {
		show: {
			on: 'flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80',
			off: 'hidden',
		},
		sizes: {
			lg: 'max-w-[980px]',
		},
	},
}

const MODAL_BODY_THEME: DeepPartial<FlowbiteModalBodyTheme> = {
	base: 'p-5 flex-1 overflow-auto z-[1000]',
}

const BUTTOM_THEME: DeepPartial<FlowbiteButtonTheme> = {
	base: 'bg-slate-100',
	color: {
		info: 'text-white bg-calendarblue-500 hover:bg-calendarblue-500/80',
	},
}

export interface TaskFormProps {
	data: Partial<ITask>
	onDataChanged: (task: Partial<ITask>) => void
	withCandidates?: boolean
	withPositions?: boolean
	withDelay?: boolean
}
export const TaskForm = observer(function ({
	data,
	onDataChanged,
	withCandidates = true,
	withPositions = true,
	withDelay = false,
	children,
}: PropsWithChildren<TaskFormProps>) {
	useEffect(() => {
		onDataChanged({ ...data, data: { ...data.data, candidateId: createTaskModal.candidateId } })

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createTaskModal.candidateId])

	const setTitle = (title: string) => {
		onDataChanged({ ...data, data: { ...data.data, title } })
	}

	const setDescription = (html: string) => {
		onDataChanged({ ...data, data: { ...data.data, html } })
	}

	const setCandidateId = (candidateId: string) => {
		onDataChanged({ ...data, data: { ...data.data, candidateId } })
	}

	const setPositionId = (positionId: string) => {
		onDataChanged({ ...data, data: { ...data.data, positionId } })
	}

	return (
		<div>
			<div className="flex flex-col items-start space-y-3 w-full">
				<TextInput
					value={data.data?.title ?? ''}
					theme={TITLE_THEME}
					placeholder="Task title"
					required
					type="text"
					onChange={(e) => setTitle(e.target.value)}
				/>
				<Textarea
					value={data.data?.html ?? ''}
					theme={DESC_THEME}
					placeholder="Add description..."
					rows={3}
					required
					onChange={(e) => setDescription(e.target.value)}
				/>
			</div>
			<div className="flex flex-wrap space-x-3 space-y-3 mt-4">
				<div>
					{/* Intended empty div as the space-y-x is moving the first container up*/}
				</div>
				<TaskPriority
					task={data}
					onChanged={(priority) => onDataChanged({ ...data, priority })}
				/>
				<TaskAssignee
					filterable
					groupByTeams
					unassigned
					value={data.assignedUserId || 'unassigned'}
					inputPlaceholder={'Assign to...'}
					onChanged={(assignedUserId) => onDataChanged({ ...data, assignedUserId })}
					includeRoles
				/>
				{withDelay ? (
					<TaskDueIn
						task={data}
						onChanged={(delay) =>
							onDataChanged({
								...data,
								dueInMs: fromUnitToMs(delay.value, delay.unit),
								dueInDelayUnit: delay.unit,
							})
						}
					/>
				) : (
					<TaskDueBy
						task={data}
						onChanged={(dueBy) => onDataChanged({ ...data, dueBy })}
					/>
				)}
				{withCandidates && (
					<TaskCandidate
						value={data.data?.candidateId}
						filterable
						onChanged={(c) => setCandidateId(c)}
					/>
				)}

				{withPositions && (
					<TaskPosition
						value={data.data?.positionId}
						filterable
						positionsForCandidateId={data.data?.candidateId}
						onChanged={(p) => setPositionId(p)}
					/>
				)}

				<div className="grow"></div>
				<div className="flex items-center">
					<Switch
						checked={data.isPrivate}
						label={<LockClosedIcon />}
						onChange={(e) => onDataChanged({ ...data, isPrivate: e })}
					/>
				</div>
				{children}
			</div>
		</div>
	)
})

export const CreateTaskFormModal = observer(function ({ onCreated }: Props) {
	const [loading, setLoading] = useState(false)
	const [taskData, setTaskData] = useState<Partial<ITask>>({
		priority: 1,
		dueBy: Date.now(),
		data: {
			title: undefined,
			html: undefined,
			candidateId: createTaskModal.candidateId,
			positionId: undefined,
		},
	})

	const handleCreateTask = async (task: Partial<ITask>) => {
		if (!task.assignedUserId || !task.data?.title) {
			console.error('No title or assignedUserId, cant create task')
			return
		}

		setLoading(true)
		const response = await rootStore.tasks.createGeneric(
			{
				assignedUserId: task.assignedUserId,
				prioriry: Number(task.priority),
				dueDate: task.dueBy,
				payload: {
					title: task.data.title,
					html: task.data.html ?? task.data.title ?? '',
					candidateId: task.data?.candidateId,
					positionId: task.data?.positionId,
				},
			},
			task.isPrivate
		)

		if (response?.success && response.task) {
			onCreated?.(response.task)
			createTaskModal.close()
			toast(<TaskCreated task={response.task} />, {
				position: 'bottom-right',
				hideProgressBar: true,
				closeOnClick: false,
				autoClose: 5000,
				className: 'w-[459px] right-[150px]',
			})
		} else {
			toast.error('Failed to create task' + response?.message ? `: ${response?.message}` : '')
		}

		setLoading(false)
	}

	return (
		<Modal
			dismissible
			show={createTaskModal.isOpen}
			onClose={() => createTaskModal.close()}
			theme={MODAL_THEME}
			size={'lg'}
		>
			<Modal.Body theme={MODAL_BODY_THEME}>
				<TaskForm data={taskData} onDataChanged={setTaskData}>
					<Button
						disabled={
							!new Boolean(taskData.priority) ||
							!taskData.data?.title ||
							!taskData.assignedUserId
						}
						theme={BUTTOM_THEME}
						onClick={() => handleCreateTask?.(taskData)}
					>
						{!loading && 'Create task'}
						{loading && (
							<div className="flex flex-row items-center">
								<Loading color={'white'} className={'text-white'} size="xs" />
								&nbsp;&nbsp;
								<p>Processing</p>
							</div>
						)}
					</Button>
				</TaskForm>
			</Modal.Body>
		</Modal>
	)
})
