import type { ITask } from '@touchpoints/requests'
import { useRouter } from 'next/router'
import { HiCheckCircle } from 'react-icons/hi'

export const TaskCreated = ({ task }: { task: ITask }) => {
	const router = useRouter()
	const payload = task?.data

	return (
		<div className="flex space-x-6 cursor-default">
			<div className="w-5 h-5">
				<HiCheckCircle size={20} className="text-calendarblue-500" />
			</div>
			<div className="flex flex-col space-y-3">
				<p className="text-base font-bold text-black">Task created</p>
				<p className="text-md font-normal">{payload?.title ?? ''}</p>
				<span
					className="text-md font-normal text-calendarblue-500 cursor-pointer"
					onClick={() => router.push(`/tasks/${task?.referenceNumber}`)}
				>
					View task
				</span>
			</div>
		</div>
	)
}
