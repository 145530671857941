import type { IThread } from '@touchpoints/requests'
import { authHeader, get } from './api'

export async function getCandidateEmailThreads(orgId: string, candidateId: string) {
	const headers = await authHeader()

	return get<{ threads: IThread[] }>(
		`/organizations/${orgId}/candidates/${candidateId}/email-threads`,
		undefined,
		headers
	)
}
