import { rootStore } from '@store'
import { ITask } from '@touchpoints/requests'
import startOfDay from 'date-fns/startOfDay'
import startCase from 'lodash/startCase'
import { autorun } from 'mobx'
import { ReactNode, useEffect, useState } from 'react'
import { useReaction } from './mobx'
import { HiOutlineChatBubbleLeftEllipsis } from 'react-icons/hi2'
import { FaLinkedin } from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi'

export function useTaskName(task: ITask) {
	const [value, setValue] = useState<ReactNode>(task.type)

	useEffect(() => {
		return autorun(() => {
			function getIcon() {
				return task.type === 'manual-text-message' ? (
					<HiOutlineChatBubbleLeftEllipsis className="w-5 h-5 mr-1" />
				) : task.type === 'manual-linkedin-message' ? (
					<FaLinkedin className="w-5 h-5 mr-1" />
				) : task.type === 'manual-email' ? (
					<HiOutlineMail className="w-5 h-5 mr-1" />
				) : null
			}
			async function action(candidateId: string) {
				const candidate = await rootStore.candidates.getCandidateByIdAsync(candidateId)
				if (!candidate) {
					setValue(`invalid ${task.type} (could not find candidate)`)
					return
				}

				if (task.type === 'manual-generic') {
					return setValue(
						<span>
							{task.data?.title ?? 'Generic Task'} for{' '}
							<span className="italic">
								{candidate.firstName} {candidate.lastName}
							</span>
						</span>
					)
				} else if (task.type === 'manual-text-message' && task.data?.subject) {
					return setValue(
						<div className="flex space-x-2 items-center">
							{getIcon()} Send{''}
							{task.data?.subject ? (
								<span className="font-medium italic">
									{task.data.subject}&nbsp;
								</span>
							) : (
								''
							)}
							to{' '}
							<span className="italic">
								{candidate.firstName} {candidate.lastName}
							</span>
						</div>
					)
				} else if (task.data?.title) {
					return setValue(
						<div className="flex space-x-2 items-center">
							{getIcon()} Send{' '}
							{task.data?.title ? (
								<span className="font-medium italic">{task.data.title}&nbsp;</span>
							) : (
								''
							)}
							{startCase(task.type.replace('manual', ''))} to{' '}
							<span className="italic">
								{candidate.firstName} {candidate.lastName}
							</span>
						</div>
					)
				} else {
					return setValue(
						<div className="flex space-x-2 items-center">
							{getIcon()} Send {startCase(task.type.replace('manual', ''))} to{' '}
							<span className="italic">
								{candidate.firstName} {candidate.lastName}
							</span>
						</div>
					)
				}
			}

			if (task.data && task.data?.candidateId) {
				action(task.data.candidateId)
			} else if (!task.data) {
				setValue(`invalid ${task.type} (missing data)`)
			} else {
				if (task.type === 'manual-generic') {
					return setValue(task.data?.title ?? task.type)
				}
				setValue(`invalid ${task.type} (missing candidateId)`)
			}
		})
	}, [task])

	return value
}

export function useGroupedTasks(tasks: ITask[]) {
	const [groupedTasks, setGroupedTasks] = useState<
		{ label: string; time: number; tasks: ITask[] }[]
	>([])
	const [orderedTasks, setOrderedTasks] = useState<ITask[]>([])

	useEffect(() => {
		const group: Record<string, ITask[]> = {
			missed: [],
			unknown: [],
			completed: [],
			closed: [],
		}

		const now = Date.now()

		for (const task of tasks) {
			if (task.status === 'completed') {
				group['completed'].push(task)
				continue
			}

			if (task.status === 'closed') {
				group['closed'].push(task)
				continue
			}

			if (!task.dueBy) {
				group['unknown'].push(task)
				continue
			}

			if (now > task.dueBy) {
				group['missed'].push(task)
				continue
			}

			const time = startOfDay(new Date(task.dueBy)).getTime()
			const key = time === now ? time.toString() : 'upcoming'

			if (!group[key]) {
				group[key] = []
			}

			group[key].push(task)
		}

		const grouped = [
			{ label: 'Missed', time: 0, tasks: group['missed'] },
			...Object.entries(group)
				.filter(
					([key]) =>
						key !== 'missed' &&
						key !== 'unknown' &&
						key !== 'completed' &&
						key !== 'closed'
				)
				.sort()
				.map(([key, tasks]) => {
					const time = parseInt(key)
					return {
						label: new Date(time).toLocaleDateString(),
						time,
						tasks,
					}
				}),
			{ label: 'No Due Date', time: 0, tasks: group['unknown'] },
			{ label: 'Completed', time: 0, tasks: group['completed'] },
			{ label: 'Closed', time: 0, tasks: group['closed'] },
		]
		setGroupedTasks(grouped)
		setOrderedTasks(grouped.flatMap((group) => group.tasks))
	}, [tasks])

	return { groupedTasks, orderedTasks, count: tasks.length }
}

export function useGroupedFinishedTasks(tasks: ITask[]) {
	const [groupedTasks, setGroupedTasks] = useState<
		{ label: string; time: number; tasks: ITask[] }[]
	>([])
	const [count, setCount] = useState(0)

	useEffect(() => {
		const group: Record<string, ITask[]> = {}

		let localCount = 0
		for (const task of tasks) {
			if (!task.completedAt) {
				continue
			}

			const time = startOfDay(new Date(task.completedAt)).getTime().toString()
			if (!group[time]) {
				group[time] = []
			}

			group[time].push(task)

			++localCount
		}

		const grouped = [
			...Object.entries(group)
				.sort((a, b) => parseInt(b[0]) - parseInt(a[0]))
				.map(([key, tasks]) => {
					const time = parseInt(key)
					return {
						label: new Date(time).toLocaleDateString(),
						time,
						tasks,
					}
				}),
		]

		setCount(localCount)
		setGroupedTasks(grouped)
	}, [tasks])

	return { groupedTasks, count }
}

export function useTaskIndex(tasks: ITask[], id: string) {
	return tasks.findIndex((t) => t.id === id || t.referenceNumber === id)
}

export function usePrioritizedTasks(showFinished: boolean) {
	const tasks = useReaction(
		() => {
			return (
				showFinished ? rootStore.tasks.filteredFinishedTasks : rootStore.tasks.filteredList
			)
				.map((t) => ({ ...t }))
				.sort((a, b) => a.priority - b.priority)
		},
		10,
		[showFinished]
	)

	return tasks
}
