import { observer } from 'mobx-react-lite'
import { FaLinkedin, FaTrello } from 'react-icons/fa'

import { Tooltip } from '@nextui-org/react'
import { rootStore } from '@store'
import { ICandidate, IPositionCandidate } from '@touchpoints/requests'
import { BiSolidMegaphone } from 'react-icons/bi'
import { HiDocumentText, HiPencilAlt } from 'react-icons/hi'
import { GoLinkExternal } from 'react-icons/go'
import { BasePosition } from '@touchpoints/store'
import { useRouter } from 'next/router'
import { HiPencilSquare } from 'react-icons/hi2'
import clsx from 'clsx'

export const PositionLabel = observer(function ({
	position,
	candidate,
	positionCandidate,
	positionCandidateId,
	canLaunchPosition = true,
	allLinks = true,
}: {
	position?: BasePosition
	candidate?: ICandidate
	positionCandidate?: IPositionCandidate
	positionCandidateId: string
	canLaunchPosition?: boolean
	allLinks?: boolean
}) {
	const router = useRouter()

	const copyCandidateInfo = async () => {
		if (candidate) {
			let text = `${candidate.firstName} ${candidate.lastName}`
			if (position) {
				text = `${text} | ${position.referenceName}`
			}
			text = `${text} | ${candidate.email}`

			// copy to clipboard
			await navigator.clipboard.writeText(text)
		}
	}

	const handleLinkedIn = () => {
		const linkedinUrl = candidate?.linkedinUrl
		if (!linkedinUrl) {
			return
		}

		window.open(linkedinUrl, '_blank')
	}

	const handleTrello = () => {
		const trelloCardUrl = positionCandidate?.trelloCardUrl
		if (!trelloCardUrl) {
			return
		}

		window.open(trelloCardUrl, '_blank')
	}

	// NOTE: this is hard-coded for now
	const handleAccountExecutiveDetails = () => {
		const link =
			position?.knowledgeDBLink || 'https://airtable.com/appZdSKL0TSRkg2oG/pag96UbIvRkJjdMSM'
		window.open(link)
	}

	// NOTE: this is hard-coded forever
	const handleOtterLink = async () => {
		await copyCandidateInfo()
		window.open('https://otter.ai/')
	}

	const handleAEInterview = () => {
		if (!positionCandidate || !position) {
			return
		}

		const id = positionCandidate.id ?? positionCandidateId
		const email = rootStore.organizationUsers.activeUser?.email ?? ''
		const candidateEmail = candidate?.email ?? ''

		const aeId = positionCandidate.accountExecutiveId ?? position.accountManagerId
		const accountExecutive = aeId ? rootStore.organizationUsers.users[aeId] : undefined
		const aeSlug = accountExecutive?.slug

		const formId = aeSlug === 'joel-keller' ? 'WB2EQYrr' : 'ghDjfYE6'

		window.open(
			`https://recruitful.typeform.com/to/${formId}#tpcandidate_id=${id}&recruiter_email=${email}&candidate_email=${candidateEmail}`
		)
	}

	const handleFormUrl = () => {
		if (!positionCandidate) {
			return
		}

		const formUrl = positionCandidate.formUrl
		if (!formUrl) {
			return
		}

		window.open(formUrl, '_blank')
	}

	const handleCallScript = (id: string) => {
		if (!positionCandidate) {
			return
		}

		rootStore.callScripts.launchCallScript(
			id,
			positionCandidate.candidateId,
			positionCandidate.positionId
		)
	}

	const handleGoToPosition = () => {
		if (!position) {
			return
		}

		router.push(`/positions/${position.id}`)
	}

	const handleOpenAllLinks = () => {
		const links = []

		copyCandidateInfo()

		// linkedin
		if (candidate?.linkedinUrl) {
			links.push(candidate.linkedinUrl)
		}

		//knowledge base
		links.push(
			position?.knowledgeDBLink || 'https://airtable.com/appZdSKL0TSRkg2oG/pag96UbIvRkJjdMSM'
		)

		// oter
		links.push('https://otter.ai/')

		// Launch Interview 2.0
		if (positionCandidate?.formUrl) {
			links.push(positionCandidate.formUrl)
		}

		for (const link of links) {
			window.open(link, '_blank')
		}
	}

	return (
		<div>
			{position && (
				<div className="flex items-center space-x-2 text-gray-500">
					<a
						className={clsx('text-sm font-normal', {
							'cursor-pointer': !!canLaunchPosition,
						})}
						onClick={canLaunchPosition ? handleGoToPosition : undefined}
					>
						{position.referenceName}
					</a>
					<div className="w-1 h-1 rounded-full bg-gray-500" />
					<a
						className={clsx('text-sm font-normal truncate', {
							'cursor-pointer': !!canLaunchPosition,
						})}
						onClick={canLaunchPosition ? handleGoToPosition : undefined}
					>
						{position.name}
					</a>
					{position.location && (
						<>
							<div className="w-1 h-1 rounded-full bg-gray-500" />
							<span className="text-sm font-normal truncate">
								{position.location}
							</span>
						</>
					)}
					{position.managerName && (
						<>
							<div className="w-1 h-1 rounded-full bg-gray-500" />
							<span className="text-sm font-normal truncate">
								{position.managerName}
							</span>
						</>
					)}
					<p className="text-lg"> | </p>

					{candidate?.linkedinUrl && (
						<Tooltip content="LinkedIn">
							<FaLinkedin className="hover:cursor-pointer" onClick={handleLinkedIn} />
						</Tooltip>
					)}
					{allLinks && positionCandidate?.trelloCardUrl && (
						<Tooltip content="Trello">
							<FaTrello className="hover:cursor-pointer" onClick={handleTrello} />
						</Tooltip>
					)}
					<Tooltip content="Knowledge Database">
						<HiDocumentText
							className="w-5 h-5 hover:cursor-pointer"
							onClick={handleAccountExecutiveDetails}
						/>
					</Tooltip>
					{allLinks && (
						<Tooltip content="Otter.ai">
							<BiSolidMegaphone
								className="w-5 h-5 hover:cursor-pointer"
								onClick={handleOtterLink}
							/>
						</Tooltip>
					)}
					{allLinks && (
						<>
							{position?.callScript1Id ? (
								<Tooltip content="Launch Interview">
									<HiPencilSquare
										className="w-5 h-5 hover:cursor-pointer fill-emerald-500"
										onClick={() => handleCallScript(position.callScript1Id)}
									/>
								</Tooltip>
							) : positionCandidate && positionCandidate.formUrl ? (
								<Tooltip content="Launch Interview 2.0">
									<HiPencilSquare
										className="w-5 h-5 hover:cursor-pointer fill-emerald-500"
										onClick={handleFormUrl}
									/>
								</Tooltip>
							) : null}

							<p className="text-lg"> | </p>
							{position?.callScript2Id ? (
								<Tooltip content="Launch AE Interview">
									<HiPencilAlt
										className="w-5 h-5 hover:cursor-pointer fill-amber-500"
										onClick={() => handleCallScript(position.callScript2Id)}
									/>
								</Tooltip>
							) : positionCandidate ? (
								<Tooltip content="Launch AE Interview">
									<HiPencilAlt
										className="w-5 h-5 hover:cursor-pointer fill-amber-500"
										onClick={handleAEInterview}
									/>
								</Tooltip>
							) : null}
							<p className="text-lg"> | </p>
							<div
								className="flex space-x-1 items-center p-1 rounded-lg hover:bg-slate-100 hover:cursor-pointer"
								onClick={handleOpenAllLinks}
							>
								<span className="text-sm">All links</span>
								<GoLinkExternal size={15} />
							</div>
						</>
					)}
				</div>
			)}
		</div>
	)
})
