import { autorun, makeAutoObservable } from 'mobx'
import { createAccount, deleteAccount, fetchAccounts, updateAccount } from '@requests/accounts'

import type { AccountStatus, IAccount } from '@types'

import type { RootStore } from '../root'
import { AirtableAccounts } from './airtable'
import { AccountSettings } from './settings'
import { CustomField } from '@touchpoints/requests'

class Account implements IAccount {
	id = ''
	organizationId = ''
	name = ''
	shortName = ''
	url = ''
	status: AccountStatus = 'active'
	benefits?: string
	hook?: string

	customFields?: Record<string, CustomField>

	trelloBoardUrls: string[] = []

	constructor(data: IAccount) {
		makeAutoObservable(this)

		this.update(data)
	}

	update(data: IAccount) {
		this.id = data.id
		this.organizationId = data.organizationId
		this.name = data.name
		this.shortName = data.shortName ?? this.name
		this.url = data.url ?? ''
		this.status = data.status
		this.benefits = data.benefits
		this.hook = data.hook
		this.customFields = data.customFields
		this.trelloBoardUrls = data.trelloBoardUrls ?? []
	}
}

export class AccountsStore {
	readonly rootStore: RootStore
	readonly settings: AccountSettings
	readonly airtable: AirtableAccounts

	readonly list: Account[] = []

	private _activeAccountId: string | null = null

	readonly filterState = {
		searchQuery: '',
		setSearchQuery(query: string) {
			this.searchQuery = query
		},
	}

	constructor(root: RootStore) {
		this.rootStore = root
		this.settings = new AccountSettings(root)
		this.airtable = new AirtableAccounts(root)

		makeAutoObservable(this, {
			rootStore: false,
			settings: false,
			airtable: false,
		})

		autorun(async () => {
			await this.fetchAccounts()
		})
	}

	get activeAccountId() {
		return this._activeAccountId
	}

	async fetchAccounts() {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await fetchAccounts(orgId)

		if (!res.data) {
			return
		}

		const { accounts } = res.data
		this.clearPositions()
		accounts.forEach((acc) => this.addAccount(acc))
	}

	addAccount(data: Account | IAccount) {
		this.list.push(data instanceof Account ? data : new Account(data))
	}

	setActiveAccountId(id: string | null) {
		this._activeAccountId = id
	}

	getAccountById(id: string) {
		if (!id) {
			return undefined
		}
		return this.list.find((acc) => acc.id === id)
	}

	async createAccount(data: Omit<IAccount, 'id' | 'organizationId'>) {
		const orgId = this.rootStore.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await createAccount(orgId, data)
		if (!res.success) {
			return
		}

		const { account } = res.data ?? {}
		if (!account) {
			return { success: false }
		}

		this.addAccount(account)
		return { success: true }
	}

	async updateAccount(id: string, changes: Partial<IAccount>) {
		const orgId = this.rootStore.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await updateAccount(orgId, id, changes)
		if (!res.success) {
			return
		}

		const { account } = res.data ?? {}
		if (!account) {
			return
		}

		this._updateAccount(id, account)
	}

	async deleteAccount(id: string) {
		const orgId = this.rootStore.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await deleteAccount(orgId, id)
		if (!res.success) {
			return
		}

		this._removeAccount(id)
	}

	private _updateAccount(id: string, account: IAccount) {
		const rec = this.getAccountById(id)
		if (!rec) {
			return
		}

		rec.update(account)
	}

	private _removeAccount(id: string) {
		const idx = this.list.findIndex((acc) => acc.id === id)
		if (idx < 0) {
			return
		}

		this.list.splice(idx, 1)
	}

	private clearPositions() {
		while (this.list.length > 0) {
			this.list.pop()
		}
	}
}
