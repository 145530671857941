export function ensureHttpInUrl(url: string, preferHttps = true) {
	if (!url) {
		return url
	}

	const hasHttps = url.startsWith('https://')

	if (hasHttps) {
		return url
	}

	const hasHttp = url.startsWith('http://')

	if (preferHttps && hasHttp) {
		return url.replace('http://', 'https://')
	} else if (hasHttp) {
		return url
	}

	if (preferHttps) {
		return `https://${url}`
	}

	return `http://${url}`
}
