import { get, authHeader, post, patch, put, del } from './api'

import { ISequence, ISequenceInstance, SequenceAction, sequences } from '@touchpoints/requests'

export type SequenceProp = Pick<
	ISequence,
	| 'name'
	| 'actions'
	| 'mailboxEmail'
	| 'triggers'
	| 'window'
	| 'keepAfterMeetingBooked'
	| 'candidateCanBeAddedOnlyOnce'
	| 'removeAfterReply'
>

export const fetchSequences = sequences.makeFetchSequences(get, authHeader)
export const fetchSequenceInstances = sequences.makeFetchSequenceInstances(get, authHeader)

export async function fetchSequenceInstance(orgId: string, instanceId: string) {
	const headers = await authHeader()
	return get<{ sequenceInstance: ISequenceInstance }>(
		`/organizations/${orgId}/sequences/instances/${instanceId}`,
		undefined,
		headers
	)
}

export async function fastForwardInstance(orgId: string, sequenceId: string, instanceId: string) {
	const headers = await authHeader()
	return post<{ sequenceInstance: ISequenceInstance }>(
		`/organizations/${orgId}/sequences/${sequenceId}/instances/${instanceId}`,
		undefined,
		headers
	)
}

export async function createSequence(orgId: string, data: SequenceProp) {
	const headers = await authHeader()
	return post<{ sequence: ISequence }>(
		`/organizations/${orgId}/sequences`,
		{
			...data,
		},
		headers
	)
}

export async function deleteSequence(orgId: string, sequenceId: string) {
	const headers = await authHeader()
	return del(`/organizations/${orgId}/sequences/${sequenceId}`, headers)
}

export async function updateSequence(
	orgId: string,
	sequenceId: string,
	props: Partial<Omit<SequenceProp, 'actions'>>
) {
	const headers = await authHeader()
	return patch<{ sequence: ISequence }>(
		`/organizations/${orgId}/sequences/${sequenceId}`,
		{ ...props, action: 'update' },
		headers
	)
}

export async function updateSequenceActions(
	orgId: string,
	sequenceId: string,
	actions: SequenceAction[]
) {
	const headers = await authHeader()
	return put<{ sequence: ISequence }>(
		`/organizations/${orgId}/sequences/${sequenceId}`,
		{
			actions,
		},
		headers
	)
}

export async function updateSequenceActionAtIndex(
	orgId: string,
	sequenceId: string,
	actionIndex: number,
	action: SequenceAction
) {
	const headers = await authHeader()
	return put<{ sequence: ISequence }>(
		`/organizations/${orgId}/sequences/${sequenceId}/actions/${actionIndex}`,
		{
			action,
		},
		headers
	)
}

export async function pauseSequence(orgId: string, sequenceId: string) {
	const headers = await authHeader()
	return patch(
		`/organizations/${orgId}/sequences/${sequenceId}`,
		{
			action: 'pause',
		},
		headers
	)
}

export async function resumeSequence(orgId: string, sequenceId: string) {
	const headers = await authHeader()
	return patch(
		`/organizations/${orgId}/sequences/${sequenceId}`,
		{
			action: 'resume',
		},
		headers
	)
}
