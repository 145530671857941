export function CandidatesIcon({ size = 15 }: { size?: number }) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.9999 10.6665H13.333C14.1063 10.6665 14.7388 9.99866 14.5021 9.26248C13.8502 7.23475 12.1702 6.39492 10.6666 6.743M7.33331 2.6665C7.33331 3.77107 6.43788 4.6665 5.33331 4.6665C4.22874 4.6665 3.33331 3.77107 3.33331 2.6665C3.33331 1.56193 4.22874 0.666504 5.33331 0.666504C6.43788 0.666504 7.33331 1.56193 7.33331 2.6665ZM13 2.99984C13 3.92031 12.2538 4.6665 11.3333 4.6665C10.4128 4.6665 9.66664 3.92031 9.66664 2.99984C9.66664 2.07936 10.4128 1.33317 11.3333 1.33317C12.2538 1.33317 13 2.07936 13 2.99984ZM7.90268 11.3332H2.76394C1.99064 11.3332 1.36273 10.6662 1.57053 9.92134C2.78124 5.58156 7.88537 5.58156 9.09609 9.92134C9.30389 10.6662 8.67597 11.3332 7.90268 11.3332Z"
				stroke="#687076"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
