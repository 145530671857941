import {
	CustomCommandPalette,
	JsonStructure,
	JsonStructureItem,
	filterItems,
	getItemIndex,
	toast,
	useHandleOpenCommandPalette,
} from '@touchpoints/ui'

import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import {
	HiArrowLeft,
	HiArrowNarrowRight,
	HiCalendar,
	HiOutlineCalendar,
	HiPlus,
} from 'react-icons/hi'

import { rootStore } from '@store'

import { AccountsIcon, CandidatesIcon, TasksIcon } from '@touchpoints/icons'
import { useReaction } from '@touchpoints/mobx-hooks'
import type { IAccount, ICandidate, IPositionCandidate } from '@touchpoints/requests'
import { useSearch } from '@touchpoints/usehooks'

import { Tooltip } from '@nextui-org/react'

import { flatMap } from 'lodash'
import debounce from 'lodash/debounce'

import { store } from './accounts/store'
import { CandidateEmail, CandidateInfoHeader } from './candidates/CandidateInfoHeader'
import { CandidateStageColorDot } from './candidates/CandidateStageColorDot'
import { CreateTaskFormModal, createTaskModal } from './tasks/CreateTaskFormModal'
import { CandidateFirstLetterIcon } from './candidates/CandidateFirstLeterIcon'

const HiPlusIcon: React.FunctionComponent = () => {
	return <HiPlus size={20} className="text-slate-500" />
}

const CandidatesItemIcon: React.FunctionComponent = () => {
	return <CandidatesIcon size={20} />
}

const AccountsItemIcon: React.FunctionComponent = () => {
	return <AccountsIcon size={20} />
}
const TasksItemIcon: React.FunctionComponent = () => {
	return <TasksIcon size={20} />
}

type Page = 'root' | 'candidates-search' | 'candidate' | 'boards'

const PcItem = ({
	candidateId,
	positionId,
	stageId,
}: {
	candidateId: string
	positionId: string
	stageId?: string
}) => {
	const candidate = rootStore.candidates.getCandidateById(candidateId)
	const position = rootStore.positions.getPositionById(positionId)
	const stage = rootStore.stages.candidates.find((s) => s.id === stageId)

	if (!candidate || !position) return undefined

	return (
		<div className="flex w-full justify-between">
			<div className="flex items-center space-x-2">
				<Tooltip content={stage?.name ?? 'Unknown'}>
					<CandidateStageColorDot color={stage?.color} noMargin />
				</Tooltip>
				<div className="truncate max-w-[300px] dark:text-white">{position.name}</div>
				<div className="px-1 py-0.5 bg-gray-100 rounded">
					<p className="text-zinc-900 text-xs align-middle">{`${candidate?.firstName} ${candidate?.lastName}`}</p>
				</div>
			</div>

			<span className="text-neutral-400 text-sm leading-tight">{position.referenceName}</span>
		</div>
	)
}

export const QuickSearch = observer(function () {
	const [page, setPage] = useState<Page>('root')
	const [search, setSearch] = useState('')

	const [candidateSearchList, setCandidateSearchList] = useState<JsonStructure | undefined>(
		undefined
	)
	const [pcSearchList, setPcSearchList] = useState<JsonStructure | undefined>(undefined)
	const [candidateResults, setCandidateResults] = useState<ICandidate[]>([])
	const [positionCandidateResults, setPositionCandidateResults] = useState<IPositionCandidate[]>(
		[]
	)

	const [selectedCandidate, setSelectedCandidate] = useState<ICandidate>()
	const [showMoreCandidates, setShowMoreCandidates] = useState(false)
	const [showMorePositionCandidates, setShowMorePositionCandidates] = useState(false)

	const router = useRouter()

	useHandleOpenCommandPalette(() => rootStore.quickSearch.open())

	const searchCandidates = useMemo(
		() =>
			debounce(async (term: string) => {
				const result = await rootStore.candidates.fetchCandidatesPage({
					page: 1,
					rows: 200,
					filter: { searchTerm: term },
				})
				if (result?.items) {
					setCandidateResults(result.items)
					setPositionCandidateResults(
						flatMap(
							result.items.map((c) =>
								rootStore.positions.getPositionCandidatesForCandidate(c.id)
							)
						)
					)
					setShowMoreCandidates(false)
					setShowMorePositionCandidates(false)

					setPage('candidates-search')
				}
			}, 250),
		[]
	)

	useEffect(() => {
		// If page changed, let's try to autofocus the search input
		if (page) {
			const input = document.getElementById('command-palette-search-input')
			input?.focus()
		}
	}, [page])

	useEffect(() => {
		if (page !== 'candidates-search' && page !== 'root') {
			return
		}

		if (!search) {
			setCandidateResults([])
			setPage('root')
			setSelectedCandidate(undefined)
			return
		}

		if (searchCandidates) {
			searchCandidates(search)
		}
	}, [search, page, searchCandidates])

	const handleSearchChanged = (v: string) => {
		setSearch(v)
		if (page === 'candidate') {
			setPage('candidates-search')
		}
	}

	const recentPositionCandidates = useReaction(() => rootStore.recent.positionCandidates)

	const filteredItems = filterItems(
		[
			{
				heading: 'Actions',
				id: 'actions',
				items: [
					{
						id: 'new-task',
						children: 'New task',
						icon: HiPlusIcon,
						onClick: () => createTaskModal.open(),
						showType: false,
					},
				],
			},
			{
				heading: 'Recent',
				id: 'recent',
				items: recentPositionCandidates.map((pc) => {
					const position = rootStore.positions.getPositionById(pc.positionId)

					return {
						id: `pc-${pc.id}`,
						children: (
							<PcItem
								candidateId={pc.candidateId}
								positionId={pc.positionId}
								stageId={pc.stage}
							/>
						),
						showType: false,
						onClick: () => {
							router.push(`/accounts/${position?.accountId}/board/${pc.id}`)
						},
					}
				}),
			},
			{
				heading: 'Navigate to',
				id: 'navitage-to',
				items: [
					{
						id: 'tasks',
						children: 'Tasks',
						icon: TasksItemIcon,
						showType: false,
						onClick: () => router.push('/tasks'),
					},
					{
						id: 'accounts',
						children: 'Accounts',
						icon: AccountsItemIcon,
						closeOnSelect: false,
						showType: false,
						onClick: () => setPage('boards'),
					},
					{
						id: 'candidates',
						children: 'Candidates',
						icon: CandidatesItemIcon,
						showType: false,
						onClick: () => router.push('/candidates'),
					},
				],
			},
		],
		search
	)

	useEffect(() => {
		if (candidateResults.length === 0) {
			setCandidateSearchList([])
		} else {
			setCandidateSearchList([
				{
					heading: 'Candidates',
					id: 'candidate-search-result',
					items: [
						...candidateResults
							.slice(0, showMoreCandidates ? undefined : 3)
							.map((candidate) => {
								return {
									id: `candidate-${candidate.id}`,
									children: (
										<div className="flex items-center">
											<CandidateFirstLetterIcon candidate={candidate} />
											<span className="text-zinc-900 dark:text-white text-sm leading-tight">{`${candidate?.firstName} ${candidate?.lastName}`}</span>
											&nbsp;
											<CandidateEmail email={candidate?.email} />
										</div>
									),
									showType: false,
									closeOnSelect: false,
									onClick: () => {
										setSelectedCandidate(candidate)
										setPage('candidate')
									},
								}
							}),
					],
				},
			])
		}

		if (positionCandidateResults.length === 0) {
			setPcSearchList([])
		} else {
			setPcSearchList([
				{
					heading: `Found in ${positionCandidateResults.length} position${
						positionCandidateResults.length > 1 ? 's' : ''
					}`,
					id: 'position-candidate-search-result',
					items: [
						...(positionCandidateResults
							.slice(0, showMorePositionCandidates ? undefined : 3)
							.map((pc) => {
								const position = rootStore.positions.getPositionById(pc.positionId)
								if (!position) {
									console.log('No position for ', pc.positionId)
									return
								}
								return {
									id: `pc-${pc.id}`,
									children: (
										<PcItem
											candidateId={pc.candidateId}
											positionId={pc.positionId}
											stageId={pc.stage}
										/>
									),
									showType: false,
									onClick: () => {
										router.push(
											`/accounts/${position.accountId}/board/${pc.id}`
										)
									},
								}
							})
							.filter((i) => !!i) as Array<JsonStructureItem>),
					],
				},
			])
		}
	}, [showMoreCandidates, showMorePositionCandidates, candidateResults, positionCandidateResults, router])

	return (
		<div className="absolute z-[1000]">
			<CustomCommandPalette
				key={'CommandPalette'}
				placeholder="Search..."
				search={search}
				isOpen={rootStore.quickSearch.isOpen}
				onChangeOpen={(v) => {
					if (v) {
						rootStore.quickSearch.open()
					} else {
						rootStore.quickSearch.close()
					}
				}}
				onChangeSearch={handleSearchChanged}
				page={page}
			>
				<CustomCommandPalette.Page id="root">
					{filteredItems.length > 0 &&
						filteredItems.map((list) => (
							<CustomCommandPalette.List key={list.id} heading={list.heading}>
								{list.items.map(({ id, ...rest }) => (
									<CustomCommandPalette.ListItem
										key={id}
										index={getItemIndex(filteredItems, id)}
										{...rest}
									/>
								))}
							</CustomCommandPalette.List>
						))}
				</CustomCommandPalette.Page>

				<CustomCommandPalette.Page id="candidates-search">
					{candidateSearchList &&
						candidateSearchList.length > 0 &&
						candidateSearchList.map((list) => (
							<CustomCommandPalette.List key={list.id} heading={list.heading}>
								{list.items.map(({ id, ...rest }) => (
									<CustomCommandPalette.ListItem
										key={id}
										index={getItemIndex(candidateSearchList, id)}
										{...rest}
									/>
								))}
								{candidateResults.length > 3 && (
									<div
										className="px-2 cursor-pointer"
										onClick={() => setShowMoreCandidates(!showMoreCandidates)}
									>
										<span className="text-neutral-400 text-xs font-medium">
											{!showMoreCandidates
												? `${candidateResults.length - 3} more results`
												: 'Show less'}
										</span>
									</div>
								)}
							</CustomCommandPalette.List>
						))}
					{candidateResults.length === 0 && (
						<div className="px-2 py-3">
							<span className="text-neutral-400 text-md font-medium">
								No candidates found
							</span>
						</div>
					)}

					{pcSearchList &&
						pcSearchList.length > 0 &&
						pcSearchList.map((list) => (
							<CustomCommandPalette.List key={list.id} heading={list.heading}>
								{list.items.map(({ id, ...rest }) => {
									const startIndex = showMoreCandidates
										? candidateSearchList?.[0].items.length
										: 3

									return (
										<CustomCommandPalette.ListItem
											key={id}
											index={getItemIndex(pcSearchList, id, startIndex)}
											{...rest}
										/>
									)
								})}

								{positionCandidateResults.length > 3 && (
									<div
										className="p-2 cursor-pointer"
										onClick={() =>
											setShowMorePositionCandidates(
												!showMorePositionCandidates
											)
										}
									>
										<span className="text-neutral-400 text-xs font-medium">
											{!showMorePositionCandidates
												? `${
														positionCandidateResults.length - 3
												  } more results`
												: 'Show less'}
										</span>
									</div>
								)}
							</CustomCommandPalette.List>
						))}
				</CustomCommandPalette.Page>

				<BoardPage search={search} setPage={setPage} />
				<CandidatePage
					setPage={setPage}
					setSearch={setSearch}
					candidate={selectedCandidate}
				/>
			</CustomCommandPalette>
			<CreateTaskFormModal />
		</div>
	)
})

type BoardPageProps = {
	search: string
	setPage: Dispatch<SetStateAction<Page>>
}
const BoardPage = observer(function ({ search, setPage }: BoardPageProps) {
	const router = useRouter()

	const accounts = useReaction(() => rootStore.accounts.list.map((a) => ({ ...a })))

	const [results, _, setSearchQuery] = useSearch(accounts, ['name', 'shortName'])

	useEffect(() => {
		setSearchQuery(search)
	}, [search, setSearchQuery])

	const handleGoToBoard = (account: IAccount) => {
		router.push(`/accounts/${account.id}/board`)
	}

	return (
		<CustomCommandPalette.Page id="boards">
			<CustomCommandPalette.List>
				{results.map((account, index) => {
					return (
						<CustomCommandPalette.ListItem
							key={account.id}
							index={index}
							onClick={() => handleGoToBoard(account)}
							showType={false}
							className="dark:text-white"
						>
							{account.name}
						</CustomCommandPalette.ListItem>
					)
				})}
				<CustomCommandPalette.ListItem
					index={results.length}
					onClick={() => setPage('root')}
					showType={false}
					closeOnSelect={false}
					className="dark:text-white"
				>
					<HiArrowLeft size="1.5em" />
					&nbsp;Back
				</CustomCommandPalette.ListItem>
			</CustomCommandPalette.List>
		</CustomCommandPalette.Page>
	)
})

type CandidatePageProps = {
	candidate: ICandidate | undefined
	setSearch: Dispatch<SetStateAction<string>>
	setPage: Dispatch<SetStateAction<Page>>
}
const CandidatePage = observer(function ({ candidate, setPage, setSearch }: CandidatePageProps) {
	const router = useRouter()

	const activeMeeting = rootStore.meetings.activeMeeting

	const positionsForCandidate = useReaction(
		() => {
			if (!candidate) {
				return []
			}

			return rootStore.positions.getPositionCandidatesForCandidate(candidate.id)
		},
		100,
		[candidate]
	)

	useEffect(() => {
		if (!candidate) {
			return
		}

		rootStore.positions.fetchPositionCandidatesForCandidate(candidate.id, true)
	}, [candidate])

	const handleGetPersonalCalendarLink = () => {
		const org = rootStore.organizations.activeOrganization
		const orgUser = rootStore.organizationUsers.activeUser

		if (!org || !orgUser || !activeMeeting || !candidate) {
			toast.error('Could not get calendar link')
			return
		}

		const baseUrl = rootStore.meetings.getCalendarBaseUrl({ slug: org.slug }, activeMeeting)
		const url = `${baseUrl}${orgUser.slug}?c=${candidate.id}`
		navigator.clipboard.writeText(url)
		toast.success('Copied to clipboard')
	}

	const handleGetTeamCalendarLink = () => {
		const org = rootStore.organizations.activeOrganization
		const orgUser = rootStore.organizationUsers.activeUser
		const activeTeam = rootStore.meetings.activeTeam

		if (!org || !orgUser || !activeMeeting || !candidate || !activeTeam) {
			toast.error('Could not get calendar link')
			return
		}

		const baseUrl = rootStore.meetings.getCalendarBaseUrl({ slug: org.slug }, activeMeeting)
		const url = `${baseUrl}${activeTeam.localId}?c=${candidate.id}`
		navigator.clipboard.writeText(url)
		toast.success('Copied to clipboard')
	}

	const handleGoToCandidatePage = () => {
		if (!candidate) {
			return
		}

		store.close()
		router.push(`/candidates/${candidate.id}`)
	}

	return (
		<CustomCommandPalette.Page id="candidate">
			{candidate && (
				<>
					<div className="pt-2 px-3">
						<CandidateInfoHeader candidate={candidate} />
					</div>
					<CustomCommandPalette.List>
						{activeMeeting && (
							<>
								<CustomCommandPalette.ListItem
									index={0}
									onClick={handleGetTeamCalendarLink}
									showType={false}
									className="dark:text-white"
								>
									<HiCalendar size="1.25em" />
									&nbsp;Copy Team Calendar Link
								</CustomCommandPalette.ListItem>
								<CustomCommandPalette.ListItem
									index={1}
									onClick={handleGetPersonalCalendarLink}
									showType={false}
									className="dark:text-white"
								>
									<HiOutlineCalendar size="1.25em" />
									&nbsp;Copy Personal Calendar Link
								</CustomCommandPalette.ListItem>
							</>
						)}
						<CustomCommandPalette.ListItem
							index={2}
							onClick={handleGoToCandidatePage}
							showType={false}
							className="dark:text-white"
						>
							<HiArrowNarrowRight size="1.25em" />
							&nbsp;See more info
						</CustomCommandPalette.ListItem>
						<CustomCommandPalette.ListItem
							index={3}
							onClick={() => createTaskModal.open(candidate.id)}
							showType={false}
							className="dark:text-white"
						>
							<HiArrowNarrowRight size="1.25em" />
							&nbsp;Create task
						</CustomCommandPalette.ListItem>
						{positionsForCandidate && (
							<div>
								<CustomCommandPalette.List heading="positions">
									{positionsForCandidate.map((pc, index) => {
										return (
											<CustomCommandPalette.ListItem
												key={pc.id}
												index={index}
												showType={false}
												className="dark:text-white"
												onClick={() =>
													store.setSelectedPositionCandidateId(pc.id)
												}
											>
												<PcItem
													candidateId={pc.candidateId}
													positionId={pc.positionId}
													stageId={pc.stage}
												/>
											</CustomCommandPalette.ListItem>
										)
									})}
								</CustomCommandPalette.List>
							</div>
						)}
						<CustomCommandPalette.ListItem
							index={4}
							onClick={() => {
								setPage('root')
								setSearch('')
							}}
							showType={false}
							closeOnSelect={false}
							className="dark:text-white"
						>
							<HiArrowLeft size="1.5em" />
							&nbsp;Back
						</CustomCommandPalette.ListItem>
					</CustomCommandPalette.List>
				</>
			)}
		</CustomCommandPalette.Page>
	)
})
