import { AddCandidateModal, modalState } from '@components/positions/candidates'
import { OrgUserAvatar } from '@components/shared/OrgUserAvatar'
import { Popover } from '@nextui-org/react'
import { rootStore } from '@store'
import { useReaction } from '@touchpoints/mobx-hooks'
import { toast } from '@touchpoints/ui'
import { format } from 'date-fns'
import { useRouter } from 'next/router'
import { HiOutlineClipboardCopy, HiOutlineDotsHorizontal, HiOutlinePlus } from 'react-icons/hi'
import { PiExport } from 'react-icons/pi'
import { ExportWithTemplateModal, exportWithTemplateStore } from './ExportWithTemplateModal'
import type { BoardEntry } from '@types'
import { memo } from 'react'

type Props = {
	positionId: string
	boardEntries: BoardEntry[]
}

export const BoardHeader = memo(function BoardHeader({ positionId, boardEntries }: Props) {
	const router = useRouter()

	const position = useReaction(() => rootStore.positions.getPositionById(positionId), 100, [
		positionId,
	])

	const accountExecutive = useReaction(
		() => {
			if (!position) {
				return undefined
			}
			const aeId = position.accountManagerId
			if (!aeId) {
				return undefined
			}

			return rootStore.organizationUsers.users[aeId]
		},
		100,
		[position]
	)

	const handleGoToPosition = () => {
		router.push(`/positions/${positionId}`)
	}

	if (!position) {
		console.log('no position', boardEntries)
		return null
	}

	return (
		<div className="flex flex-col space-y-2 w-full flex-initial py-5">
			<div className="flex flex-col space-y-2 overflow-clip p-2">
				<div className="flex flex-col">
					<div className="flex items-center space-x-1 self-end">
						<div className="cursor-pointer hover:opacity-100 w-[20px] overflow-visible">
							<Popover isBordered>
								<Popover.Trigger>
									<div>
										<HiOutlineDotsHorizontal size="20px" />
									</div>
								</Popover.Trigger>
								<Popover.Content css={{ overflow: 'visible', zIndex: 10 }}>
									<BoardColumOptions
										positionId={positionId}
										boardEntries={boardEntries}
									/>
								</Popover.Content>
							</Popover>
						</div>

						<HiOutlinePlus
							className="cursor-pointer hover:opacity-100"
							onClick={() => {
								rootStore.positions.setActivePositionId(position.id)
								modalState.open()
							}}
						/>
					</div>
					<span
						className="text-xl font-semibold leading-7 truncate cursor-pointer hover:underline"
						onClick={handleGoToPosition}
					>
						{position.name}
					</span>
				</div>
				<AddCandidateModal />
				<ExportWithTemplateModal />

				<div className="flex space-x-2">
					<div className="flex flex-initial">
						<OrgUserAvatar user={accountExecutive} size="xs" />
					</div>
					<div className="flex w-full items-center space-x-2">
						<span className="text-sm font-normal text-gray-500">
							{position.referenceName}
						</span>
						{position.location && (
							<>
								<div className="text-[#c1c8cd] text-xs font-medium font-['Inter']">
									•
								</div>
								<span className="text-sm font-normal text-gray-500 truncate">
									{position.location}
								</span>
							</>
						)}
						{position.managerName && (
							<>
								<div className="text-[#c1c8cd] text-xs font-medium font-['Inter']">
									•
								</div>
								<span className="text-sm font-normal text-gray-500 line-clamp-1">
									{position.managerName}
								</span>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	)
})

interface BoardColumnOptionsProps {
	positionId: string
	boardEntries: BoardEntry[]
}

function BoardColumOptions({ positionId, boardEntries }: BoardColumnOptionsProps) {
	const handleExportWithTemplate = () => {
		exportWithTemplateStore.setOpen(true, positionId)
	}

	return (
		<div className="p-3 flex flex-col space-y-3 overflow-visible">
			<div
				className="w-full cursor-pointer flex flex-row"
				onClick={async () => {
					navigator.clipboard.writeText(
						await boardEntriesToClipboard(positionId, boardEntries)
					)
					toast.success(`Copied to clipboard!`)
				}}
			>
				<div className="flex items-center">
					<HiOutlineClipboardCopy aria-label="copy board" size={'24px'} />
					<span className="text-base ml-1">Copy</span>
				</div>
			</div>
			<div className="w-full cursor-pointer flex flex-row" onClick={handleExportWithTemplate}>
				<div className="flex items-center">
					<PiExport aria-label="export with template" size={'24px'} />
					<span className="text-base ml-1">Export with template</span>
				</div>
			</div>
		</div>
	)
}

const boardEntriesToClipboard = async (
	positionId: string,
	entries: BoardEntry[]
): Promise<string> => {
	const result: string[] = []

	entries.forEach(async (entry) => {
		const firstName = entry.candidate?.firstName
		const lastName = entry.candidate?.lastName
		const stage = rootStore.stages.candidates.find((stage) => {
			return stage.id === entry.positionCandidate?.stage
		})

		// Get the date of the last stage change
		let stageChangeDate = ''
		if (entry?.candidate?.id) {
			const events =
				rootStore.candidates.events.getEventsForCandidate(entry.candidate.id) ?? []

			events
				.filter((e) => e.resourceType === 'candidate-position')
				.filter((e) => e.name === 'stage-changed')
				.filter((e) => e.positionId === positionId)
				.sort((a, b) => {
					return b.ts - a.ts
				})
			const lastStage = events[0]
			if (lastStage) {
				stageChangeDate = ` - ${format(new Date(lastStage.ts), 'M/d/yyyy hh:mm a')}`
			}
		}

		result.push(`${firstName} ${lastName} - ${stage?.name} ${stageChangeDate}`)
	})
	return result.join('\n')
}
