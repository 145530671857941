import { makeAutoObservable } from 'mobx'

export class SidebarStore {
	open = false

	constructor() {
		makeAutoObservable(this)
	}

	toggle() {
		this.open = !this.open
	}
}
