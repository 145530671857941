import type { ICustomField, CustomFieldType, CustomFieldCondition } from '@touchpoints/requests'
import { makeAutoObservable } from 'mobx'

export class CustomField implements ICustomField {
	name = ''
	type: CustomFieldType = 'single-line'
	key = ''
	help = ''
	options: string[] = []

	conditions?: CustomFieldCondition[] = []

	constructor(data: ICustomField) {
		makeAutoObservable(this)

		this.update(data)
	}

	update(data: ICustomField) {
		this.name = data.name
		this.type = data.type
		this.key = data.key
		this.help = data.help ?? ''
		this.options = [...(data.options ?? [])]
		this.conditions = [...(data.conditions ?? [])]
	}
}
