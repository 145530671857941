import { authHeader, del, get } from './api'

import { ICandidateEvent, IPositionCandidateEvent } from '@touchpoints/requests'

export async function fetchAllCandidateEvents(orgId: string, candidateId: string) {
	const headers = await authHeader()
	return get<{ events: (ICandidateEvent | IPositionCandidateEvent)[] }>(
		`/organizations/${orgId}/candidates/${candidateId}/all-events`,
		undefined,
		headers
	)
}

export async function fetchCandidateEvents(orgId: string, candidateId: string) {
	const headers = await authHeader()
	return get<{ events: ICandidateEvent[] }>(
		`/organizations/${orgId}/candidates/${candidateId}/events`,
		undefined,
		headers
	)
}

export async function deleteCandidateEvent(orgId: string, candidateId: string, eventId: string) {
	const headers = await authHeader()
	return del<{ event: ICandidateEvent }>(
		`/organizations/${orgId}/candidates/${candidateId}/events/${eventId}`,
		headers
	)
}

export async function fetchPositionCandidateEvents(
	orgId: string,
	accountId: string,
	positionId: string,
	candidateId: string
) {
	const headers = await authHeader()
	return get<{ events: IPositionCandidateEvent[] }>(
		`/organizations/${orgId}/accounts/${accountId}/positions/${positionId}/candidates/${candidateId}/events`,
		undefined,
		headers
	)
}

export async function deletePositionCandidateEvent(
	orgId: string,
	accountId: string,
	positionId: string,
	candidateId: string,
	eventId: string
) {
	const headers = await authHeader()
	return del<{ event: IPositionCandidateEvent }>(
		`/organizations/${orgId}/accounts/${accountId}/positions/${positionId}/candidates/${candidateId}/events/${eventId}`,
		headers
	)
}
