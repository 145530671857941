import type { ICandidateEvent, IPositionCandidateEvent } from '@touchpoints/requests'

export function getSourceForEvent(event: ICandidateEvent | IPositionCandidateEvent) {
	const metadata = event.payload?.['metadata'] ?? event.payload
	const { source } = (metadata ?? {}) as {
		source?: string
	}

	return source
}

export function getOrgUserIdForEvent(event: ICandidateEvent | IPositionCandidateEvent) {
	const metadata = event.payload?.['metadata'] ?? event.payload
	const { id, orgUserId } = (metadata ?? {}) as {
		id?: string
		orgUserId?: string
	}

	const source = getSourceForEvent(event)

	const _id = id || orgUserId

	if (source !== 'user' && source !== 'system-via-user') {
		return undefined
	}

	return _id
}
