import format from 'date-fns/format'

export * from '@touchpoints/time'

export const formatTaskDate = (time: number) => {
	return format(new Date(time), 'EEE, MMM do')
}

export const formatTaskDateAndTime = (time: number) => {
	return format(new Date(time), 'EEE, MMM do hh:mm a')
}

export const formatTaskDateAndTimeShort = (time: number) => {
	return format(new Date(time), 'M/d hh:mm a')
}

export const formatTaskTime = (time: number) => {
	return format(new Date(time), 'hh:mm a')
}
