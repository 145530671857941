import { ReactNode } from 'react'

interface ListProps {
	children: ReactNode
	heading?: string
}

export default function List({ children, heading }: ListProps) {
	return (
		<div className="space-y-1 outline-none" tabIndex={-1}>
			{heading && <span className="px-3.5 text-gray-500 text-xs font-medium">{heading}</span>}

			<ul tabIndex={-1} className="outline-none">
				{children}
			</ul>
		</div>
	)
}
