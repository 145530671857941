export const processMentions = (
	mentions: string,
	linkify = false,
	markdown = false,
	onFormatUserId?: (id: string) => string
) => {
	const orgUserIds: string[] = []
	const messageForUser: Record<string, string> = {}

	let value = ''
	let plainValue = ''
	let i = 0
	let mentionedUserId = ''
	let message = ''

	while (i < mentions.length) {
		const char = mentions.charAt(i)

		if (char === '@' && mentions.charAt(i + 1) === '[') {
			// take out the mention
			// eg: @[DISPLAY HERE]:(ID) ...rest of value
			// find index up to colon separator
			let endIdx = mentions.indexOf(':', i)
			// then find up to space
			endIdx = mentions.indexOf(')', endIdx) + 1

			// extract mention and move i by length of mention
			const mention = mentions.substring(i, endIdx)
			i += mention.length

			// get orgUser id from mention
			const parts = mention.split(':')
			if (parts.length < 2) {
				continue
			}

			// remove the parentheses on both ends
			const id = parts[1].substring(1, parts[1].length - 1)

			// add to orgUserIds array
			if (id) {
				orgUserIds.push(id)

				const name = onFormatUserId?.(id) ?? id

				if (linkify) {
					value += markdown
						? `[${name}](/tasks?u=${id})`
						: `<a href="/tasks?u=${id}">${name}</a>`
				} else {
					value += name
				}

				mentionedUserId = id
				plainValue += name
			}
			continue
		}

		value += char
		plainValue += char
		++i

		if (mentionedUserId) {
			message += char
		}

		if (mentionedUserId && (char === '\n' || i >= mentions.length)) {
			messageForUser[mentionedUserId] = message.trim()
			message = ''
			mentionedUserId = ''
		}
	}

	return {
		mentionedOrgUserIds: orgUserIds,
		value,
		messageForUser,
		plainValue,
	}
}
