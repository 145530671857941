export function PlusCircleIcon({ size = 20 }: { size?: number }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
		>
			<g id="plus-circle, add">
				<path
					id="Icon"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M1.66699 10.0013C1.66699 5.39893 5.39795 1.66797 10.0003 1.66797C14.6027 1.66797 18.3337 5.39893 18.3337 10.0013C18.3337 14.6037 14.6027 18.3346 10.0003 18.3346C5.39795 18.3346 1.66699 14.6037 1.66699 10.0013ZM13.3337 10.8345C13.7939 10.8345 14.167 10.4614 14.167 10.0012C14.167 9.54095 13.7939 9.16787 13.3336 9.16788L10.8337 9.16795V6.66807C10.8337 6.20783 10.4606 5.83474 10.0003 5.83474C9.54009 5.83474 9.16699 6.20783 9.16699 6.66807V9.168L6.66697 9.16807C6.20673 9.16808 5.83365 9.54119 5.83366 10.0014C5.83367 10.4617 6.20678 10.8347 6.66702 10.8347L9.16699 10.8347V13.3346C9.16699 13.7949 9.54009 14.168 10.0003 14.168C10.4606 14.168 10.8337 13.7949 10.8337 13.3346V10.8346L13.3337 10.8345Z"
					fill="#0091FF"
				/>
			</g>
		</svg>
	)
}
