export async function result<Type>(
	promise: Promise<Type>
): Promise<[Type | undefined, Error | undefined]> {
	try {
		const res = await promise
		return [res, undefined]
	} catch (err) {
		return [undefined, err instanceof Error ? err : new Error(`${err}`)]
	}
}
