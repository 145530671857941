import { IFunnel } from '@touchpoints/requests'
import { authHeader, del, get, post } from './api'

export async function getAllFunnels(orgId: string) {
	const headers = await authHeader()
	return get<{ funnels: IFunnel[] }>(`/organizations/${orgId}/funnels`, {}, headers)
}

export async function addFunnel(orgId: string, data: Partial<Omit<IFunnel, 'id'>>) {
	const headers = await authHeader()
	return post<{ funnel: IFunnel }>(`/organizations/${orgId}/funnels`, data, headers)
}

export async function updateFunnel(orgId: string, id: string, data: Partial<Omit<IFunnel, 'id'>>) {
	const headers = await authHeader()
	return post<{ funnel: IFunnel }>(`/organizations/${orgId}/funnels/${id}`, data, headers)
}

export async function getFunnelById(orgId: string, id: string) {
	const headers = await authHeader()
	return get<{ funnel: IFunnel }>(`/organizations/${orgId}/funnels/${id}`, {}, headers)
}

export async function deleteFunnel(orgId: string, id: string) {
	const headers = await authHeader()
	return del(`/organizations/${orgId}/funnels/${id}`, {}, headers)
}

export async function getFunnelData(
	orgId: string,
	id: string,
	accountId: string | undefined,
	positionIds: string[]
) {
	const headers = await authHeader()
	const params: Record<string, string | number | boolean | undefined | string[]> = {}

	if (accountId) {
		params['accountId'] = accountId
	} else {
		params['positionIds'] = positionIds
	}

	return get<{ countByStep: Record<string, Record<string, number>> }>(
		`/organizations/${orgId}/funnels/${id}/data`,
		params,
		headers
	)
}
