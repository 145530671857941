export function AddCandidateIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 20 20"
			fill="none"
		>
			<g id="add people, add user, add person">
				<path
					id="Icon"
					d="M11.6668 11.0416C11.1441 10.9057 10.5868 10.8333 10.0001 10.8333C6.83708 10.8333 4.52834 12.9362 3.89304 15.7631C3.78537 16.2422 4.17439 16.6667 4.66543 16.6667H9.16675M15.0001 12.5V15M15.0001 15V17.5M15.0001 15H12.5001M15.0001 15H17.5001M12.9168 5.41667C12.9168 7.0275 11.6109 8.33333 10.0001 8.33333C8.38925 8.33333 7.08342 7.0275 7.08342 5.41667C7.08342 3.80584 8.38925 2.5 10.0001 2.5C11.6109 2.5 12.9168 3.80584 12.9168 5.41667Z"
					stroke="#12A594"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	)
}
