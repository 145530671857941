const TOUCHPOINTS_TOKEN = 'touchpoints-local-data'

const hasWindow = typeof window !== 'undefined'

type LocalData = {
	currentOrgId?: string
	orgSettings: Record<string, any>
	blueprints: {
		detailed?: boolean
	}
	candidatesTable?: {
		rowsPerPage?: number
	}
	viewMode?: string
	recentPositionCandidateIds?: string[]
}

const cachedLocalData = _get()

export function store(data: Partial<LocalData>) {
	if (!hasWindow) {
		return
	}

	try {
		localStorage.setItem(
			TOUCHPOINTS_TOKEN,
			JSON.stringify({
				...get(),
				...data,
			})
		)
	} catch (err) {
		console.error(err)
	}
}

function _get(): LocalData {
	if (!hasWindow) {
		return { orgSettings: {}, blueprints: {} }
	}

	const data = JSON.parse(localStorage.getItem(TOUCHPOINTS_TOKEN) ?? '{}') as LocalData
	if (!data.orgSettings) {
		data.orgSettings = {}
	}
	if (!data.blueprints) {
		data.blueprints = {}
	}
	return data
}

export function get() {
	if (cachedLocalData) {
		return cachedLocalData
	}
	return _get()
}

export function remove() {
	if (!hasWindow) {
		return
	}

	return localStorage.removeItem(TOUCHPOINTS_TOKEN)
}
