import {
	createCandidateAttachmentUrl,
	createPositionCandidateAttachmentUrl,
} from '@touchpoints/utils'
import { authHeader, get, post, del } from './api'

import type { ICandidateAttachment } from '@touchpoints/requests'

export const positionCandidateAttachmentUrl = (
	pcId: string,
	attachmentId: string,
	filename: string
) => {
	return createPositionCandidateAttachmentUrl(
		process.env.NEXT_PUBLIC_S3_BUCKET,
		pcId,
		attachmentId,
		filename
	)
}

export const candidateAttachmentUrl = (
	candidateId: string,
	attachmentId: string,
	filename: string
) => {
	return createCandidateAttachmentUrl(
		process.env.NEXT_PUBLIC_S3_BUCKET,
		candidateId,
		attachmentId,
		filename
	)
}

export async function fetchAllResumes(organizationId: string) {
	const headers = await authHeader()
	return get<{ attachments: ICandidateAttachment[] }>(
		`/organizations/${organizationId}/candidates/resumes`,
		undefined,
		headers
	)
}

export async function getAllResumesForCandidate(organizationId: string, candidateId: string) {
	const headers = await authHeader()
	return get<{ attachments: ICandidateAttachment[] }>(
		`/organizations/${organizationId}/candidates/${candidateId}/resumes`,
		undefined,
		headers
	)
}

export async function addResumeToCandidate(orgId: string, candidateId: string, data: FormData) {
	const headers = await authHeader()
	return post<{ success: boolean; message?: string; doc?: ICandidateAttachment }>(
		`/organizations/${orgId}/candidates/${candidateId}/resume`,
		data,
		headers
	)
}

export async function deleteCandidateAttachment(
	orgId: string,
	candidateId: string,
	attachmentId: string
) {
	const headers = await authHeader()
	return del<{ success: boolean; message?: string }>(
		`/organizations/${orgId}/candidates/${candidateId}/resume/${attachmentId}`,
		headers
	)
}
