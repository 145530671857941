import Fuse from 'fuse.js'
import { isEqual } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

export function useFuse<ItemType>(
	items: ItemType[],
	keys: (string | { name: string; weight?: number })[]
) {
	const [_keys, setKeys] = useState(keys)

	useEffect(() => {
		if (isEqual(keys, _keys)) {
			return
		}

		setKeys(keys)
	}, [keys, _keys])

	const fuse = useMemo(() => {
		return new Fuse(items, {
			keys: _keys,
			threshold: 0.3,
			includeScore: true,
		})
	}, [items, _keys]) as Fuse<ItemType>

	return fuse
}

export function useSearch<ItemType>(items: ItemType[], keys: string[]) {
	const [searchQuery, setSearchQuery] = useState('')
	const [results, setResults] = useState<ItemType[]>([])
	const fuse = useFuse(items, keys)

	useEffect(() => {
		if (searchQuery) {
			const fuseResults = fuse.search(searchQuery)
			const quickSearchResults = fuseResults.map((c) => c.item)

			setResults(quickSearchResults)
			return
		}

		setResults(items)
	}, [fuse, items, searchQuery])

	return [results, searchQuery, setSearchQuery] as const
}
