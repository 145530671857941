import { rootStore } from '@store/index'
import { useReaction } from './mobx'
import { useMemo } from 'react'
import { isSimilar } from '@services/color'

export function useSimplifiedStageColors(stageIds?: string[]) {
	const stages = useReaction(
		() => {
			if (!stageIds) {
				return rootStore.stages.candidates.map((stage) => ({ ...stage }))
			} else {
				return stageIds
					.map((id) => rootStore.stages.candidatesStageById[id])
					.filter((v) => !!v)
			}
		},
		100,
		[stageIds]
	)
	const colors = useMemo(() => {
		const list: string[] = []
		if (!stages) {
			return list
		}

		if (!stageIds) {
			list.push('#000000')
		}

		for (const stage of stages) {
			const color = stage.color ?? '#000000'

			const similarC = list.find((c) => isSimilar(color, c))

			if (similarC) {
				continue
			}

			list.push(color)
		}
		return list
	}, [stages, stageIds])

	return colors
}

export function useStageOptions(includeNone = true) {
	const options = useReaction<{ value: string; label: string; color?: string }[]>(() => {
		return [
			...rootStore.stages.candidates.map((stage) => ({
				value: stage.id,
				label: stage.name,
				color: stage.color,
			})),
			...(includeNone ? [{ value: '', label: 'None' }] : []),
		]
	})

	return options
}
