import {
	addCandidateStage,
	removeCandidateStage,
	updateCandidateStage,
} from '@requests/organizations'
import { ICandidateStage } from '@touchpoints/requests'
import { makeAutoObservable, reaction, runInAction } from 'mobx'
import type { RootStore } from './root'

const sortFunc = (a: ICandidateStage, b: ICandidateStage) => {
	if (a.name < b.name) {
		return -1
	} else if (a.name > b.name) {
		return 1
	}
	return 0
}

export class StagesStore {
	private readonly rootStore: RootStore
	candidates: ICandidateStage[] = []

	private candidatesSet = new Set<string>()

	activeCandidateStageId = ''

	get candidatesStageById() {
		const out: Record<string, ICandidateStage> = {}
		this.candidates.forEach((stage) => {
			out[stage.id] = stage
		})
		return out
	}

	get activeCandidateStage() {
		return this.candidates.find((s) => s.id === this.activeCandidateStageId)
	}

	constructor(root: RootStore) {
		this.rootStore = root

		makeAutoObservable(this)

		reaction(
			() => this.rootStore.organizations.activeOrganization,
			(org) => {
				if (!org) {
					return
				}

				org.candidateStages
					.slice()
					.sort(sortFunc)
					.forEach((stage) => {
						this._addCandidate(stage)
					})
			}
		)
	}

	setActiveCandidateStageId(id: string) {
		this.activeCandidateStageId = id
	}

	async addCandidate(
		stage: Partial<Omit<ICandidateStage, 'id'>> & Pick<ICandidateStage, 'name'>
	) {
		if (this.candidatesSet.has(stage.name)) {
			return
		}

		const orgId = this.rootStore.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await addCandidateStage(orgId, [stage])
		if (!res.success) {
			return
		}

		const { organization } = res.data ?? {}
		if (!organization) {
			return
		}

		const addedStage = organization.candidateStages.find((s) => s.name === stage.name)
		if (!addedStage) {
			return
		}

		this._addCandidate(addedStage)
	}

	private _addCandidate(stage: ICandidateStage) {
		if (this.candidatesSet.has(stage.name)) {
			return
		}

		this.candidates.push(stage)
		const sorted = this.candidates.slice().sort(sortFunc)
		this.candidates.splice(0, this.candidates.length, ...sorted)
		this.candidatesSet.add(stage.name)
	}

	async updateCandidates(data: Record<string, Omit<ICandidateStage, 'id' | 'name'>>) {
		const orgId = this.rootStore.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await updateCandidateStage(
			orgId,
			Object.entries(data).map(([id, stage]) => {
				const existing = this.candidatesStageById[id]
				return { ...stage, id, name: existing.name }
			})
		)

		if (!res.success) {
			return
		}

		const { organization } = res.data ?? {}
		if (!organization) {
			return
		}

		runInAction(() => {
			this.candidates.length = 0
			this.candidatesSet.clear()
			organization.candidateStages.forEach((s) => {
				this._addCandidate(s)
			})
		})

		this.rootStore.organizations.activeOrganization?.update(organization)
	}

	async updateCandidate(
		id: string,
		stage: Partial<Omit<ICandidateStage, 'id'>> & Pick<ICandidateStage, 'name'>
	) {
		const orgId = this.rootStore.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const idx = this.candidates.findIndex((s) => s.id === id)
		if (idx < 0) {
			return
		}

		const old = this.candidates[idx]
		if (!old) {
			return
		}

		const res = await updateCandidateStage(orgId, [{ ...stage, id: old.id }])
		if (!res.success) {
			return
		}

		const { organization } = res.data ?? {}
		if (!organization) {
			return
		}

		runInAction(() => {
			this.candidates.length = 0
			this.candidatesSet.clear()
			organization.candidateStages.forEach((s) => {
				this._addCandidate(s)
			})
		})

		this.rootStore.organizations.activeOrganization?.update(organization)
	}

	async removeCandidate(stage: ICandidateStage) {
		const orgId = this.rootStore.organizations.activeOrganizationId
		if (orgId) {
			await removeCandidateStage(orgId, [stage])
		}

		const idx = this.candidates.findIndex((s) => s.name === stage.name)
		if (idx >= 0) {
			this.candidates.splice(idx, 1)
		}
		this.candidatesSet.delete(stage.name)
	}
}
