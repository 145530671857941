export function TasksIcon({ size = 14 }: { size?: number }) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 13 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.33331 9V1.66667C1.33331 1.29848 1.63179 1 1.99998 1H10.7543C11.2868 1 11.6044 1.59343 11.309 2.03647L9.57985 4.6302C9.43056 4.85413 9.43056 5.14587 9.57985 5.3698L11.309 7.96353C11.6044 8.40657 11.2868 9 10.7543 9H1.33331ZM1.33331 9V13"
				stroke="#687076"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
