import {
	addMeeting,
	fetchMeetings,
	MeetingPreviewOptions,
	previewMeeting,
	removeMeeting,
	updateMeeting,
} from '@requests/meetings'
import type { IMeeting, IMeetingTriggerAction, MeetingRole } from '@touchpoints/requests'
import { autorun, makeAutoObservable } from 'mobx'
import type { RootStore } from './root'

class Meeting implements IMeeting {
	id = ''
	organizationId = ''
	name = ''
	createdBy = ''
	title = ''
	location = ''
	description = ''
	duration = 30 * 60 * 1000
	interval = 30
	ownerId = ''
	localId = 0
	assignToRole: MeetingRole = 'recruiter'
	triggers: IMeetingTriggerAction[] = []

	constructor(data: IMeeting) {
		this.update(data)

		makeAutoObservable(this)
	}

	update(data: IMeeting) {
		this.id = data.id
		this.organizationId = data.organizationId
		this.name = data.name
		this.createdBy = data.createdBy
		this.title = data.title ?? ''
		this.location = data.location ?? ''
		this.description = data.description ?? ''
		this.duration = data.duration ?? 30 * 60 * 1000
		this.interval = data.interval ?? 30
		this.ownerId = data.ownerId
		this.localId = data.localId
		this.assignToRole = (data.assignToRole ?? 'recruiter') as MeetingRole
		this.triggers = data.triggers ?? []
	}
}

export class MeetingsStore {
	private readonly root: RootStore

	readonly list: Meeting[] = []

	private _activeMeetingId: string | null = null
	private _activeTeamId: string | null = null

	get activeMeetingId() {
		return this._activeMeetingId
	}

	get activeTeamId() {
		return this._activeTeamId
	}

	get activeMeeting() {
		if (!this.activeMeetingId) {
			return null
		}

		return this.getById(this.activeMeetingId)
	}

	get activeTeam() {
		if (!this.activeTeamId) {
			return null
		}
		return this.root.teams.getTeamById(this.activeTeamId)
	}

	constructor(root: RootStore) {
		this.root = root

		makeAutoObservable(this)

		autorun(async () => {
			await this.fetchMeetings()
		})
	}

	setActiveMeetingId(id: string | null) {
		this._activeMeetingId = id
	}

	setActiveTeamId(id: string | null) {
		this._activeTeamId = id
	}

	async fetchMeetings() {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await fetchMeetings(orgId)
		if (!res.success) {
			return
		}

		const { meetings } = res.data ?? {}
		if (!meetings) {
			return
		}

		this.clearMeetings()
		for (const meeting of meetings) {
			this.addMeeting(meeting)
		}
	}

	async createMeeting(name: string) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await addMeeting(orgId, name)

		if (!res.success) {
			return
		}

		const { meeting } = res.data ?? {}
		if (!meeting) {
			return
		}

		return this.addMeeting(meeting)
	}

	async updateMeeting(id: string, data: Partial<IMeeting>) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await updateMeeting(orgId, id, data)

		if (!res.success) {
			return
		}

		const { meeting } = res.data ?? {}
		if (!meeting) {
			return
		}

		const existingMeeting = this.getById(id)
		existingMeeting?.update(meeting)
	}

	async deleteMeeting(id: string) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await removeMeeting(orgId, id)

		if (!res.success) {
			return
		}

		this.removeMeeting(id)
	}

	getById(id: string) {
		return this.list.find((t) => t.id === id)
	}

	async preview(meetingId: string, opts: MeetingPreviewOptions) {
		const orgId = this.root.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await previewMeeting(orgId, meetingId, opts)
		if (!res.success) {
			return
		}

		if (!res.data) {
			return
		}

		return res.data
	}

	getCalendarBaseUrl(org: { slug: string }, meeting: { localId: number }) {
		return `${process.env.NEXT_PUBLIC_APP_URL}/book/${org.slug}/m/${meeting.localId}/t/`
	}

	private addMeeting(meeting: IMeeting) {
		const m = new Meeting(meeting)
		this.list.push(m)
		return m
	}

	private removeMeeting(id: string) {
		const idx = this.list.findIndex((t) => t.id === id)
		if (idx < 0) {
			return
		}
		this.list.splice(idx, 1)
	}

	private clearMeetings() {
		while (this.list.length > 0) {
			this.list.pop()
		}
	}
}
