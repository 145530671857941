import { ICandidate, IPositionCandidate } from '@touchpoints/requests'
import type { RootStore } from './root'
import {
	getCandidatesThatNeedCleaning,
	getPositionsNeedCleaning,
	markCandidateAsCleaned,
} from '@requests/cleaning'
import { ObservableSet, autorun, makeAutoObservable, runInAction } from 'mobx'
import type { Position } from './positions'

export class CleaningStore {
	private readonly root: RootStore

	_list: ICandidate[] = []

	positionCandidates: IPositionCandidate[] = []

	totalCount = 0

	loading = false

	activePositionId = ''

	private positionIds = new ObservableSet<string>()

	private hasMore = false
	private page = 0

	constructor(root: RootStore) {
		this.root = root

		makeAutoObservable(this)

		autorun(() => {
			if (root.organizations.activeOrganizationId) {
				this.fetchPositions()
			}
		})
	}

	get list() {
		return this._list.slice()
	}

	get positions() {
		return Array.from(this.positionIds)
			.map((id) => this.root.positions.getPositionById(id))
			.filter((p) => !!p) as Position[]
	}

	setActivePositionId(id: string) {
		this.activePositionId = id

		this.fetchCandidates(false, true)
	}

	async fetchPositions() {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await getPositionsNeedCleaning(orgId)
		if (!res.success) {
			return
		}

		const { positions = [] } = res.data ?? {}
		runInAction(() => {
			for (const p of positions) {
				this.positionIds.add(p.id)
				this.root.positions.addPosition(p)
			}
		})
	}

	async fetchCandidates(nextPage = false, force = false, filter?: string) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		if (!force && this.list.length > 0 && !this.hasMore) {
			return
		}

		this.loading = true
		const res = await getCandidatesThatNeedCleaning(
			orgId,
			nextPage ? this.page + 1 : undefined,
			this.activePositionId,
			filter
		)
		if (!res.success) {
			this.loading = false
			return
		}

		const {
			candidates = [],
			hasNextPage = false,
			totalItems = 0,
			currentPage = 1,
		} = res.data ?? {}

		this.hasMore = hasNextPage
		this.totalCount = totalItems
		this.page = currentPage

		runInAction(() => {
			if (currentPage <= 1) {
				this._list.length = 0
			}

			for (const c of candidates) {
				const candidate = this.root.candidates.addCandidate(c.candidate)
				this._list.push(candidate)

				for (const pc of c.positionCandidates) {
					this.positionIds.add(pc.positionId)
					const positionCandidate = this.root.positions.addPositionCandidate(pc)
					if (
						positionCandidate &&
						positionCandidate.stage ===
							this.root.organizations.activeOrganization?.sourcingSettings
								?.stageWhenCandidateIsAdded
					) {
						this.positionCandidates.push(positionCandidate)
					}
				}
			}
		})

		this.loading = false
	}

	async markCleaned(
		candidateId: string,
		data: Pick<
			ICandidate,
			'firstName' | 'lastName' | 'email' | 'additionalEmails' | 'recentEmployer'
		>
	) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return { success: false, error: 'no active organization' }
		}

		const res = await markCandidateAsCleaned(orgId, candidateId, data)

		if (!res.success) {
			return { success: false, error: res.message }
		}

		const { candidate } = res.data ?? {}

		if (!candidate) {
			return { success: false, error: 'unknown error' }
		}

		// this will update the candidate if it already exists
		this.root.candidates.addCandidate(candidate)

		// remove from list
		let index = this.list.findIndex((c) => c.id === candidate.id)
		if (index >= 0) {
			runInAction(() => {
				const idx = this._list.findIndex((c) => c.id === candidate.id)
				this._list.splice(idx, 1)
			})
		}

		if (this._list.length === 0 && this.hasMore) {
			await this.fetchCandidates(true)
			index = 0
		}

		const nextCandidate = this.list[index] ?? this.list[0]

		return { success: true, nextCandidate }
	}

	getNextCandidate(currentCandidateId: string) {
		const idx = this.list.findIndex((c) => c.id === currentCandidateId)
		if (idx < 0) {
			return undefined
		}

		const nextCandidate = this.list[idx + 1] ?? this.list[0]

		return nextCandidate
	}

	async skipCandidate(candidateId: string) {
		let idx = this.list.findIndex((c) => c.id === candidateId)
		if (idx < 0) {
			return { success: false }
		}

		idx += 1

		if (this.list.length === 0 && this.hasMore) {
			await this.fetchCandidates(true)
			idx = 0
		}

		const nextCandidate = this.list[idx] ?? this.list[0]

		return { success: true, nextCandidate }
	}
}
