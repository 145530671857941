import { observer } from 'mobx-react-lite'

import { SlidePanel } from '@touchpoints/ui'

import { rootStore } from '@store'
import { ICandidate } from '@touchpoints/requests'
import { useEffect, useState } from 'react'
import { useWindowSize } from 'usehooks-ts'
import { CandidatePanel } from './CandidatePanel'
import { candidateSlidingPanelStore } from './store'

export const CandidateSlidingPanel = observer(function () {
	const [candidate, setCandidate] = useState<ICandidate | undefined>(undefined)
	const winSize = useWindowSize()
	const candidateId = candidateSlidingPanelStore.activeCandidateId

	useEffect(() => {
		const getCandidate = async () => {
			const response = await rootStore.candidates.getCandidateByIdAsync(candidateId)
			if (response) {
				setCandidate(response)
			}
		}
		if (candidateId) {
			getCandidate()
		} else {
			setCandidate(undefined)
		}
	}, [candidateId])

	const width = winSize.width <= 1024 ? winSize.width * 0.8 : winSize.width * 0.6

	return (
		<>
			{candidate && (
				<SlidePanel
					width={`${width}px`}
					isOpen={candidateSlidingPanelStore.isOpen}
					onRequestClose={() => candidateSlidingPanelStore.close()}
					hideHeader
					panelContenClassName="p-0"
				>
					<CandidatePanel
						candidate={candidate}
						onDeleted={() => candidateSlidingPanelStore.close()}
					/>
				</SlidePanel>
			)}
		</>
	)
})
