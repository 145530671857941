export function LinkChainIcon({ size = 17 }: { size?: number }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 17 16"
			fill="none"
		>
			<g id="link 2, chain">
				<path
					id="Icon"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M1.16699 5.33333C1.16699 4.59695 1.76395 4 2.50033 4H5.83366C6.20185 4 6.50033 4.29848 6.50033 4.66667C6.50033 5.03486 6.20185 5.33333 5.83366 5.33333H2.50033V10.6667H5.83366C6.20185 10.6667 6.50033 10.9651 6.50033 11.3333C6.50033 11.7015 6.20185 12 5.83366 12H2.50033C1.76395 12 1.16699 11.403 1.16699 10.6667V5.33333ZM10.5003 4.66667C10.5003 4.29848 10.7988 4 11.167 4H14.5003C15.2367 4 15.8337 4.59695 15.8337 5.33333V10.6667C15.8337 11.403 15.2367 12 14.5003 12H11.167C10.7988 12 10.5003 11.7015 10.5003 11.3333C10.5003 10.9651 10.7988 10.6667 11.167 10.6667H14.5003V5.33333H11.167C10.7988 5.33333 10.5003 5.03486 10.5003 4.66667ZM4.50033 8C4.50033 7.63181 4.7988 7.33333 5.16699 7.33333H11.8337C12.2018 7.33333 12.5003 7.63181 12.5003 8C12.5003 8.36819 12.2018 8.66667 11.8337 8.66667H5.16699C4.7988 8.66667 4.50033 8.36819 4.50033 8Z"
					fill="#889096"
				/>
			</g>
		</svg>
	)
}
