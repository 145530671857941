import ReactSelect, { SingleValue, components } from 'react-select'
import type { OptionProps, ControlProps, MultiValueGenericProps } from 'react-select'
import Creatable from 'react-select/creatable'
import { test } from 'fuzzyjs'
import AsyncSelect from 'react-select/async'

import type { Props, GroupBase } from 'react-select'
import type { CreatableProps } from 'react-select/creatable'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

const isSSR = () => typeof window === 'undefined'

export type SelectProps = {
	label?: string | JSX.Element
	fullWidth?: boolean
	width?: string
	loadOptions?: (inputValue: string, callback: (options: any[]) => void) => void
	cacheOptions?: boolean
	defaultOptions?: boolean
	isDark?: boolean
	wrapperClassName?: string
}
export type AutocompleteProps = {
	filterKeys?: string[]
}

export function createSelectOnChange<T extends string | number | undefined = string>(
	func: (v: T) => void
) {
	return (s: SingleValue<T | { value: T }>) => {
		if (s === undefined || s === null) {
			return
		}

		if (typeof s === 'object') {
			func(s.value)
			return
		}

		func(s)
	}
}

export function Select<OptionType, IsMulti extends boolean = false>({
	// NOTE: nested paths are not supported
	filterKeys = [],
	fullWidth = true,
	label,
	width,
	loadOptions,
	isDark = false,
	wrapperClassName,
	...props
}: Props<OptionType, IsMulti> & SelectProps & AutocompleteProps) {
	const [isMounted, setIsMounted] = useState(false)

	// Must be deleted once
	// https://github.com/JedWatson/react-select/issues/5459 is fixed.
	useEffect(() => setIsMounted(true), [])

	const menuPortalTarget = isSSR() ? undefined : document.body
	const styles = {
		container: (base: any) => ({
			...base,
			width: fullWidth ? '100%' : width,
		}),
		control: (base: any) => ({
			...base,
			borderRadius: '8px',
			paddingTop: '1.5px',
			paddingBottom: '1.5px',
			width: '100%',
			outline: '1px solid #dfe3e6',
		}),
		menuPortal: (base: any) => ({ ...base, zIndex: 10000 }),
	}

	if (!isMounted) {
		return null
	}

	return (
		<div className={clsx('flex flex-col w-full p-0', wrapperClassName)}>
			{label && (
				<div
					className={clsx('text-sm text-gray-800 mb-[6px] ml-1', {
						'dark:text-white': isDark,
					})}
				>
					{label}
				</div>
			)}
			{loadOptions ? (
				<AsyncSelect
					styles={styles}
					loadOptions={loadOptions}
					menuPortalTarget={menuPortalTarget}
					{...props}
				/>
			) : (
				<ReactSelect
					styles={styles}
					filterOption={(option, _input) => {
						const input = _input.trim()
						if (!input || !option) {
							return true
						}

						if (filterKeys.length <= 0) {
							return test(input, option.label) || test(input, option.value ?? '')
						}

						for (const key of filterKeys) {
							if (!(key in (option.data as unknown as object))) {
								continue
							}

							if (
								test(
									input,
									(option.data as unknown as Record<string, any>)[key] ?? ''
								)
							) {
								return true
							}
						}

						return false
					}}
					menuPortalTarget={menuPortalTarget}
					{...props}
				/>
			)}
		</div>
	)
}

export function CreatableSelect<OptionType, IsMulti extends boolean = false>({
	fullWidth = true,
	...props
}: CreatableProps<OptionType, IsMulti, GroupBase<OptionType>> & SelectProps) {
	return (
		<Creatable
			styles={{
				container: (base) => ({ ...base, width: fullWidth ? '100%' : undefined }),
				menuPortal: (base) => ({ ...base, zIndex: 10000 }),
			}}
			menuPortalTarget={document.body}
			isClearable
			{...props}
			getOptionLabel={(option) => {
				if (
					'__isNew__' in (option as unknown as object) &&
					'label' in (option as unknown as object)
				) {
					return (option as any).label
				}
				return props.getOptionLabel?.(option)
			}}
		/>
	)
}

export {
	components as SelectComponents,
	OptionProps as SelectOptionProps,
	ControlProps as SelectControlProps,
	MultiValueGenericProps as SelectMultiValueProps,
}
