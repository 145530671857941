import { makeAutoObservable } from 'mobx'
import { getUser, removeUserMailbox, updateUser, updateUserMailbox } from '@requests/users'

import type { IUser, Mailbox } from '@touchpoints/requests'

class User implements IUser {
	firstName = ''
	lastName = ''
	id = ''
	email = ''
	mailboxes: Mailbox[] = []

	linkedInProfileUrl?: string
	phone?: string
	timezone?: string

	constructor(data: IUser) {
		makeAutoObservable(this)

		this.update(data)
	}

	update(data: IUser) {
		this.id = data.id
		this.firstName = data.firstName ?? ''
		this.lastName = data.lastName ?? ''
		this.email = data.email
		this.mailboxes = [...(data.mailboxes ?? [])]
		this.linkedInProfileUrl = data.linkedInProfileUrl
		this.phone = data.phone
		this.timezone = data.timezone
	}

	async saveMailbox(email: string, data: Partial<Pick<Mailbox, 'signature' | 'constraints'>>) {
		const res = await updateUserMailbox(email, data)
		if (!res.success) {
			return false
		}

		const idx = this.mailboxes.findIndex((box) => box.email === email)
		if (idx < 0) {
			return
		}

		this.mailboxes[idx].signature = data.signature
		this.mailboxes[idx].constraints = data.constraints
	}

	async removeMailbox(email: string) {
		const res = await removeUserMailbox(email)
		if (!res.success) {
			return false
		}

		const idx = this.mailboxes.findIndex((box) => box.email === email)
		if (idx < 0) {
			return
		}

		this.mailboxes.splice(idx, 1)
	}
}

export class UsersStore {
	loggedInUserId = ''

	users: Record<string, User | undefined> = {}

	private _loading = true

	get list() {
		return Object.values(this.users)
	}

	get loggedInUser() {
		return this.users[this.loggedInUserId]
	}

	get isLoading() {
		return this._loading
	}

	constructor() {
		makeAutoObservable(this)
	}

	async fetchLoggedInUser() {
		this.setLoading(true)
		const res = await getUser()
		this.setLoading(false)

		if (!res.success || !res.data) {
			return
		}

		const user = res.data

		this.setLoggedInUser(user.id)
		this.addUser(user.id, user)
	}

	async updateLoggedInUser(changes: Pick<IUser, 'lastName' | 'firstName'>) {
		const res = await updateUser(changes)
		if (!res.success || !res.data) {
			return false
		}

		const user = res.data
		this.loggedInUser?.update(user)
		return true
	}

	private setLoggedInUser(id: string) {
		this.loggedInUserId = id
	}

	private setLoading(v: boolean) {
		this._loading = v
	}

	addUser(id: string, data: IUser) {
		this.users[id] = new User(data)
	}
}
