import {
	FloatingFocusManager,
	Placement,
	autoUpdate,
	flip,
	offset,
	shift,
	useClick,
	useDismiss,
	useFloating,
	useId,
	useInteractions,
	useRole,
} from '@floating-ui/react'
import { Button, Spacer } from '@nextui-org/react'
import clsx from 'clsx'
import { compareAsc, format } from 'date-fns'
import { Label } from 'flowbite-react'
import { Dictionary, groupBy } from 'lodash'
import capitalize from 'lodash/capitalize'
import { useEffect, useState } from 'react'
import { HiArrowNarrowLeft, HiArrowNarrowRight, HiOutlineCalendar } from 'react-icons/hi'
import type { Spacetime } from 'spacetime'
import spacetime from 'spacetime'

export interface DateTimePickerProps {
	selected?: Date
	disabled?: boolean
	maxDate?: Date
	minDate?: Date
	onChange?: (date: Date) => void
	timeSelect?: boolean
	placement?: Placement
	withConfirmation?: boolean
	closeOnChange?: boolean
	dateFormat?: string
	showIcon?: boolean
	borderless?: boolean
	triggerClassName?: string
}
export function DateTimePicker({
	selected,
	disabled,
	minDate,
	maxDate,
	onChange,
	timeSelect = true,
	placement,
	withConfirmation = false,
	closeOnChange = false,
	dateFormat = 'MMM d',
	showIcon = true,
	borderless,
	triggerClassName,
}: DateTimePickerProps) {
	const [isOpen, setIsOpen] = useState(false)
	const dateFormatted = selected ? format(selected, dateFormat) : 'No date'
	const { refs, floatingStyles, context } = useFloating({
		placement: placement ? placement : 'bottom-start',
		open: isOpen,
		onOpenChange: (open) => {
			if (!disabled) setIsOpen(open)
		},
		middleware: [offset(4), flip({ fallbackAxisSideDirection: 'end' }), shift()],
		whileElementsMounted: autoUpdate,
	})

	const click = useClick(context)
	const dismiss = useDismiss(context, { ancestorScroll: true })
	const role = useRole(context)

	const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role])

	const headingId = useId()

	return (
		<>
			<div
				ref={refs.setReference}
				{...getReferenceProps()}
				className={clsx(
					'flex items-center space-x-1 rounded px-2 py-0.5',
					{
						'cursor-pointer hover:bg-[#f8f9fa]': !disabled,
						'cursor-not-allowed': disabled,
						'hover:bg-[#dfe3e6]': !!borderless,
						'shadow-sm border border-slate-100 hover:bg-slate-50': !borderless,
					},
					triggerClassName
				)}
			>
				{showIcon && (
					<>
						<HiOutlineCalendar /> &nbsp;&nbsp;
					</>
				)}
				{selected && <span>{dateFormatted}</span>}
			</div>
			{isOpen && (
				<FloatingFocusManager context={context} modal={true}>
					<div
						ref={refs.setFloating}
						style={{ zIndex: 100, ...floatingStyles }}
						aria-labelledby={headingId}
						{...getFloatingProps()}
					>
						<Calendar
							selected={selected}
							minDate={minDate}
							maxDate={maxDate}
							timeSelect={timeSelect}
							onChange={(date) => {
								onChange?.(date)
								if (closeOnChange) {
									setIsOpen(false)
								}
							}}
							withConfirmation={withConfirmation}
						/>
					</div>
				</FloatingFocusManager>
			)}
		</>
	)
}

// Generate an array of dates from min to max
function generateDays(min: Spacetime, max: Spacetime) {
	let current = min.clone()
	const days = [current]

	while (current.isBetween(min, max, true)) {
		current = current.add(1, 'day')
		days.push(current)
	}
	return days
}

/**
 * We should fill the dates for the previous/next month
 * so the amount of rows stays consistent within the calendar
 * */
function fillMonths(months: Dictionary<Date[]>) {
	const filledMonths: Date[][] = []
	Object.keys(months).forEach((key) => {
		const currentMonth = months[key]
		let from = spacetime(currentMonth[0]).startOf('month')
		let to = spacetime(currentMonth[0]).endOf('month')

		// Calendar starts on a sunday
		while (from.dayName() !== 'sunday') {
			from = from.add(-1, 'day')
		}
		// And ends on the saturday
		while (to.dayName() !== 'friday') {
			to = to.add(1, 'day')
		}

		const filledMonth = [from]
		let current = from.clone()

		do {
			current = current.add(1, 'day')
			filledMonth.push(current)
		} while (current.isBefore(to))

		filledMonths.push(filledMonth.map((e) => e.toNativeDate()))
	})
	return filledMonths
}

function getMostCommon(dates: Date[], labelFunc: (date: Date) => string) {
	if (!dates) return

	const grouped = groupBy(dates, (e) => labelFunc(e))
	return Object.keys(grouped)
		.map((key) => {
			const group = grouped[key]
			return { amount: group.length, label: labelFunc(group[0]) }
		})
		.sort((a, b) => b.amount - a.amount)[0].label
}

const DEFAULT_DAYS_WINDOW = 30
type CalendarProps = {
	selected?: Date
	minDate?: Date
	maxDate?: Date
	onChange?: (date: Date) => void
	timeSelect?: boolean
	withConfirmation?: boolean
}
export function Calendar({
	selected: inputDate,
	maxDate,
	minDate,
	onChange,
	timeSelect,
	withConfirmation,
}: CalendarProps) {
	const [selected, setSelected] = useState(inputDate)
	const [different, setDifferent] = useState(false)
	const [min, setMin] = useState<Spacetime | null>(null)
	const [max, setMax] = useState<Spacetime | null>(null)
	const [months, setMonths] = useState<Date[][] | null>(null)
	const [monthIndex, setMonthIndex] = useState(0)
	const [backDisabled, setBackDisabled] = useState<boolean>(true)
	const [forwardDisabled, setForwardDisabled] = useState<boolean>(true)
	const [currentMonth, setCurrentMonth] = useState<Date[] | null>(null)
	const [monthLabel, setMonthLabel] = useState<string | null>(null)
	const [yearLabel, setYearLabel] = useState<string | null>(null)

	useEffect(() => {
		const now = selected ? spacetime(selected) : spacetime.now()
		const tempMin = minDate
			? spacetime(minDate).add(-1, 'day')
			: now.add(-DEFAULT_DAYS_WINDOW, 'days')
		const tempMax = maxDate ? spacetime(maxDate) : now.add(DEFAULT_DAYS_WINDOW, 'days')

		setMin(tempMin)
		setMax(tempMax)
	}, [maxDate, minDate, selected])

	useEffect(() => {
		if (!min || !max) return

		const days = generateDays(min, max)
		const sorted = days.sort((a, b) => (a.isAfter(b) ? 1 : -1)).map((e) => e.toNativeDate())
		const grouped = fillMonths(groupBy(sorted, (a) => `${a.getMonth()}-${a.getFullYear()}`))
		setMonths(Object.values(grouped))
	}, [min, max])

	useEffect(() => {
		if (!months) {
			setBackDisabled(true)
			setForwardDisabled(true)
			return
		}

		setBackDisabled(monthIndex === 0)
		setForwardDisabled(monthIndex + 1 >= months?.length)
		setCurrentMonth(months[monthIndex])
	}, [monthIndex, months])

	useEffect(() => {
		if (!currentMonth) return

		// This is needed as the current month is filled with days from previous/next month
		const monthLabel = getMostCommon(currentMonth, (date) => spacetime(date).monthName())
		const yearLabel = getMostCommon(currentMonth, (date) => `${date.getFullYear()}`)
		setMonthLabel(monthLabel || '')
		setYearLabel(yearLabel || '')
	}, [currentMonth])

	useEffect(() => {
		if (!selected) {
			return
		}
		// Position the calendar in the selected date
		months?.forEach((month, index) => {
			const monthLabel = getMostCommon(month, (date) => spacetime(date).monthName())
			const yearLabel = getMostCommon(month, (date) => `${date.getFullYear()}`)
			const sel = spacetime(selected)
			if (monthLabel === sel.monthName() && yearLabel === `${selected.getFullYear()}`) {
				setMonthIndex(index)
				return
			}
		})
	}, [selected, months])

	useEffect(() => {
		if (selected && inputDate && compareAsc(selected, inputDate) !== 0) {
			setDifferent(true)
		} else {
			setDifferent(false)
		}
	}, [inputDate, selected])

	const handleBack = () => {
		setMonthIndex(monthIndex - 1)
	}

	const handleForward = () => {
		setMonthIndex(monthIndex + 1)
	}

	const handleSelectDate = (date: Spacetime) => {
		setSelected(date.time(spacetime(selected).time()).toNativeDate())
		if (!withConfirmation) {
			onChange?.(date.time(spacetime(selected).time()).toNativeDate())
		}
	}
	const handleSelectTime = (date: Date) => {
		setSelected(date)
		if (!withConfirmation) {
			onChange?.(date)
		}
	}
	const confirmUpdates = () => {
		if (selected) {
			onChange?.(selected)
		}
	}

	return (
		<div className="flex flex-col space-y-2 bg-white p-3 w-72 border rounded-lg shadow-xl z-[1000] cursor-default border-gray-100 dark:bg-gray-800 dark:border-gray-600">
			<div className="flex flex-col">
				<Label className="text-base font-medium dark:text-slate-200">Due Date</Label>
				<span className="border p-2 rounded-md dark:text-slate-200">
					{selected ? format(selected, 'MM.dd.yyyy') : ''}
				</span>
			</div>
			{timeSelect && (
				<>
					<div className="flex flex-col">
						<Label className="text-base font-medium dark:text-white">Time</Label>
						<TimeSelector selected={selected} onChange={handleSelectTime} />
					</div>
					<Spacer y={1} />
				</>
			)}

			<div className="flex justify-between items-center">
				<p className="text-base font-medium">
					{monthLabel && capitalize(monthLabel)} {yearLabel}
				</p>

				<div className="flex items-center space-x-2">
					<div
						onClick={() => {
							if (!backDisabled) handleBack()
						}}
						className={clsx('hover:cursor-pointer', {
							'hover:cursor-not-allowed text-slate-300': backDisabled,
						})}
					>
						<HiArrowNarrowLeft className="w-5 h-5 m-1 dark:text-white" />
					</div>
					<div
						onClick={() => {
							if (!forwardDisabled) handleForward()
						}}
						className={clsx('hover:cursor-pointer', {
							'hover:cursor-not-allowed text-slate-300': forwardDisabled,
						})}
					>
						<HiArrowNarrowRight className="w-5 h-5 m-1 dark:text-white" />
					</div>
				</div>
			</div>
			<div className="grid grid-cols-7 gap-1">
				<div className="w-1/7 text-center text-xs text-gray-400 aspect-12/10 flex items-center justify-center">
					Sun
				</div>
				<div className="w-1/7 text-center text-xs text-gray-400 aspect-12/10 flex items-center justify-center">
					Mon
				</div>
				<div className="w-1/7 text-center text-xs text-gray-400 aspect-12/10 flex items-center justify-center">
					Tue
				</div>
				<div className="w-1/7 text-center text-xs text-gray-400 aspect-12/10 flex items-center justify-center">
					Wed
				</div>
				<div className="w-1/7 text-center text-xs text-gray-400 aspect-12/10 flex items-center justify-center">
					Thu
				</div>
				<div className="w-1/7 text-center text-xs text-gray-400 aspect-12/10 flex items-center justify-center">
					Fri
				</div>
				<div className="w-1/7 text-center text-xs text-gray-400 aspect-12/10 flex items-center justify-center">
					Sat
				</div>
				{currentMonth &&
					currentMonth.map((day) => {
						const date = spacetime(day)
						const isSelectable = min && max ? date.isBetween(min, max) : false
						const isWithinMonth = monthLabel === date.monthName()
						const isSelectedDay =
							spacetime(selected).isSame(date, 'day') && isWithinMonth
						const dayNumber = date.date()

						return (
							<div
								className={clsx(
									'text-center text-xs flex items-center justify-center rounded-md py-2 px-2',
									{
										'cursor-pointer': isSelectable && isWithinMonth,
										'bg-calendarblue-500': isSelectedDay,
										'text-white': isSelectedDay,
										'hover:bg-calendarblue-500': isSelectable && isWithinMonth,
										'hover:text-white': isSelectable && isWithinMonth,
										'text-slate-300': !isSelectable || !isWithinMonth,
										'dark:text-slate-600': !isSelectable || !isWithinMonth,
									}
								)}
								key={date.format('nice')}
								onClick={() => {
									if (isSelectable) handleSelectDate(date)
								}}
							>
								{' '}
								{dayNumber}{' '}
							</div>
						)
					})}
			</div>
			{withConfirmation && (
				<div className="w-full">
					<Button
						aria-label="Confirm"
						bordered={false}
						auto
						disabled={!different}
						onClick={confirmUpdates}
						css={{ background: '#0091FF' }}
					>
						Confirm
						{/* {questionState.loading ? <Loading /> : 'Next'} */}
					</Button>
				</div>
			)}
		</div>
	)
}

const TIME_STEP_MINUTES = 15
export interface TimeSelectorProps {
	selected?: Date
	disabled?: boolean
	onChange?: (date: Date) => void
}
export function TimeSelector({ selected, onChange }: TimeSelectorProps) {
	const [open, setOpen] = useState(false)
	const [values, setValues] = useState<Spacetime[]>([])

	const dateFormatted = selected ? format(selected, 'hh:mm a') : 'No date'

	useEffect(() => {
		const d = spacetime(selected)
		let s = d.startOf('day')
		const values = [s]

		while (s.isSame(d, 'day')) {
			s = s.add(TIME_STEP_MINUTES, 'minutes')
			if (s.isSame(d, 'day')) values.push(s)
		}
		setValues(values)
	}, [selected])

	return (
		<div className="cursor-pointer">
			<div
				className="shadow-sm rounded-md p-2 border border-slate-100"
				onClick={() => setOpen(!open)}
			>
				{selected && <span>{dateFormatted}</span>}
			</div>
			{open && (
				<div className="flex flex-col bg-white p-3 w-64 max-h-[200px] overflow-y-auto border rounded-lg absolute mt-2 shadow-xl z-[1000] cursor-default border-gray-100 dark:bg-gray-800 dark:border-gray-600">
					{values &&
						values.map((time, index) => {
							return (
								<div
									key={`${time}-${index}`}
									className="hover:bg-calendarblue-500/10 cursor-pointer"
									onClick={() => {
										setOpen(false)
										onChange?.(time.toNativeDate())
									}}
								>
									{format(time.toNativeDate(), 'hh:mm a')}
								</div>
							)
						})}
				</div>
			)}
		</div>
	)
}
