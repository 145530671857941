import { observer } from 'mobx-react-lite'

import { Text, Tooltip } from '@nextui-org/react'

import { EmailAvatar } from '@components/shared/OrgUserAvatar'
import { convertHtmlToReact, convertNodeToReactElement } from '@hedgedoc/html-to-react'
import { formatDate } from '@services/time'
import { IThread } from '@touchpoints/requests'
import { formatTimeDifference } from '@touchpoints/time'
import { DividerWithText, SlidePanel, SlidePanelProps } from '@touchpoints/ui'
import { formatDistanceToNowStrict } from 'date-fns'
import { ReactElement, useState } from 'react'
import { HiDotsHorizontal } from 'react-icons/hi'

type CandidateEmailProps = Omit<SlidePanelProps, 'children'> & {
	thread: IThread
}

export const CandidateEmail = observer(function ({ thread, ...props }: CandidateEmailProps) {
	const parseContent = (htmlContent?: string): any => {
		if (!htmlContent) return

		const emailContent = document.createElement('span')
		emailContent.innerHTML = htmlContent

		const RemoveQuoteTransformer = (
			node: any,
			index: number | string
		): ReactElement | void | null | string => {
			const [open, setOpen] = useState(false)

			if (
				(node.name === 'div' && node.attribs.class === 'gmail_quote') ||
				(node.name === 'blockquote' && node.attribs.type === 'cite')
			) {
				return (
					<div key={index}>
						<HiDotsHorizontal
							onClick={() => {
								setOpen(!open)
							}}
						></HiDotsHorizontal>
						{!!open && convertNodeToReactElement(node, index)}
					</div>
				)
			}
		}

		return <div>{convertHtmlToReact(htmlContent, { transform: RemoveQuoteTransformer })}</div>
	}

	return (
		<SlidePanel
			title={
				<div className="flex justify-between items-center">
					<Text h4>{thread.subject}</Text>
				</div>
			}
			{...props}
		>
			<div>
				<p className="text-xl font-medium text-gray-700">{thread.subject || ''}</p>
				{thread.messages.map((message) => {
					return (
						<div key={message.id} className="borde flex my-3">
							<div className="flex-none">
								<EmailAvatar size="lg" email={message.from} />
							</div>
							<div className="flex-1">
								<div className="flex justify-between m-2">
									<p className="flex text-sm font-medium text-gray-900">
										{message.from}
									</p>
									<div className="flex-2">
										<Tooltip
											placement={'leftStart'}
											content={<p>{formatDate(message.date)}</p>}
										>
											<p className="flex text-sm font-light text-gray-700">
												{formatTimeDifference(message.date)} (
												{formatDistanceToNowStrict(new Date(message.date))}{' '}
												ago)
											</p>
										</Tooltip>
									</div>
								</div>

								<div className="flex flex-col space-y-2 p-3 w-full">
									{parseContent(message.htmlContent || message.textContent)}
								</div>
								<DividerWithText />
							</div>
						</div>
					)
				})}
			</div>
		</SlidePanel>
	)
})
