import { formatInTimeZone as _formatInTimeZone } from 'date-fns-tz'
import type { TimeZone } from '@vvo/tzdb'
import { getTimeZones } from '@vvo/tzdb'

const timeZoneLookup: TimeZone[] = getTimeZones()

export type Timezone =
	| 'Europe/London'
	| 'America/New_York'
	| 'America/Chicago'
	| 'America/Denver'
	| 'America/Los_Angeles'
	| 'Asia/Singapore'

export function timezoneOptions(): { value: Timezone; label: string }[] {
	return [
		{ value: 'America/New_York', label: 'Eastern' },
		{ value: 'America/Chicago', label: 'Central' },
		{ value: 'America/Denver', label: 'Mountain' },
		{ value: 'America/Los_Angeles', label: 'Pacific' },
	]
}

export function formatInTimeZone(
	date: Date,
	timezone: Timezone,
	format = 'yyyy-MM-dd HH:mm:ssXXX'
) {
	return _formatInTimeZone(date, timezone, format)
}

export function toAbbreviation(tz: Timezone) {
	return new Date()
		.toLocaleTimeString('en-us', { timeZone: tz, timeZoneName: 'short' })
		.split(' ')[2]
}

const timezones = timezoneOptions()
export function tzFriendlyName(tz: Timezone) {
	return timezones.find((t) => t.value === tz)?.label ?? tz
}

export function tzShortName(timezone: string) {
	if (!timeZoneLookup) {
		return timezone
	}

	const timeZoneAbbr = timeZoneLookup.find((timeZone) => {
		return timezone === timeZone.name
	})
	if (!timeZoneAbbr) {
		return 'America - New York'
	} else {
		return timeZoneAbbr['abbreviation']
	}
}

export function tzLocalShortName() {
	const tz = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/New_York'
	return tzShortName(tz)
}
