import { ICandidate } from '@touchpoints/requests'
import { observer } from 'mobx-react-lite'
import { CandidateStageList } from './CandidateStageList'

type CandidatePositionListProps = {
	candidate: ICandidate
}
export const CandidatePositionList = observer(function ({ candidate }: CandidatePositionListProps) {
	return (
		<div className="flex flex-col space-y-3" data-tag="allowRowEvents">
			<span className="text-sm text-zinc-900 font-semibold leading-tight">Positions</span>
			<div className="text-zinc-900 text-xs">
				<CandidateStageList candidate={candidate} displayFlex showJobDescription />
			</div>
		</div>
	)
})
