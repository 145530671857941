import { getSession as _getSession } from 'next-auth/react'
import type { Session } from 'next-auth'

const TOUCHPOINTS_TOKEN = 'touchpoints-token'

export function store(token: string) {
	return localStorage.setItem(TOUCHPOINTS_TOKEN, token)
}

export function get() {
	return localStorage.getItem(TOUCHPOINTS_TOKEN)
}

export function remove() {
	return localStorage.removeItem(TOUCHPOINTS_TOKEN)
}

export async function getSession() {
	const session = await _getSession()
	return session as Session & { accessToken?: string }
}
