export function AlertAction({ size = 20 }: { size?: number }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
		>
			<g id="actions">
				<path
					id="Icon"
					d="M16.4309 14.7286V15.3536H17.1648L17.0479 14.6291L16.4309 14.7286ZM3.56891 14.7286L2.95189 14.6291L2.83503 15.3536H3.56891V14.7286ZM15.2623 7.4831L15.8793 7.38358L15.2623 7.4831ZM4.73754 7.4831L4.12052 7.38358L4.73754 7.4831ZM2.12263 6.2349L1.95112 6.83591L3.15313 7.17893L3.32464 6.57792L2.12263 6.2349ZM5.22088 3.45318L5.67405 3.02277L4.81323 2.11641L4.36005 2.54682L5.22088 3.45318ZM15.6398 2.54682L15.1866 2.11641L14.3258 3.02277L14.779 3.45318L15.6398 2.54682ZM16.6752 6.57792L16.8467 7.17893L18.0487 6.83591L17.8772 6.2349L16.6752 6.57792ZM14.6453 7.58262L15.8139 14.8281L17.0479 14.6291L15.8793 7.38358L14.6453 7.58262ZM16.4309 14.1036H3.56891V15.3536H16.4309V14.1036ZM4.18594 14.8281L5.35457 7.58262L4.12052 7.38358L2.95189 14.6291L4.18594 14.8281ZM9.99992 3.62649C12.3094 3.62649 14.2775 5.30259 14.6453 7.58262L15.8793 7.38358C15.4139 4.49786 12.9229 2.37649 9.99992 2.37649V3.62649ZM9.99992 2.37649C7.0769 2.37649 4.58596 4.49786 4.12052 7.38358L5.35457 7.58262C5.72231 5.30259 7.69042 3.62649 9.99992 3.62649V2.37649ZM12.4423 14.506C12.0068 15.6482 11.046 16.3734 9.99992 16.3734V17.6234C11.6611 17.6234 13.027 16.4811 13.6103 14.9513L12.4423 14.506ZM9.99992 16.3734C8.95384 16.3734 7.99306 15.6482 7.55756 14.506L6.38957 14.9513C6.97284 16.4811 8.33873 17.6234 9.99992 17.6234V16.3734ZM3.32464 6.57792C3.66927 5.37024 4.33342 4.29606 5.22088 3.45318L4.36005 2.54682C3.31434 3.54 2.53001 4.80735 2.12263 6.2349L3.32464 6.57792ZM14.779 3.45318C15.6664 4.29606 16.3306 5.37024 16.6752 6.57792L17.8772 6.2349C17.4698 4.80735 16.6855 3.54 15.6398 2.54682L14.779 3.45318Z"
					fill="#E54D2E"
				/>
			</g>
		</svg>
	)
}
