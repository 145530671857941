import { PropsWithChildren } from 'react'

import type { ICandidate } from '@touchpoints/requests'
import { IconHeader } from '@touchpoints/ui'
import { observer } from 'mobx-react-lite'
import { HiUserCircle } from 'react-icons/hi'
import { CandidateInfoViewer } from './CandidateInfoViewer'

type CandidateInfoProps = {
	candidate?: Partial<ICandidate>
}

export const CandidateInfo = observer(function ({
	candidate,
	children,
}: PropsWithChildren<CandidateInfoProps>) {
	return (
		<div className="flex flex-col space-y-3">
			<IconHeader
				icon={<HiUserCircle className="text-[#889096]" />}
				message="Candidate Info"
			/>

			{candidate && (
				<div className="max-w-[500px]">
					<CandidateInfoViewer candidate={candidate} readOnly />
				</div>
			)}
			{children}
		</div>
	)
})
