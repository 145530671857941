import {
	addClientRecord,
	ClientRecordField,
	getClientRecords,
	updateClientRecord,
	deleteClientRecord,
} from '@requests/airtable'
import type { AirtableRecord } from '@requests/airtable'
import { makeAutoObservable } from 'mobx'

import type { RootStore } from '../root'

import type { AccountStatus, IAccount } from '@types'

function toRecord(data: Partial<IAccount>) {
	return {
		[ClientRecordField.Name]: data.name,
		[ClientRecordField.ShortName]: data.shortName,
		[ClientRecordField.Status]: data.status,
		[ClientRecordField.Benefits]: data.benefits,
		[ClientRecordField.Hook]: data.hook,
	}
}

// NOTE: this can be removed once the positions are updated
// to the same system as clients and candidates
class AirtableAccount implements IAccount {
	id = ''
	organizationId = ''
	name = ''
	shortName = ''
	status: AccountStatus = 'active'
	benefits?: string
	hook?: string

	fields: Record<string, unknown> = {}

	constructor(data: AirtableRecord) {
		makeAutoObservable(this)

		this.update(data)
	}

	update(data: AirtableRecord) {
		this.id = data.id
		this.organizationId = 'none'
		this.name = (data.fields[ClientRecordField.Name] as string) ?? 'No name'
		this.shortName = (data.fields[ClientRecordField.ShortName] as string) ?? this.name
		this.status = data.fields[ClientRecordField.Status] as AccountStatus
		this.benefits = data.fields[ClientRecordField.Benefits] as string
		this.hook = data.fields[ClientRecordField.Hook] as string

		this.fields = data.fields
	}
}

export class AirtableAccounts {
	private readonly root: RootStore

	readonly list: AirtableAccount[] = []

	readonly fields: ClientRecordField[] = [
		ClientRecordField.Name,
		ClientRecordField.Status,
		ClientRecordField.Benefits,
		ClientRecordField.Hook,
	]

	constructor(root: RootStore) {
		this.root = root

		makeAutoObservable(this)
	}

	async refresh() {
		const settings = this.root.settings
		if (!settings.useAirtableBase) {
			return
		}

		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}
		const res = await getClientRecords(orgId)
		if (!res.success) {
			return
		}

		this.clear()

		const { records } = res.data ?? {}

		records?.forEach((record) => this.addRecord(record))
	}

	addRecord(record: AirtableRecord) {
		this.list.push(new AirtableAccount(record))
	}

	async createAccount(data: Omit<IAccount, 'id' | 'organizationId'>) {
		const orgId = this.root.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await addClientRecord(orgId, toRecord(data))
		if (!res.success) {
			return
		}

		const { record } = res.data ?? {}
		if (!record) {
			return
		}

		this.addRecord(record)
	}

	async updateRecord(id: string, changes: Partial<IAccount>) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await updateClientRecord(orgId, id, toRecord(changes))
		if (!res.success) {
			return
		}

		const { record } = res.data ?? {}
		if (!record) {
			return
		}

		this._updateRecord(id, record)
	}

	async deleteRecord(id: string) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await deleteClientRecord(orgId, id)
		if (!res.success) {
			return
		}

		this._removeRecord(id)
	}

	getAccountById(id: string) {
		if (!id) {
			return undefined
		}
		return this.list.find((acc) => acc.id === id)
	}

	private _updateRecord(id: string, record: AirtableRecord) {
		const rec = this.getAccountById(id)
		if (!rec) {
			return
		}

		rec.update(record)
	}

	private _removeRecord(id: string) {
		const idx = this.list.findIndex((acc) => acc.id === id)
		if (idx < 0) {
			return
		}

		this.list.splice(idx, 1)
	}

	private clear() {
		this.list.length = 0
	}
}
