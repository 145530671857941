import { rootStore } from '@store'
import { ICandidate, IOrgStage, IStageInstance } from '@touchpoints/requests'
import { observer } from 'mobx-react-lite'
import { CandidateStageSelect } from './CandidateStageSelect'
import { useState } from 'react'
import { useStageOptions } from '@hooks/stages'
import { useReaction } from '@touchpoints/mobx-hooks'

type PCProps = {
	candidate: ICandidate | string
	positionId?: string
	onChange?: (stage: string) => void
}
export const PositionCandidateStage = observer(function ({
	candidate,
	positionId,
	onChange,
}: PCProps) {
	const [loading, setLoading] = useState(false)
	const pc = rootStore.positions.getPositionCandidate(
		typeof candidate === 'string' ? candidate : candidate.id,
		positionId ?? rootStore.positions.activePositionId ?? ''
	)
	const position = rootStore.positions.getPositionById(
		positionId ?? rootStore.positions.activePositionId ?? ''
	)
	const stageTemplate = position?.stageTemplateId
		? rootStore.stageTemplates.getStageTemplateById(position.stageTemplateId)
		: null

	const globalStages = rootStore.organizations.activeOrganization?.stages.groups.flatMap(
		(sg) => sg.stages as IOrgStage[]
	)

	const options = stageTemplate
		? useReaction(() => {
				if (stageTemplate)
					return stageTemplate.stageGroups.map((stageGroup) => {
						return stageGroup.stages.map((stage) => {
							const stageInstance = stage as IStageInstance
							const stageReference = globalStages?.find(
								(s) => s.id === stageInstance.stageReferenceId
							)
							return {
								value: stage.id,
								label: stageReference?.name ?? '',
								color: stageGroup.color,
							}
						})
					})
				else return []
		  }).flat()
		: useStageOptions()

	const handleStageChange = async (value: string) => {
		if (!pc) {
			return
		}

		setLoading(true)
		await rootStore.positions.setPositionCandidateStage(pc.candidateId, pc.positionId, value)
		await rootStore.candidates.events.fetchAllEventsForCandidate(pc.candidateId)
		setLoading(false)
		onChange?.(value)
	}

	if (!pc) {
		return null
	}

	return (
		<CandidateStageSelect
			options={options}
			stage={pc.stage}
			isLoading={loading}
			onChange={handleStageChange}
		/>
	)
})
