import { Card, Input, Text } from '@nextui-org/react'
import { styled } from '@stitches/react'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
import Fuse from 'fuse.js'
import { useEffect, useState } from 'react'

const Root = styled('div', {
	position: 'relative',
	display: 'flex',
	width: '100%',
	height: '50px',
	zIndex: 500,
})

const Wrapper = styled('div', {
	position: 'absolute',
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
})

type Props = {
	onRenderResult: (result: any, top: boolean) => JSX.Element
	placeholder?: string
	items?: any[]
	keys: string[]
}

const state = makeAutoObservable({
	text: '',
	setText(t: string) {
		this.text = t
	},
})

export const AutoComplete = observer(function ({
	placeholder,
	items = [],
	onRenderResult,
	keys = [],
}: Props) {
	const [fuse, setFuse] = useState<Fuse<any>>()
	const [results, setResults] = useState<Fuse.FuseResult<unknown>[]>([])

	useEffect(() => {
		const f = new Fuse(items, {
			keys,
		})
		setFuse(f)

		return () => {
			state.setText('')
		}
	}, [])

	useEffect(() => {
		if (!fuse) {
			return
		}

		fuse.setCollection(items)
	}, [items, fuse])

	useEffect(() => {
		if (!fuse) {
			return
		}

		const id = setTimeout(() => {
			setResults(fuse.search(state.text))
		}, 300)

		return () => {
			clearTimeout(id)
		}
	}, [state.text, fuse])

	return (
		<Root>
			<Wrapper>
				<Input
					value={state.text}
					onChange={(e) => state.setText(e.currentTarget.value)}
					bordered
					shadow={false}
					placeholder={placeholder}
					autoFocus
				/>
				{state.text && results.length > 0 && (
					<Card>
						<Card.Body
							css={{
								paddingLeft: '0px',
								paddingRight: '0px',
								maxHeight: '200px',
								overflowY: 'scroll',
							}}
						>
							{results.map((res, idx) => onRenderResult(res.item, idx === 0))}
						</Card.Body>
					</Card>
				)}
				{state.text && results.length <= 0 && (
					<Card>
						<Card.Body>
							<Text>No Results</Text>
						</Card.Body>
					</Card>
				)}
			</Wrapper>
		</Root>
	)
})
