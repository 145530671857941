import { useEditor } from '@components/editor'
import { PreviewPanel, VariablesDrawer, isConditional } from '@components/templates/PreviewPanel'
import { Button } from '@nextui-org/react'
import { sendPreviewTemplate } from '@requests/templates'
import { rootStore } from '@store/index'
import { useReaction } from '@touchpoints/mobx-hooks'
import { ITemplate, Query } from '@touchpoints/requests'
import { Divider, Modal, Select2, toast } from '@touchpoints/ui'
import { makeAutoObservable } from 'mobx'
import { useCallback, useEffect, useState } from 'react'

export const exportWithTemplateStore = makeAutoObservable({
	open: false,
	query: {},
	positionId: '',
	setOpen(open: boolean, positionId = '') {
		this.open = open
		this.positionId = positionId
	},
	setQuery(query: Query) {
		this.query = query
	},
})

export function ExportWithTemplateModal() {
	const open = useReaction(() => exportWithTemplateStore.open)

	const [content, setContent] = useState('')
	const [templateId, setTemplateId] = useState('')
	const [template, setTemplate] = useState<ITemplate | undefined>(undefined)

	const { instance: editor, component: Editor } = useEditor()

	const [context, setContext] = useState({})
	const [subject, setSubject] = useState('')

	useEffect(() => {
		setTemplate(rootStore.templates.getTemplate(templateId))
	}, [templateId])

	const handleSelectVariable = (variable: string) => {
		const text = editor?.selection.getRng().startContainer.textContent

		let finalVariable = variable
		if (text) {
			if (isConditional(text.trim())) {
				// remove the variable brackets
				finalVariable = variable.slice(2, variable.length - 2)
			}
		}

		editor?.insertContent(finalVariable)
	}

	const buildQuery = () => {
		const q: Query = {
			conjunction: 'and',
			conditions: [exportWithTemplateStore.query],
		}

		if (exportWithTemplateStore.positionId) {
			q.conditions.push({
				field: 'info.positionId.keyword',
				operator: 'Is',
				value: exportWithTemplateStore.positionId,
			})
		}
		return q
	}

	const handleSendPreview = async () => {
		const orgId = rootStore.organizations.activeOrganizationId
		if (!orgId || !template?.id) {
			return
		}

		const res = await sendPreviewTemplate(orgId, template.id, {
			positionId: exportWithTemplateStore.positionId,
			data: {
				query: JSON.stringify(buildQuery()),
			},
		})
		if (!res.success) {
			toast('Failed to send preview', { type: 'error' })
			return
		}

		toast('Preview email sent!', { type: 'success' })
	}

	const handlePreview = useCallback(async () => {
		const content = editor?.getContent({ format: 'html' })

		if (!content || !template?.id) {
			return
		}

		const val = await rootStore.templates.preview('new', {
			positionId: exportWithTemplateStore.positionId,
			rawHtml: content,
			data: {
				query: JSON.stringify(buildQuery()),
			},
		})
		if (!val) {
			return
		}
		setContent(val.html)
		setContext(val.context)
		setSubject(val.subject)
	}, [editor, template])

	useEffect(() => {
		if (!template) {
			return
		}

		editor?.setContent(template.html)
		setSubject(template.subject ?? '')
	}, [template, editor])

	return (
		<Modal
			blur
			width="80%"
			isOpen={open}
			onClose={() => {
				exportWithTemplateStore.setOpen(false)
			}}
		>
			<Modal.Header>
				<h2 className="text-lg font-semibold">Export with template</h2>
			</Modal.Header>
			<Modal.Body>
				<div className="flex flex-col w-full space-y-2">
					<Select2
						label="Template"
						value={
							rootStore.templates.list.find((s) => s.id === templateId)?.name ?? ''
						}
						placeholder={'Select a template'}
						onChange={(v) => setTemplateId(v.value)}
						options={rootStore.templates.list.map((u) => {
							return {
								value: u.id,
								label: u.name,
								searchString: u.name,
							}
						})}
						filterable
						inputPlaceholder="Search templates"
					/>
					<div className="flex flex-row space-x-2">
						<div className="border rounded-md p-3 md:w-1/2">
							{template && <Editor />}
							{!template && (
								<span className="text-2xl font-semibold align-middle">
									No template selected
								</span>
							)}
						</div>
						<div className="border rounded-md p-3 w-full md:w-1/2">
							<PreviewPanel subject={subject} html={content}>
								<Divider />
								<VariablesDrawer
									context={context}
									onSelectVariable={handleSelectVariable}
								/>
								<div className="flex w-full justify-end pt-2">
									<Button auto onClick={handleSendPreview}>
										Send Preview
									</Button>
								</div>
							</PreviewPanel>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer justify="space-between">
				<div className="flex space-x-3 justify-end">
					<Button
						auto
						bordered
						color="secondary"
						onClick={() => exportWithTemplateStore.setOpen(false)}
					>
						Close
					</Button>
					<Button auto bordered color="primary" onClick={handlePreview}>
						Preview
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	)
}
