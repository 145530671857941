import type { IQuestion, QuestionType } from '@touchpoints/requests'
import { makeAutoObservable, runInAction } from 'mobx'
import type { RootStore } from './root'
import {
	addQuestion,
	deleteQuestion,
	getAllQuestions,
	getQuestion,
	previewQuestion,
	updateQuestion,
} from '@requests/questions'

class Question implements IQuestion {
	id: string
	organizationId: string
	name: string
	question: string
	description: string
	createdAt: number
	updatedAt: number

	type: QuestionType

	choices: string[] = []

	constructor(data: IQuestion) {
		this.id = data.id
		this.organizationId = data.organizationId
		this.name = data.name
		this.question = data.question
		this.description = data.description || ''
		this.createdAt = data.createdAt
		this.updatedAt = data.updatedAt
		this.type = data.type || 'text'
		this.choices = data.choices ?? []

		makeAutoObservable(this)
	}

	update(data: Partial<IQuestion>) {
		this.name = data.name ?? this.name
		this.question = data.question ?? ''
		this.description = data.description ?? ''
		this.createdAt = data.createdAt ?? this.createdAt
		this.updatedAt = data.updatedAt ?? this.updatedAt
		this.type = data.type || 'text'
		this.choices = data.choices ?? []
	}
}

export class QuestionsStore {
	private readonly root: RootStore

	readonly list: Question[] = []

	constructor(root: RootStore) {
		this.root = root

		makeAutoObservable(this)
	}

	findById(id: string) {
		return this.list.find((q) => q.id === id)
	}

	async fetchQuestions() {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await getAllQuestions(orgId)
		if (!res.success) {
			return
		}

		runInAction(() => {
			this.list.length = 0
			this.list.push(...(res.data?.questions.map((q) => new Question(q)) ?? []))
		})
	}

	async fetchQuestion(id: string) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await getQuestion(orgId, id)
		if (!res.success) {
			return
		}

		const { question } = res.data ?? {}
		if (!question) {
			return
		}

		const existing = this.findById(question.id)
		if (!existing) {
			const q = new Question(question)
			this.list.push(q)
			return q
		}

		existing.update(question)
		return existing
	}

	async addQuestion(data: Pick<IQuestion, 'name' | 'question'>) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await addQuestion(orgId, data)
		if (!res.success) {
			return
		}

		const { question } = res.data ?? {}
		if (!question) {
			return
		}

		const q = new Question(question)
		this.list.push(q)

		return q
	}

	async saveQuestion(id: string, data: Partial<IQuestion>) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await updateQuestion(orgId, id, data)
		if (!res.success) {
			return
		}

		const { question } = res.data ?? {}
		if (!question) {
			return
		}

		const existing = this.findById(question.id)
		if (!existing) {
			const q = new Question(question)
			this.list.push(q)
			return q
		}

		existing.update(question)

		return existing
	}

	async removeQuestion(id: string) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await deleteQuestion(orgId, id)
		if (!res.success) {
			return false
		}

		const index = this.list.findIndex((q) => q.id === id)
		if (index === -1) {
			return false
		}

		this.list.splice(index, 1)

		return true
	}

	async preview(id: string, data: { candidateId: string; positionId: string }) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await previewQuestion(orgId, id, data.candidateId, data.positionId)
		if (!res.success) {
			return
		}

		return res.data
	}
}
