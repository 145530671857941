import { fetchCandidateSettings, updateCandidatesSettings } from '@requests/settings'
import { CustomField } from '@store/customField'
import { QualityField } from '@store/qualityField'
import type {
	ICandidateRejectSettings,
	ICandidateTransferSettings,
	ICustomField,
	IEventType,
	IQualityField,
} from '@touchpoints/requests'
import { makeAutoObservable, reaction } from 'mobx'
import type { RootStore } from '../root'

type SaveOptions = {
	customFields?: ICustomField[]
	qualityFields?: IQualityField[]
	eventTypes?: IEventType[]
	transferSettings?: ICandidateTransferSettings
	rejectSettings?: ICandidateRejectSettings
}

export class CandidatesSettings {
	private readonly root: RootStore

	readonly customFields: CustomField[] = []
	readonly quailityFields: IQualityField[] = []
	readonly evenTypes: IEventType[] = []
	readonly transferSettings: ICandidateTransferSettings = {}
	readonly rejectSettings: ICandidateRejectSettings = {}

	get activeQualityFields() {
		return this.quailityFields.filter((field) => field.selected)
	}

	constructor(root: RootStore) {
		this.root = root

		makeAutoObservable(this)

		reaction(
			() => this.root.organizations.activeOrganizationId,
			() => {
				this.refresh()
			}
		)
	}

	async refresh() {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await fetchCandidateSettings(orgId)
		if (!res.success) {
			return
		}

		const { settings } = res.data ?? {}
		if (!settings) {
			return
		}

		settings.customFields?.forEach((field) => this.addCustomField(field))
		settings.qualityFields?.forEach((field) => this.addQualityField(field))
		settings.eventTypes?.forEach((type) => this.addEventType(type))

		this.transferSettings.defaultStageId = settings.transferSettings?.defaultStageId
		this.rejectSettings.stageWhenCandidateIsRejected =
			settings.rejectSettings?.stageWhenCandidateIsRejected
	}

	addCustomField(field: ICustomField) {
		this.customFields.push(new CustomField(field))
	}

	addQualityField(field: IQualityField) {
		this.quailityFields.push(new QualityField(field))
	}

	addEventType(evtType: IEventType) {
		this.evenTypes.push(evtType)
	}

	async save(options: SaveOptions) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		if (options.customFields) {
			this.clearCustomFields()
			options.customFields.forEach((f) => this.addCustomField(f))
		}

		if (options.qualityFields) {
			this.clearQualityFields()
			options.qualityFields.forEach((f) => this.addQualityField(f))
		}

		if (options.eventTypes) {
			this.clearEventTypes()
			options.eventTypes.forEach((f) => this.addEventType(f))
		}

		const res = await updateCandidatesSettings(orgId, {
			customFields: this.customFields.map((f) => ({ ...f })),
			qualityFields: this.quailityFields.map((f) => ({ ...f })),
			eventTypes: this.evenTypes.map((f) => ({ ...f })),
			transferSettings: options.transferSettings,
			rejectSettings: options.rejectSettings,
		})

		if (!res.success) {
			return
		}

		const { settings } = res.data ?? {}
		if (!settings) {
			return
		}

		this.clearCustomFields()
		this.clearQualityFields()
		this.clearEventTypes()

		settings.customFields?.forEach((field) => this.addCustomField(field))
		settings.qualityFields?.forEach((field) => this.addQualityField(field))
		settings.eventTypes?.forEach((type) => this.addEventType(type))

		this.transferSettings.defaultStageId = settings.transferSettings?.defaultStageId ?? ''
		this.rejectSettings.stageWhenCandidateIsRejected =
			settings.rejectSettings?.stageWhenCandidateIsRejected ?? ''
	}

	private clearCustomFields() {
		while (this.customFields.length > 0) {
			this.customFields.pop()
		}
	}

	private clearQualityFields() {
		while (this.quailityFields.length > 0) {
			this.quailityFields.pop()
		}
	}

	private clearEventTypes() {
		this.evenTypes.length = 0
	}
}
