export type UniversalOperators = 'IsEmpty' | 'IsNotEmpty'

export type StringOperators = 'Contains' | 'DoesNotContain' | 'Is' | 'IsNot' | UniversalOperators

export type BooleanOperators = 'Is' | 'IsNot' | UniversalOperators

export type NumberOperators =
	| 'Equal'
	| 'NotEqual'
	| 'LessThan'
	| 'LessThanOrEqual'
	| 'GreaterThan'
	| 'GreaterThanOrEqual'
	| UniversalOperators

export type DateOperators =
	| 'Equal'
	| 'NotEqual'
	| 'LessThan'
	| 'LessThanOrEqual'
	| 'GreaterThan'
	| 'GreaterThanOrEqual'
	| UniversalOperators

export type OperatorType = StringOperators | BooleanOperators | NumberOperators | DateOperators

export interface Operator {
	label: string
	type: OperatorType
}

export type FieldType = 'string' | 'boolean' | 'number' | 'date' | 'select'

export interface Field {
	id: string
	label: string
	type: FieldType
}

export interface Condition {
	field?: string
	operator?: OperatorType
	value?: string | string[] | Date | number | boolean | undefined | null
}

export interface Query {
	conjunction: 'and' | 'or'
	conditions: (Condition | Query)[]
}

function isValidCondition(condition: Condition) {
	if (!condition.field) {
		return false
	}

	// Only field and operator requiried
	if (condition.operator === 'IsEmpty' || condition.operator === 'IsNotEmpty') {
		return true
	}

	return condition.field && condition.operator && condition.value
}

export const instanceOfQuery = (object: any): object is Query => {
	return 'conjunction' in object
}

export function isValidQuery(query?: Query): boolean {
	if (!query) return false
	return query.conditions.every((condition) => {
		if (instanceOfQuery(condition)) {
			return isValidQuery(condition)
		} else {
			return isValidCondition(condition)
		}
	})
}
