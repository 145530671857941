import { IOrganizationUser } from '@touchpoints/requests'
import toUpper from 'lodash/toUpper'

export function orgUserDisplay(user: IOrganizationUser) {
	return `${user.firstName} ${user.lastName} (${user.email})`
}

export function orgUserNameDisplay(
	user?: Pick<IOrganizationUser, 'firstName' | 'lastName' | 'email'>
) {
	if (!user) {
		return 'Unknown User'
	}

	if (!user.firstName && !user.lastName) {
		return user.email
	}

	if (user.firstName && !user.lastName) {
		return `${user.firstName} (${user.email})`
	}

	return `${user.firstName} ${user.lastName}`
}

export const getAvatarText = (user?: {
	firstName?: string
	lastName?: string
	email: string | string[]
}) => {
	if (!user) {
		return '?'
	}

	if (user.firstName && user.lastName) {
		return toUpper(user.firstName[0] + user.lastName[0])
	}

	if (user.firstName) {
		return toUpper(user.firstName[0])
	}

	if (user.lastName) {
		return toUpper(user.lastName[0])
	}

	if (typeof user.email === 'string') {
		return toUpper(user.email)
	}

	return toUpper(user.email[0])
}
