import { HiOutlineClipboardCopy, HiOutlineExternalLink } from 'react-icons/hi'
import { toast } from '../Toast'
import { Input, InputProps } from './Input'

type Props = {
	launch?: boolean
	onLaunch?: (value: string) => void
} & InputProps
export function InputWithCopy({ value, onChange, launch, onLaunch, ...props }: Props) {
	const handleCopyField = () => {
		if (!value) {
			return
		}
		navigator.clipboard.writeText(value)
		toast.success(`Copied to clipboard!`)
	}

	const handleOpenUrl = () => {
		if (!value) {
			return
		}

		if (onLaunch) {
			onLaunch(value)
		} else {
			window.open(value, '_blank')
		}
	}

	return (
		<Input value={value} onChange={onChange} {...props}>
			{value ? (
				<div className="flex">
					<HiOutlineClipboardCopy
						aria-label="Copy to clipboard"
						className="m-2 cursor-pointer text-xl hover:opacity-60"
						onClick={handleCopyField}
					/>
					{launch && (
						<HiOutlineExternalLink
							aria-label="Open in new tab"
							className="m-2 cursor-pointer text-xl hover:opacity-60"
							onClick={handleOpenUrl}
						/>
					)}
				</div>
			) : null}
		</Input>
	)
}

type InputForFieldProps<T> = {
	data: Partial<T>
	field: keyof T
	onFieldChange?: (field: keyof T, val: unknown) => void
	bottomLabel?: string
} & Props
export function InputForField<T>({
	data,
	onFieldChange,
	field,
	bottomLabel,
	...props
}: InputForFieldProps<T>) {
	return (
		<div className="flex flex-col space-y-2 w-full">
			<InputWithCopy
				value={(data[field] ?? '') as string}
				onChange={(e) => onFieldChange?.(field, e)}
				{...props}
			/>
			{bottomLabel && <p className="text-sm text-gray-500">{bottomLabel}</p>}
		</div>
	)
}
