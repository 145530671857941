import { authHeader, get, post } from './api'

import type { INotification } from '@touchpoints/requests'

export async function fetchNotifications(organizationId: string) {
	const headers = await authHeader()
	return get<{ notifications: INotification[] }>(
		`/organizations/${organizationId}/notifications`,
		undefined,
		headers
	)
}

export async function markNotificationAsRead(organizationId: string, notificationId: string) {
	const headers = await authHeader()
	return post<{ notification: INotification }>(
		`/organizations/${organizationId}/notifications/${notificationId}/mark-as-read`,
		undefined,
		headers
	)
}
