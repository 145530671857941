import type { IUser, IOrganization, GetMethodFunc } from '../types'

type FetchOrganizationsResponse = {
	organizations: IOrganization[]
}

export function makeFetchUser(
	getMethod: GetMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function fetchUser() {
		const headers = await authHeader()
		if (!headers['Authorization']) {
			return { status: 400, success: false }
		}

		return getMethod<IUser>('/users/me', undefined, headers)
	}
}

export function makeFetchOrganizationsForUser(
	getMethod: GetMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function fetchOrganizationsForUser(userId: string) {
		const headers = await authHeader()
		return getMethod<FetchOrganizationsResponse>(
			`/users/${userId}/organizations`,
			undefined,
			headers
		)
	}
}

export function makeFetchOrganizationById(
	getMethod: GetMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function fetchOrganizationById(orgId: string) {
		const headers = await authHeader()
		return getMethod<{ organization: IOrganization }>(
			`/organizations/${orgId}`,
			undefined,
			headers
		)
	}
}
