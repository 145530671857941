import { authHeader, get, post, del, put } from './api'

import type { IMeeting } from '@touchpoints/requests'

export async function fetchMeetings(organizationId: string) {
	const headers = await authHeader()
	return get<{ meetings: IMeeting[] }>(
		`/organizations/${organizationId}/meetings`,
		undefined,
		headers
	)
}

export async function addMeeting(organizationId: string, name: string) {
	const headers = await authHeader()
	return put<{ meeting: IMeeting }>(
		`/organizations/${organizationId}/meetings`,
		{
			name,
		},
		headers
	)
}

export async function updateMeeting(
	organizationId: string,
	meetingId: string,
	data: Partial<Omit<IMeeting, 'id' | 'createdBy'>>
) {
	const headers = await authHeader()
	return post<{ meeting: IMeeting }>(
		`/organizations/${organizationId}/meetings/${meetingId}`,
		{
			...data,
		},
		headers
	)
}

export async function removeMeeting(organizationId: string, meetingId: string) {
	const headers = await authHeader()
	return del(`/organizations/${organizationId}/meetings/${meetingId}`, headers)
}

export type MeetingPreviewOptions = {
	candidateId?: string
	positionId?: string
	accountId?: string
	rawTitle?: string
	rawLocation?: string
	rawDescription?: string
}
export async function previewMeeting(
	organizationId: string,
	meetingId: string,
	opts: MeetingPreviewOptions
) {
	const headers = await authHeader()
	return post<{
		meeting: { title: string; location: string; description: string }
		context: Record<string, any>
	}>(`/organizations/${organizationId}/meetings/${meetingId}/preview`, { ...opts }, headers)
}
