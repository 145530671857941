import Autolinker from 'autolinker'
import format from 'date-fns/format'
import { Kbd } from 'flowbite-react'
import { observer } from 'mobx-react-lite'
import { KeyboardEvent, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import ReactMarkdown from 'react-markdown'

import { TextInput, Button as FBButton } from 'flowbite-react'

import { rootStore } from '@store'

import { OrgUserAvatar } from '@components/shared/OrgUserAvatar'
import { Button, Loading, Tooltip } from '@nextui-org/react'
import { orgUserDisplay, orgUserNameDisplay } from '@services/format'
import { linkMentions, processMentions } from '@services/mentions'
import { useMutate, useReaction } from '@touchpoints/mobx-hooks'
import type {
	ICandidateEvent,
	IOrganizationUser,
	IPositionCandidateEvent,
	IResourceComment,
	ITask,
} from '@touchpoints/requests'
import { formatTimeDifference } from '@touchpoints/time'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { HiCheck, HiPencil, HiX } from 'react-icons/hi'
import { Mention, MentionsInput } from 'react-mentions'
import { TaskActivityWrapper } from '../tasks/TaskActivity'
import { useSignal, useSignals } from '@preact/signals-react/runtime'
import { Input, Popover2, toast } from '@touchpoints/ui'
import { TITLE_THEME } from '@components/tasks/themes'
import { TaskPriority } from '@components/tasks/TaskPriority'
import { TaskAssignee } from '@components/tasks/TaskAssignee'
import { TaskDueBy } from '@components/tasks/TasksFooter'
import { signal } from '@preact/signals-react'
import { TaskCreated } from '@components/tasks/TaskCreatedToastContent'
import { CloseIcon, DoneIcon, TodoIcon } from '@components/tasks/icons'
import { useCandidateEvents } from '@components/candidates/useCandidateEvents'
import { getMessageForEvent, getOrgUserForEvent } from '@components/candidates/CandidateEvents'
import { useInnerTaskContent } from '@components/tasks/useInnerTaskView'
import { getSourceForEvent } from '@touchpoints/utils'
import { GoLinkExternal } from 'react-icons/go'

const TASK_TITLE_THEME = {
	...TITLE_THEME,
	field: {
		input: {
			...TITLE_THEME.field?.input,
			sizes: {
				md: 'text-base font-normal placeholder:text-neutral-500',
			},
			colors: {
				gray: 'bg-inherit',
			},
		},
	},
}

const taskData = signal<Partial<ITask>>({})

function useCommentsWithActivity(
	positionCandidateId: string,
	comments: IResourceComment[],
	showActivity = false
) {
	const tasks = useReaction(
		() => {
			const pc = rootStore.positions.getPositionCandidateById(positionCandidateId)
			const taskFilter = (t: ITask) => {
				{
					if (
						t.data?.candidateId &&
						pc?.candidateId &&
						t.data?.positionId &&
						pc?.positionId
					) {
						return (
							t.data.candidateId === pc.candidateId &&
							t.data.positionId === pc.positionId
						)
					}
					return false
				}
			}
			return [
				...rootStore.tasks.pendingTasks.filter(taskFilter),
				...rootStore.tasks.finishedTasks.filter(taskFilter),
			]
		},
		100,
		[positionCandidateId]
	)

	const positionCandidate = useReaction(
		() => rootStore.positions.getPositionCandidateById(positionCandidateId),
		100,
		[positionCandidateId]
	)

	const activityEvents = useCandidateEvents(
		showActivity ? positionCandidate?.candidateId ?? '' : ''
	)

	const list = useMemo(() => {
		const results: {
			createdAt: number
			type: 'task' | 'comment' | 'activity-event'
			data: ITask | IResourceComment | ICandidateEvent | IPositionCandidateEvent
		}[] = []

		for (const comment of comments) {
			results.push({
				createdAt: comment.createdAt,
				type: 'comment',
				data: comment,
			})
		}

		for (const task of tasks) {
			results.push({
				createdAt: task.createdAt,
				type: 'task',
				data: task,
			})
		}

		for (const event of activityEvents) {
			results.push({
				createdAt: event.ts,
				type: 'activity-event',
				data: event,
			})
		}

		return results.sort((a, b) => b.createdAt - a.createdAt)
	}, [tasks, comments, activityEvents])

	return list
}

type CommentTab = 'comment' | 'template' | 'task'
const selectedTab = signal<CommentTab>('template')
const dataById: Record<string, { who: string; message: string }> = {}

type CommentsPanelProps = {
	positionCandidateId: string
	comments: IResourceComment[]
	onSubmitMessage?: (
		message: string,
		plainMessage?: string,
		messageValue?: string
	) => Promise<void>
	onUpdateComment?: (commentId: string, comment: string) => Promise<void>
	loading?: boolean
	showActivity?: boolean
}
export const CommentsPanel = function ({
	positionCandidateId,
	comments,
	loading,
	onSubmitMessage,
	onUpdateComment,
	showActivity = false,
}: CommentsPanelProps) {
	useSignals()

	const taskLoading = useSignal(false)

	const [message, setMessage] = useState('')
	const who = useSignal('')
	const activeUser = useReaction(() => rootStore.organizationUsers.activeUser)

	const list = useCommentsWithActivity(positionCandidateId, comments, showActivity)

	useEffect(() => {
		// store message data for each position candidate on unmount
		return () => {
			dataById[positionCandidateId] = { who: who.value, message: message }
		}
	}, [message, who, positionCandidateId])

	useEffect(() => {
		setMessage(dataById[positionCandidateId]?.message ?? '')
		who.value = dataById[positionCandidateId]?.who ?? ''
	}, [positionCandidateId, who])

	const handleCreateTask = async (task: Partial<ITask>) => {
		if (!task.assignedUserId || !task.data?.title) {
			console.error('No title or assignedUserId, cant create task')
			return
		}

		const pc = rootStore.positions.getPositionCandidateById(positionCandidateId)

		taskLoading.value = true
		const response = await rootStore.tasks.createGeneric(
			{
				assignedUserId: task.assignedUserId,
				prioriry: Number(task.priority),
				dueDate: task.dueBy,
				payload: {
					title: task.data.title,
					html: task.data.html ?? task.data.title ?? '',
					candidateId: pc?.candidateId,
					positionId: pc?.positionId,
				},
			},
			task.isPrivate
		)

		if (response?.success && response.task) {
			toast(<TaskCreated task={response.task} />, {
				position: 'bottom-right',
				hideProgressBar: true,
				closeOnClick: false,
				autoClose: 5000,
				className: 'w-[459px] right-[150px]',
			})
		} else {
			toast.error('Failed to create task' + response?.message ? `: ${response?.message}` : '')
		}

		taskLoading.value = false
	}

	const handleSubmitComment = async () => {
		switch (selectedTab.value) {
			case 'task': {
				if (
					!taskData.value.data?.title ||
					!taskData.value.data?.html ||
					!taskData.value.assignedUserId
				) {
					return
				}
				await handleCreateTask(taskData.value)
				break
			}

			case 'template':
			case 'comment': {
				if (!message) {
					break
				}

				let finalMessage = message
				if (selectedTab.value === 'template') {
					finalMessage = `**Who:** ${who.value}

**What/Why:** ${message}`
				}

				const { value, plainValue } = processMentions(finalMessage)

				onSubmitMessage?.(finalMessage, plainValue, value)
			}
		}

		setMessage('')
		who.value = ''
		taskData.value = {}
	}

	return (
		<div className="w-full h-full flex flex-col space-y-5 pl-5 pr-7">
			{onSubmitMessage && (
				<div className="w-full flex space-x-2">
					<OrgUserAvatar user={activeUser} size="xs" />
					{loading || taskLoading.value ? (
						<div className="flex grow items-center justify-center">
							<Loading />
						</div>
					) : (
						<div className="flex w-full space-x-2 pl-3.5">
							<div className="flex flex-col w-full border bg-white shadow-sm rounded-lg">
								<div className="flex flex-row ml-2 mt-2 space-x-1">
									<CommentTabItem
										active={selectedTab.value === 'comment'}
										onClick={() => (selectedTab.value = 'comment')}
									>
										Comment
									</CommentTabItem>
									<CommentTabItem
										active={selectedTab.value === 'template'}
										onClick={() => (selectedTab.value = 'template')}
									>
										Template
									</CommentTabItem>
									<CommentTabItem
										active={selectedTab.value === 'task'}
										onClick={() => (selectedTab.value = 'task')}
									>
										Task
									</CommentTabItem>
								</div>
								{selectedTab.value === 'comment' && (
									<div className="flex flex-grow">
										<CommentsInput
											placeholder="Write a message about this candidate..."
											message={message}
											onChange={setMessage}
											onSubmit={handleSubmitComment}
										/>
									</div>
								)}
								{selectedTab.value === 'template' && (
									<div className="flex flex-grow pl-4">
										<TemplateInput
											who={who.value}
											message={message}
											onWhoChange={(v) => (who.value = v)}
											onMessageChange={(m) => setMessage(m)}
											onSubmit={handleSubmitComment}
										/>
									</div>
								)}
								{selectedTab.value === 'task' && (
									<div className="flex flex-grow pl-4 pt-2">
										<TaskInput onSubmit={handleSubmitComment} />
									</div>
								)}
								<div className="flex flex-initial justify-between px-3 pb-3">
									<div>{selectedTab.value === 'task' && <TaskOptions />}</div>
									<Tooltip
										isDisabled={!message}
										content={
											<p className="flex w-full items-center justify-center">
												<Kbd>Cmd</Kbd>
												<span className="text-gray-500 mx-0.5">+</span>
												<Kbd>Enter</Kbd>
											</p>
										}
									>
										<FBButton
											onClick={handleSubmitComment}
											className="!h-9 !border !border-neutral-200 text-neutral-800 !shadow-sm !rounded-lg"
											disabled={
												selectedTab.value === 'task'
													? !taskData.value.data?.title &&
													  !taskData.value.data?.html &&
													  !taskData.value.assignedUserId
													: selectedTab.value === 'comment'
													? !message
													: selectedTab.value === 'template'
													? !message || !who.value
													: false
											}
										>
											Comment
										</FBButton>
									</Tooltip>
								</div>
							</div>
						</div>
					)}
				</div>
			)}
			<div className="w-full flex-col space-y-3">
				{list.length <= 0 && (
					<div className="w-full h-40 items-center justify-center flex text-slate-500">
						No comments
					</div>
				)}
				{list.map((item) => {
					switch (item.type) {
						case 'comment': {
							const comment = item.data as IResourceComment
							if (comment.createdBy === 'system') {
								if (comment.data?.attribution === 'trigger') {
									return (
										<TaskActivityWrapper
											key={comment.id}
											customAvatar={
												<div className="flex flex-row items-top">
													<OrgUserAvatar size={'xs'} text={'TB'} />
												</div>
											}
										>
											<UserComment label="Trigger Bot" comment={comment} />
										</TaskActivityWrapper>
									)
								}
								return <SystemComment key={comment.id} comment={comment} />
							}

							const orgUser = comment.createdBy
								? rootStore.organizationUsers.users[comment.createdBy]
								: undefined
							return (
								<TaskActivityWrapper userAvatar={orgUser} key={comment.id}>
									<UserComment
										orgUser={orgUser}
										comment={comment}
										onUpdateComment={onUpdateComment}
									/>
								</TaskActivityWrapper>
							)
						}

						case 'task': {
							const task = item.data as ITask
							const orgUser = task.createdByOrgUserId
								? rootStore.organizationUsers.users[task.createdByOrgUserId]
								: undefined
							const assignedOrgUser = task.assignedUserId
								? rootStore.organizationUsers.users[task.assignedUserId]
								: undefined
							return (
								<TaskActivityWrapper
									key={task.id}
									customAvatar={<OrgUserAvatar size={'xs'} user={orgUser} />}
								>
									<TaskActivity
										task={task}
										orgUser={orgUser}
										assignedOrgUser={assignedOrgUser}
									/>
								</TaskActivityWrapper>
							)
						}

						case 'activity-event': {
							const event = item.data as ICandidateEvent | IPositionCandidateEvent
							const orgUser = getOrgUserForEvent(event)
							const source = getSourceForEvent(event)
							return (
								<TaskActivityWrapper
									key={event.id ?? `${event.name}-${event.ts}`}
									customAvatar={
										source === 'user' ? (
											<OrgUserAvatar size={'xs'} user={orgUser} />
										) : source === 'trigger' ? (
											<div className="flex flex-row items-top">
												<OrgUserAvatar size={'xs'} text={'TB'} />
											</div>
										) : undefined
									}
								>
									<ActivityEvent
										event={event}
										orgUser={orgUser}
										source={source ?? ''}
									/>
								</TaskActivityWrapper>
							)
						}

						default: {
							return null
						}
					}
				})}
			</div>
		</div>
	)
}

function ActivityEvent({
	event,
	orgUser,
	source,
}: {
	event: ICandidateEvent | IPositionCandidateEvent
	orgUser?: IOrganizationUser
	source: string
}) {
	return (
		<div className="flex flex-col w-full space-y-2">
			<div className="flex flex-row items-center space-x-2">
				<p className="font-semibold text-sm">
					{orgUser
						? orgUserNameDisplay(orgUser)
						: source === 'trigger'
						? 'Trigger Bot'
						: 'Unknown'}
				</p>
				<div className="w-1 h-1 bg-slate-400 rounded-full" />
				<Tooltip
					content={
						event.ts ? format(new Date(event.ts), "MMM d, yyyy 'at' h:mm a") : 'Unknown'
					}
				>
					<span className="text-xs text-slate-400">
						{event.ts ? formatTimeDifference(new Date(event.ts)) : 'Unknown'}
					</span>
				</Tooltip>
			</div>
			<div className="items-center flex flex-row rounded-lg bg-neutral-100 w-fit py-1 px-2 shadow-sm border border-neutral-200">
				{getMessageForEvent(event, { includeBy: false })}
			</div>
		</div>
	)
}

function TaskActivity({
	task,
	orgUser,
	assignedOrgUser,
}: {
	task: ITask
	orgUser?: IOrganizationUser
	assignedOrgUser?: IOrganizationUser
}) {
	const handleGoToTask = () => {
		window.open(`/tasks/${task.id}`)
	}

	const taskView = useInnerTaskContent(task, 'sm')

	return (
		<div className="flex flex-col w-full space-y-2">
			<div className="flex flex-row items-center space-x-2">
				<p className="font-semibold text-sm">
					{orgUser ? orgUserNameDisplay(orgUser) : 'Unknown'}
				</p>
				<div className="w-1 h-1 bg-slate-400 rounded-full" />
				<div className="flex space-x-1">
					<p className="text-sm text-neutral-700">Created task</p>
					<span
						className="text-sm font-bold hover:cursor-pointer hover:text-blue-600"
						onClick={handleGoToTask}
					>
						{task.referenceNumber}
					</span>
				</div>
				<div className="w-1 h-1 bg-slate-400 rounded-full" />
				<Tooltip
					content={
						task.createdAt
							? format(new Date(task.createdAt), "MMM d, yyyy 'at' h:mm a")
							: 'Unknown'
					}
				>
					<span className="text-xs text-slate-400">
						{task.createdAt
							? formatTimeDifference(new Date(task.createdAt))
							: 'Unknown'}
					</span>
				</Tooltip>
			</div>
			<div className="items-start flex space-x-3 rounded-lg bg-[#f1f3f5] w-fit p-4 border border-neutral-200">
				{task.status === 'completed' ? (
					<span>
						<DoneIcon />
					</span>
				) : task.status === 'closed' ? (
					<span>
						<CloseIcon />
					</span>
				) : (
					<span>
						<TodoIcon />
					</span>
				)}
				<div className="flex flex-col space-y-2">
					<div className="line-clamp-1">{taskView}</div>
				</div>
				{/* <Popover2
					trigger={
						<p className="text-sm mx-2">
							{task.referenceNumber} {task.data?.title}
						</p>
					}
					placement="top-start"
				>
					<div className="flex flex-col bg-neutral-50 rounded-md shadow-lg shadow-neutral-400 max-w-xl space-y-3">
						<div className="flex flex-row items-center space-x-2 bg-neutral-100 p-2">
							{task.status === 'completed' ? (
								<DoneIcon />
							) : task.status === 'closed' ? (
								<CloseIcon />
							) : (
								<TodoIcon />
							)}
							<p className="font-semibold text-sm">
								{task.referenceNumber ?? task.data?.title}
							</p>
							<div className="w-1 h-1 bg-slate-400 rounded-full" />
							<span className="text-xs text-slate-400">
								{task.createdAt
									? formatTimeDifference(new Date(task.createdAt))
									: 'Unknown'}
							</span>
						</div>
						<div className="flex flex-col w-full max-h-72 overflow-y-auto p-3">
							{taskView}
						</div>
						<div className="flex w-full justify-end p-3">
							<Button auto light onClick={handleGoToTask}>
								View Task
							</Button>
						</div>
					</div>
				</Popover2> */}
				<OrgUserAvatar size="xs" user={assignedOrgUser} />
			</div>
			<div className="self-end"></div>
		</div>
	)
}

type CommentTabItemProps = PropsWithChildren<{
	active?: boolean
	onClick?: () => void
}>
function CommentTabItem({ active, children, onClick }: CommentTabItemProps) {
	return (
		<div
			className={clsx('flex items-center px-2 py-1.5 rounded-md', {
				'bg-neutral-100 cursor-default': active,
				'cursor-pointer hover:bg-neutral-50': !active,
			})}
			onClick={onClick}
		>
			<span
				className={clsx('font-medium text-sm', {
					'text-neutral-400': !active,
					'text-neutral-600 font-semibold': active,
				})}
			>
				{children}
			</span>
		</div>
	)
}

function TaskOptions() {
	return (
		<div className="flex flex-row space-x-1">
			<TaskPriority
				task={taskData.value}
				onChanged={(priority) => (taskData.value = { ...taskData.value, priority })}
			/>
			<TaskAssignee
				filterable
				groupByTeams
				value={taskData.value.assignedUserId || 'unassigned'}
				inputPlaceholder={'Assign to...'}
				onChanged={(assignedUserId) =>
					(taskData.value = { ...taskData.value, assignedUserId })
				}
			/>
			<TaskDueBy
				task={taskData.value}
				onChanged={(dueBy) => (taskData.value = { ...taskData.value, dueBy })}
			/>
		</div>
	)
}

function TaskInput({ onSubmit }: { onSubmit?: () => void }) {
	useSignals()

	const handleOnKeydown = useSendShortcut(onSubmit)

	return (
		<div className="w-full">
			<div className="flex flex-col items-start space-y-3 w-full">
				<TextInput
					value={taskData.value.data?.title ?? ''}
					theme={TASK_TITLE_THEME}
					placeholder="Task title"
					required
					type="text"
					onChange={(e) => {
						taskData.value = {
							...taskData.value,
							data: { ...taskData.value.data, title: e.currentTarget.value },
						}
					}}
				/>
				<MentionsInput
					placeholder="Add description..."
					onChange={(e) => {
						taskData.value = {
							...taskData.value,
							data: { ...taskData.value.data, html: e.target.value },
						}
					}}
					onKeyDown={handleOnKeydown}
					value={taskData.value.data?.html ?? ''}
					autoFocus={false}
					className="w-full"
					style={{
						control: {
							fontSize: 16,
							fontWeight: 'normal',
						},

						'&multiLine': {
							highlighter: {
								padding: '8px',
							},
							input: {
								padding: '0px',
								paddingLeft: '0px',
								border: 'none',
							},
						},
					}}
				>
					<Mention trigger="@@@@@@@@@@@@@@@@@@@@@@@" data={[]} />
				</MentionsInput>
			</div>
		</div>
	)
}

type TemplateInputProps = {
	who: string
	message: string
	onWhoChange?: (who: string) => void
	onMessageChange?: (message: string) => void
	onSubmit?: () => void
}
function TemplateInput({
	who,
	message,
	onWhoChange,
	onMessageChange,
	onSubmit,
}: TemplateInputProps) {
	const handleOnKeydown = useSendShortcut(onSubmit)

	return (
		<div className="flex flex-col w-full">
			<div className="flex flex-row w-full items-center">
				<div className="flex w-fit">
					<p className="font-semibold text-neutral-800 text-sm">Who:</p>
				</div>
				<div className="flex pl-1 flex-grow">
					<Input
						placeholder="Who said the details of the note?"
						className="h-8 outline-0 w-full"
						noBorder
						value={who}
						onInput={(e) => onWhoChange?.(e.currentTarget.value)}
					/>
				</div>
			</div>
			<div className="flex flex-row w-full items-top">
				<div className="flex w-fit">
					<p className="font-semibold text-neutral-800 text-sm pt-3">What/Why:</p>
				</div>
				<div className="flex flex-grow">
					<MentionsInput
						placeholder="Copy/Paste the detail in with quotes or summarize if phone conversation..."
						onChange={(e) => onMessageChange?.(e.target.value)}
						onKeyDown={handleOnKeydown}
						value={message}
						autoFocus={false}
						className="w-full"
						style={{
							control: {
								fontSize: 16,
								fontWeight: 'normal',
							},

							'&multiLine': {
								highlighter: {
									padding: '8px',
								},
								input: {
									padding: '10px',
									paddingLeft: '6px',
									border: 'none',
								},
							},
						}}
					>
						<Mention trigger="@@@@@@@@@@@@@@@@@@@@@@@" data={[]} />
					</MentionsInput>
				</div>
			</div>
		</div>
	)
}

function useSendShortcut(onSubmit?: () => void) {
	const handleOnKeydown = useCallback(
		(e: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
			if (!onSubmit) {
				return
			}

			if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
				onSubmit()
			}
		},
		[onSubmit]
	)

	return handleOnKeydown
}

type CommentsInputProps = {
	message: string
	placeholder?: string
	onChange?: (message: string) => void
	onSubmit?: () => void
}
function CommentsInput({ message, onChange, placeholder, onSubmit }: CommentsInputProps) {
	const users = useReaction(() =>
		rootStore.organizationUsers.usersForActiveOrg.map((u) => ({
			id: u.id,
			display: orgUserDisplay(u),
		}))
	)

	const handleOnKeydown = useSendShortcut(onSubmit)

	return (
		<MentionsInput
			placeholder={placeholder || 'write a comment...'}
			onChange={(e) => onChange?.(e.target.value)}
			onKeyDown={handleOnKeydown}
			value={message}
			autoFocus={false}
			className="w-full"
			style={{
				control: {
					fontSize: 16,
					fontWeight: 'normal',
				},

				'&multiLine': {
					highlighter: {
						padding: '10px',
					},
					input: {
						padding: '10px',
						border: 'none',
					},
				},
				suggestions: {
					list: {
						border: '1px solid rgba(0,0,0,0.15)',
						fontSize: 14,
						borderRadius: '8px',
						marginLeft: '-30px',
						marginTop: '8px',
					},
					item: {
						padding: '5px 15px',
						borderBottom: '1px solid rgba(0,0,0,0.15)',
						'&focused': {
							backgroundColor: '#cee4e5',
						},
					},
				},
			}}
		>
			<Mention
				trigger="@"
				displayTransform={(id, display) => {
					const user = rootStore.organizationUsers.users[id]
					if (!user) {
						return display
					}

					if (!user.firstName || !user.lastName) {
						return user.email
					}

					return `${user.firstName} ${user.lastName}`
				}}
				markup="@[__display__]:(__id__)"
				appendSpaceOnAdd
				data={users}
				style={{
					paddingTop: '4px',
					paddingBottom: '4px',
					paddingLeft: '2px',
					paddingRight: '2px',
					marginLeft: '-2px',
				}}
			/>
		</MentionsInput>
	)
}

export function SystemComment({ comment }: { comment: IResourceComment }) {
	const router = useRouter()

	const { taskId } = comment.data ?? ({} as { taskId?: string })

	const handleClick = () => {
		if (!taskId) {
			return
		}

		router.push(`/tasks/${taskId}?finished=1`)
	}
	return (
		<div className="flex flex-grow flex-col space-y-1 w-full cursor-pointer ">
			<div
				className={clsx(
					'flex flex-grow flex-col space-y-1 w-full rounded-lg bg-gray-100 p-2 dark:bg-gray-600 dark:border-gray-600',
					{ 'hover:bg-blue-100 dark:hover:bg-slate-400': !!taskId }
				)}
				onClick={handleClick}
			>
				<div className="prose max-w-full text-center dark:prose-invert break-all">
					{comment.content}
					<p className="text-xs">
						{format(new Date(comment.createdAt), `MMM d 'at' h:mm a`)}
					</p>
				</div>
			</div>
		</div>
	)
}

type UserCommentProps = {
	comment: IResourceComment
	label?: string
	orgUser?: IOrganizationUser
	markdown?: boolean
	onUpdateComment?: (commentId: string, comment: string) => Promise<void>
}
function UserComment({
	comment,
	orgUser,
	markdown = true,
	onUpdateComment,
	label,
}: UserCommentProps) {
	const [isEditing, setIsEditing] = useState(false)
	const [commentDraft, setCommentDraft] = useState(comment.content)

	const content = fixMarkdown(comment.content)
	const isOwnComment = orgUser?.id === rootStore.organizationUsers.activeOrgUserId

	useEffect(() => {
		setCommentDraft(comment.content)
	}, [comment.content])

	const handleEdit = () => {
		if (!isOwnComment) {
			return
		}

		setIsEditing(true)
		console.log(comment)
	}

	const handleCancelEdit = () => {
		setIsEditing(false)
		setCommentDraft(comment.content)
	}

	const handleConfirmEdit = () => {
		setIsEditing(false)
		onUpdateComment?.(comment.id, commentDraft)
	}

	return (
		<div className="flex flex-grow flex-col space-y-1 w-full">
			<div className="max-w-full border rounded-xl shadow-sm p-2 bg-white">
				<div className="flex justify-between items-center">
					<div className="flex items-center">
						<span className="font-medium text-sm">
							{label ? `${label}` : orgUserNameDisplay(orgUser)}
						</span>
						<span className="mx-2 w-1 h-1 rounded-full bg-slate-400" />
						<Tooltip
							content={
								comment.createdAt
									? format(new Date(comment.createdAt), "MMM d, yyyy 'at' h:mm a")
									: 'Unknown'
							}
						>
							<span className="text-xs text-slate-400">
								{comment.createdAt
									? formatTimeDifference(new Date(comment.createdAt))
									: 'Unknown'}
							</span>
						</Tooltip>
					</div>
					{isOwnComment && !isEditing ? (
						<Button auto light size="xs" onClick={handleEdit}>
							<HiPencil />
						</Button>
					) : isOwnComment ? (
						<div className="flex space-x-1">
							<Button auto light size="xs" onClick={handleCancelEdit}>
								<HiX className="fill-red-600" />
							</Button>
							<Button auto light size="xs" onClick={handleConfirmEdit}>
								<HiCheck className="fill-emerald-600" />
							</Button>
						</div>
					) : null}
				</div>
				{isEditing ? (
					<CommentsInput message={commentDraft} onChange={setCommentDraft} />
				) : (
					<div className="prose dark:prose-invert text-sm">
						{markdown ? (
							<ReactMarkdown skipHtml>
								{Autolinker.link(linkMentions(content, true))}
							</ReactMarkdown>
						) : (
							<div
								dangerouslySetInnerHTML={{
									__html: Autolinker.link(linkMentions(content))
										.split('\n')
										.join('<br/>'),
								}}
							></div>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

type PositionCandidateCommentsPanelProps = {
	positionCandidateId: string
	taskId?: string
	showActivity?: boolean
	showSystem?: boolean
}
export const PositionCandidateCommentsPanel = observer(function ({
	positionCandidateId,
	taskId,
	showSystem = false,
	showActivity = false,
}: PositionCandidateCommentsPanelProps) {
	const [updateComments, mutateComments] = useMutate()
	const [addingComment, setAddingComment] = useState(false)

	const positionCandidate = useReaction(
		() => rootStore.positions.getPositionCandidateById(positionCandidateId),
		10,
		[updateComments, positionCandidateId]
	)
	const comments = [...(positionCandidate?.comments ?? [])].reverse()

	const handleAddComment = async (comment: string, plainComment?: string) => {
		if (!positionCandidate) {
			return
		}

		setAddingComment(true)
		await rootStore.positions.addCandidateComment(
			positionCandidate.candidateId,
			positionCandidate.positionId,
			comment,
			{
				plainComment,
				taskId,
			}
		)
		setAddingComment(false)
	}

	const handleUpdateComment = async (commentId: string, comment: string) => {
		if (!positionCandidate) {
			return
		}

		await rootStore.positions.updateCandidateComment(
			positionCandidate.candidateId,
			positionCandidate.positionId,
			commentId,
			comment
		)
		mutateComments()
	}

	const finalComments = showSystem
		? comments
		: comments.filter((c) => c.createdBy !== 'system' || c.data?.attribution === 'trigger')

	return (
		<CommentsPanel
			positionCandidateId={positionCandidateId}
			comments={finalComments}
			loading={addingComment}
			onSubmitMessage={handleAddComment}
			onUpdateComment={handleUpdateComment}
			showActivity={showActivity}
		/>
	)
})

function fixMarkdown(text: string) {
	// sometimes bold text comes in as **TEXT ** instead of **TEXT**
	return text.replace(/\b +\*{2}/g, '**')
}
