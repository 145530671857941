import { deburr, words } from 'lodash'

export function nameToObject(name: string) {
	const val: Record<string, number> = {}
	const valCount: Record<string, number> = {}

	deburr(words(name).join(' '))
		.toLowerCase()
		.split('')
		.forEach((c) => {
			if (!(c in valCount)) {
				valCount[c] = 0
			}

			const count = valCount[c]

			valCount[c]++

			if (c === ' ') {
				val[`${c}${count}`] = 0
			} else {
				val[`${c}${count}`] = (c.charCodeAt(0) - 96) / 26
			}
		})

	for (const key in val) {
		val[key] /= 30
	}

	return val
}

export function nameToNumbers(name: string, max = 32) {
	const val = deburr(name)
		.toLowerCase()
		.split('')
		.map((char) => {
			const c = char.charCodeAt(0)
			if (isNaN(c)) {
				return 0
			}

			return c / 255
		})
		.slice(0, max)

	if (val.length < max) {
		for (let i = val.length; i < max; ++i) {
			val.push(0)
		}
	}

	return val
}
