import { styled } from '@stitches/react'
import { ITriggerDelayUnit } from '@touchpoints/requests'
import { Dropdown, Input, Label, Select2, SelectOption } from '@touchpoints/ui'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { ReactNode, useState } from 'react'
import {
	HiChevronDown,
	HiChevronUp,
	HiOutlineChevronLeft,
	HiOutlineChevronRight,
	HiOutlineClock,
} from 'react-icons/hi2'

export interface IDelay {
	value: number
	unit: ITriggerDelayUnit
}

type AssigneeSelectProps = {
	delay: IDelay
	label?: string
	placeholder?: string | JSX.Element
	trigger?: JSX.Element
	readOnly?: boolean
	onChange?: (delay: IDelay) => void
}
export const DelaySelect = observer(function ({
	label,
	delay,
	placeholder,
	trigger,
	readOnly,
	onChange,
}: AssigneeSelectProps) {
	const [open, setOpen] = useState(false)
	const [custom, setCustom] = useState(false)

	const DelayLabel = ({ value }: { value: string }) => {
		return (
			<div className="flex items-center space-x-2 text-zinc-900 text-sm font-medium">
				<HiOutlineClock />
				&nbsp; {value}
			</div>
		)
	}

	const delayOptions: {
		value: IDelay
		label: ReactNode
	}[] = [
		{
			value: { value: 5, unit: 'seconds' },
			label: <DelayLabel value={'5 seconds'} />,
		},
		{
			value: { value: 30, unit: 'seconds' },
			label: <DelayLabel value={'30 seconds'} />,
		},
		{
			value: { value: 1, unit: 'minutes' },
			label: <DelayLabel value={'1 minute'} />,
		},
	]

	return (
		<div>
			{label && <Label>{label}</Label>}
			<Dropdown
				side="bottom"
				align="start"
				open={open}
				onCloseAutoFocus={() => setOpen(false)}
				onEscapeKeyDown={() => setOpen(false)}
				onPointerDownOutside={() => setOpen(false)}
				onFocusOutside={() => setOpen(false)}
				onInteractOutside={() => setOpen(false)}
				trigger={
					trigger ? (
						<div onClick={() => setOpen(!readOnly && !open)}>{trigger}</div>
					) : (
						<div
							className={clsx(
								`flex justify-between w-full items-center border rounded-md shadow-xs px-3 py-2 hover:cursor-pointer`,
								'cursor-pointer font-light text-[#11181C] focus:outline focus:outline-[#0091FF]',
								{ 'border-[#0091FF]': open, 'bg-slate-50': !!readOnly }
							)}
							onClick={() => setOpen(!readOnly && !open)}
						>
							<div className="w-full flex truncate">
								{delay ? (
									<div className="flex items-center justify-between w-full">
										<div className="flex space-x-3 items-center">
											<HiOutlineClock size={20} />
											<span className="text-zinc-900 font-normal">
												{delay.value}
											</span>
										</div>

										<span className="px-3 text-zinc-900 font-normal">
											{delay.unit}
										</span>
									</div>
								) : (
									<Dropdown.Label className="truncate text-slate-400 font-light">
										{placeholder}
									</Dropdown.Label>
								)}
							</div>
							{!open && (
								<div className="w-[16px]">
									<HiChevronDown />
								</div>
							)}
							{open && (
								<div className="w-[16px]">
									<HiChevronUp />
								</div>
							)}
						</div>
					)
				}
			>
				{custom ? (
					<CustomPanel
						delay={delay}
						onGoBack={() => setCustom(false)}
						onChange={(delay) => {
							onChange?.(delay)
							setOpen(false)
						}}
					/>
				) : (
					<div className="px-2 border">
						{delayOptions.map((option, index) => {
							const isString = typeof option.label === 'string'
							return (
								<Dropdown.Item
									key={`${option.value}-${index}`}
									onSelect={() => {
										onChange?.(option.value)
										setOpen(false)
									}}
								>
									{isString && (
										<p className="font-light text-sm leading-4 p-3 hover:bg-slate-50 truncate">
											{option.label}
										</p>
									)}
									{!isString && <div className="px-2 py-1">{option.label}</div>}
								</Dropdown.Item>
							)
						})}
						<Dropdown.Item
							key={`custom-delay`}
							onSelect={() => {
								setCustom(true)
							}}
						>
							<div className="flex items-center justify-between px-2 py-1">
								<div className="flex items-center space-x-2 text-zinc-900 text-sm font-medium">
									<HiOutlineClock />
									&nbsp; Custom
								</div>
								<HiOutlineChevronRight />
							</div>
						</Dropdown.Item>
					</div>
				)}
			</Dropdown>
		</div>
	)
})

const CustomInput = styled(Input, {
	'&[type=number]::-webkit-inner-spin-button': {
		'-webkit-appearance': 'none',
	},
	border: 'none',
	outline: 'none',
	'&:focus': {
		outline: 'none',
		border: 'none',
	},
	padding: '0.5rem',
	width: '100px',
})

function CustomPanel({
	delay,
	onGoBack,
	onChange,
}: {
	delay: IDelay
	onGoBack: () => void
	onChange: (delay: IDelay) => void
}) {
	const [value, setValue] = useState(delay.value)
	const [unit, setUnit] = useState<ITriggerDelayUnit>(delay.unit || 'seconds')

	return (
		<div className="p-2.5 flex flex-col space-y-3">
			<div className="flex items-center justify-between">
				<div
					className="flex items-center space-x-2 text-zinc-900 text-sm font-medium hover:cursor-pointer hover:text-gray-500"
					onClick={onGoBack}
				>
					<HiOutlineChevronLeft />
					&nbsp; Back
				</div>
			</div>
			<div className="flex items-center rounded-md border border-zinc-200 pl-1">
				<CustomInput
					type="number"
					value={value as unknown as string}
					onChange={(e: any) => setValue(e)}
					noBorder
					noFocus
				>
					<Select2
						options={[
							{ value: 'seconds', label: 'Seconds' },
							{ value: 'minutes', label: 'Minutes' },
							{ value: 'hours', label: 'Hours' },
							{ value: 'days', label: 'Days' },
							{ value: 'weeks', label: 'Weeks' },
						]}
						triggerClassName="border-0"
						value={unit}
						onChange={function (value: SelectOption): void {
							setUnit(value.value as ITriggerDelayUnit)
						}}
					/>
				</CustomInput>
			</div>
			<div
				className="p-1 px-2 self-end border rounded-md border-slate-200 hover:cursor-pointer  hover:text-gray-500"
				onClick={() => onChange({ value: value ?? 0, unit })}
			>
				Set delay
			</div>
		</div>
	)
}
