import { AbilityBuilder, Ability } from '@casl/ability'
import type { OrganizationUser } from '@touchpoints/requests'

import type { AppAbility } from './types'

function defineAdminRole(user: OrganizationUser) {
	const { can, cannot, build } = new AbilityBuilder<AppAbility>(Ability)

	cannot('create', 'user')
	can('read', 'user')
	can('update', 'user', { id: user.id })
	can('update', 'user', ['role', 'status'])
	can('delete', 'user')

	can(['create', 'read', 'update', 'delete'], 'invite')
	can(['create', 'read', 'update', 'delete'], 'position')
	can(['create', 'read', 'update', 'delete'], 'position-candidate')
	can(['read', 'delete'], 'position-candidate-action-item')
	can(['create', 'read', 'update', 'delete'], 'availability')
	can(['create', 'read', 'update', 'delete'], 'candidate')
	can(['create', 'read', 'update', 'delete'], 'candidate-time-selection')
	can(['create', 'read', 'update', 'delete'], 'sequence')
	can(['create', 'read', 'update', 'delete'], 'sequence-instance')
	can(['create', 'read', 'update', 'delete'], 'template')
	can(['create', 'read', 'update', 'delete'], 'snippet')
	can(['create', 'read', 'update', 'delete'], 'trigger')
	can(['create', 'read', 'update', 'delete'], 'account')
	can(['create', 'read', 'update', 'delete'], 'team')
	can(['create', 'read', 'update', 'delete'], 'meeting')

	can(['create', 'read', 'update', 'delete'], 'task')
	can('update', 'task', ['assignedUserId'])
	can('update', 'task', ['dueBy'])

	can(['create', 'read', 'update', 'delete'], 'trigger-action')
	can(['create', 'read', 'update', 'delete'], 'blueprint')
	can(['create', 'read', 'update', 'delete'], 'stage-templates')
	can(['create', 'read', 'update', 'delete'], 'question')
	can(['create', 'read', 'update', 'delete'], 'call-script')

	return build()
}

function defineRecruiterRole(user: OrganizationUser) {
	const { can, cannot, build } = new AbilityBuilder<AppAbility>(Ability)

	cannot('create', 'user')
	can('read', 'user')
	can('update', 'user', { id: user.id })
	cannot('update', 'user', ['role', 'status'])
	cannot('delete', 'user')

	cannot(['create', 'update', 'delete'], 'invite')
	can('read', 'invite')

	can(['create', 'read', 'update', 'delete'], 'position')
	can(['create', 'read', 'update', 'delete'], 'position-candidate')
	can(['read'], 'position-candidate-action-item')
	cannot(['delete'], 'position-candidate-action-item')
	cannot('update', 'position', [
		'teamId',
		'supportContactId',
		'engagementContactId',
		'accountManagerId',
		'defaultMailbox',
	])
	cannot('update', 'position-candidate', [
		'engagementContactId',
		'recruiterId',
		'accountExecutiveId',
		'supportContactId',
	])

	can(['create', 'read', 'update', 'delete'], 'availability')
	can(['create', 'read', 'update', 'delete'], 'candidate')
	can(['create', 'read', 'update', 'delete'], 'candidate-time-selection')
	can(['create', 'read', 'update', 'delete'], 'sequence')
	can(['create', 'read', 'update', 'delete'], 'sequence-instance')
	can(['create', 'read', 'update', 'delete'], 'template')
	can(['create', 'read', 'update', 'delete'], 'snippet')
	can(['create', 'read', 'update', 'delete'], 'trigger')
	can(['create', 'read', 'update', 'delete'], 'account')
	can(['create', 'read', 'update', 'delete'], 'team')
	can(['create', 'read', 'update', 'delete'], 'meeting')

	can(['create', 'read'], 'task')
	can('update', 'task', { assignedUserId: user.id })
	can('update', 'task', ['dueBy'])
	cannot('update', 'task', ['assignedUserId'])
	cannot('delete', 'task')

	can(['create', 'read', 'update', 'delete'], 'trigger-action')
	can(['create', 'read', 'update', 'delete'], 'blueprint')

	can(['create', 'read', 'update', 'delete'], 'stage-templates')
	can(['create', 'read', 'update', 'delete'], 'question')
	can(['create', 'read', 'update', 'delete'], 'call-script')

	return build()
}

function defineViewerRole(_user: OrganizationUser) {
	const { can, cannot, build } = new AbilityBuilder<AppAbility>(Ability)

	cannot('create', 'user')
	can('read', 'user')
	cannot('update', 'user')
	cannot('delete', 'user')

	can('read', 'invite')
	cannot(['create', 'update', 'delete'], 'invite')

	can('read', 'position')
	cannot(['create', 'update', 'delete'], 'position')

	can('read', 'position-candidate')
	cannot(['create', 'update', 'delete'], 'position-candidate')

	can('read', 'availability')
	cannot(['create', 'update', 'delete'], 'availability')

	can('read', 'candidate')
	cannot(['create', 'update', 'delete'], 'candidate')

	can('read', 'candidate-time-selection')
	cannot(['create', 'update', 'delete'], 'candidate-time-selection')

	can('read', 'sequence')
	cannot(['create', 'update', 'delete'], 'sequence')

	can('read', 'sequence-instance')
	cannot(['create', 'update', 'delete'], 'sequence-instance')

	can('read', 'template')
	cannot(['create', 'update', 'delete'], 'template')

	can('read', 'snippet')
	cannot(['create', 'update', 'delete'], 'snippet')

	can('read', 'trigger')
	cannot(['create', 'update', 'delete'], 'trigger')

	can('read', 'account')
	cannot(['create', 'update', 'delete'], 'account')

	can('read', 'team')
	cannot(['create', 'update', 'delete'], 'team')

	can('read', 'meeting')
	cannot(['create', 'update', 'delete'], 'meeting')

	can('read', 'task')
	cannot(['create', 'update', 'delete'], 'task')

	can('read', 'trigger-action')
	cannot(['create', 'update', 'delete'], 'trigger-action')

	can('read', 'blueprint')
	cannot(['create', 'update', 'delete'], 'blueprint')

	can('read', 'stage-templates')
	cannot(['create', 'update', 'delete'], 'stage-templates')

	can('read', 'question')
	cannot(['create', 'update', 'delete'], 'question')

	can('read', 'call-script')
	cannot(['create', 'update', 'delete'], 'call-script')

	return build()
}

export function abilityFor(user: OrganizationUser) {
	const role = user.role ?? 'viewer'

	switch (role) {
		case 'admin':
			return defineAdminRole(user)
		case 'recruiter':
			return defineRecruiterRole(user)
		default:
		case 'viewer':
			return defineViewerRole(user)
	}
}
