import { Button, Collapse } from '@nextui-org/react'
import { PropsWithChildren, useEffect, useState } from 'react'

import { rootStore } from '@store'

import { CustomFieldsForm } from '@components/CustomFields'
import { PositionCandidateInfo } from '@components/positions/candidates/PositionCandidateInfo'
import { OrgUserAvatar } from '@components/shared/OrgUserAvatar'
import { DoneIcon } from '@components/tasks/icons'
import { orgUserDisplay } from '@services/format'
import type { ICandidate } from '@touchpoints/requests'
import { timezoneOptions } from '@touchpoints/timezone'
import {
	InputForField,
	InputWithCopy,
	Label,
	PhoneInput,
	Select2,
	TagsInput,
	TextArea,
	createSelectOnChange,
	toast,
} from '@touchpoints/ui'
import { quickFormat } from '@touchpoints/utils'
import { observer } from 'mobx-react-lite'
import Link from 'next/link'
import {
	HiArrowCircleDown,
	HiArrowCircleUp,
	HiArrowSmRight,
	HiOutlineClipboardCopy,
	HiOutlinePlus,
	HiOutlineTrash,
} from 'react-icons/hi'
import { EmailSource } from './EmailSource'

type Props = {
	candidate: Partial<ICandidate>
	onFieldChange?: (field: string, val: unknown) => void
	showLastPosition?: boolean
	positionId?: string
	hideEmptyFields?: boolean
	mode?: 'edit' | 'create'
	readOnly?: boolean
}

export const CandidateForm = observer(function ({
	candidate,
	onFieldChange,
	showLastPosition = false,
	positionId,
	children,
	mode = 'edit',
	readOnly = false,
}: PropsWithChildren<Props>) {
	const [timezones] = useState(timezoneOptions())
	const users = rootStore.organizationUsers.usersForActiveOrg.map((u) => ({
		value: u.id,
		label: (
			<div className="flex items-center space-x-2">
				<OrgUserAvatar user={u} size="xs" />
				&nbsp; {orgUserDisplay(u)}
			</div>
		),
	}))
	const sourcers = [{ value: 'other', label: 'Other' }, ...users]

	const positionCandidate =
		positionId && candidate?.id
			? rootStore.positions.getPositionCandidate(candidate.id, positionId)
			: showLastPosition && candidate?.id
			? rootStore.positions.getLastPositionCandidate(candidate.id)
			: undefined

	const position = positionId
		? rootStore.positions.getPositionById(positionId)
		: showLastPosition && positionCandidate
		? rootStore.positions.getPositionById(positionCandidate?.positionId)
		: undefined

	useEffect(() => {
		if ((!showLastPosition && !positionId) || !candidate?.id) {
			return
		}
		rootStore.positions.fetchPositionCandidatesForCandidate(candidate.id)
	}, [candidate?.id, showLastPosition, positionId])

	const handleCopy = (value: string) => {
		navigator.clipboard.writeText(value)
		toast.success('Copied to clipboard')
	}

	const handleDemoteEmail = () => {
		if (!candidate.email) {
			return
		}
		if (!candidate.additionalEmails) {
			candidate.additionalEmails = []
		}
		candidate.additionalEmails.push(candidate.email)
		onFieldChange?.('additionalEmails', candidate.additionalEmails)
		onFieldChange?.('email', '')
	}

	const handlePromoteEmail = (email: string) => {
		if (!candidate.additionalEmails) {
			candidate.additionalEmails = []
		}

		console.log(candidate.email, email)
		if (candidate.email) {
			candidate.additionalEmails.push(candidate.email)
		}

		onFieldChange?.(
			'additionalEmails',
			candidate.additionalEmails.filter((e) => e !== email)
		)
		onFieldChange?.('email', email)
	}

	return (
		<div className="flex flex-col w-full px-6 py-4 space-y-4">
			<div className="flex items-center justify-between">
				<div className="pt-0">
					<p className="text-sm font-semibold">Personal information</p>
				</div>
			</div>

			<div className="w-full flex items-center space-x-2">
				<InputForField
					label="First Name"
					field="firstName"
					data={candidate}
					readOnly={readOnly}
					onFieldChange={onFieldChange}
					tabIndex={1}
					autoFocus
				/>
				<InputForField
					label="Last Name"
					field="lastName"
					data={candidate}
					readOnly={readOnly}
					onFieldChange={onFieldChange}
					tabIndex={2}
				/>
			</div>

			<InputForField
				label="Nickname"
				field="nickname"
				data={candidate}
				readOnly={readOnly}
				onFieldChange={onFieldChange}
				tabIndex={3}
			/>
			<div className="flex flex-row w-full space-x-2">
				<InputForField
					label={
						<div className="w-full flex items-center justify-between">
							<Label>Email</Label>
							{candidate.email && !readOnly && (
								<button
									className="p-1 flex items-center justify-end space-x-1 w-full text-sm text-zinc-500"
									onClick={() => {
										if (!candidate.additionalEmails) {
											candidate.additionalEmails = []
										}
										candidate.additionalEmails?.push('')
										onFieldChange?.(
											'additionalEmails',
											candidate.additionalEmails
										)
									}}
								>
									<HiOutlinePlus className="cursor-pointer hover:opacity-100" />
									<span>Aditional email</span>
								</button>
							)}
						</div>
					}
					field="email"
					data={candidate}
					readOnly={readOnly}
					onFieldChange={onFieldChange}
					tabIndex={4}
				/>
				{mode === 'edit' && (
					<EmailSource
						candidate={candidate as ICandidate}
						email={candidate.email ?? ''}
					/>
				)}
				{!readOnly && (
					<div className="flex items-end space-x-1 max-h-[66px]">
						<Button
							auto
							light
							className="hover:bg-red-200 border-red-500"
							disabled={!candidate.email}
							onClick={handleDemoteEmail}
						>
							<HiArrowCircleDown className="w-5 h-5 mr-2 fill-red-500" /> Demote
						</Button>
					</div>
				)}
			</div>
			{candidate.additionalEmails?.map((email, idx) => {
				return (
					<div key={idx} className="flex items-center space-x-2">
						<HiArrowSmRight />
						<InputWithCopy
							aria-label="Additional Email"
							value={email}
							readOnly={readOnly}
							onChange={(e) => {
								const emails = candidate.additionalEmails?.map((u) =>
									u === email ? e : u
								)
								onFieldChange?.('additionalEmails', emails)
							}}
						/>
						{mode === 'edit' && (
							<EmailSource candidate={candidate as ICandidate} email={email ?? ''} />
						)}
						{!readOnly && (
							<Button
								auto
								light
								className="hover:bg-emerald-200"
								onClick={() => handlePromoteEmail(email)}
							>
								<HiArrowCircleUp className="w-5 h-5 mr-2" /> Promote
							</Button>
						)}
						{!readOnly && (
							<HiOutlineTrash
								className="m-2 cursor-pointer text-xl hover:opacity-60 text-red-500"
								size={25}
								onClick={() => {
									onFieldChange?.(
										'additionalEmails',
										candidate.additionalEmails?.filter((e) => e !== email)
									)
								}}
							/>
						)}
					</div>
				)
			})}

			<TagsInput
				value={candidate.skills ?? []}
				onChange={(value) => onFieldChange?.('skills', value)}
				readOnly={readOnly}
				label="Skills"
				placeHolder={readOnly ? '' : 'Add a skill'}
				tabIndex={5}
			/>

			<PhoneInput
				label="Phone"
				fullWidth
				value={quickFormat(candidate.phone)}
				readOnly={readOnly}
				onChange={(value) => onFieldChange?.('phone', value)}
				defaultCountry="US"
				countryOptionsOrder={['US', '|', '...']}
				tabIndex={6}
			>
				<HiOutlineClipboardCopy
					aria-label="Copy to clipboard"
					className="cursor-pointer text-xl hover:opacity-60 mt-2.5"
					onClick={() => {
						handleCopy(candidate.phone || '')
					}}
				/>
			</PhoneInput>
			<Select2
				label="Timezone"
				placeholder="Select timezone"
				value={timezones.find((tz) => tz.value === candidate.timezone)?.label ?? ''}
				options={timezones}
				readOnly={readOnly}
				onChange={createSelectOnChange((value) => {
					onFieldChange?.('timezone', value)
				})}
				tabIndex={7}
			/>
			<div
				className="flex items-center space-x-2 hover:cursor-pointer rounded-md focus:outline focus:outline-[#0091FF]"
				onClick={(_) => {
					if (!readOnly) onFieldChange?.('resubmittable', !candidate.resubmittable)
				}}
				tabIndex={8}
			>
				{candidate.resubmittable && (
					<div className="w-4">
						<DoneIcon size={15} />
					</div>
				)}
				{!candidate.resubmittable && (
					<div className="w-4 h-4 rounded-full bg-slate-50 border" />
				)}
				<p className="text-zinc-900 text-sm font-normal leading-[19px]">
					Candidate can be submitted to more than one position
				</p>
			</div>

			<div className="pt-5">
				<p className="text-sm font-semibold">Socials</p>
			</div>
			<InputForField
				label="LinkedIn Profile"
				field="linkedinUrl"
				launch
				data={candidate}
				readOnly={readOnly}
				onFieldChange={onFieldChange}
				tabIndex={9}
			/>
			<div className="pt-5">
				<p className="text-sm font-semibold">Recent Employer</p>
			</div>
			<InputForField
				label="Employer"
				field="recentEmployer"
				data={candidate}
				readOnly={readOnly}
				onFieldChange={onFieldChange}
				tabIndex={10}
			/>
			<InputForField
				label="Job Title"
				field="recentJobTitle"
				data={candidate}
				readOnly={readOnly}
				onFieldChange={onFieldChange}
				tabIndex={11}
			/>

			<div className="pt-5">
				<p className="text-sm font-semibold">Notes</p>
			</div>
			<TextArea
				value={candidate.notes ?? ''}
				placeholder={readOnly ? '' : 'Add notes for the candidate'}
				rows={5}
				readOnly={readOnly}
				onChange={(e) => onFieldChange?.('notes', e)}
				tabIndex={12}
			/>
			<Select2
				label="Main Contact"
				placeholder="Select contact"
				value={users.find((u) => u.value === candidate.mainContactId)?.label ?? ''}
				options={users}
				onChange={createSelectOnChange((value) => {
					onFieldChange?.('mainContactId', value)
				})}
				readOnly={readOnly}
				tabIndex={13}
			/>

			<Select2
				label="Sourcer"
				placeholder="Select sourcer"
				value={sourcers.find((u) => u.value === candidate.sourcer)?.label ?? ''}
				options={sourcers}
				onChange={createSelectOnChange((value) => {
					onFieldChange?.('sourcer', value)
				})}
				readOnly={readOnly}
				tabIndex={14}
			/>

			{positionCandidate && (
				<PositionCandidateInfo
					label={`${position?.referenceName}${
						position?.account?.name ? ` with ${position?.account?.name}` : ''
					}`}
					positionCandidate={positionCandidate}
					readOnly={true}
				/>
			)}
			<Collapse
				bordered
				title="Custom fields"
				css={{
					borderRadius: '$xs',
				}}
			>
				<CustomFieldsForm
					fields={rootStore.candidates.settings.customFields}
					data={candidate}
					onFieldChanged={onFieldChange}
					readOnly={readOnly}
					empty={
						<div className="flex w-full justify-center items-center p-5">
							<p>
								Candidates have no custom fields.{' '}
								<Link href="/settings/candidates">Create them here.</Link>
							</p>
						</div>
					}
				/>
			</Collapse>
			{children}
		</div>
	)
})
