import { Tooltip } from '@nextui-org/react'
import type { CustomField, ICustomField } from '@touchpoints/requests'
import {
	DatePicker,
	InputWithCopy,
	Label,
	Select,
	TextArea,
	createSelectOnChange,
	toast,
} from '@touchpoints/ui'
import keyBy from 'lodash/keyBy'
import { PropsWithChildren, useEffect, useState } from 'react'
import { HiClipboardCopy, HiInformationCircle } from 'react-icons/hi'
import { ListInput } from './ListInput'

const CustomFieldLabel = ({ field }: { field: ICustomField }) => {
	return (
		<div className="flex items-center space-x-2">
			<Label>{field.name}</Label>
			{field.help && (
				<Tooltip placement="left" content={field.help}>
					<HiInformationCircle />
				</Tooltip>
			)}
		</div>
	)
}

type CustomFieldsFormProps = {
	fields: ICustomField[]
	data: { customFields?: Record<string, CustomField> }
	onFieldChanged?: (field: string, value: unknown) => void
	empty?: JSX.Element
	readOnly?: boolean
}
export function CustomFieldsForm({
	fields,
	data,
	onFieldChanged,
	children,
	empty,
	readOnly = false,
}: PropsWithChildren<CustomFieldsFormProps>) {
	const [customFieldsByKey, setCustomFieldsByKey] = useState<Record<string, ICustomField>>({})

	useEffect(() => {
		setCustomFieldsByKey(keyBy(fields, 'key'))
	}, [fields])

	const passesConditions = (field: ICustomField) => {
		if (!field.conditions || field.conditions.length <= 0) {
			return true
		}

		if (!data.customFields) {
			return true
		}

		for (const condition of field.conditions) {
			const { fieldKey, value } = condition
			if (!(fieldKey in customFieldsByKey)) {
				continue
			}

			const otherField = customFieldsByKey[fieldKey]
			if (data.customFields[otherField.key]?.value !== value) {
				return false
			}
		}

		return true
	}

	const handleCopyField = (field: string) => {
		const value = data.customFields?.[field]?.value ?? ''
		navigator.clipboard.writeText(value?.toString())
		toast.success('Copied to clipboard')
	}

	return (
		<div className="flex flex-col w-full space-y-4 px-3">
			{fields.length <= 0 && <div className="flex">{empty}</div>}
			{fields.map((field) => {
				if (!passesConditions(field)) {
					return null
				}

				switch (field.type) {
					case 'single-line':
						return (
							<InputWithCopy
								aria-label="Single line input"
								key={field.key}
								label={<CustomFieldLabel field={field} />}
								readOnly={readOnly}
								value={
									(data.customFields &&
										(data.customFields[field.key]?.value as string)) ??
									''
								}
								onChange={(e) =>
									onFieldChanged?.('customFields', {
										...data.customFields,
										[field.key]: {
											type: 'string',
											value: e,
										},
									})
								}
							/>
						)

					case 'multi-line':
						return (
							<div className="flex w-full relative" key={field.key}>
								<TextArea
									aria-label="Multi line input"
									label={<CustomFieldLabel field={field} />}
									// readOnly={readOnly}
									value={
										((data.customFields &&
											data.customFields[field.key]?.value) as string) ?? ''
									}
									onChange={(e) =>
										onFieldChanged?.('customFields', {
											...data.customFields,
											[field.key]: {
												type: 'string',
												value: e,
											},
										})
									}
								/>
								<div className="absolute flex w-full justify-end pt-11">
									<HiClipboardCopy
										className="m-2 cursor-pointer text-xl hover:opacity-60"
										onClick={() => handleCopyField(field.key)}
									/>
								</div>
							</div>
						)

					case 'select':
						return (
							<Select
								key={field.key}
								isDisabled={readOnly}
								label={
									<div className="flex items-center space-x-2">
										<p>{field.name}</p>
										{field.help && (
											<Tooltip placement="right" content={field.help}>
												<HiInformationCircle />
											</Tooltip>
										)}
									</div>
								}
								options={field.options?.map((o) => ({ value: o }))}
								getOptionLabel={(s) => {
									return s.value
								}}
								value={
									(data.customFields && {
										value:
											(data.customFields[field.key]?.value as string) ??
											'None',
									}) ?? { value: 'None' }
								}
								onChange={createSelectOnChange((v) =>
									onFieldChanged?.('customFields', {
										...data.customFields,
										[field.key]: { type: 'string', value: v },
									})
								)}
							/>
						)

					case 'list':
						return (
							<ListInput
								key={field.key}
								label={
									<div className="flex items-center space-x-2">
										<p>{field.name}</p>
										{field.help && (
											<Tooltip placement="right" content={field.help}>
												<HiInformationCircle />
											</Tooltip>
										)}
									</div>
								}
								readOnly={readOnly}
								values={
									((data.customFields &&
										data.customFields[field.key]?.value) as string[]) ?? []
								}
								onValuesChanged={(v) => {
									onFieldChanged?.('customFields', {
										...data.customFields,
										[field.key]: { type: 'list', value: v },
									})
								}}
							/>
						)
					case 'date': {
						const dateVal =
							data.customFields && (data.customFields[field.key]?.value as Date)
						return (
							<DatePicker
								key={field.key}
								readOnly={readOnly}
								label={
									<div className="flex items-center space-x-2">
										<p>{field.name}</p>
										{field.help && (
											<Tooltip placement="right" content={field.help}>
												<HiInformationCircle />
											</Tooltip>
										)}
									</div>
								}
								selected={dateVal ?? null}
								placeholderText="Select a date"
								onChange={(v) => {
									onFieldChanged?.('customFields', {
										...data.customFields,
										[field.key]: { type: 'date', value: v },
									})
								}}
							/>
						)
					}
				}
			})}
			{children}
		</div>
	)
}
