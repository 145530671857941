import React from 'react'
import { Modal as NextUIModal, ModalProps } from '@nextui-org/react'
import { useEffect, useState } from 'react'
import { styled } from '@stitches/react'

import type { PropsWithChildren } from 'react'

export type Props = {
	isOpen?: boolean
	onClose?: () => void
}

const Root = styled(NextUIModal, {
	zIndex: 999,
})

const Header = styled(NextUIModal.Header, {
	//
})

const Body = styled(NextUIModal.Body, {
	overflow: 'visible',
})

const Footer = styled(NextUIModal.Footer, {
	//
})

export function Modal({
	isOpen,
	onClose,
	children,
	...props
}: PropsWithChildren<Props & Partial<ModalProps>>) {
	const [open, setOpen] = useState(false)

	useEffect(() => {
		setOpen(isOpen ?? false)
	}, [isOpen])

	useEffect(() => {
		if (!isOpen) {
			return
		}

		const item = document.getElementById('nextui-modal')
		if (!item) {
			return
		}

		item.style.position = 'fixed'
		item.style.zIndex = '1000'

		// next tick
		setTimeout(() => {
			const content = item.firstChild as HTMLDivElement
			if (content) {
				content.style.zIndex = '2000'
			}
		})
	}, [isOpen])

	useEffect(() => {
		const toolTipElem = document.getElementById('nextui-tooltip')
		const item = document.getElementById('nextui-modal')
		if (!item || !toolTipElem) {
			return
		}

		item.parentElement?.appendChild(toolTipElem)
	}, [])

	return (
		<Root
			open={open}
			onClose={() => {
				setOpen(false)
				onClose?.()
			}}
			{...props}
		>
			{children}
		</Root>
	)
}

Modal.Header = Header
Modal.Body = Body
Modal.Footer = Footer
