import { getSession } from 'next-auth/react'

import { api } from '@touchpoints/requests'
import { getApiUrl } from '../environment'
import * as token from '@services/apiToken'
import { Session } from 'next-auth'

async function _getSession() {
	const session = await getSession()
	return session as Session & { accessToken?: string; refreshToken?: string; userId?: string }
}

export const refreshToken = api.makeRefreshToken(getApiUrl())
export const authHeader = api.makeAuthHeader(async () => {
	// look for it in localStorage first since that is the most updated one
	// we then fallback to what's in the next-auth session
	let accessToken = token.get()
	if (!accessToken) {
		const session = await _getSession()
		if (!session || !session.accessToken) {
			return ''
		}

		accessToken = session.accessToken as string
	}

	return accessToken
})
export const doRefreshToken = async () => {
	const session = await _getSession()
	if (session && session.refreshToken) {
		return refreshToken(session.userId as string, session.refreshToken as string)
	}

	return api.makeApiResponse(400, false)
}
export const didRefreshToken = api.makeDidRefreshToken(doRefreshToken, async (accessToken) => {
	return token.store(accessToken)
})

export const get = api.makeGet(getApiUrl(), didRefreshToken, authHeader)
export const post = api.makePost(getApiUrl(), didRefreshToken, authHeader)
export const put = api.makePut(getApiUrl(), didRefreshToken, authHeader)
export const patch = api.makePatch(getApiUrl(), didRefreshToken, authHeader)
export const del = api.makeDel(getApiUrl(), didRefreshToken, authHeader)

export function getApiStatus() {
	return get('/status')
}
