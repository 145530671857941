import { IStageTemplateUpdateProps } from '@touchpoints/service-types'
import { authHeader, del, get, post, put } from './api'

import { IStageTemplate, templates } from '@touchpoints/requests'

export const fetchTemplates = templates.makeFetchTemplates(get, authHeader)

export async function createStageTemplate(
	organizationId: string,
	stageTemplate: IStageTemplateUpdateProps
) {
	const headers = await authHeader()
	return post<IStageTemplate>(
		`/organizations/${organizationId}/stage-templates`,
		{
			stageTemplate: {
				...stageTemplate,
				id: undefined,
			},
		},
		headers
	)
}

export async function updateStageTemplate(
	organizationId: string,
	stageTemplateId: string,
	stageTemplate: IStageTemplateUpdateProps
) {
	const headers = await authHeader()
	return put<IStageTemplate>(
		`/organizations/${organizationId}/stage-templates/${stageTemplateId}`,
		{ stageTemplate },
		headers
	)
}

export async function fetchStageTemplates(organizationId: string) {
	const headers = await authHeader()
	return get<IStageTemplate[]>(
		`/organizations/${organizationId}/stage-templates`,
		undefined,
		headers
	)
}

export async function removeStageTemplate(organizationId: string, stageTemplateId: string) {
	const headers = await authHeader()
	return del<IStageTemplate[]>(
		`/organizations/${organizationId}/stage-templates/${stageTemplateId}`,
		undefined,
		headers
	)
}

export async function fetchStageTemplateById(organizationId: string, stageTemplateId: string) {
	const headers = await authHeader()
	return get<IStageTemplate | undefined>(
		`/organizations/${organizationId}/stage-templates/${stageTemplateId}`,
		undefined,
		headers
	)
}

export async function testTriggerAction(
	organizationId: string,
	stageTemplateId: string,
	actionId: string,
	data: { positionId: string; candidateId: string }
) {
	const headers = await authHeader()
	return post(
		`/organizations/${organizationId}/stage-templates/${stageTemplateId}/trigger-actions/${actionId}/test`,
		data,
		headers
	)
}
