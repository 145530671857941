import type { GetMethodFunc, PostMethodFunc, ITemplate, TemplatePreviewOptions } from '../types'

export function makeFetchTemplates(
	getMethod: GetMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function fetchTemplates(organizationId: string) {
		const headers = await authHeader()
		return getMethod<{ templates: ITemplate[] }>(
			`/organizations/${organizationId}/templates`,
			undefined,
			headers
		)
	}
}

export function makeProcessTemplate(
	postMethod: PostMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function previewTemplate(
		organizationId: string,
		templateId: string,
		opts: TemplatePreviewOptions
	) {
		const headers = await authHeader()
		return postMethod<{ subject: string; html: string; context: object }>(
			`/organizations/${organizationId}/templates/${templateId}/preview`,
			{
				...opts,
			},
			headers
		)
	}
}
