import { IAvailabilityBlock, ITriggerDelayUnit } from '@touchpoints/requests'
import { Timezone } from '@touchpoints/timezone'
import addMinutes from 'date-fns/addMinutes'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import uniqWith from 'lodash/uniqWith'
import ms from 'ms'
import spacetime from 'spacetime'
import { DEFAULT_TIMEZONE, timeSelectionsEqual } from './date'
import { format } from 'date-fns'

const dayInMs = ms('1d')
const hourInMs = ms('1h')
const minInMs = ms('1m')

export function timeIntoComponents(delay: number) {
	const days = Math.floor(delay / dayInMs)
	const hours = Math.floor((delay % dayInMs) / hourInMs)
	const minutes = Math.floor((delay % hourInMs) / minInMs)
	return { days, hours, minutes }
}

export function timeFromComponents(days: number, hours: number, minutes: number) {
	return days * dayInMs + hours * hourInMs + minutes * minInMs
}

const localTZ = spacetime().timezone()

export function timeCalc(
	blocks: IAvailabilityBlock[],
	date: Date,
	duration = 60,
	interval = 30,
	tz: Timezone = DEFAULT_TIMEZONE
) {
	const slots: { start: Date; end: Date }[] = []

	for (const block of blocks) {
		// the total time that has been put into a slot
		let accumulator = 0

		const dateInTz = spacetime(date).goto(tz).format('MMMM do, yyyy')

		// NOTE: 12:00am end time is actually the beginning of the next day
		// so use 11:59pm instead
		const blockEnd = block.end === '12:00am' ? '11:59pm' : block.end

		const start = spacetime(`${dateInTz} ${block.start}`, tz).goto(localTZ.name).toNativeDate()
		const end = spacetime(`${dateInTz} ${blockEnd}`, tz).goto(localTZ.name).toNativeDate()

		// the total amount of milliseconds that can be cut up
		const diff = differenceInMinutes(end, start)

		// check that there is enough time for a full slot to fit
		// into the remaining time
		// remaining time: diff - accumulator
		// full slot: duration
		while (accumulator + duration <= diff) {
			// slot start time is the current event time block
			// plus the value of the accumulator which is total used time
			const s = addMinutes(start, accumulator)

			// add the slot with start and end time
			slots.push({
				start: s,
				end: addMinutes(s, duration),
			})

			// add duration to accumulator
			accumulator += interval
		}
	}

	return uniqWith(slots, timeSelectionsEqual).sort((a, b) => {
		return a.start.getTime() - b.start.getTime()
	})
}

export async function waitForSeconds(seconds: number) {
	return new Promise((resolve) => setTimeout(resolve, seconds * 1000))
}

export function formatDate(date: Date) {
	return format(new Date(date), "MMM d, yyyy 'at' h:mm a")
}

export function formatTs(ts: number) {
	return format(new Date(ts), "MMM d, yyyy 'at' h:mm a")
}

export function formatHour(ts: number | Date) {
	return format(new Date(ts), 'h:mm a')
}

export function fromUnitToMs(value: number, unit: ITriggerDelayUnit) {
	switch (unit) {
		case 'milliseconds':
			return value
		case 'seconds':
			return value * 1000
		case 'minutes':
			return value * 60000
		case 'hours':
			return value * 3600000
		case 'days':
			return value * 86400000
		case 'weeks':
			return value * 604800000
	}
}

export function fromMsToUnit(valueMs: number, unit: ITriggerDelayUnit) {
	switch (unit) {
		case 'milliseconds':
			return valueMs
		case 'seconds':
			return valueMs / 1000
		case 'minutes':
			return valueMs / 60000
		case 'hours':
			return valueMs / 3600000
		case 'days':
			return valueMs / 86400000
		case 'weeks':
			return valueMs / 604800000
	}
}
