'use client'
import { rootStore } from '@store/index'
import { ITask } from '@touchpoints/requests'
import { Dropdown } from '@touchpoints/ui'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { ReactNode, useState } from 'react'
import { HighPriorityIcon, LowPriorityIcon, MediumPriorityIcon, NoPriorityIcon } from './icons'

const PriorityContainer = ({ children }: { children: ReactNode }) => {
	return (
		<div className="cursor-pointer">
			<div className="flex space-x-1 py-1 items-center text-[#11181c] text-sm font-normal">
				{children}
			</div>
		</div>
	)
}
const PriorityView = ({ priority, iconOnly }: { priority?: number; iconOnly?: boolean }) => {
	return (
		<PriorityContainer>
			{priority === undefined && (
				<>
					<NoPriorityIcon size={16} />
					{!iconOnly && (
						<>
							&nbsp;&nbsp;<p>No Priority</p>
						</>
					)}
				</>
			)}
			{priority === 0 && (
				<>
					<HighPriorityIcon size={16} />
					{!iconOnly && (
						<>
							&nbsp;&nbsp;<span>High</span>
						</>
					)}
				</>
			)}
			{priority === 1 && (
				<>
					<MediumPriorityIcon size={16} />
					{!iconOnly && (
						<>
							&nbsp;&nbsp;<p>Medium</p>
						</>
					)}
				</>
			)}
			{priority === 2 && (
				<>
					<LowPriorityIcon size={16} />
					{!iconOnly && (
						<>
							&nbsp;&nbsp;<p>Low</p>
						</>
					)}
				</>
			)}
		</PriorityContainer>
	)
}

type TaskPriorityProps = {
	task: Partial<ITask>
	iconOnly?: boolean
	borderless?: boolean
	onChanged?: (priority: number) => void
	readOnly?: boolean
}
export const TaskPriority = observer(function ({
	task,
	iconOnly,
	borderless,
	onChanged,
	readOnly,
}: TaskPriorityProps) {
	const [open, setOpen] = useState(false)
	const [priority, setPriority] = useState(task.priority)

	const handleUpdatePriority = async (priority: number) => {
		onChanged?.(priority)
		setPriority(priority)

		if (!task || !task.id) {
			return
		}

		const t = rootStore.tasks.getTaskById(task.id)
		const res = await t?.changePriority(priority)

		if (!res?.success) {
			return
		}

		rootStore.tasks.clearCache()
		setOpen(false)
	}

	const priorityOptions = [
		{ value: 2, label: 'Low' },
		{ value: 1, label: 'Medium' },
		{ value: 0, label: 'High' },
	]

	return (
		<div className="w-auto self-center">
			<Dropdown
				side="bottom"
				align="start"
				open={open}
				onCloseAutoFocus={() => setOpen(false)}
				onEscapeKeyDown={() => setOpen(false)}
				onPointerDownOutside={() => setOpen(false)}
				onFocusOutside={() => setOpen(false)}
				onInteractOutside={() => setOpen(false)}
				trigger={
					<div
						className={clsx(
							'rounded px-2 py-0.5 flex flex-row items-center justify-between dark:bg-slate-700 dark:border-slate-700',
							{
								'cursor-pointer': !readOnly,
								'shadow-sm border border-slate-100 hover:bg-slate-50': !borderless,
								'hover:bg-[#dfe3e6]': !!borderless,
								'cursor-not-allowed': readOnly,
								'pointer-events-none': readOnly,
								'bg-gray-50': readOnly,
							}
						)}
						onClick={() => setOpen(!open)}
					>
						<PriorityView priority={priority} iconOnly={iconOnly} />
					</div>
				}
			>
				<div className="w-[231px] p-1 bg-white rounded-md shadow border border-[#eceef0] flex-col">
					{priority === undefined && (
						<Dropdown.Item>
							<PriorityView />
						</Dropdown.Item>
					)}
					{priorityOptions.map((priorityOption, index) => {
						return (
							<Dropdown.Item
								key={`team-${index}`}
								onClick={() => handleUpdatePriority(priorityOption.value)}
							>
								<PriorityView priority={priorityOption.value} />
							</Dropdown.Item>
						)
					})}
				</div>
			</Dropdown>
		</div>
	)
})
