export function MoveStageAction({ size = 20 }: { size?: number }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
		>
			<g id="layers 2, stack">
				<path
					id="Icon"
					d="M6.49743 10L2.7566 11.8614C2.13713 12.1696 2.13713 13.0593 2.75659 13.3676L9.6309 16.7881C9.86375 16.904 10.1369 16.904 10.3697 16.7881L17.2441 13.3676C17.8635 13.0593 17.8635 12.1696 17.2441 11.8614L13.5032 10M6.49743 10L2.75659 8.13862C2.13713 7.83039 2.13712 6.94067 2.75659 6.63243L9.6309 3.2119C9.86375 3.09603 10.1369 3.09603 10.3697 3.2119L17.2441 6.63243C17.8635 6.94067 17.8635 7.83039 17.2441 8.13862L13.5032 10M6.49743 10L9.6309 11.5592C9.86375 11.675 10.1369 11.675 10.3697 11.5592L13.5032 10"
					stroke="#0894B3"
					strokeWidth="1.25"
					strokeLinecap="square"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	)
}
