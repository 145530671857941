export function TaskAction({ size = 20 }: { size?: number }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
		>
			<g id="actions">
				<path
					id="Icon"
					d="M16.6673 3.33203H17.2923C17.2923 2.98685 17.0125 2.70703 16.6673 2.70703V3.33203ZM16.6673 16.6654V17.2904C17.0125 17.2904 17.2923 17.0105 17.2923 16.6654H16.6673ZM3.33398 16.6654H2.70898C2.70898 17.0105 2.98881 17.2904 3.33398 17.2904V16.6654ZM3.33398 3.33203V2.70703C2.98881 2.70703 2.70898 2.98685 2.70898 3.33203H3.33398ZM9.16462 11.8737L8.72268 12.3156C8.83989 12.4329 8.99886 12.4987 9.16462 12.4987C9.33038 12.4987 9.48935 12.4329 9.60656 12.3156L9.16462 11.8737ZM13.3566 8.56565C13.6006 8.32157 13.6006 7.92584 13.3566 7.68176C13.1125 7.43769 12.7168 7.43769 12.4727 7.68176L13.3566 8.56565ZM7.93989 9.7651C7.69582 9.52102 7.30009 9.52102 7.05601 9.7651C6.81193 10.0092 6.81193 10.4049 7.05601 10.649L7.93989 9.7651ZM16.0423 3.33203V16.6654H17.2923V3.33203H16.0423ZM16.6673 16.0404H3.33398V17.2904H16.6673V16.0404ZM3.95898 16.6654V3.33203H2.70898V16.6654H3.95898ZM3.33398 3.95703H16.6673V2.70703H3.33398V3.95703ZM9.60656 12.3156L13.3566 8.56565L12.4727 7.68176L8.72268 11.4318L9.60656 12.3156ZM7.05601 10.649L8.72268 12.3156L9.60656 11.4318L7.93989 9.7651L7.05601 10.649Z"
					fill="#0091FF"
				/>
			</g>
		</svg>
	)
}
