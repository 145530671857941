import type {
	IAccountSettings,
	IIntegrationSettings,
	ICandidateSettings,
	IPositionSettings,
	IOrgEmailSettings,
	ISourcingSettings,
	ICleaningSettings,
	IOrganization,
	IWebhookSettings,
	IStageGroup,
	INotificationSettings,
} from '@touchpoints/requests'

import { authHeader, get, patch, put } from './api'

export async function fetchAirtableSettings(orgId: string) {
	const headers = await authHeader()
	return get<{ settings: IIntegrationSettings }>(
		`/organizations/${orgId}/settings/airtable`,
		undefined,
		headers
	)
}

export async function updateAirtableSettings(
	orgId: string,
	data: Omit<IIntegrationSettings, 'trelloToken'>
) {
	const headers = await authHeader()
	return patch<{ settings: IIntegrationSettings }>(
		`/organizations/${orgId}/settings/airtable`,
		{ settings: { ...data } },
		headers
	)
}

export async function fetchPositionSettings(orgId: string) {
	const headers = await authHeader()
	return get<{ settings: IPositionSettings }>(
		`/organizations/${orgId}/settings/position`,
		undefined,
		headers
	)
}

export async function updatePositionSettings(orgId: string, data: IPositionSettings) {
	const headers = await authHeader()
	return patch<{ settings: IPositionSettings }>(
		`/organizations/${orgId}/settings/position`,
		{ settings: { ...data } },
		headers
	)
}

export async function fetchAccountSettings(orgId: string) {
	const headers = await authHeader()
	return get<{ settings: IAccountSettings }>(
		`/organizations/${orgId}/settings/accounts`,
		undefined,
		headers
	)
}

export async function updateAccountSettings(orgId: string, data: IAccountSettings) {
	const headers = await authHeader()
	return patch<{ settings: IAccountSettings }>(
		`/organizations/${orgId}/settings/accounts`,
		{ settings: { ...data } },
		headers
	)
}

export async function updateOrg(
	orgId: string,
	data: Partial<Pick<IOrganization, 'name' | 'defaultTimezone'>>
) {
	const headers = await authHeader()
	return patch<{ organization: IOrganization }>(
		`/organizations/${orgId}`,
		{ organization: { ...data } },
		headers
	)
}

export async function updateEmailsSettings(orgId: string, data: Partial<IOrgEmailSettings>) {
	const headers = await authHeader()
	return patch<{ settings: IOrgEmailSettings }>(
		`/organizations/${orgId}/settings/emails`,
		{ settings: { ...data } },
		headers
	)
}

export async function updateSourcingSettings(orgId: string, data: Partial<ISourcingSettings>) {
	const headers = await authHeader()
	return patch<{ settings: ISourcingSettings }>(
		`/organizations/${orgId}/settings/sourcing`,
		{ settings: { ...data } },
		headers
	)
}

export async function updateCleaningSettings(orgId: string, data: Partial<ICleaningSettings>) {
	const headers = await authHeader()
	return patch<{ settings: ICleaningSettings }>(
		`/organizations/${orgId}/settings/cleaning`,
		{ settings: { ...data } },
		headers
	)
}

export async function updateWebhooksSettings(orgId: string, data: Partial<IWebhookSettings>) {
	const headers = await authHeader()
	return patch<{ settings: IWebhookSettings }>(
		`/organizations/${orgId}/settings/webhooks`,
		{ settings: { ...data } },
		headers
	)
}

export async function updateNotificationSettings(
	orgId: string,
	data: Partial<INotificationSettings>
) {
	const headers = await authHeader()
	return patch<{ settings: INotificationSettings }>(
		`/organizations/${orgId}/settings/notifications`,
		{ settings: { ...data } },
		headers
	)
}

export async function fetchCandidateSettings(orgId: string) {
	const headers = await authHeader()
	return get<{ settings: ICandidateSettings }>(
		`/organizations/${orgId}/settings/candidates`,
		undefined,
		headers
	)
}

export async function updateCandidatesSettings(orgId: string, data: Partial<ICandidateSettings>) {
	const headers = await authHeader()
	return patch<{ settings: ICandidateSettings }>(
		`/organizations/${orgId}/settings/candidates`,
		{ settings: { ...data } },
		headers
	)
}

export async function updateStages(orgId: string, stageGroups: IStageGroup[]) {
	const headers = await authHeader()
	return put<{ stageGroups: IStageGroup[] }>(
		`/organizations/${orgId}/settings/stages`,
		{ stageGroups },
		headers
	)
}
