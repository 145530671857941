import { useReaction, useRefreshWhen } from '@hooks/mobx'
import { Tooltip } from '@nextui-org/react'
import { rootStore } from '@store'
import { ICandidateStage } from '@touchpoints/requests'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { CandidateStageColorDot } from './CandidateStageColorDot'

const StageView = ({
	stage,
	expanded,
	last,
}: {
	stage: Omit<ICandidateStage, 'id'>
	expanded: boolean
	last: boolean
}) => {
	let props = {}
	if (last) {
		props = {
			borderColor: stage.color,
			borderWidth: '2px',
			borderStyle: 'dashed',
		}
	}

	if (expanded) {
		return (
			<div
				className="flex shrink items-center px-2 m-1 space-x-2 rounded-full text-sm"
				style={{
					background: `${last ? 'white' : stage.color + '30'}`,
					...props,
				}}
			>
				<CandidateStageColorDot
					noMargin
					size="sm"
					color={stage.color}
					className="ring-transparent"
				/>
				<p className="whitespace-nowrap">{stage.name}</p>
			</div>
		)
	}

	return (
		<Tooltip
			content={
				<div className="flex space-x-2">
					<CandidateStageColorDot noMargin color={stage.color} />
					<p>{stage.name}</p>
				</div>
			}
		>
			<CandidateStageColorDot noMargin color={stage.color} />
		</Tooltip>
	)
}

type Props = {
	candidateId: string
	positionId: string
	canExpand?: boolean
}
export function CandidateStageTimeline({ candidateId, positionId, canExpand = false }: Props) {
	const [expanded, setExpanded] = useState(false)

	useRefreshWhen(
		() => !rootStore.candidates.getCandidateById(candidateId),
		() => rootStore.candidates.getCandidateByIdAsync(candidateId)
	)

	useEffect(() => {
		if (!candidateId) return
		const events = rootStore.candidates.events.getEventsForCandidate(candidateId)
		if (!events) {
			rootStore.candidates.events.fetchAllEventsForCandidate(candidateId)
		}
	}, [candidateId])

	const events = useReaction(
		() => {
			return (
				(rootStore.candidates.events.getEventsForCandidate(candidateId) ?? [])
					.filter((e) => e.name === 'stage-changed' && e.positionId === positionId)
					.filter((e) => !e.deleted)
					.sort((a, b) => a.ts - b.ts)
					.map((e) => ({
						ts: e.ts,
						candidateId: e.resourceId,
						stageId: (e.payload?.stage ?? '') as string,
						positionId: e.positionId,
					}))
					.filter((e) => !!e.stageId)
					// only show last X events
					.slice(-10)
			)
		},
		500,
		[candidateId, positionId]
	)

	const stage = useReaction(
		() => {
			const positionCandidate = rootStore.positions.getPositionCandidate(
				candidateId,
				positionId
			)
			return positionCandidate?.stage
				? { ...rootStore.stages.candidatesStageById[positionCandidate.stage] }
				: { color: '#000000', name: 'Unknown' }
		},
		100,
		[candidateId, positionId, events]
	)

	const handleExpand = () => {
		if (canExpand) setExpanded(!expanded)
	}

	return (
		<div
			className="flex items-center space-x-2 w-full hover:cursor-pointer"
			onClick={handleExpand}
		>
			<div
				className={clsx('flex items-center w-full', {
					'-space-x-1 rounded-md': !expanded,
					'flex-wrap': expanded,
					'hover:bg-slate-50': canExpand && !expanded,
				})}
			>
				{events.length === 0 && stage && (
					<StageView stage={stage} expanded={expanded} last={true} />
				)}
				{events.map((event, idx) => {
					const stage = rootStore.stages.candidatesStageById?.[event.stageId]
					if (!stage) {
						return null
					}

					return (
						<StageView
							key={event.ts}
							stage={stage}
							expanded={expanded}
							last={idx + 1 === events.length}
						/>
					)
				})}
				{!expanded && <p className="px-2 text-gray-500 truncate">{stage?.name}</p>}
			</div>
		</div>
	)
}
