import { CardDetailedView } from '@components/accounts/CardDetailedView'
import { RecentBar } from '@components/recent/RecentBar'
import { PropsWithChildren, ReactElement } from 'react'
import { QuickSearch } from '../QuickSearch'
import { Sidebar } from '../Sidebar'
import Topbar from '../Topbar'
import { SettingsNav } from '../settings/SettingsNav'
import { CandidateSlidingPanel } from '@components/candidates/CandidateSlidingPanel'

export * from './NoLayout'

export function NoSidebarLayout({ children }: PropsWithChildren<unknown>) {
	return (
		<div className="flex w-full h-full">
			<div className="flex grow flex-col h-full overflow-auto relative">{children}</div>
			<QuickSearch />
			<CardDetailedView />
			<CandidateSlidingPanel />
		</div>
	)
}

export function NewMainLayout({ children }: PropsWithChildren<unknown>) {
	return (
		<div className="flex w-full h-full">
			<Sidebar />
			<div className="flex grow flex-col h-full overflow-auto relative">
				{children}
				<RecentBar />
			</div>
			<QuickSearch />
			<CardDetailedView />
			<CandidateSlidingPanel />
		</div>
	)
}

function MainLayout({ children }: PropsWithChildren<unknown>) {
	return (
		<div className="flex w-full h-full">
			<Sidebar />
			<div className="flex grow flex-col h-full w-full">
				<Topbar />
				<div className="flex flex-col h-full w-full overflow-auto dark:bg-gray-800 dark:text-white">
					{children}
				</div>
				<RecentBar />
			</div>
			<QuickSearch />
			<CardDetailedView />
			<CandidateSlidingPanel />
		</div>
	)
}

export function getNoSidebarLayout(page: ReactElement) {
	return <NoSidebarLayout>{page}</NoSidebarLayout>
}

export function getMainLayout(page: ReactElement) {
	return <MainLayout>{page}</MainLayout>
}

export function getNewMainLayout(page: ReactElement) {
	return <NewMainLayout>{page}</NewMainLayout>
}

export function getSettingsLayout(page: ReactElement) {
	return (
		<MainLayout>
			<div className="flex w-full h-full">
				<div className="flex w-48 border-r-2 min-h-full">
					<SettingsNav />
				</div>
				<div className="flex flex-grow">{page}</div>
			</div>
		</MainLayout>
	)
}
