import { ReactNode, useCallback, useContext, useEffect } from 'react'
import { PageContext, SearchContext } from '../lib/context'

interface PageProps {
	searchPrefix?: string[]
	onEscape?: () => void
	children: ReactNode
	id: string
}

export default function Page({ searchPrefix, children, onEscape, id }: PageProps) {
	const { page, setSearchPrefix } = useContext(PageContext)
	const { search } = useContext(SearchContext)

	const isActive = page === id

	const handleKeyDown = useCallback(
		(e: KeyboardEvent) => {
			if (e.key === 'Escape') {
				e.preventDefault()
				e.stopPropagation()
				onEscape?.()
			} else if (e.key === 'Backspace' && !search) {
				e.preventDefault()
				e.stopPropagation()
				onEscape?.()
			}
		},
		[onEscape, search]
	)

	useEffect(() => {
		if (onEscape && isActive) {
			document.addEventListener('keydown', handleKeyDown)

			return () => {
				document.removeEventListener('keydown', handleKeyDown)
			}
		}
	}, [isActive, search, handleKeyDown, onEscape])

	useEffect(() => {
		if (isActive && setSearchPrefix) {
			setSearchPrefix(searchPrefix)
		}
	}, [searchPrefix, isActive, setSearchPrefix])

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return isActive ? <>{children}</> : null
}
