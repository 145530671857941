import { authHeader, get, post } from '@requests/api'
import { AirtableRecord } from './types'

export enum PositionRecordField {
	ClientName = 'Client Name',
	Client = 'Client',
	PositionId = 'Position  ID',
	JobTitle = 'Job Title',
	Priority = 'Priority',
	Type = 'Type',
	Location = 'Location (City, St or Remote)',
	EmailToSend = 'Email to send from in Outreach',
	BillNotes = 'Bill Email Notes',
	ColdNotStartedStatus = 'Cold Not Started Status',
	Sourcer = 'Sourcer',
	Holding = 'Holding',
	Status = 'Status',
	DateCreated = 'Date Created',
	JobTitleForEmail = 'Job Title for Body of Email',
	ClientTeam = 'Client Team',
	TechStackEnv = 'Tech Stack or Environment',
	TechStackDetails = 'Tech Stack Details',
	ManagerName = 'Manager Name',
	ManagerEmail = 'Manager Email',
	NumberOfPositions = 'Number of Positions',
	Zipcode = 'Zipcode',
	Salary = 'Salary/Bill Rate',
	ClientReqID = 'Client Req ID',
	SourcingRequired = 'Sourcing Required?',
	Description = 'Description',
	Skills = 'Skills and Qualifications',
	AccountName = 'Account Name',
	ClientURL = 'Client URL',
	// https://airtable.com/appKdFfy2iFKiqT1M/api/docs#javascript/table:typeform%20templates:update
	BaseTypeformURL = 'Base Typeform URL',
}

export async function getPositionRecords(orgId: string, fields: string[]) {
	const headers = await authHeader()
	return get<{ records: AirtableRecord[] }>(
		`/organizations/${orgId}/airtable/positions`,
		{
			fields: JSON.stringify(fields),
		},
		headers
	)
}

export async function addPositionRecord(
	orgId: string,
	fields: Partial<Record<PositionRecordField, any>>
) {
	const headers = await authHeader()
	return post<{ record: AirtableRecord }>(
		`/organizations/${orgId}/airtable/positions`,
		{
			fields: fields as Record<string, any>,
		},
		headers
	)
}
