import { getEmailStats, type Stats } from '@requests/dashboard'
import type { RootStore } from './root'
import { ObservableMap } from 'mobx'
import { createAsyncQueue } from '@touchpoints/async-queue'

export class StatsStore {
	private readonly root: RootStore

	queuedByEmail: ObservableMap<string, Stats> = new ObservableMap()
	queuedByPositionByEmail: ObservableMap<string, ObservableMap<string, Stats>> =
		new ObservableMap()

	private requestQueue = createAsyncQueue({ cacheTTL: 1000 * 60 * 5 })

	constructor(root: RootStore) {
		this.root = root
	}

	async fetchEmailStats(organizationId?: string) {
		const orgId = organizationId ?? this.root.organizations.activeOrganizationId
		const res = await this.requestQueue.addUnique('email-stats', () => getEmailStats(orgId))
		if (!res.success) {
			console.error(res.message)
			return
		}

		const { queuedByEmail, queuedByPositionByEmail } = res.data ?? {}

		this.queuedByEmail.clear()
		for (const email in queuedByEmail) {
			this.queuedByEmail.set(email, queuedByEmail[email])
		}

		this.queuedByPositionByEmail.clear()
		for (const email in queuedByPositionByEmail) {
			const positions = queuedByPositionByEmail[email]
			const map = new ObservableMap<string, Stats>()
			for (const position in positions) {
				map.set(position, positions[position])
			}
			this.queuedByPositionByEmail.set(email, map)
		}
	}
}
