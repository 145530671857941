import { rootStore } from '@store'
import type { IPositionCandidateScheduledEvent, ITask } from '@touchpoints/requests'
import type { BoardViewLayout } from '@types'
import { useEffect, useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'

export function useAllTasks(positionId?: string, candidateId?: string) {
	const [tasks, setTasks] = useState<ITask[]>([])

	useEffect(() => {
		if (!positionId || !candidateId) {
			return
		}
		const list = rootStore.tasks.list.filter(
			(task) => task.data?.candidateId === candidateId && task.data?.positionId === positionId
		)

		setTasks(list)
	}, [positionId, candidateId])

	return tasks
}

export function useTasks(positionId?: string, candidateId?: string) {
	const [tasks, setTasks] = useState<ITask[]>([])

	useEffect(() => {
		if (!positionId || !candidateId) {
			return
		}
		const list = rootStore.tasks.pendingTasks.filter(
			(task) => task.data?.candidateId === candidateId && task.data?.positionId === positionId
		)

		setTasks(list)
	}, [positionId, candidateId])

	return tasks
}

export function useDueTimeColor(dueBy?: number) {
	const [color, setColor] = useState('bg-slate-300')

	useEffect(() => {
		if (dueBy === undefined) {
			return
		}

		const now = new Date().getTime()
		const diff = dueBy - now
		const diffInHours = diff / (1000 * 60 * 60)

		if (diffInHours < 0) {
			setColor('bg-red-300')
		} else if (diffInHours < 6) {
			setColor('bg-amber-300')
		} else {
			setColor('bg-emerald-200')
		}
	}, [dueBy])

	return color
}

export function useBoardLayout() {
	return useLocalStorage<BoardViewLayout>('tp:boardlayout', 'kanban')
}

export function useDueTime(events: IPositionCandidateScheduledEvent[], tasks: ITask[]) {
	const oldestEvent = useOldestEvent(events)
	const oldestDueTask = useOldestDueTask(tasks)

	return oldestEvent?.time ?? oldestDueTask?.dueBy
}

function useOldestEvent(events: IPositionCandidateScheduledEvent[]) {
	const [newestEvent, setNewestEvent] = useState<IPositionCandidateScheduledEvent | undefined>(
		undefined
	)

	useEffect(() => {
		if (events.length === 0) {
			setNewestEvent(undefined)
			return
		}

		const sortedEvents = [...events].sort((a, b) => {
			if (!a.time) {
				return 1
			}

			if (!b.time) {
				return -1
			}

			return a.time - b.time
		})

		setNewestEvent(sortedEvents[sortedEvents.length - 1])
	}, [events])

	return newestEvent
}

function useOldestDueTask(tasks: ITask[]) {
	const [oldestDue, setOldestDueDate] = useState<ITask | undefined>(undefined)

	useEffect(() => {
		if (tasks.length === 0) {
			setOldestDueDate(undefined)
			return
		}

		const sortedTasks = tasks.sort((a, b) => {
			if (!a.dueBy) {
				return 1
			}

			if (!b.dueBy) {
				return -1
			}

			return a.dueBy - b.dueBy
		})

		setOldestDueDate(sortedTasks[0])
	}, [tasks])

	return oldestDue
}
