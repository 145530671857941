export function AccountsIcon({
	size = 14,
	stroke = '#687076',
}: {
	size?: number
	stroke?: string
}) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 20 15"
			fill="none"
		>
			<path
				d="M3.33366 13.833H11.667M3.33366 13.833V1.33301H11.667V4.66634M3.33366 13.833H1.66699M16.667 13.833H11.667M16.667 13.833V4.66634H11.667M16.667 13.833H18.3337M11.667 13.833V4.66634M8.33366 5.49967H6.66699M6.66699 8.83301H8.33366"
				stroke={stroke}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
