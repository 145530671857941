import debounce from 'lodash/debounce'
import { reaction } from 'mobx'
import { useCallback, useEffect, useRef, useState } from 'react'

export function useReaction<T>(expression: () => T, debounceTime = 300, dependencies: any[] = []) {
	const callbackRef = useRef(expression)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const expressionWrapper = useCallback(() => callbackRef.current(), [])

	const [value, setValue] = useState<T>(expressionWrapper())

	useEffect(() => {
		callbackRef.current = expression
	}, [expression])

	useEffect(() => {
		setValue(expressionWrapper())
		return reaction(
			expressionWrapper,
			debounce((v) => setValue(v), debounceTime, { leading: true })
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [expressionWrapper, debounceTime, ...dependencies])

	return value
}

export function useMutate() {
	const [value, setValue] = useState(0)

	return [value, () => setValue((v) => v + 1)] as const
}

export function useRefreshWhen<WhenType>(
	when: () => WhenType,
	action: () => void,
	dependencies: any[] = []
) {
	const callbackRef = useRef(action)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const actionWrapper = useCallback(() => callbackRef.current(), [])

	useEffect(() => {
		callbackRef.current = action
	}, [action])

	const value = useReaction(when)

	useEffect(() => {
		actionWrapper()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, actionWrapper, ...dependencies])
}
