export function PositionsIcon() {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7Z"
				stroke="#687076"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.50001 4.20956C6.80941 4.03093 7.19059 4.03094 7.49999 4.20956L9.16666 5.17179C9.47606 5.35042 9.66667 5.68055 9.66667 6.03782V7.96222C9.66667 8.31949 9.47606 8.64962 9.16666 8.82825L7.49999 9.79048C7.19059 9.9691 6.80941 9.9691 6.50001 9.79048L4.83334 8.82825C4.52394 8.64962 4.33333 8.31949 4.33333 7.96222V6.03782C4.33333 5.68055 4.52394 5.35042 4.83334 5.17179L6.50001 4.20956Z"
				stroke="#687076"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
