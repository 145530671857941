export function QuestionIcon({ size = 18 }: { size?: number }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 18 18"
			fill="none"
		>
			<path
				d="M13.8617 8.30556V2.75H3.44507V15.25H7.95896M6.22285 11.0833H6.91729M6.22285 5.52778H11.084M6.22285 8.30556H8.30618"
				stroke="#6E56CF"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.8334 11.5L12.0556 14.9722L10.6667 13.5833"
				stroke="#6E56CF"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
