import { FocusEvent, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'

export function useOutsideHandler(ref: MutableRefObject<any>, func: () => void) {
	useEffect(() => {
		function handleClickOutside(event: any) {
			if (!ref.current || (ref.current && !ref.current.contains(event.target))) {
				func()
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [ref, func])
}

// NOTE: okay, this is just a crazy way to get the input to work inside this
// flowbite-react dropdown that captures keyboard events
// it is not ideal and maybe we should just use a custom dropdown
// but what's happening here is that when the input is focused, we listen for keydown
// events on the window (we remove the listener on blur)
// and then we process the keydown keys as if it was typed into the input
// it tries to handle the expected use cases of selections, backspace, inserts, etc
// might not be perfect though
export function useCustomFilterableInput() {
	const [query, setQuery] = useState('')
	const wrapperRef = useRef<HTMLInputElement>(null)

	const handleDown = useCallback(
		(e: KeyboardEvent) => {
			e.stopImmediatePropagation()
			e.stopPropagation()

			const key = e.key

			if (key === 'Backspace' && (e.metaKey || e.ctrlKey || e.altKey)) {
				setQuery('')
			}

			if (e.metaKey || e.ctrlKey || e.altKey) {
				return
			}

			switch (key) {
				case 'ArrowUp':
				case 'ArrowDown':
				case 'ArrowLeft':
				case 'ArrowRight':
					return
			}

			const selectionStart = wrapperRef.current?.selectionStart
			const selectionEnd = wrapperRef.current?.selectionEnd
			let newSelection = selectionEnd

			if (key === 'Backspace' || key.length === 1) {
				if (key === 'Backspace') {
					setQuery((q) => {
						if (
							typeof selectionStart === 'number' &&
							typeof selectionEnd === 'number'
						) {
							let str = ''

							if (selectionStart === selectionEnd) {
								for (let i = 0; i < q.length; ++i) {
									if (i === selectionStart - 1) {
										continue
									}

									if (i === selectionEnd) {
										newSelection = str.length
									}

									str += q.at(i)
								}
								return str
							}

							for (let i = 0; i < q.length; ++i) {
								if (i >= selectionStart && i < selectionEnd) {
									continue
								}

								if (i === selectionEnd) {
									newSelection = str.length
								}

								str += q.at(i)
							}
							return str
						}
						return q.slice(0, -1)
					})
				} else {
					setQuery((q) => {
						if (
							typeof selectionStart === 'number' &&
							typeof selectionEnd === 'number'
						) {
							let str = ''

							for (let i = 0; i < q.length; ++i) {
								if (i >= selectionStart && i < selectionEnd) {
									continue
								}

								if (i === selectionEnd) {
									str += key
									newSelection = str.length
								}

								str += q.at(i)
							}

							if (str.length <= selectionEnd) {
								str += key
							}

							if (q.length === selectionEnd) {
								newSelection = str.length + 1
							}

							return str
						}

						return q + key
					})
				}
			}

			setTimeout(() => {
				if (newSelection !== undefined && newSelection !== null) {
					wrapperRef.current?.setSelectionRange(newSelection, newSelection)
				}
			}, 10)
		},
		[wrapperRef]
	)

	const onFocus = useCallback(
		(e: FocusEvent<HTMLInputElement, Element>) => {
			e.stopPropagation()
			window.addEventListener('keydown', handleDown, { capture: true })
		},
		[handleDown]
	)

	const onBlur = useCallback(
		(e: FocusEvent<HTMLInputElement, Element> | null) => {
			if (e) e.stopPropagation()
			window.removeEventListener('keydown', handleDown, { capture: true })
		},
		[handleDown]
	)

	useOutsideHandler(wrapperRef, () => {
		onBlur(null)
	})

	useEffect(() => {
		return () => {
			onBlur(null)
		}
	}, [onBlur])

	return { query, wrapperRef, onFocus, onBlur }
}
