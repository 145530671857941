import type { RootStore } from './root'
import { fetchNotifications, markNotificationAsRead } from '../requests/notifications'
import { autorun, makeAutoObservable, runInAction } from 'mobx'
import type { INotification } from '@touchpoints/requests'
import { chunk } from 'lodash'

export class NotificationsStore {
	private readonly root: RootStore

	readonly list: INotification[] = []

	get unreadCount() {
		return this.unread.length
	}

	get unread() {
		return this.list.filter((n) => !n.readAt)
	}

	constructor(root: RootStore) {
		this.root = root

		makeAutoObservable(this)

		autorun(() => {
			this.fetchNotifications()
		})
	}

	async fetchNotifications() {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await fetchNotifications(orgId)
		if (!res.success) {
			return
		}

		const { notifications = [] } = res.data ?? {}

		runInAction(() => {
			this.list.length = 0
			this.list.push(...notifications)
		})
	}

	async markAsRead(notificationId: string) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await markNotificationAsRead(orgId, notificationId)
		if (!res.success) {
			return false
		}

		return true
	}

	async markAllAsRead() {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const chunkedNotifications = chunk(this.list, 10)

		runInAction(async () => {
			for (const notifications of chunkedNotifications) {
				await Promise.all(
					notifications.map((notification) => {
						notification.readAt = new Date().getTime()
						return this.markAsRead(notification.id)
					})
				)
			}

			const newList = this.list.map((n) => ({ ...n }))
			this.list.length = 0
			this.list.push(...newList)
		})

		return true
	}
}
