import type { DeepPartial, FlowbiteTextInputTheme, FlowbiteTextareaTheme } from 'flowbite-react'

export const TITLE_THEME: DeepPartial<FlowbiteTextInputTheme> = {
	base: 'flex w-full',
	field: {
		input: {
			base: 'w-full border-none p-0',
			sizes: {
				md: 'text-2xl font-normal placeholder:text-slate-500',
			},
			colors: {
				gray: 'bg-inherit',
			},
		},
	},
}
export const DESC_THEME: DeepPartial<FlowbiteTextareaTheme> = {
	base: 'block w-full rounded-lg border-none disabled:cursor-not-allowed disabled:opacity-50 text-xl font-light text-slate-500 placeholder:text-slate-500 p-0 resize-none',
	colors: {
		gray: 'bg-inherit border-gray-300 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500',
	},
}
