export function StageTemplateIcon({ size = 20 }: { size?: number }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
		>
			<g id="layers 3, stack">
				<g id="Icon">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M9.7389 2.55708C9.90453 2.48097 10.0951 2.48097 10.2608 2.55708L17.9691 6.09874C18.191 6.20068 18.3332 6.4225 18.3332 6.66667C18.3332 6.91084 18.191 7.13265 17.9691 7.23459L10.2608 10.7763C10.0951 10.8524 9.90453 10.8524 9.7389 10.7763L2.03057 7.23459C1.80869 7.13265 1.6665 6.91084 1.6665 6.66667C1.6665 6.4225 1.80869 6.20068 2.03057 6.09874L9.7389 2.55708ZM3.78851 6.66667L9.99984 9.52052L16.2112 6.66667L9.99984 3.81281L3.78851 6.66667Z"
						fill="#889096"
					/>
					<path
						d="M17.9691 9.43208C18.191 9.53402 18.3332 9.75583 18.3332 10C18.3332 10.2442 18.191 10.466 17.9691 10.5679L10.2608 14.1096C10.0951 14.1857 9.90453 14.1857 9.7389 14.1096L2.03057 10.5679C1.80869 10.466 1.6665 10.2442 1.6665 10C1.6665 9.75583 1.80869 9.53402 2.03057 9.43208C2.19618 9.35598 2.38676 9.35598 2.55237 9.43208L9.99984 12.8539L17.4471 9.43208C17.6128 9.35596 17.8034 9.35596 17.9691 9.43208Z"
						fill="#889096"
					/>
					<path
						d="M17.9691 12.7654C18.191 12.8674 18.3332 13.0892 18.3332 13.3333C18.3332 13.5775 18.191 13.7993 17.9691 13.9013L10.2608 17.4429C10.0951 17.519 9.90453 17.519 9.7389 17.4429L2.03057 13.9013C1.80869 13.7993 1.6665 13.5775 1.6665 13.3333C1.6665 13.0892 1.80869 12.8674 2.03057 12.7654C2.19618 12.6893 2.38676 12.6893 2.55237 12.7654L9.99984 16.1872L17.4471 12.7654C17.6128 12.6893 17.8034 12.6893 17.9691 12.7654Z"
						fill="#889096"
					/>
				</g>
			</g>
		</svg>
	)
}
