import { rootStore } from '@store'
import { useReaction } from '@touchpoints/mobx-hooks'
import { createSelectOnChange, Select } from '@touchpoints/ui'

type Props = {
	positionId: string
	onPositionIdChanged?: (positionId: string) => void
}
export function PositionSelect({ positionId, onPositionIdChanged }: Props) {
	const positionOptions = useReaction(() => rootStore.positions.groupedSelectOptions)
	return (
		<Select
			value={positionOptions
				.find((o) => o.positionId === positionId)
				?.options.find((o) => o.value === positionId)}
			label="Position"
			options={positionOptions}
			onChange={createSelectOnChange((v) => {
				onPositionIdChanged?.(v)
			})}
		/>
	)
}
