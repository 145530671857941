import { makeAutoObservable } from 'mobx'

export class QuickSearchStore {
	isOpen = false

	constructor() {
		makeAutoObservable(this)
	}

	open() {
		this.isOpen = true
	}

	close() {
		this.isOpen = false
	}
}
