import type { ICandidate, ISequence, ISequenceInstance } from '@touchpoints/requests'
import { observer } from 'mobx-react-lite'
import { nameDisplayForCandidate } from './utils'
import { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import { HiDotsHorizontal, HiExternalLink, HiOutlineMail, HiPhone } from 'react-icons/hi'
import { Button, Loading } from '@nextui-org/react'
import { useComputed, useSignal, useSignals } from '@preact/signals-react/runtime'
import {
	Dropdown,
	Modal,
	Pill,
	Select,
	createSelectOnChange,
	toast,
	useConfirm,
} from '@touchpoints/ui'
import clsx from 'clsx'
import { signal } from '@preact/signals-react'
import { useReaction } from '@touchpoints/mobx-hooks'
import { store } from './store'
import { rootStore } from '@store/index'
import { useEffect } from 'react'
import { CandidateStageSelect } from '@components/candidates/CandidateStageSelect'
import { useStageOptions } from '@hooks/stages'
import { autorun } from 'mobx'
import { useRouter } from 'next/router'
import { useOrgUserOptions } from '@hooks/users'
import { SequencesIcon } from '@touchpoints/icons'

const transfer = {
	open: signal(false),
	toPositionId: signal(''),
	stageId: signal(''),

	clear() {
		this.toPositionId.value = ''
		this.stageId.value = ''
	},
}

export const CandidateLabel = observer(function ({
	candidate,
	refreshing = false,
	showMenu = true,
	activeSequence,
	onCandidateClick,
	onSequenceClick,
}: {
	candidate?: ICandidate
	refreshing?: boolean
	showMenu?: boolean
	activeSequence?: {
		instance: ISequenceInstance
		sequence: ISequence
	}
	onCandidateClick?: () => void
	onSequenceClick?: () => void
}) {
	const cardTitle = nameDisplayForCandidate(candidate)

	return (
		<div className="flex flex-rowitems-center justify-between">
			<div className="flex leading-7">
				<p
					className={clsx('h-full align-super text-lg font-medium', {
						'cursor-pointer': onCandidateClick,
					})}
					onClick={onCandidateClick}
				>
					{cardTitle}
				</p>
				<div className="ml-5 flex space-x-4 text-sm text-slate-500">
					{candidate && candidate.phone && isValidPhoneNumber(candidate.phone) && (
						<div
							className="flex items-center space-x-4 hover:cursor-pointer"
							onClick={async (e) => {
								e.stopPropagation()
								if (candidate.phone) navigator.clipboard.writeText(candidate.phone)
								toast.success(`Phone copied to clipboard!`)
							}}
						>
							<HiPhone />
							&nbsp;
							{formatPhoneNumber(candidate.phone)}
						</div>
					)}
					&nbsp;&nbsp;
					{candidate && candidate.email && (
						<div
							className="flex items-center space-x-4 hover:cursor-pointer"
							onClick={async (e) => {
								e.stopPropagation()
								navigator.clipboard.writeText(candidate.email)
								toast.success(`Email copied to clipboard!`)
							}}
						>
							<HiOutlineMail />
							&nbsp;
							{candidate.email}
						</div>
					)}
					{activeSequence && (
						<Pill
							size="sm"
							color="bg-blue-100"
							className="flex items-center cursor-pointer"
							onClick={onSequenceClick}
						>
							<SequencesIcon />
							<span className="text-neutral-800 ml-1.5">
								{activeSequence.sequence.name}
							</span>
							<span className="text-neutral-500 ml-1">
								- Step {activeSequence.instance.actionStep + 1}
							</span>
						</Pill>
					)}
				</div>
			</div>
			{showMenu && <Options candidate={candidate} />}
			<div className="flex items-center absolute top-1 right-1">
				{refreshing && <Loading size="sm" />}
			</div>
			<TransferModal />
		</div>
	)
})

function Options({ candidate }: { candidate: ICandidate | undefined }) {
	useSignals()

	const router = useRouter()

	const open = useSignal(false)
	const confirm = useConfirm()

	const rejectStage = useReaction(
		() => rootStore.candidates.settings.rejectSettings?.stageWhenCandidateIsRejected
	)

	const handleViewCandidate = () => {
		if (!candidate) {
			return
		}

		router.push(`/candidates/${candidate.id}`)
	}

	const handleRejectCandidate = async () => {
		open.value = false

		const pc = rootStore.positions.getPositionCandidateById(store.activePositionCandidateId)
		if (!pc) {
			toast.error('No candidate was found')
			return
		}

		if (pc.stage === rejectStage) {
			toast.error('Candidate is already in rejection stage')
			return
		}

		const shouldContinue = await confirm({
			title: 'Reject Candidate',
			message: 'Are you sure you want to reject this candidate?',
			confirmText: 'Reject',
			cancelText: 'Cancel',
		})

		if (!shouldContinue) {
			return
		}

		if (!rejectStage) {
			toast.error('No stage was set for rejection')
			return
		}

		await rootStore.positions.setPositionCandidateStage(
			pc.candidateId,
			pc.positionId,
			rejectStage
		)
	}

	return (
		<div className="flex w-9 h-full ml-2">
			<Dropdown
				side="bottom"
				align="end"
				open={open.value}
				onCloseAutoFocus={() => (open.value = false)}
				onEscapeKeyDown={() => (open.value = false)}
				onPointerDownOutside={() => (open.value = false)}
				onFocusOutside={() => (open.value = false)}
				onInteractOutside={() => (open.value = false)}
				trigger={
					<div
						className={clsx(
							'flex items-center space-x-2 text-[#687076] px-1 py-1 hover:bg-slate-50 cursor-pointer',
							{
								'bg-slate-100': open.value,
							}
						)}
						onClick={() => (open.value = !open.value)}
					>
						<HiDotsHorizontal size={20} />
					</div>
				}
			>
				<div className="p-2 bg-white">
					{candidate && (
						<>
							<Dropdown.Item onClick={handleViewCandidate}>
								<div className="flex items-center space-x-2 text-slate-500 px-2 py-1">
									View Candidate
								</div>
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() => {
									window.open(
										`${process.env.NEXT_PUBLIC_APP_URL}/resumes/${candidate.id}`,
										'_blank'
									)
								}}
							>
								<div className="flex items-center space-x-2 text-slate-500 px-2 py-1">
									Resume <HiExternalLink className="w-5 h-5 ml-2" />
								</div>
							</Dropdown.Item>
						</>
					)}
					<Dropdown.Item
						onClick={() => {
							transfer.open.value = true
							open.value = false
						}}
					>
						<div className="flex items-center space-x-2 text-slate-500 px-2 py-1">
							Transfer
						</div>
					</Dropdown.Item>
					<Dropdown.Item onClick={handleRejectCandidate} disabled={!rejectStage}>
						<div className="flex items-center space-x-2 text-red-500 px-2 py-1">
							Reject
						</div>
					</Dropdown.Item>
				</div>
			</Dropdown>
		</div>
	)
}

function TransferModal() {
	useSignals()

	const loading = useSignal(false)
	const message = useSignal('')
	const finished = useSignal(false)
	const recruiterId = useSignal(rootStore.organizationUsers.activeOrgUserId)

	const positionCandidate = useReaction(() =>
		rootStore.positions.getPositionCandidateById(store.activePositionCandidateId)
	)

	const positionsForCandidate = useReaction(
		() => {
			if (!positionCandidate) {
				return []
			}
			return [...rootStore.positions.getPositionsForCandidate(positionCandidate.candidateId)]
		},
		100,
		[positionCandidate]
	)

	const positionOptions = useReaction(() =>
		rootStore.positions.groupedSelectOptions.map((o) => {
			return {
				...o,
				options: o.options.filter(
					(o) => !positionsForCandidate.some((p) => p.id === o.value)
				),
			}
		})
	)

	const stageOptions = useStageOptions(false)

	useEffect(() => {
		return autorun(() => {
			transfer.stageId.value =
				rootStore.candidates.settings.transferSettings?.defaultStageId ?? ''
		})
	}, [transfer.open.value])

	const canTransfer = useComputed(() => !!transfer.toPositionId.value && !!transfer.stageId.value)

	const handleTransfer = async () => {
		if (!positionCandidate) {
			toast.error('No candidate was found')
			return
		}

		const position = rootStore.positions.getPositionById(transfer.toPositionId.value)
		if (!position) {
			toast.error('Position was not found')
			return
		}

		// NOTE: doing the logic client side for now; may be better on server
		loading.value = true

		message.value = 'Adding candidate to new position...'
		const newPC = await rootStore.positions.createPositionCandidate(
			position,
			positionCandidate.candidateId
		)

		message.value = 'Updating stage for new position...'
		await rootStore.positions.updatePositionCandidate(
			positionCandidate.candidateId,
			position.id,
			{
				stage: transfer.stageId.value,
				recruiterId: recruiterId.value || undefined,
			}
		)

		message.value = 'Candidate transferred successfully!'
		loading.value = false
		finished.value = true

		setTimeout(() => {
			transfer.open.value = false
			store.activePositionCandidateId = ''
			setTimeout(() => {
				store.activePositionCandidateId = newPC?.id ?? ''
			}, 500)
		}, 1000)
	}

	const users = useOrgUserOptions(true)

	return (
		<Modal
			open={transfer.open.value}
			onClose={() => {
				transfer.open.value = false
				loading.value = false
				message.value = ''
				finished.value = false
				transfer.clear()
			}}
		>
			<Modal.Header>
				<p className="text-lg font-semibold">Transfer Candidate</p>
			</Modal.Header>
			<Modal.Body>
				<Select
					value={positionOptions
						.find((o) => o.positionId === transfer.toPositionId.value)
						?.options.find((o) => o.value === transfer.toPositionId.value)}
					label="Transfer to position:"
					options={positionOptions}
					onChange={createSelectOnChange((v) => (transfer.toPositionId.value = v))}
				/>
				<CandidateStageSelect
					label="Stage for transferred position:"
					options={stageOptions}
					stage={transfer.stageId.value}
					onChange={createSelectOnChange((v) => (transfer.stageId.value = v))}
				/>
				<Select
					label="Recruiter"
					value={users.find((u) => u.value === recruiterId.value)}
					options={users}
					onChange={createSelectOnChange((value) => {
						recruiterId.value = value
					})}
				/>
			</Modal.Body>
			<Modal.Footer>
				<div className="flex w-full justify-between space-x-3 items-center">
					<p className="flex justify-start">
						{message.value && (
							<span className="text-sm text-neutral-700">{message.value}</span>
						)}
					</p>
					<Button
						auto
						disabled={!canTransfer.value || loading.value || finished.value}
						onClick={handleTransfer}
					>
						{loading.value ? <Loading /> : 'Transfer'}
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	)
}
