import { Tooltip } from '@nextui-org/react'
import type { ICandidateStage, IPosition } from '@touchpoints/requests'
import { CandidateStageColorDot } from './CandidateStageColorDot'
import clsx from 'clsx'

type CandidateStageEntryProps = {
	position: IPosition & { referenceName: string }
	stage: ICandidateStage
	noMargin?: boolean
	className?: string
	showJobDescription?: boolean
}
export function CandidateStageEntry({
	position,
	stage,
	noMargin = false,
	className,
	showJobDescription = false,
}: CandidateStageEntryProps) {
	return (
		<div
			className={clsx(
				'w-fit flex items-center space-x-2 p-1 rounded-md bg-[#F1F3F5]',
				{
					'm-1': !noMargin,
				},
				className
			)}
		>
			<CandidateStageColorDot size="sm" noMargin noRing color={stage?.color} />

			<span className="whitespace-nowrap" data-tag="allowRowEvents">
				{position.referenceName}
			</span>

			<div className="w-1 h-1 rounded-full bg-slate-400" />
			{showJobDescription ? (
				<span className="truncate">{position.name ?? 'Unknown'}</span>
			) : (
				<span className="truncate">
					<Tooltip content={stage?.name ?? 'Unknown'} data-tag="allowRowEvents">
						{stage?.name ?? 'Unknown'}
					</Tooltip>
				</span>
			)}
		</div>
	)
}
