import type { IBlueprint } from '@touchpoints/requests'
import { authHeader, get, patch, post, del } from './api'

export async function fetchBlueprints(organizationId: string) {
	const headers = await authHeader()
	return get<{ blueprints: IBlueprint[] }>(
		`/organizations/${organizationId}/blueprints`,
		undefined,
		headers
	)
}
export async function addBlueprint(
	organizationId: string,
	data: Pick<IBlueprint, 'name'> &
		Partial<Pick<IBlueprint, 'candidateStageOverrides' | 'candidateStageNodes'>>
) {
	const headers = await authHeader()
	return post<{ blueprint: IBlueprint }>(
		`/organizations/${organizationId}/blueprints`,
		{
			...data,
		},
		headers
	)
}

export async function updateBlueprint(
	organizationId: string,
	blueprintId: string,
	data: Partial<Pick<IBlueprint, 'name' | 'candidateStageOverrides' | 'candidateStageNodes'>>
) {
	const headers = await authHeader()
	return patch<{ blueprint: IBlueprint }>(
		`/organizations/${organizationId}/blueprints/${blueprintId}`,
		{
			...data,
		},
		headers
	)
}

export async function removeBlueprint(organizationId: string, blueprintId: string) {
	const headers = await authHeader()
	return del(`/organizations/${organizationId}/blueprints/${blueprintId}`, headers)
}
