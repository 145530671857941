import { makeAutoObservable } from 'mobx'
import type { BoardEntry } from '@types'

const onCloseCallbacks: (() => void)[] = []
export const store = makeAutoObservable({
	activePositionCandidateId: '',
	boardEntries: [] as BoardEntry[],
	get isOpen() {
		return !!this.activePositionCandidateId
	},
	setSelectedPositionCandidateId(id: string) {
		this.activePositionCandidateId = id
		if (!id) {
			this.close()
		}
	},
	setBoardEntries(entries: BoardEntry[]) {
		this.boardEntries = entries
	},
	close() {
		if (!this.isOpen) {
			return
		}
		this.activePositionCandidateId = ''
		this.boardEntries = []
		onCloseCallbacks.forEach((cb) => cb())
	},
	onClose(cb: () => void) {
		onCloseCallbacks.push(cb)

		return () => {
			const index = onCloseCallbacks.indexOf(cb)
			if (index > -1) {
				onCloseCallbacks.splice(index, 1)
			}
		}
	},
})

export const accountModalStore = makeAutoObservable({
	isOpen: false,
	close() {
		this.isOpen = false
	},
	open() {
		this.isOpen = true
	},
})
