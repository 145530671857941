import { createSelectOnChange, InputWithCopy, Select } from '@touchpoints/ui'

import type { IPositionCandidate } from '@touchpoints/requests'
import { PropsWithChildren } from 'react'
import { useOrgUserOptions } from '@hooks/users'
import { observer } from 'mobx-react-lite'
import { rootStore } from '@store/index'
import { useReaction } from '@hooks/mobx'

const canUpdateField = (field: keyof IPositionCandidate) => {
	return (
		rootStore.organizationUsers.activeUser?.ability.can(
			'update',
			'position-candidate',
			field
		) ?? false
	)
}

type Props = {
	label?: string
	positionCandidate?: Partial<IPositionCandidate>
	readOnly?: boolean
	onFieldChange?: (field: keyof IPositionCandidate, value: any) => void
}
export const PositionCandidateInfo = observer(function ({
	label,
	positionCandidate,
	onFieldChange,
	readOnly,
	children,
}: PropsWithChildren<Props>) {
	const canUpdateEngagementContactId = canUpdateField('engagementContactId')
	const canUpdateRecruiterId = canUpdateField('recruiterId')
	const canUpdateAccountExecutiveId = canUpdateField('accountExecutiveId')
	const canUpdateSupportContactId = canUpdateField('supportContactId')
	const canUpdateTrelloCardUrl = canUpdateField('trelloCardUrl')

	const users = useOrgUserOptions(true)

	const position = useReaction(
		() => {
			if (!positionCandidate || !positionCandidate.positionId) {
				return
			}
			return rootStore.positions.getPositionById(positionCandidate.positionId)
		},
		100,
		[positionCandidate]
	)

	return (
		<div className="flex flex-col space-y-3 w-full">
			{label && <p className="font-medium text-lg">{label}</p>}
			<Select
				label="Engagement Contact"
				isDisabled={!!readOnly || !canUpdateEngagementContactId}
				value={users.find(
					(u) =>
						u.value ===
						(positionCandidate?.engagementContactId || position?.engagementContactId)
				)}
				options={users}
				onChange={createSelectOnChange((value) => {
					onFieldChange?.('engagementContactId', value)
				})}
			/>
			<Select
				label="Recruiter Contact"
				isDisabled={!!readOnly || !canUpdateRecruiterId}
				value={users.find((u) => u.value === (positionCandidate?.recruiterId ?? ''))}
				options={users}
				onChange={createSelectOnChange((value) => {
					onFieldChange?.('recruiterId', value)
				})}
			/>
			<Select
				label="Account Executive"
				isDisabled={!!readOnly || !canUpdateAccountExecutiveId}
				value={users.find(
					(u) =>
						u.value ===
						(positionCandidate?.accountExecutiveId || position?.accountManagerId)
				)}
				options={users}
				onChange={createSelectOnChange((value) => {
					onFieldChange?.('accountExecutiveId', value)
				})}
			/>
			<Select
				label="Support Contact"
				isDisabled={!!readOnly || !canUpdateSupportContactId}
				value={users.find(
					(u) =>
						u.value ===
						(positionCandidate?.supportContactId || position?.supportContactId)
				)}
				options={users}
				onChange={createSelectOnChange((value) => {
					onFieldChange?.('supportContactId', value)
				})}
			/>
			<InputWithCopy
				label="Trello Card"
				value={positionCandidate?.trelloCardUrl}
				onChange={(e) => {
					onFieldChange?.('trelloCardUrl', e)
				}}
				readOnly={!!readOnly || !canUpdateTrelloCardUrl}
				launch
			/>
			{children}
		</div>
	)
})
