import { Dropdown, useCustomFilterableInput } from '@touchpoints/ui'
import clsx from 'clsx'
import Fuse from 'fuse.js'

import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { HiChevronDown, HiChevronUp, HiPlus } from 'react-icons/hi'
import { MdOutlineCheck } from 'react-icons/md'

export interface QueryBuilderOption {
	value: string
	label: string | ReactNode
	searchString?: string
}

type QueryBuilderSelectProps = {
	options: QueryBuilderOption[]
	value: QueryBuilderOption[]
	onChange: (option: QueryBuilderOption | QueryBuilderOption[]) => void
	isMulti?: boolean
	triggerClassName?: string
	contentClassName?: string
	filterable?: boolean
	inputPlaceholder?: string
}
export const QueryBuilderSelect = function ({
	options,
	value,
	onChange,
	isMulti = false,
	triggerClassName = '',
	contentClassName = 'p-1 mt-1',
	filterable = false,
	inputPlaceholder,
}: QueryBuilderSelectProps) {
	const [open, setOpen] = useState(false)
	const [label, setLabel] = useState(<></>)
	const [plus, setPlus] = useState(<></>)
	const [dropdownOptions, setDropdownOptions] = useState(options)
	const originalOptions = useMemo(() => [...options], [options])
	const [search, setSearch] = useState(
		filterable
			? new Fuse<QueryBuilderOption>(originalOptions, {
					keys: ['searchString'],
			  })
			: null
	)
	const ref = useRef<HTMLInputElement>(null)

	useEffect(() => {
		if (!value || value.length === 0) {
			setLabel(<span className="text-slate-500">{'Select'}</span>)
			setPlus(<></>)
		} else {
			const first = (
				<span className="max-w-[150px] overflow-hidden truncate">{value[0].label}</span>
			)

			if (value.length > 1) {
				setPlus(
					<span className="flex items-center text-xs p-0.5 bg-slate-100 rounded-md">
						<HiPlus /> {value.length - 1}
					</span>
				)
			} else {
				setPlus(<></>)
			}
			setLabel(<span className="flex items-center space-x-1 text-slate-500">{first}</span>)
		}
	}, [value])

	const { query, wrapperRef, onFocus, onBlur } = useCustomFilterableInput()

	useEffect(() => {
		search?.setCollection(originalOptions)
	}, [originalOptions, search])

	useEffect(() => {
		if (!filterable) {
			setSearch(null)
		}
		setSearch(
			new Fuse<QueryBuilderOption>(originalOptions, {
				keys: ['searchString'],
			})
		)
	}, [filterable, originalOptions])

	useEffect(() => {
		if (!query) {
			setDropdownOptions([...originalOptions])
			return
		}

		const id = setTimeout(() => {
			if (!search) {
				return
			}

			const res = search.search(query)
			const items = res.map((r) => r.item)
			setDropdownOptions(items)
			ref.current?.focus()
		}, 300)

		return () => {
			clearTimeout(id)
		}
	}, [search, query, originalOptions])

	return (
		<Dropdown
			side="bottom"
			align="start"
			open={open}
			className={contentClassName}
			onCloseAutoFocus={() => setOpen(false)}
			onEscapeKeyDown={() => setOpen(false)}
			onPointerDownOutside={() => setOpen(false)}
			onFocusOutside={() => setOpen(false)}
			onInteractOutside={() => setOpen(false)}
			trigger={
				<div
					className={clsx(
						triggerClassName,
						`flex justify-between min-w-16 max-w-[190px] items-center border rounded-md shadow-xs px-2 py-1 hover:cursor-pointer`,
						'cursor-pointer font-light text-[#11181C] hover:bg-slate-100 z-100 bg-white',
						{ 'border-[#0091FF]': open }
					)}
					onClick={() => setOpen(!open)}
				>
					<div className="flex truncate">
						<Dropdown.Label className="truncate">{label}</Dropdown.Label>
						&nbsp;
						{plus}
					</div>
					{!open && (
						<div className="w-[16px]">
							<HiChevronDown />
						</div>
					)}
					{open && (
						<div className="w-[16px]">
							<HiChevronUp />
						</div>
					)}
				</div>
			}
		>
			{filterable && (
				<>
					<div className="flex flex-col bg-inherit sticky top-0">
						<input
							ref={wrapperRef}
							type="text"
							className={clsx(
								'w-full text-gray-900 text-sm my-1 p-2 focus:ring-blue-500 focus:border-blue-500 block dark:placeholder-gray-400 dark:text-white border-0 ring-0'
							)}
							aria-label="filter members"
							placeholder={inputPlaceholder}
							value={query}
							onFocus={onFocus}
							onBlur={onBlur}
							onChange={() => {
								//do nothing...
							}}
						/>
						<hr className="h-0 w-full border-t border-slate-100"></hr>
					</div>
				</>
			)}
			<div className="px-2 sm:max-h-[100px] md:max-h-[200px] lg:max-h-[300px] xl:max-h-[400px] 2xl:max-h-[700px]">
				{!isMulti &&
					dropdownOptions.map((option, index) => {
						return (
							<Dropdown.Item
								key={`${option.value}-${index}`}
								onSelect={() => {
									onChange(option)
									setOpen(isMulti)
								}}
							>
								<p className="font-light text-sm leading-4 p-1 hover:bg-slate-50 truncate">
									{option.label}
								</p>
							</Dropdown.Item>
						)
					})}

				{isMulti &&
					dropdownOptions.map((option, index) => {
						return (
							<Dropdown.CheckboxItem
								key={`${option.value}-${index}`}
								checked={value.some((v) => v.value === option.value)}
								onCheckedChange={(checked) => {
									if (checked) {
										onChange([...value, option])
									} else {
										onChange(value.filter((v) => v.value !== option.value))
									}
									setOpen(isMulti)
								}}
							>
								<div className="flex items-center">
									<div className="w-5">
										<Dropdown.ItemIndicator>
											<MdOutlineCheck className="w-[16px]" />
										</Dropdown.ItemIndicator>
									</div>
									<div className="text-sm p-1 hover:bg-slate-50 truncate">
										{option.label}
									</div>
								</div>
							</Dropdown.CheckboxItem>
						)
					})}
			</div>
		</Dropdown>
	)
}
