import sortBy from 'lodash/sortBy'
import { makeAutoObservable } from 'mobx'

import { fetchOrganizationsForUser } from '@requests/users'
import * as localData from '@services/localData'

import {
	updateCleaningSettings,
	updateEmailsSettings,
	updateNotificationSettings,
	updateOrg,
	updateSourcingSettings,
	updateStages,
	updateWebhooksSettings,
} from '@requests/settings'
import {
	type ICandidateStage,
	type ICandidateView,
	type ICleaningSettings,
	INotificationSettings,
	type IOrganization,
	type IOrgEmailSettings,
	IOrgStageProps,
	type ISourcingSettings,
	type IStageGroup,
	IStageGroupProps,
	type IWebhookSettings,
	STAGE_GROUPS,
} from '@touchpoints/requests'
import { OrgStage, StageGroup } from './stageTemplates'

class Organization implements IOrganization {
	id = ''
	name = ''
	slug = ''
	createdBy = ''
	candidateStages: ICandidateStage[] = []
	stages: {
		groups: IStageGroup[]
	} = { groups: [] }
	candidateViews: ICandidateView[] = []
	emailSettings: IOrgEmailSettings = {}
	sourcingSettings: ISourcingSettings = {}
	cleaningSettings: ICleaningSettings = {}
	webhookSettings: IWebhookSettings = {}
	notificationSettings: INotificationSettings = {}
	defaultTimezone = ''

	constructor(data: IOrganization) {
		makeAutoObservable(this)

		this.update(data)
	}

	update(data: IOrganization) {
		this.id = data.id
		this.name = data.name
		this.slug = data.slug
		this.createdBy = data.createdBy
		this.candidateStages = data.candidateStages
		this.stages = this.mapToOrgStages(data.stages.groups)
		this.candidateViews = data.candidateViews
		this.emailSettings = data.emailSettings ?? {}
		this.sourcingSettings = data.sourcingSettings ?? {}
		this.cleaningSettings = data.cleaningSettings ?? {}
		this.webhookSettings = data.webhookSettings ?? {}
		this.defaultTimezone = data.defaultTimezone ?? ''
		this.notificationSettings = data.notificationSettings ?? {}

		if (this.stages.groups.length === 0) {
			this.stages = this.mapToOrgStages(STAGE_GROUPS)
		}
	}

	private mapToOrgStages(stageGroups: IStageGroupProps[]): { groups: IStageGroup[] } {
		return {
			groups: stageGroups.map((sg) => {
				return new StageGroup({
					...sg,
					stages: sg.stages.map((s: IOrgStageProps) => new OrgStage(s)),
				})
			}),
		}
	}
}

export class OrganizationsStore {
	activeOrganizationId = ''

	readonly organizations: Record<string, Organization | undefined> = {}

	private _loading = true

	get activeOrganization() {
		return this.organizations[this.activeOrganizationId]
	}

	get emailSettings() {
		return this.activeOrganization?.emailSettings
	}

	get sourcingSettings() {
		return this.activeOrganization?.sourcingSettings
	}

	get cleaningSettings() {
		return this.activeOrganization?.cleaningSettings
	}

	get webhookSettings() {
		return this.activeOrganization?.webhookSettings
	}

	get notificationSettings() {
		return this.activeOrganization?.notificationSettings
	}

	get list() {
		const keys = sortBy(Object.keys(this.organizations))
		return keys.map((key) => this.organizations[key]) as Organization[]
	}

	get isLoading() {
		return this._loading
	}

	constructor() {
		makeAutoObservable(this)
	}

	async fetchOrganizations() {
		this.setLoading(true)
		const res = await fetchOrganizationsForUser('me')
		this.setLoading(false)

		if (!res.success || !res.data) {
			return
		}

		const out: Record<string, Organization> = {}

		this.clearOrganizations()

		res.data.organizations.forEach((orgData) => {
			const org = new Organization(orgData)

			out[org.id] = org
			this.addOrganization(org)
		})

		return out
	}

	async switchTo(orgId?: string) {
		if (orgId === this.activeOrganizationId) {
			return
		}

		if (orgId) {
			this.setActiveOrganizationId(orgId)
		}

		await this.fetchOrganizations()

		const orgs = Object.keys(this.organizations)
		if (!orgId && orgs.length > 0) {
			orgId = this.organizations[orgs[0]]?.id
		}

		if (!orgId) {
			return
		}

		this.setActiveOrganizationId(orgId)

		if (!this.organizations[orgId]) {
			// we don't know about this org
			console.error('tried switching to an unknown organization')
			return
		}

		localData.store({
			currentOrgId: orgId,
		})
	}

	addOrganization(orgData: IOrganization | Organization) {
		this.organizations[orgData.id] =
			orgData instanceof Organization ? orgData : new Organization(orgData)
	}

	clearOrganizations() {
		const keys = Object.keys(this.organizations)
		for (let i = 0; i < keys.length; ++i) {
			const key = keys[i]
			delete this.organizations[key]
		}
	}

	async saveOrganization(orgData: Partial<Pick<IOrganization, 'name' | 'defaultTimezone'>>) {
		if (!this.activeOrganizationId) {
			return
		}

		const res = await updateOrg(this.activeOrganizationId, orgData)

		if (!res.success) {
			return
		}

		const { organization: updatedOrg } = res.data ?? {}

		if (!updatedOrg) {
			return
		}

		this.activeOrganization?.update(updatedOrg)
	}

	async saveEmailSettings(settings: IOrgEmailSettings) {
		if (!this.activeOrganizationId) {
			return
		}

		const res = await updateEmailsSettings(this.activeOrganizationId, settings)

		if (!res.success) {
			return
		}

		const { settings: updatedSettings } = res.data ?? {}
		if (!updatedSettings) {
			return
		}

		if (!this.activeOrganization) {
			return
		}

		this.activeOrganization.update({
			...this.activeOrganization,
			emailSettings: updatedSettings,
		})

		return updatedSettings
	}

	async saveSourcingSettings(settings: ISourcingSettings) {
		if (!this.activeOrganizationId) {
			return
		}

		const res = await updateSourcingSettings(this.activeOrganizationId, settings)

		if (!res.success) {
			return
		}

		const { settings: updatedSettings } = res.data ?? {}
		if (!updatedSettings) {
			return
		}

		if (!this.activeOrganization) {
			return
		}

		this.activeOrganization.update({
			...this.activeOrganization,
			sourcingSettings: updatedSettings,
		})

		return updatedSettings
	}

	async saveCleaningSettings(settings: ICleaningSettings) {
		if (!this.activeOrganizationId) {
			return
		}

		const res = await updateCleaningSettings(this.activeOrganizationId, settings)

		if (!res.success) {
			return
		}

		const { settings: updatedSettings } = res.data ?? {}
		if (!updatedSettings) {
			return
		}

		if (!this.activeOrganization) {
			return
		}

		this.activeOrganization.update({
			...this.activeOrganization,
			cleaningSettings: updatedSettings,
		})

		return updatedSettings
	}

	async saveWebhookSettings(settings: IWebhookSettings) {
		if (!this.activeOrganizationId) {
			return
		}

		const res = await updateWebhooksSettings(this.activeOrganizationId, settings)

		if (!res.success) {
			return
		}

		const { settings: updatedSettings } = res.data ?? {}
		if (!updatedSettings) {
			return
		}

		if (!this.activeOrganization) {
			return
		}

		this.activeOrganization.update({
			...this.activeOrganization,
			webhookSettings: updatedSettings,
		})

		return updatedSettings
	}

	async saveNotificationSettings(settings: INotificationSettings) {
		if (!this.activeOrganizationId) {
			return
		}

		const res = await updateNotificationSettings(this.activeOrganizationId, settings)

		if (!res.success) {
			return
		}

		const { settings: updatedSettings } = res.data ?? {}
		if (!updatedSettings) {
			return
		}

		if (!this.activeOrganization) {
			return
		}

		this.activeOrganization.update({
			...this.activeOrganization,
			notificationSettings: updatedSettings,
		})

		return updatedSettings
	}

	async saveStages(payload: IStageGroup[]) {
		if (!this.activeOrganizationId) {
			return
		}

		const res = await updateStages(this.activeOrganizationId, payload)

		if (!res.success) {
			return
		}

		const { stageGroups } = res.data ?? { stageGroups: [] }
		if (!stageGroups) {
			return
		}

		if (!this.activeOrganization) {
			return
		}

		this.activeOrganization.update({
			...this.activeOrganization,
			stages: { groups: stageGroups },
		})
	}

	private setLoading(v: boolean) {
		this._loading = v
	}

	private setActiveOrganizationId(id: string) {
		this.activeOrganizationId = id
	}
}
