import {
	autorun,
	makeAutoObservable,
	observable,
	ObservableMap,
	ObservableSet,
	runInAction,
} from 'mobx'
import {
	deleteSequence,
	fastForwardInstance,
	fetchSequenceInstance,
	fetchSequenceInstances,
	fetchSequences,
	pauseSequence,
	resumeSequence,
	updateSequence,
} from '@requests/sequences'
import { SequenceProp } from '@requests/sequences'

import {
	ICandidate,
	ISequence,
	ISequenceInstance,
	ISequenceTrigger,
	ISequenceWindow,
	SequenceAction as SequenceActionProps,
	SequenceActionType,
	SequenceInstanceCompletedAction,
	SequenceInstanceStatus,
	SequenceInstanceStepStatus,
	SequenceStatus,
	TriggerActionName,
	TriggerEvent,
	TriggerType,
} from '@touchpoints/requests'

import type { RootStore } from './root'
import { addCandidateToSequence, fetchCandidateSequenceInstances } from '@requests/candidates'
import type { AddCandidateToSequenceOptions } from '@requests/candidates'
import { createAsyncQueue } from '@services/queue'
import { fetchPositionCandidateSequences } from '@requests/positions'

class SequenceAction {
	delay = 0
	type: SequenceActionType = 'auto-email'
	data?: Record<string, any>

	constructor(data: SequenceActionProps) {
		makeAutoObservable(this)

		this.delay = data.delay
		this.type = data.type
		this.data = data.data
	}
}

class SequenceTrigger implements ISequenceTrigger {
	id: string
	type = TriggerType.Sequence
	event: TriggerEvent
	action = TriggerActionName.ChangeCandidateStage
	candidateStage = ''
	stageTemplateStageId = ''

	constructor(data: Partial<ISequenceTrigger>) {
		this.id = data.id ?? 'unknown'
		this.type = data.type ?? this.type
		this.event = data.event ?? TriggerEvent.CandidateAddedToSequence
		this.action = data.action ?? this.action
		this.candidateStage = data.candidateStage ?? this.candidateStage
		this.stageTemplateStageId = data.stageTemplateStageId ?? this.stageTemplateStageId
		makeAutoObservable(this)
	}
}

function defaultSequenceWindow() {
	return {
		days: ['mon', 'tue', 'wed', 'thu', 'fri'],
		startHour: '8:00am',
		endHour: '5:00pm',
	} as ISequenceWindow
}

class Sequence implements ISequence {
	id = ''
	organizationId = ''
	name = ''
	actions: SequenceAction[] = []
	status: SequenceStatus = 'running'
	mailboxEmail = ''
	triggers: SequenceTrigger[] = []
	triggerActions: { id: string; event: TriggerEvent; referenceId: string }[] = []
	window: ISequenceWindow = defaultSequenceWindow()
	keepAfterMeetingBooked = false
	candidateCanBeAddedOnlyOnce = false
	removeAfterReply = true

	constructor(data: ISequence) {
		this.update(data)

		makeAutoObservable(this)
	}

	update(data: ISequence) {
		this.id = data.id
		this.organizationId = data.organizationId
		this.name = data.name
		this.actions = data.actions.map((action) => new SequenceAction(action))
		this.status = data.status
		this.mailboxEmail = data.mailboxEmail
		this.triggers = data.triggers.map((trigger) => new SequenceTrigger(trigger))
		this.triggerActions = data.triggerActions ?? []
		this.window = data.window ?? defaultSequenceWindow()
		this.keepAfterMeetingBooked = data.keepAfterMeetingBooked ?? false
		this.candidateCanBeAddedOnlyOnce = data.candidateCanBeAddedOnlyOnce ?? false
		this.removeAfterReply = data.removeAfterReply ?? true
	}

	setActions(actions: SequenceAction[]) {
		this.actions.length = 0
		actions.forEach((action) => this.actions.push(new SequenceAction(action)))
	}
}

class SequenceInstance implements ISequenceInstance {
	id: string
	candidateId: string
	positionId: string
	sequenceId: string
	organizationId: string
	actionStep: number
	createdAt: Date
	updatedAt: Date
	pausedAt?: Date
	completedAt?: Date
	status: SequenceInstanceStatus
	stepStatus: SequenceInstanceStepStatus
	candidate?: ICandidate
	senderId?: string
	senderEmail?: string
	lastSendAt?: number
	activeTaskId?: string | null
	nextActionAt?: number
	completedAction?: SequenceInstanceCompletedAction

	constructor(data: ISequenceInstance) {
		makeAutoObservable(this, {
			candidate: false,
		})

		this.id = data.id
		this.candidateId = data.candidateId
		this.positionId = data.positionId
		this.sequenceId = data.sequenceId
		this.organizationId = data.organizationId
		this.actionStep = data.actionStep
		this.createdAt = data.createdAt
		this.updatedAt = data.updatedAt
		this.pausedAt = data.pausedAt
		this.completedAt = data.completedAt
		this.status = data.status
		this.stepStatus = data.stepStatus
		this.senderId = data.senderId
		this.senderEmail = data.senderEmail
		this.lastSendAt = data.lastSendAt
		this.activeTaskId = data.activeTaskId
		this.nextActionAt = data.nextActionAt
		this.completedAction =
			data.completedAction ?? (data.status === 'completed' ? 'finished' : undefined)
	}

	update(data: ISequenceInstance) {
		Object.assign(this, data)
	}
}

export class SequencesStore {
	readonly rootStore: RootStore

	readonly list: Sequence[] = []

	readonly instances: SequenceInstance[] = []

	activeSequenceId: string | null = null

	readonly instancesByCandidateId = new ObservableMap<string, SequenceInstance[]>()

	readonly queuedForFastForward = new ObservableSet()

	private requestQueue = createAsyncQueue({ maxChannels: 3 })
	private instanceById: Record<string, SequenceInstance> = {}

	private recentlyDeletedInstanceIds = new Set<string>()

	constructor(root: RootStore) {
		this.rootStore = root

		makeAutoObservable(this, {
			rootStore: false,
			getSequenceById: observable,
		})

		autorun(async () => {
			await this.fetchSequences()
		})

		autorun(async () => {
			if (!this.activeSequence) {
				return
			}

			await this.fetchSequenceInstances(this.activeSequence.id)
		})
	}

	get isLoading() {
		return this.rootStore.organizations.isLoading || !this.requestQueue.isEmpty()
	}

	get activeSequence() {
		if (!this.activeSequenceId) {
			return undefined
		}
		return this.getSequenceById(this.activeSequenceId)
	}

	get activeInstances() {
		return this.instances.filter((instance) => instance.sequenceId === this.activeSequenceId)
	}

	get activeRunningInstances() {
		return this.activeInstances.filter((instance) => instance.status !== 'completed')
	}

	setActiveSequenceId(id: string) {
		this.activeSequenceId = id
	}

	clearActiveSequence() {
		this.activeSequenceId = null
	}

	async fetchSequences() {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await fetchSequences(orgId)
		if (!res.data) {
			return
		}

		const { sequences = [] } = res.data

		this.clearSequences()
		sequences.forEach((seq) => this.addSequence(seq))
	}

	async fetchSequenceInstance(id: string) {
		// TODO: maybe throttle this
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await this.requestQueue.addUnique(`fetch-sequence-instance:${id}`, () =>
			fetchSequenceInstance(orgId, id)
		)
		if (!res.data) {
			return
		}

		const { sequenceInstance } = res.data ?? {}
		if (!sequenceInstance) {
			return
		}

		return this.addSequenceInstance(sequenceInstance)
	}

	async fetchSequenceInstances(sequenceId: string) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await fetchSequenceInstances(orgId, sequenceId)
		if (!res.data) {
			return
		}

		const { sequenceInstances = [] } = res.data

		runInAction(() => {
			sequenceInstances.forEach((instance) => {
				const candidateData = instance.candidate

				this.addSequenceInstance(instance)
				if (candidateData) {
					this.rootStore.candidates.addCandidate(candidateData)
				}
			})
		})
	}

	async fetchSequenceInstancesForCandidate(candidateId: string, status?: SequenceInstanceStatus) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId || !candidateId) {
			return
		}

		const res = await this.requestQueue.addUnique(
			`fetch-candidate-sequences:${candidateId}${status ? `:${status}` : ''}`,
			() => fetchCandidateSequenceInstances(orgId, candidateId, { status })
		)

		if (!res.data) {
			return
		}

		const { sequenceInstances } = res.data

		runInAction(() => {
			sequenceInstances.forEach((instance) => {
				const candidateData = instance.candidate

				this.addSequenceInstance(instance)
				if (candidateData) {
					this.rootStore.candidates.addCandidate(candidateData)
				}
			})
		})
	}

	async fetchSequenceInstancesForPositionCandidate(
		positionCandidateId: string,
		status?: SequenceInstanceStatus
	) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId || !positionCandidateId) {
			return
		}

		const res = await this.requestQueue.addUnique(
			`fetch-pc-sequences:${positionCandidateId}${status ? `:${status}` : ''}`,
			() => fetchPositionCandidateSequences(orgId, positionCandidateId, { status })
		)

		if (!res.data) {
			return
		}

		const { sequenceInstances } = res.data

		runInAction(() => {
			sequenceInstances.forEach((instance) => {
				const candidateData = instance.candidate

				this.addSequenceInstance(instance)
				if (candidateData) {
					this.rootStore.candidates.addCandidate(candidateData)
				}
			})
		})
	}

	async addCandidateToSequence(
		sequenceId: string,
		candidateId: string,
		positionId: string,
		options?: AddCandidateToSequenceOptions
	) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return { success: false, message: 'missing orgId' }
		}

		const res = await addCandidateToSequence(
			orgId,
			sequenceId,
			candidateId,
			positionId,
			options
		)

		if (!res.success) {
			return { success: false, message: res.message }
		}

		this.rootStore.candidates.addSequenceToCandidate(candidateId, sequenceId)

		const { sequenceInstance } = res.data ?? {}
		if (sequenceInstance) {
			this.rootStore.sequences.addSequenceInstance(sequenceInstance)
		}

		return {
			success: true,
			data: res.data,
		}
	}

	async fastForwardSequenceInstance(instanceId: string) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return { success: false, message: 'missing orgId' }
		}

		const instance =
			this.instanceById[instanceId] ?? this.instances.find((i) => i.id === instanceId)
		if (!instance) {
			return { success: false, message: 'cannot find instance' }
		}

		const res = await fastForwardInstance(orgId, instance.sequenceId, instanceId)
		if (!res.success) {
			return { success: false, message: res.message }
		}

		const { sequenceInstance } = res.data ?? {}
		if (sequenceInstance) {
			this.addSequenceInstance(sequenceInstance)
		}

		this.queuedForFastForward.add(instanceId)

		return { success: true }
	}

	isQueuedForFastForward(instanceId: string) {
		return this.queuedForFastForward.has(instanceId)
	}

	getSequenceById(id: string) {
		if (!id) {
			return undefined
		}
		return this.list.find((seq) => seq.id === id)
	}

	getSequenceInstanceById(id: string) {
		if (!id) {
			return undefined
		}
		return this.instanceById[id]
	}

	getSequenceInstanceByCandidateId(id: string) {
		if (!id) {
			return []
		}
		return this.instancesByCandidateId.get(id) ?? []
	}

	getSequenceInstancesByCandidateAndPosition(candidateId: string, positionId: string) {
		if (!candidateId || !positionId) {
			return []
		}

		const list = this.getSequenceInstanceByCandidateId(candidateId)
		const instances = list.filter((i) => i.positionId === positionId) ?? []

		return instances
	}

	isSequenceInstanceRecentlyDeleted(id: string) {
		return this.recentlyDeletedInstanceIds.has(id)
	}

	addSequence(sequence: ISequence | Sequence) {
		this.list.push(sequence instanceof Sequence ? sequence : new Sequence(sequence))
	}

	async removeSequence(sequence: ISequence) {
		if (!sequence.organizationId || !sequence.id) {
			return undefined
		}

		const res = await deleteSequence(sequence.organizationId, sequence.id)
		if (!res.success) {
			return undefined
		}

		const idx = this.list.findIndex((seq) => seq.id === sequence.id)
		return this.list.splice(idx, 1)[0]
	}

	async updateSequence(sequence: ISequence, props: Partial<Omit<SequenceProp, 'actions'>>) {
		if (!sequence.organizationId || !sequence.id) {
			return undefined
		}

		const res = await updateSequence(sequence.organizationId, sequence.id, props)
		if (!res.success) {
			return undefined
		}

		const { sequence: newSequence } = res.data ?? {}
		if (!newSequence) {
			return undefined
		}

		const idx = this.list.findIndex((seq) => seq.id === newSequence.id)
		if (!idx) {
			return undefined
		}

		this.list[idx].update(newSequence)
	}

	addSequenceInstance(instance: ISequenceInstance | SequenceInstance) {
		const si = instance instanceof SequenceInstance ? instance : new SequenceInstance(instance)

		if (si.id in this.instanceById) {
			this.instanceById[si.id].update(si)
		} else {
			this.instanceById[si.id] = si
			this.instances.push(si)
		}

		if (!this.instancesByCandidateId.has(si.candidateId)) {
			this.instancesByCandidateId.set(si.candidateId, [])
		}
		const list = this.instancesByCandidateId.get(si.candidateId) ?? []

		const idx = list.findIndex((i) => i.id === si.id)
		if (idx >= 0) {
			list[idx].update(si)
		} else {
			list.push(si)
		}

		return si
	}

	removeSequenceInstance(instance: ISequenceInstance) {
		const idx = this.instances.findIndex((i) => i.id === instance.id)
		this.instances.splice(idx, 1)
		delete this.instanceById[instance.id]
		this.instancesByCandidateId.delete(instance.candidateId)

		this.recentlyDeletedInstanceIds.add(instance.id)
	}

	clearSequences() {
		while (this.list.length > 0) {
			this.list.pop()
		}
	}

	clearSequenceInstances() {
		while (this.activeInstances.length > 0) {
			this.activeInstances.pop()
		}
		this.instancesByCandidateId.clear()
	}

	async pauseSequence(sequenceId: string) {
		const orgId = this.rootStore.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const seq = this.getSequenceById(sequenceId)
		if (!seq) {
			return
		}

		const prevStatus = seq.status

		seq.status = 'paused'

		const res = await pauseSequence(orgId, sequenceId)
		console.log(res)

		if (!res.success) {
			seq.status = prevStatus
			console.error(res)
			return
		}

		return res.data
	}

	async resumeSequence(sequenceId: string) {
		const orgId = this.rootStore.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const seq = this.getSequenceById(sequenceId)
		if (!seq) {
			return
		}

		const prevStatus = seq.status

		seq.status = 'running'

		const res = await resumeSequence(orgId, sequenceId)
		console.log(res)

		if (!res.success) {
			seq.status = prevStatus
			console.error(res)
			return
		}

		return res.data
	}
}
