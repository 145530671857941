export function CommentAction({ size = 20 }: { size?: number }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
		>
			<g id="actions">
				<path
					id="Icon"
					d="M3.267 13.3079L2.70625 13.5839H2.70625L3.267 13.3079ZM6.79216 16.7813L6.52452 17.3461H6.52452L6.79216 16.7813ZM6.52954 16.7461L6.4146 16.1317L6.41459 16.1317L6.52954 16.7461ZM3.10943 17.386L2.99449 16.7716H2.99449L3.10943 17.386ZM2.62464 16.8927L3.23688 17.0183H3.23688L2.62464 16.8927ZM3.30373 13.5836L2.69149 13.458H2.69149L3.30373 13.5836ZM16.875 10C16.875 13.797 13.797 16.875 10 16.875V18.125C14.4873 18.125 18.125 14.4873 18.125 10H16.875ZM3.125 10C3.125 6.20304 6.20304 3.125 10 3.125V1.875C5.51269 1.875 1.875 5.51269 1.875 10H3.125ZM10 3.125C13.797 3.125 16.875 6.20304 16.875 10H18.125C18.125 5.51269 14.4873 1.875 10 1.875V3.125ZM3.82775 13.0318C3.37795 12.118 3.125 11.0894 3.125 10H1.875C1.875 11.2851 2.17378 12.5021 2.70625 13.5839L3.82775 13.0318ZM10 16.875C8.94676 16.875 7.95048 16.6386 7.0598 16.2165L6.52452 17.3461C7.57897 17.8458 8.75775 18.125 10 18.125V16.875ZM6.41459 16.1317L2.99449 16.7716L3.22438 18.0003L6.64448 17.3604L6.41459 16.1317ZM3.23688 17.0183L3.91597 13.7093L2.69149 13.458L2.0124 16.767L3.23688 17.0183ZM2.99449 16.7716C3.13996 16.7444 3.26663 16.8733 3.23688 17.0183L2.0124 16.767C1.86364 17.4919 2.497 18.1364 3.22438 18.0003L2.99449 16.7716ZM7.0598 16.2165C6.86439 16.1239 6.63902 16.0897 6.4146 16.1317L6.64448 17.3604C6.59804 17.3691 6.5563 17.3612 6.52452 17.3461L7.0598 16.2165ZM2.70625 13.5839C2.68993 13.5507 2.68148 13.5067 2.69149 13.458L3.91597 13.7093C3.96428 13.4738 3.92824 13.236 3.82775 13.0318L2.70625 13.5839Z"
					fill="#D6409F"
				/>
			</g>
		</svg>
	)
}
