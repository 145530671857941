import { fetchCandidateEventsForAccount } from '@requests/accounts'
import {
	deleteCandidateEvent,
	deletePositionCandidateEvent,
	fetchAllCandidateEvents,
	fetchCandidateEvents,
	fetchPositionCandidateEvents,
} from '@requests/events'
import { ICandidateEvent, IPositionCandidateEvent } from '@touchpoints/requests'
import { orderBy } from 'lodash'
import { makeAutoObservable, runInAction } from 'mobx'
import type { RootStore } from '../root'

export class CandidateEvents {
	private readonly root: RootStore

	eventsBycandidate: Record<string, (ICandidateEvent | IPositionCandidateEvent)[]> = {}

	constructor(root: RootStore) {
		this.root = root
		makeAutoObservable(this)
	}

	getEventsForCandidate(candidateId: string) {
		return this.eventsBycandidate[candidateId]
	}

	async fetchEventsForAccount(orgId: string, accountId: string) {
		if (!orgId || !accountId) return

		const res = await fetchCandidateEventsForAccount(orgId, accountId)

		const { events } = res.data ?? {}

		events?.forEach((event) => {
			runInAction(() => {
				this.eventsBycandidate[event.candidateId] = orderBy(
					[...event.candidateEvents, ...event.positionCandidateEvents],
					'ts',
					'desc'
				)
			})
		})
	}

	async fetchAllEventsForCandidate(candidateId: string) {
		const orgId = this.root.organizations.activeOrganizationId

		if (!orgId || !candidateId) {
			return
		}

		const response = await fetchAllCandidateEvents(orgId, candidateId)
		if (response === undefined) {
			return []
		}

		const { events } = response.data ?? {}

		this.updateEvents(candidateId, events || [])

		return this.eventsBycandidate[candidateId]
	}

	async deleteCandidateEvent(candidateId: string, eventId: string) {
		const orgId = this.root.organizations.activeOrganizationId

		if (!orgId || !candidateId) {
			return
		}

		const res = await deleteCandidateEvent(orgId, candidateId, eventId)
		if (!res.data) {
			return
		}

		const { event } = res.data

		return this.updateEvent(candidateId, event)
	}

	async deletePositionCandidateEvent(positionId: string, candidateId: string, eventId: string) {
		const orgId = this.root.organizations.activeOrganizationId

		if (!orgId || !positionId) {
			return
		}

		const pos = this.root.positions.getPositionById(positionId)
		if (!pos) {
			return
		}

		const res = await deletePositionCandidateEvent(
			orgId,
			pos.accountId,
			positionId,
			candidateId,
			eventId
		)
		if (!res.data) {
			return
		}

		const { event } = res.data

		// events are logically deleted
		return this.updateEvent(candidateId, event)
	}

	async fetchCandidateEvents(id: string) {
		const orgId = this.root.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await fetchCandidateEvents(orgId, id)
		if (!res.data) {
			return
		}

		const { events = [] } = res.data
		this.updateCandidateEvents(id, events)
		return events
	}

	async fetchPositionCandidateEvents(positionId: string, candidateId: string) {
		const orgId = this.root.organizations.activeOrganizationId

		if (!orgId || !positionId || !candidateId) {
			return
		}

		const pos = this.root.positions.list.find((p) => p.id === positionId)
		if (!pos) {
			return
		}

		const res = await fetchPositionCandidateEvents(
			orgId,
			pos.accountId,
			positionId,
			candidateId
		)
		if (!res.data) {
			return
		}

		const { events } = res.data
		return this.updatePositionCandidateEvents(candidateId, events)
	}

	updateEvents(candidateId: string, events: (ICandidateEvent | IPositionCandidateEvent)[]) {
		runInAction(() => {
			this.eventsBycandidate[candidateId] = orderBy(events, 'ts', 'desc')
		})
	}

	private updatePositionCandidateEvents(candidateId: string, events: IPositionCandidateEvent[]) {
		const allEvents = this.eventsBycandidate[candidateId]
		const candidateEvents = allEvents.filter((e) => e.resourceType === 'candidate')
		runInAction(() => {
			this.eventsBycandidate[candidateId] = orderBy(
				[...candidateEvents, ...events],
				'ts',
				'desc'
			)
		})
	}

	private updateCandidateEvents(candidateId: string, events: ICandidateEvent[]) {
		const allEvents = this.eventsBycandidate[candidateId]
		const positionCandidateEvents = allEvents.filter(
			(e) => e.resourceType === 'candidate-position'
		)

		runInAction(() => {
			this.eventsBycandidate[candidateId] = orderBy(
				[...positionCandidateEvents, ...events],
				'ts',
				'desc'
			)
		})
	}

	private updateEvent(candidateId: string, event: ICandidateEvent | IPositionCandidateEvent) {
		const allEvents = this.eventsBycandidate[candidateId]

		runInAction(() => {
			this.eventsBycandidate[candidateId] = orderBy(
				[...allEvents.filter((e) => e.id !== event.id), event],
				'ts',
				'desc'
			)
		})
	}
}
