import clsx from 'clsx'
import { PropsWithChildren, useEffect } from 'react'
import SlidingPane from 'react-sliding-pane'

export type SlidePanelProps = {
	isOpen?: boolean
	title?: React.ReactNode
	subtitle?: React.ReactNode
	from?: 'left' | 'right' | 'bottom'
	children: React.ReactNode
	className?: string
	overlayClassName?: string
	panelContenClassName?: string
	width?: string
	closeIcon?: React.ReactNode
	shouldCloseOnEsc?: boolean
	hideHeader?: boolean
	onRequestClose: () => void
	onAfterOpen?: () => void
	onAfterClose?: () => void
}

export function SlidePanel({
	children,
	isOpen,
	panelContenClassName,
	...props
}: PropsWithChildren<SlidePanelProps>) {
	useEffect(() => {
		const toolTipElem = document.getElementById('nextui-tooltip')
		const elems = document.getElementsByClassName('ReactModalPortal')
		const item = elems.item(0)
		if (!item || !toolTipElem) {
			return
		}

		item.parentElement?.appendChild(toolTipElem)
	}, [])

	useEffect(() => {
		// Not proud of this hack, but it works
		setTimeout(() => {
			// this if is needed to avoid SSR build errors
			if (typeof document !== 'undefined') {
				const panelContent = document.getElementsByClassName('slide-pane__content')
				for (let i = 0; i < panelContent.length; i++) {
					if (panelContent[i] && panelContenClassName) {
						panelContent[i].className = clsx(
							panelContent[i].className,
							panelContenClassName
						)
					}
				}
			}
		}, 0)
	}, [isOpen, panelContenClassName])

	return (
		<SlidingPane overlayClassName="z-[500]" className="rounded-none" isOpen={isOpen} {...props}>
			{children}
		</SlidingPane>
	)
}
