import { makeAutoObservable } from 'mobx'

export const candidateSlidingPanelStore = makeAutoObservable({
	activeCandidateId: '',
	get isOpen() {
		return !!this.activeCandidateId
	},
	setSelectedCandidateId(id: string) {
		this.activeCandidateId = id
		console.log('setting candidateId: ', id)
		if (!id) {
			this.close()
		}
	},
	close() {
		this.activeCandidateId = ''
	},
})
