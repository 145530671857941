import { Tooltip } from '@nextui-org/react'
import { rootStore } from '@store/index'
import { type ITask } from '@touchpoints/requests'
import Avvvatars from 'avvvatars-react'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import { HiOutlineChevronDown, HiOutlineChevronUp } from 'react-icons/hi'
import { TaskName } from './TaskName'
import { StatusView } from './TasksFooter'

export const TaskListItem = observer(function ({
	task,
	avatarSize = 20,
}: {
	task: ITask
	avatarSize?: number
}) {
	const router = useRouter()
	const assigneeUserOrg = task.assignedUserId
		? rootStore.organizationUsers.users[task.assignedUserId]
		: undefined
	return (
		<div
			className="flex flex-row justify-between w-full items-center cursor-pointer"
			onClick={() => {
				router.push(`/tasks/${task.id}`)
			}}
		>
			<StatusView status={task.status} onlyIcon />
			<div className="mx-2 w-full">
				<TaskName task={task} />
			</div>
			<div>
				<Tooltip content={`${assigneeUserOrg?.firstName} ${assigneeUserOrg?.lastName}`}>
					<Avvvatars
						style="character"
						value={assigneeUserOrg?.email || ''}
						size={avatarSize}
						border
					/>
				</Tooltip>
			</div>
		</div>
	)
})

export interface TaskListProps {
	candidateId: string
	taskList?: ITask[]
	collapsable?: boolean
	avatarSize?: number
}
export const TaskList = observer(function ({
	candidateId,
	taskList,
	collapsable = true,
	avatarSize = 20,
}: TaskListProps) {
	const [open, setOpen] = useState(true)

	const candidateTasks = useMemo(() => {
		const taskFilter = (t: ITask) => {
			{
				if (t.data?.candidateId && candidateId) {
					return t.data.candidateId === candidateId
				}
				return false
			}
		}

		const list = taskList
			? taskList
			: [
					...rootStore.tasks.list.filter(taskFilter),
					...rootStore.tasks.finishedTasks.filter(taskFilter),
			  ]

		return list.sort((a, b) => {
			if (a.createdAt < b.createdAt) {
				return 1
			}
			if (a.createdAt > b.createdAt) {
				return -1
			}
			return 0
		})
	}, [taskList, candidateId])

	if (candidateTasks.length <= 0) {
		return
	}

	return (
		<div className="flex flex-col">
			{collapsable && (
				<div
					className="flex flex-row justify-between cursor-pointer"
					onClick={() => setOpen(!open)}
				>
					<div className="flex items-center">
						{open && (
							<HiOutlineChevronDown size={'20px'} className="mx-2 text-slate-500" />
						)}
						{!open && (
							<HiOutlineChevronUp size={'20px'} className="mx-2 text-slate-500" />
						)}
						<div className="text-base font-bold">Related tasks</div>
					</div>
				</div>
			)}

			{open && (
				<div className="flex flex-col space-y-3 my-4">
					{candidateTasks.map((relatedTask, index) => {
						return (
							<TaskListItem
								key={`${index}`}
								task={relatedTask}
								avatarSize={avatarSize}
							/>
						)
					})}
				</div>
			)}
			{candidateTasks.length > 0 && <></>}
		</div>
	)
})
