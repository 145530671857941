import { makeAutoObservable } from 'mobx'
import type { RootStore } from './root'

export class OnboardingStore {
	private readonly rootStore: RootStore

	get isNeeded() {
		const connectedMailboxes = (this.rootStore.users.loggedInUser?.mailboxes ?? []).length
		return !this.rootStore.organizations.activeOrganizationId || connectedMailboxes <= 0
	}

	get gettingStartedStep() {
		if (!this.rootStore.organizations.activeOrganizationId) {
			return 0
		}

		const connectedMailboxes = (this.rootStore.users.loggedInUser?.mailboxes ?? []).length
		if (!connectedMailboxes) {
			return 1
		}

		return -1
	}

	constructor(root: RootStore) {
		this.rootStore = root

		makeAutoObservable(this)
	}
}
