import clsx from 'clsx'
import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from 'react'

type Size = 'xs' | 'sm' | 'md' | 'lg'
function getP(size: Size) {
	switch (size) {
		case 'xs':
			return 'p-0'

		case 'sm':
			return 'p-1'

		case 'md':
			return 'p-1.5'

		case 'lg':
			return 'p-2'
	}
}

function getPx(size: Size) {
	switch (size) {
		case 'xs':
			return 'px-1'

		case 'sm':
			return 'px-2'

		case 'md':
			return 'px-3'

		case 'lg':
			return 'px-5'
	}
}

function getText(size: Size) {
	switch (size) {
		case 'xs':
			return 'text-xs'

		case 'sm':
			return 'text-sm'

		case 'md':
			return 'text-base'

		case 'lg':
			return 'text-lg'
	}
}

export type PillProps = {
	color?: string
	size?: Size
}
export function Pill({
	color = 'bg-gray-300',
	size = 'md',
	children,
	className,
	...props
}: PropsWithChildren<
	DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & PillProps
>) {
	const p = getP(size)
	const px = getPx(size)
	const text = getText(size)
	const classes = clsx(p, px, text, 'rounded-3xl', color, ...(className?.split(' ') ?? []))
	return (
		<span className={classes} {...props}>
			{children}
		</span>
	)
}
