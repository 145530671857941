import { autorun, makeAutoObservable, runInAction } from 'mobx'

import type {
	IResourceComment,
	ITask,
	ITaskActivity,
	TaskStatus,
	TaskType,
} from '@touchpoints/requests'

import type { RootStore } from './root'
import {
	addComment,
	assignTask,
	changeTaskDueDate,
	closeTask,
	completeTask,
	createGenericTask,
	getFinishedTasks,
	getFinishedTasksForCandidate,
	getTasks,
	removeComment,
	previewTaskFields,
	getTaskById,
	changeTaskPriority,
} from '@requests/tasks'
import { createAsyncQueue } from '@services/queue'

const recentlyCompletedTasks = new Set<string>()

class Task implements ITask {
	id: string
	referenceNumber: string
	createdAt: number
	organizationId: string
	assignedUserId: string
	createdByOrgUserId?: string
	type: TaskType
	status: TaskStatus
	sequenceInstanceId: string
	dueBy?: number
	completedAt?: number | null = null
	priority = 2
	isPrivate = false
	comments: IResourceComment[] = []
	activity: ITaskActivity[] = []

	data?: Record<string, any>

	constructor(data: ITask) {
		this.id = data.id
		this.referenceNumber = data.referenceNumber
		this.createdAt = data.createdAt
		this.organizationId = data.organizationId
		this.organizationId = data.organizationId
		this.assignedUserId = data.assignedUserId
		this.createdByOrgUserId = data.createdByOrgUserId
		this.type = data.type
		this.status = data.status
		this.sequenceInstanceId = data.sequenceInstanceId ?? ''
		this.dueBy = data.dueBy ?? undefined
		this.completedAt = data.completedAt ?? null
		this.priority = data.priority ?? 2
		this.isPrivate = data.isPrivate ?? false
		this.comments = data.comments ?? []
		this.activity = data.activity ?? []
		this.data = data.data

		makeAutoObservable(this)
	}

	async assignTo(orgUserId: string) {
		const res = await assignTask(this.organizationId, this.id, orgUserId)

		if (!res.success) {
			return {
				success: false,
				message: res.message ?? 'Failed to assign user to task',
			}
		}

		this.assignedUserId = orgUserId

		const { task } = res.data ?? {}
		if (!task) {
			return
		}

		this.update(task)
	}

	async changeDueDate(dueBy: number) {
		const res = await changeTaskDueDate(this.organizationId, this.id, dueBy)

		if (!res.success) {
			return {
				success: false,
				message: res.message ?? 'Failed to change due date',
			}
		}

		const { task } = res.data ?? {}

		if (!task) {
			return
		}

		this.update(task)
	}

	async changePriority(priority: number) {
		const res = await changeTaskPriority(this.organizationId, this.id, priority)

		if (!res.success) {
			return {
				success: false,
				message: res.message ?? 'Failed to change priority',
			}
		}

		const { task } = res.data ?? {}

		if (!task) {
			return {
				success: false,
				message: 'updated task was not returned',
			}
		}

		this.update(task)

		return {
			success: true,
		}
	}

	close() {
		this.status = 'closed'
	}

	complete() {
		this.status = 'completed'
		this.completedAt = new Date().getTime()
		recentlyCompletedTasks.add(this.id)
	}

	update(data: ITask) {
		const d = recentlyCompletedTasks.has(this.id)
			? { ...data, status: this.status, completedAt: this.completedAt }
			: data
		Object.assign(this, d)
	}
}

export class TasksStore {
	private rootStore: RootStore

	readonly list: Task[] = []

	readonly filterState = {
		userFilterId: '',
		teamFilterId: '',
		setUserFilterId(id?: string) {
			this.userFilterId = id ?? ''
			this.teamFilterId = ''
		},
		setTeamFilterId(teamId?: string) {
			this.teamFilterId = teamId ?? ''
			this.userFilterId = ''
		},
		searchQuery: '',
		setSearchQuery(query: string) {
			this.searchQuery = query
		},
	}

	private readonly tasksById: Record<string, Task> = {}

	private readonly requestsQueue = createAsyncQueue({
		maxChannels: 3,
		sleepBetween: 50,
		cacheTTL: 1000 * 15,
	})

	private _loading = true

	get isLoading() {
		return (
			this.rootStore.organizationUsers.isLoading ||
			this.rootStore.organizations.isLoading ||
			!this.requestsQueue.isEmpty() ||
			this._loading
		)
	}

	get userIdsToFilter() {
		const { userFilterId, teamFilterId } = this.filterState
		const memberIds = this.rootStore.teams.getTeamById(teamFilterId)?.memberIds ?? []
		return teamFilterId
			? this.rootStore.organizationUsers.usersForActiveOrg
					.filter((u) => memberIds.includes(u.userId))
					.map((u) => u.id)
			: [userFilterId]
	}

	get filteredList() {
		const userIds = this.userIdsToFilter

		return this.pendingTasks.filter((t) => {
			const instanceDeleted = this.rootStore.sequences.isSequenceInstanceRecentlyDeleted(
				t.sequenceInstanceId
			)
			if (instanceDeleted) {
				// will be closed by server
				return false
			}

			if (userIds?.includes('none')) {
				return true
			}
			if (userIds?.includes('unassigned')) {
				return !t.assignedUserId
			}
			return userIds?.includes(t.assignedUserId)
		})
	}

	get filteredFinishedTasks() {
		const userIds = this.userIdsToFilter
		const filteredTasks = this.finishedTasks.filter((t) => {
			if (userIds?.includes('none')) {
				return true
			}
			if (userIds?.includes('unassigned')) {
				return !t.assignedUserId
			}
			return userIds?.includes(t.assignedUserId)
		})

		return filteredTasks
	}

	get filteredCompletedTasks() {
		const userIds = this.userIdsToFilter
		return this.completedTasks.filter((t) => {
			if (userIds?.includes('none')) {
				return true
			}
			if (userIds?.includes('unassigned')) {
				return !t.assignedUserId
			}
			return userIds?.includes(t.assignedUserId)
		})
	}

	get filteredClosedTasks() {
		const userIds = this.userIdsToFilter
		return this.closedTasks.filter((t) => {
			if (userIds?.includes('none')) {
				return true
			}
			if (userIds?.includes('unassigned')) {
				return !t.assignedUserId
			}
			return userIds?.includes(t.assignedUserId)
		})
	}

	get ownTasks() {
		return this.pendingTasks.filter(
			(t) => t.assignedUserId === this.rootStore.organizationUsers.activeUserOrgUserId
		)
	}

	get pendingTasks() {
		return this.list.filter((t) => t.status === 'pending')
	}

	get finishedTasks() {
		return this.list.filter((t) => t.status === 'completed' || t.status === 'closed')
	}

	get completedTasks() {
		return this.finishedTasks.filter((t) => t.status === 'completed')
	}

	get closedTasks() {
		return this.finishedTasks.filter((t) => t.status === 'closed')
	}

	constructor(root: RootStore) {
		this.rootStore = root

		makeAutoObservable(this, {
			isLoading: false,
		})

		autorun(async () => {
			const orgId = this.rootStore.organizations.activeOrganizationId
			const userId = this.rootStore.organizationUsers.activeUserOrgUserId

			if (!orgId || !userId) {
				return
			}

			// fetch for specific user
			await this.fetchTasks(userId, orgId)

			// fetch for everyone else (including self again)
			this.fetchTasks()
			this.fetchFinishedTasks()
		})
	}

	clearCache() {
		this.requestsQueue.clearCache()
	}

	async fetchTasks(assignedUserId?: string, orgId?: string) {
		if (!orgId) {
			orgId = this.rootStore.organizations.activeOrganizationId
		}

		if (!orgId) {
			return
		}

		this.setLoading(true)
		const key = assignedUserId ? `get-pending-tasks:${assignedUserId}` : 'get-pending-tasks'
		const res = await this.requestsQueue.addUnique(
			key,
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			() => getTasks(orgId!, assignedUserId),
			{
				priority: assignedUserId ? 5 : undefined,
			}
		)
		const { tasks = [] } = res.data ?? {}
		this.setLoading(false)

		runInAction(() => {
			for (const task of tasks) {
				this._add(task)
			}
		})

		// also fetch finished tasks
		await this.fetchFinishedTasks(orgId)
	}

	async fetchTask(taskId: string, orgId?: string) {
		if (!orgId) {
			orgId = this.rootStore.organizations.activeOrganizationId
		}

		if (!orgId) {
			return
		}

		const res = await this.requestsQueue.addUnique(
			`get-task-${taskId}`,
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			() => getTaskById(orgId!, taskId),
			{ priority: 1 }
		)

		if (res.status !== 200) {
			return
		}

		const { task } = res.data ?? {}

		if (!task) {
			return
		}

		return this._add(task)
	}

	async fetchFinishedTasks(orgId?: string) {
		if (!orgId) {
			orgId = this.rootStore.organizations.activeOrganizationId
		}

		if (!orgId) {
			return
		}

		this.setLoading(true)
		const res = await this.requestsQueue.addUnique('fetch-finished-tasks', () =>
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			getFinishedTasks(orgId!)
		)
		const { tasks = [] } = res.data ?? {}
		this.setLoading(false)

		runInAction(() => {
			for (const task of tasks) {
				this._add(task)
			}
		})
	}

	async fetchFinishedTasksForCandidate(candidateId: string, orgId?: string) {
		if (!orgId) {
			orgId = this.rootStore.organizations.activeOrganizationId
		}

		if (!orgId) {
			return
		}

		this.setLoading(true)
		const res = await getFinishedTasksForCandidate(orgId, candidateId)
		const { tasks = [] } = res.data ?? {}
		this.setLoading(false)

		return tasks
	}

	private _add(obj: ITask | Task) {
		let task = this.tasksById[obj.id]
		if (task) {
			task.update(obj)
			return task
		} else {
			task = obj instanceof Task ? obj : new Task(obj)
			this.tasksById[task.id] = task
		}

		if (!task) {
			return
		}

		this.list.push(task)

		return task
	}

	getTaskById(id: string) {
		const task = this.tasksById[id]
		return task ?? this.list.find((t) => t.id === id || t.referenceNumber === id)
	}

	getFinishedTaskById(id: string) {
		const task = this.tasksById[id]
		return task ?? this.finishedTasks.find((t) => t.id === id || t.referenceNumber === id)
	}

	async createGeneric(
		taskData: {
			assignedUserId: string
			prioriry: number
			dueDate?: number
			payload: {
				title: string
				html: string
				candidateId?: string
				positionId?: string
				accountId?: string
			}
		},
		isPrivate?: boolean
	) {
		const orgId = this.rootStore.organizations.activeOrganizationId
		if (!orgId) {
			return {
				success: false,
				message: 'No active organization',
			}
		}

		const res = await createGenericTask(
			orgId,
			{
				assignedUserId: taskData.assignedUserId,
				priority: taskData.prioriry,
				dueDate: taskData.dueDate,
				payload: { ...taskData.payload },
			},
			isPrivate
		)

		if (!res.success) {
			return {
				success: false,
				message: res.message ?? 'Failed to create manual-generic task',
			}
		}

		const { task } = res.data ?? {}
		if (!task) {
			return
		}

		this._add(task)
		return {
			success: true,
			task,
		}
	}

	async complete(task: ITask) {
		const orgId = this.rootStore.organizations.activeOrganizationId
		if (!orgId) {
			return {
				success: false,
				message: 'No active organization',
			}
		}

		const res = await completeTask(orgId, task.id)

		if (!res.success) {
			return {
				success: false,
				message: res.message ?? 'Failed to complete task',
			}
		}

		const idx = this.list.findIndex((t) => t.id === task.id)
		if (idx < 0) {
			return {
				success: false,
				message: 'Failed to remove task locally',
			}
		}

		runInAction(() => {
			const t = this.tasksById[task.id]
			if (t) {
				t.complete()
			}
		})

		this.requestsQueue.deleteCache('fetch-finished-tasks')

		return {
			success: true,
		}
	}

	async markClosed(task: ITask, reason?: string) {
		const orgId = this.rootStore.organizations.activeOrganizationId
		if (!orgId) {
			return {
				success: false,
				message: 'No active organization',
			}
		}

		const res = await closeTask(orgId, task.id, reason)

		if (!res.success) {
			return {
				success: false,
				message: res.message ?? 'Failed to close task',
			}
		}

		const idx = this.list.findIndex((t) => t.id === task.id)
		if (idx < 0) {
			return {
				success: false,
				message: 'Failed to remove task locally',
			}
		}

		runInAction(() => {
			const t = this.tasksById[task.id]
			if (t) {
				t.close()
			}
		})

		this.requestsQueue.deleteCache('fetch-finished-tasks')

		return {
			success: true,
		}
	}

	async addComment(taskId: string, comment: string) {
		const orgId = this.rootStore.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await addComment(orgId, taskId, comment)

		if (!res.success) {
			return
		}

		const { comment: newComment } = res.data ?? {}
		if (!newComment) {
			return {
				success: false,
				message: 'Failed to add comment',
			}
		}

		const task = this.getTaskById(taskId) ?? this.getFinishedTaskById(taskId)
		if (!task) {
			return
		}

		task.comments.push(newComment)
	}

	async removeComment(taskId: string, commentId: string) {
		const orgId = this.rootStore.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await removeComment(orgId, taskId, commentId)

		if (!res.success) {
			return
		}

		const task = this.getTaskById(taskId) ?? this.getFinishedTaskById(taskId)
		if (!task) {
			return
		}

		const idx = task.comments.findIndex((c) => c.id === commentId)
		if (idx < 0) {
			return
		}

		task.comments.splice(idx, 1)
	}

	async preview(
		inputs: { title?: string; subject?: string; html?: string },
		options: { candidateId: string; positionId: string }
	) {
		const orgId = this.rootStore.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await previewTaskFields(orgId, inputs, options)

		if (!res.success) {
			return
		}

		const { fields, context } = res.data ?? {}
		if (!fields) {
			return { context }
		}

		return { fields, context }
	}

	private setLoading(v: boolean) {
		this._loading = v
	}
}
