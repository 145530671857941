import {
	addBlueprint,
	fetchBlueprints,
	removeBlueprint,
	updateBlueprint,
} from '@requests/blueprints'
import { BlueprintCandidateStageNode, IBlueprint, ICandidateStage } from '@touchpoints/requests'
import { makeAutoObservable, runInAction } from 'mobx'
import type { RootStore } from './root'
import cloneDeep from 'lodash/cloneDeep'

class Blueprint implements IBlueprint {
	id = ''
	organizationId = ''
	createdBy = ''
	createdAt = -1

	name = ''
	candidateStageOverrides: Record<string, Omit<ICandidateStage, 'id' | 'name'>> = {}
	candidateStageNodes: Record<string, BlueprintCandidateStageNode> = {}

	constructor(data: IBlueprint) {
		this.id = data.id
		this.organizationId = data.organizationId
		this.createdBy = data.createdBy
		this.createdAt = data.createdAt
		this.name = data.name

		this.candidateStageOverrides = data.candidateStageOverrides ?? {}
		this.candidateStageNodes = data.candidateStageNodes ?? {}

		makeAutoObservable(this)
	}

	get stagesForAction() {
		const result: Record<string, string[]> = {}
		const overrides = { ...this.candidateStageOverrides }

		for (const key in overrides) {
			const stage = overrides[key]
			const stageId = key
			if (!stage) {
				continue
			}

			const actionReferences = stage.actionReferences ?? []
			for (const ref of actionReferences) {
				const stages = result[ref.referenceId] ?? []
				stages.push(stageId)
				result[ref.referenceId] = stages
			}
		}

		return result
	}
}

export class BlueprintsStore {
	private readonly root: RootStore

	readonly list: Blueprint[] = []

	private _activeBlueprintId: string | null = null

	constructor(root: RootStore) {
		this.root = root

		makeAutoObservable(this)
	}

	get activeBlueprintId() {
		return this._activeBlueprintId
	}

	get activeBlueprint() {
		if (!this.activeBlueprintId) {
			return null
		}

		return this.list.find((b) => b.id === this.activeBlueprintId) ?? null
	}

	setActiveBlueprintId(id: string | null) {
		this._activeBlueprintId = id
	}

	async fetchBlueprints() {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await fetchBlueprints(orgId)

		if (!res) {
			return
		}

		const { blueprints } = res.data ?? {}

		if (!blueprints) {
			return
		}

		runInAction(() => {
			for (const blueprint of blueprints) {
				this.addBlueprint(blueprint)
			}

			this.sortBlueprints()
		})
	}

	async create(data: Pick<IBlueprint, 'name'>) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await addBlueprint(orgId, data)

		if (!res) {
			return
		}

		const { blueprint } = res.data ?? {}

		if (!blueprint) {
			return
		}

		runInAction(() => {
			this.addBlueprint(blueprint)
			this.sortBlueprints()
		})

		return blueprint
	}

	async updateLocal(id: string, data: Partial<IBlueprint>) {
		const b = this.getById(id)
		if (b) {
			runInAction(() => Object.assign(b, data))
		}
	}

	async update(
		id: string,
		data: Partial<Pick<IBlueprint, 'name' | 'candidateStageOverrides' | 'candidateStageNodes'>>
	) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await updateBlueprint(orgId, id, data)

		if (!res) {
			return
		}

		const { blueprint } = res.data ?? {}

		if (!blueprint) {
			return
		}

		this.addBlueprint(blueprint)
	}

	async delete(id: string) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await removeBlueprint(orgId, id)

		if (!res) {
			return
		}

		this.removeBlueprint(id)
	}

	async duplicate(id: string) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const copy = cloneDeep(this.getById(id))
		if (!copy) {
			return
		}
		copy.name = `${copy.name} (copy)`

		const res = await addBlueprint(orgId, {
			name: copy.name,
			candidateStageOverrides: copy.candidateStageOverrides,
			candidateStageNodes: copy.candidateStageNodes,
		})

		if (!res) {
			return
		}

		const { blueprint } = res.data ?? {}

		if (!blueprint) {
			return
		}

		runInAction(() => {
			this.addBlueprint(blueprint)
			this.sortBlueprints()
		})

		return blueprint
	}

	getById(id: string) {
		return this.list.find((t) => t.id === id)
	}

	private addBlueprint(blueprint: IBlueprint) {
		const existing = this.getById(blueprint.id)

		if (existing) {
			Object.assign(existing, blueprint)
		} else {
			this.list.push(new Blueprint(blueprint))
		}
	}

	private removeBlueprint(id: string) {
		const index = this.list.findIndex((t) => t.id === id)

		if (index < 0) {
			return
		}

		this.list.splice(index, 1)
	}

	private sortBlueprints() {
		this.list.sort((a, b) => a.name.localeCompare(b.name))
	}
}
