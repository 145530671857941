import { getCandidateEmailThreads } from '@requests/threads'
import type { IMessage, IThread } from '@touchpoints/requests'
import { ObservableMap, makeAutoObservable } from 'mobx'
import type { RootStore } from '../root'

class Thread implements IThread {
	id: string
	timestamp: number
	subject?: string
	recipients: string[]
	messages: IMessage[]
	opened: number[]

	constructor(data: IThread) {
		this.id = data.id
		this.timestamp = data.timestamp
		this.recipients = data.recipients
		this.subject = data.subject
		this.messages = data.messages?.map((m) => new Message(m)) ?? []
		this.opened = data.opened
	}
}

class Message implements IMessage {
	id: string
	from: string
	to: string
	cc?: string
	date: Date
	textContent?: string
	htmlContent?: string

	constructor(data: IMessage) {
		this.id = data.id
		this.from = data.from
		this.to = data.to
		this.cc = data.cc
		this.date = data.date
		this.textContent = data.textContent
		this.htmlContent = data.htmlContent
	}
}

export class CandidateEmailThreads {
	private readonly root: RootStore

	readonly listByCandidateId: ObservableMap<string, Thread[]> = new ObservableMap()
	private _loading = false

	constructor(root: RootStore) {
		this.root = root

		makeAutoObservable(this)
	}

	get isLoading() {
		return this._loading
	}

	async fetchEmailThreads(candidateId: string) {
		const orgId = this.root.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		this.setLoading(true)
		const res = await getCandidateEmailThreads(orgId, candidateId)
		this.setLoading(false)

		if (!res.data) {
			return
		}

		const threads = res.data as unknown as IThread[]
		this.setThreads(candidateId, threads ?? [])
	}

	setThreads(candidateId: string, threads: (IThread | Thread)[]) {
		const list = threads
			.filter((t) => !!t)
			.map((t) => {
				if (t instanceof Thread) {
					return t
				}

				return new Thread(t)
			})
		this.listByCandidateId.set(candidateId, list)
	}

	private setLoading(v: boolean) {
		this._loading = v
	}
}
