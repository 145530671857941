import { GetMethodFunc, IPosition, IAccount, IPositionCandidate } from '../types'

export function makeFetchPositions(
	getMethod: GetMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function fetchPositions(organizationId: string) {
		const headers = await authHeader()
		return getMethod<{ positions: IPosition[]; accountsById: Record<string, IAccount> }>(
			`/organizations/${organizationId}/positions`,
			undefined,
			headers
		)
	}
}

export function makeFetchPositonCandidatesForCandidate(
	getMethod: GetMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function fetchPositionCandidatesForCandidate(
		orgId: string,
		candidateId: string,
		opts = { includeAccounts: false }
	) {
		const headers = await authHeader()
		return getMethod<{
			positionCandidates: IPositionCandidate[]
			positions: IPosition[]
			accounts?: IAccount[]
		}>(`/organizations/${orgId}/candidates/${candidateId}/positions`, opts, headers)
	}
}
