import { autorun, makeAutoObservable, ObservableMap } from 'mobx'

import {
	deleteSnippet,
	fetchSnippets,
	getSnippet,
	processSnippetTemplate,
	updateSnippet,
} from '@requests/snippets'
import {
	ISnippet,
	SnippetProps,
	TriggerActionName,
	TriggerEvent,
	ISnippetTriggerAction,
	ITriggerActionReference,
} from '@touchpoints/requests'

import type { RootStore } from './root'
import { TemplatePreviewOptions } from '@touchpoints/requests'

class SnippetTrigger implements ISnippetTriggerAction {
	id: string
	event: TriggerEvent
	actionName = TriggerActionName.AddCandidateToSequence
	sequenceId = ''
	candidateStageId = ''

	displayName?: string

	organizationId: string
	createdBy: string
	createdAt: number

	triggerActions: ITriggerActionReference[]

	constructor(data?: ISnippetTriggerAction) {
		this.id = data?.id ?? 'unknown'
		this.event = data?.event ?? TriggerEvent.SnippetSentInEmail
		this.actionName = data?.actionName ?? TriggerActionName.AddCandidateToSequence
		this.sequenceId = data?.sequenceId ?? ''
		this.candidateStageId = data?.candidateStageId ?? ''
		this.organizationId = data?.organizationId ?? ''
		this.createdBy = data?.createdBy ?? ''
		this.createdAt = data?.createdAt ?? Date.now()
		this.triggerActions = data?.triggerActions ?? []

		makeAutoObservable(this)
	}
}

class Snippet implements ISnippet {
	id = ''
	organizationId = ''
	createdBy = ''
	name = ''
	html = ''
	triggers: ISnippetTriggerAction[] = []
	triggerActions: { id: string; event: TriggerEvent; referenceId: string }[] = []

	constructor(data: ISnippet) {
		makeAutoObservable(this)

		this.update(data)
	}

	update(data: ISnippet) {
		this.id = data.id
		this.organizationId = data.organizationId
		this.createdBy = data.createdBy
		this.name = data.name
		this.html = data.html
		this.triggers = (data.triggers ?? []).map((trigger) => new SnippetTrigger(trigger))
		this.triggerActions = data.triggerActions ?? []
	}

	addTrigger(data?: ISnippetTriggerAction) {
		this.triggers.push(new SnippetTrigger(data))
	}

	removeTrigger(idx: number) {
		this.triggers.splice(idx, 1)
	}
}

export class SnippetStore {
	readonly rootStore: RootStore

	readonly list: ISnippet[] = []

	readonly snippetsById = new ObservableMap<string, Snippet>()

	activeSnippetId = ''

	get activeSnippet() {
		return this.getSnippet(this.activeSnippetId)
	}

	constructor(root: RootStore) {
		this.rootStore = root

		makeAutoObservable(this, {
			rootStore: false,
		})

		autorun(async () => {
			await this.fetchSnippets()
		})

		autorun(async () => {
			if (!this.activeSnippetId) {
				return
			}
			if (this.activeSnippet) {
				return
			}

			// only try fetching if we didn't already get it
			await this.fetchSnippet(this.activeSnippetId)
		})
	}

	async fetchSnippets() {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await fetchSnippets(orgId)

		if (!res.data) {
			return
		}

		const { snippets } = res.data
		this.clearSnippets()
		snippets.forEach((s) => this.addSnippet(s))
	}

	async fetchSnippet(id: string) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await getSnippet(orgId, id)

		if (!res.data || !res.success) {
			return
		}

		const { snippet } = res.data
		this.addSnippet(snippet)
	}

	setActiveSnippetId(id: string) {
		this.activeSnippetId = id
	}

	addSnippet(data: ISnippet | Snippet) {
		if (this.snippetsById.has(data.id)) {
			const snippet = this.snippetsById.get(data.id)
			snippet?.update(data)
			return snippet
		}

		const snippet = data instanceof Snippet ? data : new Snippet(data)
		this.list.push(snippet)
		this.snippetsById.set(snippet.id, snippet)

		return snippet
	}

	getSnippet(id: string) {
		return this.snippetsById.get(id)
	}

	async updateSnippet(id: string, data: Partial<SnippetProps>) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await updateSnippet(orgId, id, data)
		if (!res.success || !res.data) {
			return
		}

		const { snippet } = res.data
		let s = this.getSnippet(id)
		if (s) {
			s.update(snippet)
		} else {
			s = this.addSnippet(snippet)
		}

		return s
	}

	async delete(id: string) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await deleteSnippet(orgId, id)

		if (res.success) {
			const idx = this.list.findIndex((s) => s.id === id)
			this.list.splice(idx, 1)

			this.snippetsById.delete(id)
		}

		return res.success
	}

	// NOTE: that we are using TemplatePreviewOptions because snippets
	// and templates are so similar that they are almost the same
	// if not then one inherits from the other (snippets have no subject)
	// could potentially just make them the same at some point?
	async preview(
		snippetId: string,
		opts: Omit<TemplatePreviewOptions, 'candidateId'> & {
			candidateEmail: string
			rawHtml?: string
		}
	) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await processSnippetTemplate(orgId, snippetId, opts)
		if (!res.success) {
			return
		}

		if (!res.data) {
			return
		}

		return res.data
	}

	private clearSnippets() {
		// NOTE: could potentially also just do list.length = 0
		// need to test if mobx picks that up
		while (this.list.length > 0) {
			this.list.pop()
		}

		this.snippetsById.clear()
	}
}
