import { snippets } from '@touchpoints/requests'

import { authHeader, del, get, patch, post } from './api'

export const fetchSnippets = snippets.makeFetchSnippets(get, authHeader)
export const getSnippet = snippets.makeGetSnippet(get, authHeader)
export const addSnippet = snippets.makeAddSnippet(post, authHeader)
export const updateSnippet = snippets.makeUpdateSnippet(patch, authHeader)
export const deleteSnippet = snippets.makeDeleteSnippet(del, authHeader)
export const processSnippetTemplate = snippets.makeProcessSnippetTemplate(post, authHeader)

type PreviewSnippetOptions = {
	candidateId?: string
	positionId?: string
}
export async function previewSnippetTemplate(
	organizationId: string,
	snippetId: string,
	options?: PreviewSnippetOptions
) {
	const headers = await authHeader()
	return post(
		`/organizations/${organizationId}/snippets/${snippetId}/send-preview`,
		{
			...options,
		},
		headers
	)
}
