import { JSONValue } from '../types'

export function get(
	url: string,
	params: Record<string, string | number | boolean | undefined | string[]> = {},
	headers: Record<string, string> = {}
) {
	const searchParams = new URLSearchParams()
	for (const key in params) {
		if (!params[key]) {
			continue
		}

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		searchParams.append(key, params[key]!.toString())
	}
	const paramsString = searchParams.toString()
	return fetch(`${url}${paramsString ? `?${paramsString}` : ''}`, {
		method: 'GET',
		headers: {
			...headers,
		},
	})
}

export function post(
	url: string,
	body: Record<string, JSONValue> | FormData = {},
	headers: Record<string, string> = {}
) {
	const requestHeaders: HeadersInit = { ...headers }
	const formData = body instanceof FormData
	if (!formData) {
		requestHeaders['Content-Type'] = 'application/json'
	}

	return fetch(url, {
		method: 'POST',
		headers: requestHeaders,
		body: formData ? body : JSON.stringify(body),
	})
}

export function put(
	url: string,
	body: Record<string, JSONValue> = {},
	headers: Record<string, string> = {}
) {
	return fetch(url, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			...headers,
		},
		body: JSON.stringify(body),
	})
}

export function patch(
	url: string,
	body: Record<string, JSONValue> = {},
	headers: Record<string, string> = {}
) {
	return fetch(url, {
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json',
			...headers,
		},
		body: JSON.stringify(body),
	})
}

export function del(
	url: string,
	headers: Record<string, string> = {},
	body: Record<string, JSONValue> = {}
) {
	return fetch(url, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			...headers,
		},
		body: JSON.stringify(body),
	})
}
