import { ICandidate, IPosition, IPositionCandidate } from '@touchpoints/requests'
import { authHeader, del, get, post } from './api'

export async function getCandidatesThatNeedCleaning(
	orgId: string,
	page?: number,
	positionId?: string,
	filter?: string
) {
	const headers = await authHeader()
	return get<{
		candidates: { candidate: ICandidate; positionCandidates: IPositionCandidate[] }[]
		currentPage: number
		hasNextPage: boolean
		totalItems: number
		totalPages: number
	}>(`/organizations/${orgId}/cleaning`, { page, positionId, filter }, headers)
}

export async function getPositionsNeedCleaning(orgId: string) {
	const headers = await authHeader()
	return get<{ positions: IPosition[] }>(
		`/organizations/${orgId}/cleaning/positions`,
		{},
		headers
	)
}

export async function markCandidateAsCleaned(
	orgId: string,
	candidateId: string,
	data: Pick<ICandidate, 'firstName' | 'lastName' | 'email' | 'additionalEmails'>
) {
	const headers = await authHeader()
	return post<{ candidate: ICandidate }>(
		`/organizations/${orgId}/cleaning/${candidateId}/mark-cleaned`,
		data,
		headers
	)
}

export async function getCompanyDictionaryNames(orgId: string) {
	const headers = await authHeader()
	return get<{
		list: { name: string; variants: string[]; id: string; pendingVariants: string[] }[]
	}>(`/organizations/${orgId}/cleaning/company-dictionary`, {}, headers)
}

export async function updateCompanyDictionaryEntry(
	orgId: string,
	id: string,
	data: { variants?: string[]; pendingVariants?: string[] }
) {
	const headers = await authHeader()
	return post<{ success: boolean }>(
		`/organizations/${orgId}/cleaning/company-dictionary/${id}`,
		{ ...data },
		headers
	)
}

export async function deleteCompanyDictionaryEntry(orgId: string, id: string) {
	const headers = await authHeader()
	return del<{ success: boolean }>(
		`/organizations/${orgId}/cleaning/company-dictionary/${id}`,
		{},
		headers
	)
}
