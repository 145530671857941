import { GetMethodFunc, ISequence, ISequenceInstance } from '../types'

export function makeFetchSequences(
	getMethod: GetMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function fetchSequences(orgId: string) {
		const headers = await authHeader()
		return getMethod<{ sequences: ISequence[] }>(
			`/organizations/${orgId}/sequences`,
			undefined,
			headers
		)
	}
}

export function makeFetchSequenceInstances(
	getMethod: GetMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function fetchSequenceInstances(orgId: string, sequenceId: string) {
		const headers = await authHeader()
		return getMethod<{ sequenceInstances: ISequenceInstance[] }>(
			`/organizations/${orgId}/sequences/${sequenceId}/candidates`,
			undefined,
			headers
		)
	}
}
