import { Button, Row, Spacer } from '@nextui-org/react'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
import { HiX } from 'react-icons/hi'

import { Modal, Pill, Select, Tabs } from '@touchpoints/ui'

import { addCandidateToPosition } from '@requests/positions'
import { rootStore } from '@store'
import { CreateCandidateForm } from '../../candidates'

import type { ICandidate } from '@touchpoints/requests'
import debounce from 'lodash/debounce'

enum AddCandidate {
	Existing = 'existing',
	New = 'create-new',
}

class ModalState {
	isOpen = false
	tab = AddCandidate.Existing
	candidate: ICandidate | null = null

	constructor() {
		makeAutoObservable(this)
	}

	setTab(t: AddCandidate) {
		this.tab = t
	}

	open() {
		this.isOpen = true
	}

	close() {
		this.isOpen = false
	}

	setCandidate(candidate: ICandidate) {
		this.candidate = candidate
	}

	clearCandidate() {
		this.candidate = null
	}
}
export const modalState = new ModalState()

export const AddCandidateModal = observer(function () {
	const handleSelectCandidate = (candidate: ICandidate) => {
		modalState.setCandidate({ ...candidate })
	}

	const handleAdd = async () => {
		const pos = rootStore.positions.activePosition
		if (!pos) {
			// TODO: handle error
			return
		}

		const candidate = modalState.candidate
		if (!candidate) {
			return
		}

		// call API to add candidate to position
		const orgId = rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await addCandidateToPosition(orgId, pos.accountId, pos.id, candidate.id)
		if (!res.data) {
			return
		}

		// add data locally
		rootStore.positions.addCandidate(pos.id, candidate)

		modalState.clearCandidate()
		modalState.close()
	}

	const handleCreateNew = async (candidate: ICandidate) => {
		modalState.setCandidate(candidate)
		await handleAdd()
	}

	const loadOptions = debounce((inputValue: string, callback: (options: any[]) => void) => {
		rootStore.candidates
			.fetchCandidatesPage({
				page: 1,
				rows: 10,
				filter: { searchTerm: inputValue },
			})
			.then((pageResult) => {
				if (pageResult?.items) {
					const candidates = pageResult.items
					callback(candidates)
				}
			})
	}, 500)

	return (
		<Modal
			blur
			width="500px"
			isOpen={modalState.isOpen}
			onClose={() => {
				modalState.clearCandidate()
				modalState.close()
			}}
		>
			<Modal.Body>
				<Tabs
					value={modalState.tab}
					onValueChange={(v) => modalState.setTab(v as AddCandidate)}
				>
					<Tabs.Bar>
						<Tabs.Item fullWidth value={AddCandidate.Existing}>
							Add Existing
						</Tabs.Item>
						<Tabs.Item fullWidth value={AddCandidate.New}>
							Create New
						</Tabs.Item>
					</Tabs.Bar>
					<Tabs.Content value={AddCandidate.Existing}>
						{modalState.candidate ? (
							<Row align="center" justify="space-between">
								<Pill>
									{`${modalState.candidate?.firstName} ${modalState.candidate?.lastName} | ${modalState.candidate?.email}`}
								</Pill>
								<Button
									auto
									light
									icon={<HiX size="1.5em" />}
									onClick={() => modalState.clearCandidate()}
								/>
							</Row>
						) : (
							<Row>
								<Select
									placeholder="search for candidate..."
									isClearable={true}
									cacheOptions
									defaultOptions
									loadOptions={loadOptions}
									options={rootStore.candidates.list.filter(
										(c) => !rootStore.positions.activeCandidatesSet.has(c.id)
									)}
									getOptionValue={(c) => c.id}
									getOptionLabel={(c) =>
										`${c.firstName} ${c.lastName} | ${c.email}`
									}
									filterKeys={['email', 'firstName', 'lastName']}
									onChange={(val) => {
										if (!val) {
											return
										}
										handleSelectCandidate(val)
									}}
								/>
							</Row>
						)}
						<Spacer />
						<Row>
							<Button style={{ width: '100%' }} onClick={handleAdd}>
								Add
							</Button>
						</Row>
					</Tabs.Content>
					<Tabs.Content value={AddCandidate.New}>
						<CreateCandidateForm
							onCreated={handleCreateNew}
							onCancel={() => {
								modalState.clearCandidate()
								modalState.close()
							}}
						/>
					</Tabs.Content>
				</Tabs>
			</Modal.Body>
		</Modal>
	)
})
