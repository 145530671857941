import { ICandidateEvent, IPositionCandidateEvent } from '@touchpoints/requests'
import { authHeader, get, post, patch, del } from './api'

import type { IAccount, AccountProps } from '@types'

export async function fetchAccounts(organizationId: string) {
	const headers = await authHeader()
	return get<{ accounts: IAccount[] }>(
		`/organizations/${organizationId}/accounts`,
		undefined,
		headers
	)
}

export async function createAccount(orgId: string, props: AccountProps) {
	const headers = await authHeader()
	return post<{ account: IAccount }>(
		`/organizations/${orgId}/accounts`,
		{
			...props,
		},
		headers
	)
}

export async function updateAccount(orgId: string, accountId: string, changes: Partial<IAccount>) {
	const headers = await authHeader()
	return patch<{ account: IAccount }>(
		`/organizations/${orgId}/accounts/${accountId}`,
		{
			...changes,
		},
		headers
	)
}

export async function deleteAccount(orgId: string, accountId: string) {
	const headers = await authHeader()
	return del<{ account: IAccount }>(
		`/organizations/${orgId}/accounts/${accountId}`,
		headers,
		undefined
	)
}

export async function fetchCandidateEventsForAccount(orgId: string, accountId: string) {
	const headers = await authHeader()
	return get<{
		events: {
			candidateId: string
			candidateEvents: ICandidateEvent[]
			positionCandidateEvents: IPositionCandidateEvent[]
		}[]
	}>(`/organizations/${orgId}/accounts/${accountId}/candidate-events`, undefined, headers)
}
