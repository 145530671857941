import uniqBy from 'lodash/uniqBy'

import { CandidateStageColorDot } from '@components/candidates/CandidateStageColorDot'
import { OrgUserAvatar } from '@components/shared/OrgUserAvatar'
import { Field } from '@components/shared/QueryBuilder'
import { Position } from '@store/positions'
import { ICandidateStage, OrganizationUser } from '@touchpoints/requests'

interface CandidateQueryProps {
	positions: Position[]
	organizationUsers: OrganizationUser[]
	stages: ICandidateStage[]
}
export const candidateQueryStructure = ({
	positions,
	organizationUsers,
	stages,
}: CandidateQueryProps): Field[] => {
	return [
		{
			id: 'info.accountId.keyword',
			label: 'Account',
			type: 'select',
			selectOptions: {
				options: uniqBy(positions || [], (p) => p.account?.name ?? p.accountId)
					.map((u) => {
						return {
							value: u.accountId,
							label: u.account?.name ?? u.accountId,
							searchString: u.account?.name,
						}
					})
					.sort((a, b) => a.label.localeCompare(b.label)),
				isMulti: true,
				filterable: true,
				inputPlaceholder: 'Search account...',
			},
		},
		{
			id: 'info.positionId.keyword',
			label: 'Position',
			type: 'select',
			selectOptions: {
				options: (positions || []).map((u) => {
					return {
						value: u.id,
						label: u.referenceName,
						searchString: u.referenceName,
					}
				}),
				isMulti: true,
				filterable: true,
				inputPlaceholder: 'Search position...',
			},
		},
		{
			id: 'info.positionStatus.keyword',
			label: 'Position Status',
			type: 'select',
			selectOptions: {
				options: [
					{ value: 'open', label: 'Open' },
					{ value: 'filled', label: 'Filled' },
					{ value: 'filled-by-competition', label: 'Filled By Competition' },
					{ value: 'on-hold', label: 'On Hold' },
					{ value: 'canceled', label: 'Canceled' },
					{ value: 'contract-ended', label: 'Contract Ended' },
				],
				isMulti: true,
			},
		},
		{
			id: 'info.subStatus.keyword',
			label: 'Candidate Sub Status',
			type: 'string',
		},
		{
			id: 'info.accountManagerId.keyword',
			label: 'Account Manager',
			type: 'select',
			selectOptions: {
				options: organizationUsers.map((u) => {
					return {
						value: u.id,
						label: (
							<span className="flex items-center space-x-1">
								<OrgUserAvatar size={'xs'} user={u} />
								&nbsp; {u.firstName} {u.lastName}
							</span>
						),
						searchString: `${u.firstName} ${u.lastName}`,
					}
				}),
				isMulti: true,
				filterable: true,
				inputPlaceholder: 'Search manager...',
			},
		},
		{
			id: 'info.engagementContactId.keyword',
			label: 'Engagement Contact',
			type: 'select',
			selectOptions: {
				options: organizationUsers.map((u) => {
					return {
						value: u.id,
						label: (
							<span className="flex items-center space-x-1">
								<OrgUserAvatar size={'xs'} user={u} />
								&nbsp; {u.firstName} {u.lastName}
							</span>
						),
						searchString: `${u.firstName} ${u.lastName}`,
					}
				}),
				isMulti: true,
				filterable: true,
				inputPlaceholder: 'Search engagement contact...',
			},
		},
		{
			id: 'info.sourcerId.keyword',
			label: 'Sourcer',
			type: 'select',
			selectOptions: {
				options: organizationUsers.map((u) => {
					return {
						value: u.id,
						label: (
							<span className="flex items-center space-x-1">
								<OrgUserAvatar size={'xs'} user={u} />
								&nbsp; {u.firstName} {u.lastName}
							</span>
						),
						searchString: `${u.firstName} ${u.lastName}`,
					}
				}),
				isMulti: true,
				filterable: true,
				inputPlaceholder: 'Search sourcer...',
			},
		},
		{
			id: 'info.stage.keyword',
			label: 'Stage',
			type: 'select',
			selectOptions: {
				options: stages.map((u) => {
					return {
						value: u.id,
						label: (
							<span className="flex items-center space-x-1">
								<CandidateStageColorDot color={u.color} />
								&nbsp; {u.name}
							</span>
						),
						searchString: u.name,
					}
				}),
				isMulti: true,
				filterable: true,
				inputPlaceholder: 'Search stage...',
			},
		},
		{
			id: 'stageHistory.stage.keyword',
			label: 'Stage History',
			type: 'select',
			selectOptions: {
				options: stages.map((u) => {
					return {
						value: u.id,
						label: (
							<span className="flex items-center space-x-1 overflow-hidden">
								<CandidateStageColorDot color={u.color} />
								&nbsp; {u.name}
							</span>
						),
						searchString: u.name,
					}
				}),
				isMulti: true,
				filterable: true,
				inputPlaceholder: 'Search stage...',
			},
		},
		{
			id: 'info.candidateStatus.keyword',
			label: 'Candidate Status',
			type: 'select',
			selectOptions: {
				options: [
					{ value: 'active', label: 'Active' },
					{ value: 'closed', label: 'Closed' },
					{ value: 'unknown', label: 'Unknown' },
				],
				isMulti: true,
			},
		},
		{
			id: 'info.recruiterId.keyword',
			label: 'Recruiter',
			type: 'select',
			selectOptions: {
				options: organizationUsers.map((u) => {
					return {
						value: u.id,
						label: (
							<span className="flex items-center space-x-1">
								<OrgUserAvatar size={'xs'} user={u} />
								&nbsp; {u.firstName} {u.lastName}
							</span>
						),
						searchString: `${u.firstName} ${u.lastName}`,
					}
				}),
				isMulti: true,
				filterable: true,
				inputPlaceholder: 'Search recruiter...',
			},
		},
	]
}
