export function AllMembersIcon() {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="10.0003" cy="10.0003" r="8.33333" stroke="#889096" strokeLinecap="square" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.5 8.33301C12.5 9.71372 11.3807 10.833 9.99996 10.833C8.61925 10.833 7.49996 9.71372 7.49996 8.33301C7.49996 6.9523 8.61925 5.83301 9.99996 5.83301C11.3807 5.83301 12.5 6.9523 12.5 8.33301ZM10.0001 16.6663C8.1348 16.6663 6.44841 15.9003 5.2384 14.6656C6.34818 13.3372 8.00405 12.4997 10.0001 12.4997C11.9962 12.4997 13.6521 13.3372 14.7618 14.6656C13.5518 15.9003 11.8655 16.6663 10.0001 16.6663Z"
				fill="#889096"
			/>
		</svg>
	)
}

export function UnassignedIcon() {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="10.0003"
				cy="10.0003"
				r="8.33333"
				stroke="#889096"
				strokeLinecap="square"
				strokeDasharray="4.17 4.17"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.5 8.33301C12.5 9.71372 11.3807 10.833 9.99996 10.833C8.61925 10.833 7.49996 9.71372 7.49996 8.33301C7.49996 6.9523 8.61925 5.83301 9.99996 5.83301C11.3807 5.83301 12.5 6.9523 12.5 8.33301ZM10.0001 16.6663C8.1348 16.6663 6.44841 15.9003 5.2384 14.6656C6.34818 13.3372 8.00405 12.4997 10.0001 12.4997C11.9962 12.4997 13.6521 13.3372 14.7618 14.6656C13.5518 15.9003 11.8655 16.6663 10.0001 16.6663Z"
				fill="#889096"
			/>
		</svg>
	)
}
