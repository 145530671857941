import { autorun, makeAutoObservable, ObservableMap, computed } from 'mobx'

import {
	deleteTemplate,
	fetchTemplates,
	getTemplate,
	previewTemplate,
	updateTemplate,
} from '@requests/templates'
import type { ITemplate, TemplateProps, TemplatePreviewOptions } from '@touchpoints/requests'

import type { RootStore } from './root'

class Template implements ITemplate {
	id = ''
	organizationId = ''
	createdBy = ''
	name = ''
	subject = ''
	html = ''

	constructor(data: ITemplate) {
		makeAutoObservable(this)

		this.update(data)
	}

	update(data: ITemplate) {
		this.id = data.id
		this.organizationId = data.organizationId
		this.createdBy = data.createdBy
		this.name = data.name
		this.subject = data.subject ?? ''
		this.html = data.html
	}
}

export class TemplateStore {
	readonly rootStore: RootStore

	readonly list: ITemplate[] = []

	readonly templatesById = new ObservableMap<string, Template>()

	activeTemplateId = ''

	get activeTemplate() {
		return this.getTemplate(this.activeTemplateId)
	}

	get sortedList() {
		return this.list.slice().sort((a, b) => a.name.localeCompare(b.name))
	}

	get sequencesByTemplates() {
		const res: Record<string, Set<string>> = {}
		this.rootStore.sequences.list.forEach((seq) => {
			seq.actions.forEach((action) => {
				if (!action.data) {
					return
				}

				const linkedTemplateId = action.data.linkedTemplateId
				if (!linkedTemplateId) {
					return
				}

				if (!(linkedTemplateId in res)) {
					res[linkedTemplateId] = new Set()
				}

				res[linkedTemplateId].add(seq.id)
			})
		})

		return res
	}

	constructor(root: RootStore) {
		this.rootStore = root

		makeAutoObservable(this, {
			rootStore: false,
			sequencesByTemplates: computed,
		})

		autorun(async () => {
			await this.fetchTemplates()
		})

		autorun(async () => {
			if (!this.activeTemplateId) {
				return
			}
			if (this.activeTemplate) {
				return
			}

			// only try fetching if we didn't already get it
			await this.fetchTemplate(this.activeTemplateId)
		})
	}

	async fetchTemplates() {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await fetchTemplates(orgId)

		if (!res.data) {
			return
		}

		const { templates } = res.data
		this.clearTemplates()
		templates.forEach((t) => this.addTemplate(t))
	}

	async fetchTemplate(id: string) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await getTemplate(orgId, id)

		if (!res.data || !res.success) {
			return
		}

		const { template } = res.data
		this.addTemplate(template)
	}

	setActiveTemplateId(id: string) {
		this.activeTemplateId = id
	}

	addTemplate(data: ITemplate | Template) {
		if (this.templatesById.has(data.id)) {
			const template = this.templatesById.get(data.id)
			template?.update(data)
			return template
		}

		const template = data instanceof Template ? data : new Template(data)
		this.list.push(template)
		this.templatesById.set(template.id, template)

		return template
	}

	getTemplate(id: string) {
		return this.templatesById.get(id)
	}

	async updateTemplate(id: string, data: Partial<TemplateProps>) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await updateTemplate(orgId, id, data)
		if (!res.success || !res.data) {
			return
		}

		const { template } = res.data
		console.log(template)
		let t = this.getTemplate(id)
		if (t) {
			t.update(template)
		} else {
			t = this.addTemplate(template)
		}

		return t
	}

	async deleteTemplate(orgId: string, id: string) {
		const res = await deleteTemplate(orgId, id)

		if (res.success) {
			const idx = this.list.findIndex((s) => s.id === id)
			this.list.splice(idx, 1)

			this.templatesById.delete(id)
		}

		return res.success
	}

	async preview(templateId: string, opts: TemplatePreviewOptions) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await previewTemplate(orgId, templateId, opts)
		if (!res.success) {
			return
		}

		if (!res.data) {
			return
		}

		return res.data
	}

	private clearTemplates() {
		// NOTE: could potentially also just do list.length = 0
		// need to test if mobx picks that up
		while (this.list.length > 0) {
			this.list.pop()
		}

		this.templatesById.clear()
	}
}
