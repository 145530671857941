import { calculateStageCounts } from '@requests/candidates'
import { Query } from '@touchpoints/requests'
import { ObservableMap, makeAutoObservable, runInAction } from 'mobx'
import type { RootStore } from '../root'

export class CandidateStageCounts {
	private readonly root: RootStore

	// readonly countsByStageId: Record<string, number> = {}
	readonly countsByStageId = new ObservableMap<string, number>()

	state: 'idle' | 'loading' = 'loading'

	get isCalculating() {
		return this.state === 'loading'
	}

	constructor(root: RootStore) {
		this.root = root
		makeAutoObservable(this)
	}

	private setState(s: 'idle' | 'loading') {
		this.state = s
	}

	async calculateStageCounts(query: Query) {
		this.setState('loading')
		await this._calculateStageCounts(query)
		this.setState('idle')
	}

	async calculateStageCountsForPosition(positionId: string) {
		return this.calculateStageCounts({
			conjunction: 'and',
			conditions: [
				{
					field: 'info.positionId.keyword',
					operator: 'Is',
					value: positionId,
				},
			],
		})
	}

	private async _calculateStageCounts(query: Query) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await calculateStageCounts(orgId, query)
		if (!res.success) {
			return
		}

		const { countsByStage = {} } = res.data ?? {}

		this.clear()
		for (const stageId in countsByStage) {
			runInAction(() => this.countsByStageId.set(stageId, countsByStage[stageId]))
		}
	}

	clear() {
		for (const key in this.countsByStageId) {
			runInAction(() => this.countsByStageId.set(key, 0))
		}
	}
}
