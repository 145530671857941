import { orderBy } from 'lodash'
import { Query } from './query'

export function sortFavoriteBoards(boards: IFavoriteBoard[]): IFavoriteBoard[] {
	// const myPositions = boards.filter((e) => e.slug === 'my-positions')
	const theRest = orderBy(
		// boards.filter((e) => e.slug !== 'my-positions'),
		boards,
		['isPrivate', 'slug'],
		['desc', 'asc']
	)

	return [...theRest]
}

export interface IEmojiProps {
	emoji: string
	emojiUrl: string
	[key: string]: any
}
export interface IFavoriteBoard {
	id: string
	organizationId: string
	userOrgId: string
	isPrivate: boolean
	name: string
	emojiProps: IEmojiProps
	query: Query
	subscribedUsers: string[]
}
