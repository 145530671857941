type Props = {
	className?: string
}
export function LockClosedIcon({ className }: Props) {
	return (
		<svg
			width="14"
			height="18"
			viewBox="0 0 14 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.00016 0.666992C4.69898 0.666992 2.8335 2.53247 2.8335 4.83366V6.50033H1.16683C0.706592 6.50033 0.333496 6.87342 0.333496 7.33366V16.5003C0.333496 16.9606 0.706592 17.3337 1.16683 17.3337H12.8335C13.2937 17.3337 13.6668 16.9606 13.6668 16.5003V7.33366C13.6668 6.87342 13.2937 6.50033 12.8335 6.50033H11.1668V4.83366C11.1668 2.53247 9.30135 0.666992 7.00016 0.666992ZM9.50016 6.50033V4.83366C9.50016 3.45295 8.38087 2.33366 7.00016 2.33366C5.61945 2.33366 4.50016 3.45295 4.50016 4.83366V6.50033H9.50016ZM7.00016 9.83366C7.4604 9.83366 7.8335 10.2068 7.8335 10.667V13.167C7.8335 13.6272 7.4604 14.0003 7.00016 14.0003C6.53993 14.0003 6.16683 13.6272 6.16683 13.167V10.667C6.16683 10.2068 6.53993 9.83366 7.00016 9.83366Z"
				fill="#687076"
			/>
		</svg>
	)
}
