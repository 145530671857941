import clsx from 'clsx'
import { PropsWithChildren, useState } from 'react'
import ReactPhoneInput, { Props } from 'react-phone-number-input'
import { Label } from './core/Label'

type PhoneInputProps = {
	label?: string
	fullWidth?: boolean
	bordered?: string
	tabIndex?: number
} & Props<typeof ReactPhoneInput>
export function PhoneInput({
	label,
	fullWidth = true,
	children,
	readOnly,
	tabIndex,
	...props
}: PropsWithChildren<PhoneInputProps>) {
	const [active, setActive] = useState(false)

	return (
		<div className={clsx('flex flex-col p-0', { 'w-full': fullWidth })}>
			{label && <Label>{label}</Label>}
			<div
				tabIndex={tabIndex}
				className={clsx(
					'flex items-center justify-between px-2 border w-full rounded-md',
					'focus:outline focus:outline-[#0091FF]',
					'placeholder:text-sm placeholder:text-slate-400',
					{
						'hover:border-slate-600': !readOnly,
						'border-[#dfe3e6]': !readOnly && !active,
					}
				)}
			>
				<ReactPhoneInput
					countryOptionsOrder={['US', '|', '...']}
					international
					defaultCountry={'US'}
					countryCallingCodeEditable={false}
					readOnly={readOnly}
					{...props}
					className="w-full"
					onFocus={() => setActive(true)}
					onBlur={() => setActive(false)}
				/>
				{children}
			</div>
		</div>
	)
}
