import { addTeam, fetchTeams, removeTeam, updateTeam } from '@requests/teams'
import type { IOrganizationUser, ITeam } from '@touchpoints/requests'
import { autorun, makeAutoObservable } from 'mobx'
import type { RootStore } from './root'

// use first 3 members to create a team name and note count of remaining members
function teamNameFromMembers(members: IOrganizationUser[], count = 3) {
	const names = members.slice(0, count).map((m) => m.firstName || 'Unknown')
	if (names.length === 1) {
		return names[0]
	}

	const remaining = members.length - count
	if (remaining > 0) {
		names.push(`, and ${remaining} other${remaining > 1 ? 's' : ''}`)
	}

	if (names.length === 2) {
		return names.join(' and ')
	}

	if (names.length <= count) {
		names.splice(names.length - 1, 0, 'and')
	}

	return `${names.slice(0, names.length - 1).join(', ')} ${names[names.length - 1]}`
}

class Team implements Omit<ITeam, 'bookings'> {
	id = ''
	organizationId = ''
	localId = 0
	name = ''
	memberIds: string[] = []

	constructor(data: ITeam) {
		this.update(data)

		makeAutoObservable(this)
	}

	update(data: ITeam) {
		this.id = data.id
		this.localId = data.localId
		this.organizationId = data.organizationId
		this.name = data.name
		this.memberIds = data.memberIds ?? []
	}
}

export class TeamsStore {
	private readonly root: RootStore

	readonly list: Team[] = []

	constructor(root: RootStore) {
		this.root = root

		makeAutoObservable(this)

		autorun(async () => {
			await this.fetchTeams()
		})
	}

	async fetchTeams() {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await fetchTeams(orgId)
		if (!res.success) {
			return
		}

		const { teams } = res.data ?? {}
		if (!teams) {
			return
		}

		this.clearTeams()
		for (const team of teams) {
			this.addTeam(team)
		}
	}

	async createTeam(name: string, membersId: string[]) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await addTeam(orgId, name, membersId)

		if (!res.success) {
			return
		}

		const { team } = res.data ?? {}
		if (!team) {
			return
		}

		this.addTeam(team)
	}

	async updateTeam(id: string, data: { name?: string; memberIds?: string[] }) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await updateTeam(orgId, id, data)

		if (!res.success) {
			return
		}

		const { team } = res.data ?? {}
		if (!team) {
			return
		}

		const existingTeam = this.getTeamById(id)
		existingTeam?.update(team)
	}

	async deleteTeam(id: string) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await removeTeam(orgId, id)

		if (!res.success) {
			return
		}

		this.removeTeam(id)
	}

	getTeamById(id: string) {
		return this.list.find((t) => t.id === id)
	}

	getTeamName(team: Partial<Pick<ITeam, 'memberIds' | 'name'>> | string, forceGenerated = false) {
		const t = typeof team === 'string' ? this.getTeamById(team) : team

		if (!t) {
			return ''
		}

		if (t.name && !forceGenerated) {
			return t.name
		}

		return teamNameFromMembers(
			(t.memberIds ?? [])
				.map((id) => this.root.organizationUsers.usersByUserId[id])
				.filter((v) => !!v) as IOrganizationUser[]
		)
	}

	private addTeam(team: ITeam) {
		this.list.push(new Team(team))
	}

	private removeTeam(id: string) {
		const idx = this.list.findIndex((t) => t.id === id)
		if (idx < 0) {
			return
		}
		this.list.splice(idx, 1)
	}

	private clearTeams() {
		while (this.list.length > 0) {
			this.list.pop()
		}
	}
}
