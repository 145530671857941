import type {
	DelMethodFunc,
	GetMethodFunc,
	ISnippet,
	toJSONObject,
	PatchMethodFunc,
	PostMethodFunc,
	SnippetProps,
} from '../types'

export function makeFetchSnippets(
	getMethod: GetMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function fetchSnippets(organizationId: string) {
		const headers = await authHeader()
		return getMethod<{ snippets: ISnippet[] }>(
			`/organizations/${organizationId}/snippets`,
			undefined,
			headers
		)
	}
}

export function makeGetSnippet(
	getMethod: GetMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function getSnippet(organizationId: string, snippetId: string) {
		const headers = await authHeader()
		return getMethod<{ snippet: ISnippet }>(
			`/organizations/${organizationId}/snippets/${snippetId}`,
			undefined,
			headers
		)
	}
}

export function makeAddSnippet(
	postMethod: PostMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function addSnippet(organizationId: string, snippet: toJSONObject<SnippetProps>) {
		const headers = await authHeader()
		return postMethod<{ snippet: ISnippet }>(
			`/organizations/${organizationId}/snippets`,
			{
				...snippet,
			},
			headers
		)
	}
}

export function makeUpdateSnippet(
	patchMethod: PatchMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function updateSnippet(
		organizationId: string,
		snippetId: string,
		data: Partial<toJSONObject<SnippetProps>>
	) {
		const headers = await authHeader()
		return patchMethod<{ snippet: ISnippet }>(
			`/organizations/${organizationId}/snippets/${snippetId}`,
			{
				...data,
			},
			headers
		)
	}
}

export function makeDeleteSnippet(
	delMethod: DelMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function deleteSnippet(organizationId: string, snippetId: string) {
		const headers = await authHeader()
		return delMethod(
			`/organizations/${organizationId}/snippets/${snippetId}`,
			headers,
			undefined
		)
	}
}

export type ProcessSnippetOptions = {
	candidateEmail: string
	positionId?: string
	subject?: string
}
export function makeProcessSnippetTemplate(
	postMethod: PostMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function processSnippetTemplate(
		organizationId: string,
		snippetId: string,
		options: ProcessSnippetOptions
	) {
		const headers = await authHeader()
		return postMethod<{ html: string; context: object; trackingId?: string }>(
			`/organizations/${organizationId}/snippets/${snippetId}/process`,
			{
				...options,
				candidate: options.candidateEmail,
			},
			headers
		)
	}
}
