import { useCallback, useEffect, useMemo, useState } from 'react'

type Options = {
	maxCount?: number
	maxValue?: number
	rate?: number
}
export function useDecay(start: number, options?: Options) {
	const [values, setValues] = useState<number[]>([start])

	const { maxCount = 10, rate = 1.5, maxValue } = options ?? {}

	const createValues = useCallback(() => {
		const list: number[] = [start]
		let val = start
		for (let i = 0; i < maxCount; ++i) {
			val = Math.round(val * rate)
			list.push(val)
		}
		return list
	}, [start, maxCount, rate])

	useEffect(() => {
		const list = createValues()
		setValues(list)
	}, [createValues])

	const next = useMemo(() => {
		let count = 0
		return () => {
			if (count >= values.length) {
				if (maxValue) {
					return maxValue
				}
				return null
			}

			const v = values[count]
			++count
			return v
		}
	}, [values, maxValue])

	const reset = useCallback(() => {
		const list = createValues()
		setValues(list)
		return 0
	}, [createValues])

	return { next, reset }
}
