import { fetchAccountSettings, updateAccountSettings } from '@requests/settings'
import { CustomField } from '@store/customField'
import type { ICustomField } from '@touchpoints/requests'
import { makeAutoObservable, reaction } from 'mobx'
import type { RootStore } from '../root'

type SaveOptions = {
	customFields?: ICustomField[]
}

export class AccountSettings {
	private readonly root: RootStore

	readonly customFields: CustomField[] = []

	constructor(root: RootStore) {
		this.root = root

		makeAutoObservable(this)

		reaction(
			() => this.root.organizations.activeOrganizationId,
			() => {
				this.refresh()
			}
		)
	}

	async refresh() {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await fetchAccountSettings(orgId)
		if (!res.success) {
			return
		}

		const { settings } = res.data ?? {}
		if (!settings) {
			return
		}

		settings.customFields?.forEach((field) => this.addCustomField(field))
	}

	async addCustomField(field: ICustomField) {
		this.customFields.push(new CustomField(field))
	}

	async save(options: SaveOptions) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		if (options.customFields) {
			this.clearCustomFields()
			options.customFields.forEach((f) => this.addCustomField(f))
		}

		const res = await updateAccountSettings(orgId, {
			customFields: this.customFields.map((f) => ({ ...f })),
		})

		if (!res.success) {
			return
		}

		const { settings } = res.data ?? {}
		if (!settings) {
			return
		}

		this.clearCustomFields()
		settings.customFields?.forEach((field) => this.addCustomField(field))
	}

	private clearCustomFields() {
		while (this.customFields.length > 0) {
			this.customFields.pop()
		}
	}
}
