import { findPhoneNumbersInText } from 'libphonenumber-js/min'
import { isPossiblePhoneNumber } from 'react-phone-number-input'

export function quickFormat(phone?: string): string {
	if (!phone) {
		return '+1'
	}

	// Remove all non-numeric characters that might be in a phone number
	// (parentheses, spaces, and, dashes)
	phone = findPhoneNumbersInText(phone, 'US')[0]?.number?.number ?? phone.replace(/[()\s-]/gm, '')
	let res = quickValidation(phone)
	if (res) {
		return res
	}

	// Some people add a trailing 0 which make the validation fail
	if (phone.startsWith('0')) {
		res = quickValidation(phone.replace('0', ''))
		if (res) return res
	}

	// Sometimes adding a trailing 0 make it valid
	res = quickValidation(`0${phone}`)
	if (res) {
		return res
	}

	return phone
}

function quickValidation(phone: string) {
	if (isPossiblePhoneNumber(phone)) {
		return phone
	}

	if (isPossiblePhoneNumber(`+${phone}`)) {
		return `+${phone}`
	}

	if (isPossiblePhoneNumber(`+1${phone}`)) {
		return `+1${phone}`
	}
	return undefined
}
