import { fetchPositionSettings, updatePositionSettings } from '@requests/settings'
import { CustomField } from '@store/customField'
import type {
	IChatAISettings,
	ICustomField,
	IPositionSettings,
	ITriggerActionReference,
} from '@touchpoints/requests'
import { makeAutoObservable, reaction } from 'mobx'
import type { RootStore } from '../root'

type SaveOptions = IPositionSettings

export class PositionSettings {
	private readonly root: RootStore

	readonly customFields: CustomField[] = []

	defaultCandidateScheduledEmail = ''

	notificationEmailTemplateId = ''

	candidateStageWhenClosed = ''
	candidateStagesToExcludeWhenClosed: string[] = []

	chatAISettings: IChatAISettings = { model: 'gpt-3.5-turbo' }

	whenPositionIsOpened: ITriggerActionReference[] = []

	constructor(root: RootStore) {
		this.root = root

		makeAutoObservable(this)

		reaction(
			() => this.root.organizations.activeOrganizationId,
			() => {
				this.refresh()
			}
		)
	}

	async refresh() {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await fetchPositionSettings(orgId)
		if (!res.success) {
			return
		}

		const { settings } = res.data ?? {}
		if (!settings) {
			return
		}

		this.setDefaultCandidateScheduledEmail(settings.defaultCandidateScheduledEmail ?? '')
		this.setNotificationEmailTemplateId(settings.notificationEmailTemplateId ?? '')
		settings.customFields?.forEach((field) => this.addCustomField(field))
		this.candidateStageWhenClosed = settings.candidateStageWhenClosed ?? ''
		this.candidateStagesToExcludeWhenClosed = settings.candidateStagesToExcludeWhenClosed ?? []
		this.chatAISettings = settings.chatAISettings ?? { model: 'gpt-3.5-turbo' }
		this.whenPositionIsOpened = settings.whenPositionOpenedTriggers ?? []
	}

	async addCustomField(field: ICustomField) {
		this.customFields.push(new CustomField(field))
	}

	async updateNotificationTemplateId(templateId: string) {
		await this.save({ notificationEmailTemplateId: templateId })
	}

	async save(options: SaveOptions) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		this.defaultCandidateScheduledEmail =
			options.defaultCandidateScheduledEmail ?? this.defaultCandidateScheduledEmail
		this.notificationEmailTemplateId =
			options.notificationEmailTemplateId ?? this.notificationEmailTemplateId
		if (options.customFields) {
			this.clearCustomFields()
			options.customFields.forEach((f) => this.addCustomField(f))
		}

		const res = await updatePositionSettings(orgId, {
			defaultCandidateScheduledEmail: this.defaultCandidateScheduledEmail,
			customFields: this.customFields.map((f) => ({ ...f })),
			notificationEmailTemplateId: this.notificationEmailTemplateId,
			candidateStagesToExcludeWhenClosed:
				options.candidateStagesToExcludeWhenClosed || undefined,
			candidateStageWhenClosed: options.candidateStageWhenClosed || undefined,
			chatAISettings: options.chatAISettings || undefined,
			whenPositionOpenedTriggers: options.whenPositionOpenedTriggers || undefined,
		})

		if (!res.success) {
			return
		}

		const { settings } = res.data ?? {}
		if (!settings) {
			return
		}

		this.setDefaultCandidateScheduledEmail(settings.defaultCandidateScheduledEmail ?? '')
		this.setNotificationEmailTemplateId(settings.notificationEmailTemplateId ?? '')
		this.clearCustomFields()
		settings.customFields?.forEach((field) => this.addCustomField(field))
		this.candidateStageWhenClosed = settings.candidateStageWhenClosed ?? ''
		this.candidateStagesToExcludeWhenClosed = settings.candidateStagesToExcludeWhenClosed ?? []
		this.chatAISettings = settings.chatAISettings ?? { model: 'gpt-3.5-turbo' }
		this.whenPositionIsOpened = settings.whenPositionOpenedTriggers ?? []
	}

	async updateDefaultCandidateScheduledEmail(email: string) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const oldEmail = this.defaultCandidateScheduledEmail

		if (oldEmail === email) {
			return
		}

		// set optimistically
		this.setDefaultCandidateScheduledEmail(email)

		const res = await updatePositionSettings(orgId, {
			defaultCandidateScheduledEmail: email,
			customFields: this.customFields.map((f) => ({ ...f })),
		})

		if (!res.success) {
			this.setDefaultCandidateScheduledEmail(oldEmail)
			return
		}

		const { settings } = res.data ?? {}
		if (!settings) {
			this.setDefaultCandidateScheduledEmail(oldEmail)
			return
		}

		this.setDefaultCandidateScheduledEmail(settings.defaultCandidateScheduledEmail ?? '')
	}

	private setDefaultCandidateScheduledEmail(email: string) {
		this.defaultCandidateScheduledEmail = email
	}

	private setNotificationEmailTemplateId(templateId: string) {
		this.notificationEmailTemplateId = templateId
	}

	private clearCustomFields() {
		while (this.customFields.length > 0) {
			this.customFields.pop()
		}
	}
}
