import type { IInvite, IOrganizationUser } from '@touchpoints/requests'

import { authHeader, post, get, del } from './api'

export async function inviteEmail(email: string, orgId: string) {
	const headers = await authHeader()
	return post<{ invite: IInvite }>(
		`/organizations/${orgId}/invites`,
		{
			email,
		},
		headers
	)
}

export async function acceptInvite(inviteId: string) {
	const headers = await authHeader()
	return post<{ user: IOrganizationUser }>(`/invites/${inviteId}`, undefined, headers)
}

export async function fetchInvites(orgId: string) {
	const headers = await authHeader()
	return get<{ invites: IInvite[] }>(`/organizations/${orgId}/invites`, undefined, headers)
}

export async function deleteInvite(orgId: string, inviteId: string) {
	const headers = await authHeader()
	return del(`/organizations/${orgId}/invites/${inviteId}`, headers)
}

export async function resendInvite(orgId: string, inviteId: string) {
	const headers = await authHeader()
	return post(`/organizations/${orgId}/invites/${inviteId}`, headers)
}
