import { nanoid } from 'nanoid'
import { ITriggerAction } from './triggers'

function newStageGroup(name: string, color: string, stages: IOrgStageProps[]) {
	return {
		id: nanoid(),
		name,
		color,
		stages,
	}
}

function newStage(name: string): IOrgStageProps {
	return {
		id: nanoid(),
		name: name,
		description: '',
	}
}

const STAGE_GROUP_1 = newStageGroup('Sourcing', '#0693e3', [newStage('Sourcing')])
const STAGE_GROUP_2 = newStageGroup('Engaging', '#12B512', [newStage('Engaging')])
const STAGE_GROUP_3 = newStageGroup('Qualifying', '#006400', [newStage('Qualifying')])
const STAGE_GROUP_4 = newStageGroup('Internally Submitted', '#FCB900', [
	newStage('Internally Submitted'),
])
const STAGE_GROUP_5 = newStageGroup('Presented to Client', '#fac8ee', [
	newStage('Presented to Client'),
])
const STAGE_GROUP_6 = newStageGroup('Interviewing', '#ff6700', [newStage('Interviewing')])
const STAGE_GROUP_7 = newStageGroup('Offers', '#ae8d0b', [newStage('Offers')])
const STAGE_GROUP_8 = newStageGroup('Started', '#179C57', [newStage('Started')])
const STAGE_GROUP_9 = newStageGroup('No Match', '#696969', [newStage('No Match')])
export const STAGE_GROUPS = [
	STAGE_GROUP_1,
	STAGE_GROUP_2,
	STAGE_GROUP_3,
	STAGE_GROUP_4,
	STAGE_GROUP_5,
	STAGE_GROUP_6,
	STAGE_GROUP_7,
	STAGE_GROUP_8,
	STAGE_GROUP_9,
]

export type IStageTemplateProps = Omit<IStageInstance, 'setName'>
export interface IStageTemplate {
	id: string
	organizationId: string
	name: string

	stageGroups: IStageGroup[]

	createdBy: string
	createdAt: number
	lastUpdateBy: string
	lastUpdateAt: number

	setName(name: string): void
}
export type IStageGroupProps = Omit<IStageGroup, 'addStageAfter' | 'insertStage'>
export interface IStageGroup {
	id: string
	name: string
	color: string
	stages: IStage[]

	[key: string]: any

	addStageAfter(stage: IStage, stageId: string): void
	insertStage(stage: IStage, index: number): void
}

export type IStage = IStageInstance | IOrgStage

export type IStageInstanceProps = Omit<IStageInstance, 'insertAction' | 'getStageGroups'>
export interface IStageInstance {
	id: string
	stageReferenceId: string
	actions: ITriggerAction[]

	insertAction(action: ITriggerAction, index: number): void
	getStageGroups?(): IStageGroup[]
}

export type IOrgStageProps = Omit<IOrgStage, 'setName' | 'setDescription' | 'getStageGroups'>
export interface IOrgStage {
	id: string

	name: string
	description: string

	setName(name: string): void
	setDescription(description: string): void
	getStageGroups?(): IStageGroup[]
}
