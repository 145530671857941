import { ClipboardEvent, useEffect, useRef, useState } from 'react'
import uniq from 'lodash/uniq'
import isEqual from 'lodash/isEqual'

import type { MouseEvent, KeyboardEvent } from 'react'
import { Label } from './core/Label'
import clsx from 'clsx'

interface TagProps {
	text: string
	remove: any
	disabled?: boolean
}

export default function Tag({ text, remove, disabled }: TagProps) {
	const handleOnRemove = (e: MouseEvent) => {
		e.stopPropagation()
		remove(text)
	}

	return (
		<span className="inline-flex items-center space-x-1 border rounded-md justify-center px-2">
			<span>{text}</span>
			{!disabled && (
				<button
					className="border-0 rounded-md cursor-pointer hover:bg-stone-100 text-xs p-0.5"
					type="button"
					onClick={handleOnRemove}
					aria-label={`remove ${text}`}
				>
					&#10005;
				</button>
			)}
		</span>
	)
}

export interface TagsInputProps {
	name?: string
	placeHolder?: string
	label?: string
	value?: string[]
	readOnly?: boolean
	onChange?: (tags: string[]) => void
	onBlur?: any
	seprators?: string[]
	onExisting?: (tag: string) => void
	onRemoved?: (tag: string) => void
	disabled?: boolean
	isEditOnRemove?: boolean
	beforeAddValidate?: (tag: string, existingTags: string[]) => boolean
	tabIndex?: number
}

const defaultSeparators = ['Enter', ',']

export const TagsInput = ({
	name,
	placeHolder,
	label,
	value,
	readOnly,
	onChange,
	onBlur,
	seprators,
	onExisting,
	onRemoved,
	disabled,
	isEditOnRemove,
	beforeAddValidate,
	tabIndex,
}: TagsInputProps) => {
	const [tags, setTags] = useState<string[]>(value ?? [])
	const inputRef = useRef<HTMLInputElement>(null)

	useEffect(() => {
		if (isEqual(tags, value)) {
			return
		}

		onChange?.(tags)
	}, [tags, value, onChange])

	const handleOnKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
		e.stopPropagation()

		const text = e.currentTarget.value

		if (e.key === 'Backspace' && tags.length && !text) {
			e.currentTarget.value = isEditOnRemove ? `${tags.at(-1)} ` : ''
			setTags([...tags.slice(0, -1)])
		}

		if (text && (seprators || defaultSeparators).includes(e.key)) {
			if (beforeAddValidate && !beforeAddValidate(text, tags)) return

			if (tags.includes(text)) {
				onExisting && onExisting(text)
				return
			}
			setTags(uniq([...tags, text]))
			e.currentTarget.value = ''
			e.preventDefault()
		}
	}

	const handleOnPaste = (e: ClipboardEvent<HTMLInputElement>) => {
		e.stopPropagation()

		const text = e.clipboardData.getData('text')
		const moreTags = text.split(',').map((t) => t.trim())
		e.currentTarget.value = ''
		e.preventDefault()
		setTags(uniq([...tags, ...moreTags]))
	}

	const onTagRemove = (text: string) => {
		setTags(tags.filter((tag) => tag !== text))
		onRemoved && onRemoved(text)
	}

	return (
		<div className="flex flex-col w-full">
			{label && <Label>{label}</Label>}
			<div
				aria-labelledby={name}
				className={clsx(
					'flex items-center border rounded-md flex-wrap gap-2 leading-6 px-1 py-1 min-h-[44px]',
					{
						'bg-slate-50 outline-none': !!readOnly,
						// 'focus:outline focus:outline-[#0091FF]': !readOnly,
					}
				)}
				onClick={() => {
					if (inputRef.current) {
						inputRef.current.style.display = 'flex'
					}
					inputRef.current?.focus()
				}}
			>
				{tags.map((tag) => (
					<Tag
						key={tag}
						text={tag}
						remove={onTagRemove}
						disabled={disabled || readOnly}
					/>
				))}

				<input
					ref={inputRef}
					tabIndex={tabIndex}
					className={clsx('border-0 outline-0 flex flex-auto p-1 ring-0 focus:ring-0', {
						'bg-slate-50 outline-none': !!readOnly,
					})}
					type="text"
					name={name}
					readOnly={readOnly}
					placeholder={tags.length <= 0 ? placeHolder : ''}
					onKeyDown={handleOnKeyUp}
					onBlur={(e) => {
						if (inputRef.current && tags.length > 0) {
							inputRef.current.style.display = 'none'
						}
						onBlur?.(e)
					}}
					onPaste={handleOnPaste}
					disabled={disabled}
				/>
			</div>
		</div>
	)
}
