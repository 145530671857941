import { rootStore } from '@store'
import type { IOrganizationUser } from '@touchpoints/requests'

import { processMentions as _processMentions } from '@touchpoints/utils'

export function processMentions(mentions: string, linkify = false, markdown = false) {
	const users: IOrganizationUser[] = []

	const result = _processMentions(mentions, linkify, markdown, (id) => {
		const user = rootStore.organizationUsers.users[id]
		if (user) {
			users.push(user)
		}
		const name = user?.firstName ? `@${user?.firstName} ${user?.lastName}` : user?.email ?? id
		return name.trim()
	})

	return {
		mentionedUsers: users,
		...result,
	}
}

export const linkMentions = (text: string, markdown = false) => {
	const data = processMentions(text, true, markdown)

	const { value } = data

	return value
}
