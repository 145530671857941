import { createCandidateView, deleteCandidateView, updateCandidateView } from '@requests/candidates'
import { ICandidateView } from '@touchpoints/requests'
import { makeAutoObservable, reaction, runInAction } from 'mobx'
import type { RootStore } from '../root'

import * as localData from '@services/localData'

export type View = Omit<ICandidateView, 'id'> & {
	id?: string
}

const DEFAULT_VIEW: View = {
	id: 'default',
	name: 'All candidates',
	query: JSON.stringify({ conjunction: 'and', conditions: [] }),
	fields: ['name', 'email', 'stage', 'phone'],
}

export class CandidatesViews {
	private readonly root: RootStore

	activeViewId = 'default'

	readonly views: View[] = [DEFAULT_VIEW]

	constructor(root: RootStore) {
		this.root = root

		makeAutoObservable(this)

		reaction(
			() => this.root.organizations.activeOrganizationId,
			() => {
				this.refresh()
			}
		)
	}

	get activeView() {
		return this.views.find((v) => v.id === this.activeViewId) ?? this.views[0]
	}

	get customViews() {
		return this.views.filter((v) => v.id !== 'default')
	}

	async refresh() {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		// get saved views
		this.views.length = 0
		const views = this.root.organizations.activeOrganization?.candidateViews ?? []
		this.addView(DEFAULT_VIEW)
		for (const v of views) {
			this.addView(v)
		}

		// set last used active view
		const data = localData.get()
		const settings = data.orgSettings[orgId] ?? {}
		this.useView(settings.activeViewId ?? 'default')
	}

	addView(v: View) {
		this.views.push(v)
	}

	useView(id: string) {
		runInAction(() => {
			this.activeViewId = id
		})

		const orgId = this.root.organizations.activeOrganizationId

		// save to local storage
		const data = localData.get()
		const settings = data.orgSettings[orgId] ?? {}
		settings.activeViewId = id

		data.orgSettings[orgId] = settings
		localData.store(data)
	}

	async createView(v: View) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		// save to db
		const res = await createCandidateView(orgId, v)

		if (!res.success) {
			console.error(res.message)
			return
		}

		const { view } = res.data ?? {}

		if (!view) {
			return
		}

		this.views.push(view)
		this.useView(view.id ?? 'default')
		return view
	}

	async updateView(view: View, changes: Partial<View>) {
		if (!view.id) {
			console.error('missing view id')
			return
		}

		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await updateCandidateView(orgId, view.id, changes)
		if (!res.success) {
			console.error(res.message)
			return
		}

		const { view: updatedView } = res.data ?? {}

		if (!updatedView) {
			return
		}

		const idx = this.views.findIndex((v) => v.id === view.id)
		if (idx < 0) {
			return
		}

		this.views.splice(idx, 1, { ...updatedView })
		return updatedView
	}

	async deleteView(viewId: string) {
		if (!viewId) {
			console.error('missing view id')
			return
		}

		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await deleteCandidateView(orgId, viewId)
		if (!res.success) {
			console.error(res.message)
			return
		}

		const { view: updatedView } = res.data ?? {}

		if (!updatedView) {
			return
		}

		const idx = this.views.findIndex((v) => v.id === viewId)
		if (idx < 0) {
			return
		}

		this.views.splice(idx, 1)
	}
}
