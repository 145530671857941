import { useState, useEffect } from 'react'

export function useVisibilityChange() {
	const [documentVisible, setDocumentVisibility] = useState(true)

	useEffect(() => {
		const handleChange = () => {
			if (document.visibilityState !== 'visible') {
				setDocumentVisibility(false)
			} else {
				setDocumentVisibility(true)
			}
		}

		document.addEventListener('visibilitychange', handleChange)

		return () => {
			document.removeEventListener('visibilitychange', handleChange)
		}
	}, [])

	return documentVisible
}
