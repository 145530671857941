import { useReaction } from '@hooks/mobx'
import { formatTaskDate } from '@services/date'
import { rootStore } from '@store'
import { ICandidate, IOrganizationUser, IPositionCandidate } from '@touchpoints/requests'
import { Pill, toast } from '@touchpoints/ui'
import { memo, useEffect, useRef } from 'react'
import { useDueTime, useDueTimeColor, useTasks } from './hooks'
import { store } from './store'
import { nameDisplayForCandidate } from './utils'
import { OrgUserAvatar } from '@components/shared/OrgUserAvatar'
import { CandidateStageTimeline } from '@components/candidates/CandidateStageTimeline'
import { useIntersectionObserver } from 'usehooks-ts'
import { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import { HiOutlineMail } from 'react-icons/hi'

import { FaRegComment } from 'react-icons/fa'
import { Tooltip } from '@nextui-org/react'
import clsx from 'clsx'

type BoardCardProps = {
	positionCandidate: IPositionCandidate
	candidate?: ICandidate
	recruiter?: IOrganizationUser
	showAsCard?: boolean
	onClick?: () => void
}
export const BoardCard = memo(function BoardCard({
	positionCandidate: pc,
	candidate,
	recruiter,
	showAsCard,
	onClick,
}: BoardCardProps) {
	const ref = useRef<HTMLDivElement>(null)
	const entry = useIntersectionObserver(ref, { threshold: 0 })

	const positionCandidate = useReaction(
		() => {
			const val = rootStore.positions.getPositionCandidateById(pc.id)
			return { ...val }
		},
		500,
		[pc.id]
	)
	const tasks = useTasks(positionCandidate?.positionId, positionCandidate?.candidateId)
	const dueTime = useDueTime(positionCandidate?.scheduledEvents ?? [], tasks)
	const dueByColor = useDueTimeColor(dueTime)

	const comments = positionCandidate?.comments ?? []
	const activePositionCandidateId = useReaction(() => store.activePositionCandidateId)

	useEffect(() => {
		// auto scroll only if position candidate is selected
		if (activePositionCandidateId === pc.id) {
			const element = document.getElementById(
				`board-column-${store.activePositionCandidateId}`
			)
			element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
		}
	}, [activePositionCandidateId, pc.id])

	return (
		<div
			id={showAsCard ? '' : `board-column-${pc.id}`}
			ref={ref}
			className={clsx(
				'flex flex-col w-full p-3 space-y-4 cursor-pointer hover:bg-[#f1f3f5]/50',
				{
					'rounded-md border shadow-sm': showAsCard,
					'border-t last:border-b': !showAsCard,
					'bg-[#f1f3f5]': pc.id === store.activePositionCandidateId,
					'opacity-60':
						store.activePositionCandidateId &&
						pc.id !== store.activePositionCandidateId,
				}
			)}
			onClick={onClick}
		>
			{entry?.isIntersecting && (
				<div className="flex justify-between items-center">
					<div className="line-clamp-1">
						<CandidateStageTimeline
							candidateId={pc.candidateId}
							positionId={pc.positionId}
						/>
					</div>
					{recruiter && !showAsCard && <OrgUserAvatar size="xs" user={recruiter} />}
				</div>
			)}
			<div className="flex flex-col space-y-2">
				<div className="flex w-full justify-between">
					<p className="text-sm font-medium truncate">
						{nameDisplayForCandidate(candidate)}
					</p>
				</div>

				<div className="flex w-full items-center">
					{candidate?.phone && isValidPhoneNumber(candidate?.phone) && (
						<p className="text-sm text-gray-500 truncate mr-2">
							{formatPhoneNumber(candidate.phone)}
						</p>
					)}
					{candidate?.email && (
						<Tooltip content={candidate?.email}>
							<HiOutlineMail
								className="text-gray-500 cursor-pointer"
								onClick={async (e) => {
									e.stopPropagation()
									navigator.clipboard.writeText(candidate.email)
									toast.success(`Copied to clipboard!`)
								}}
							/>
						</Tooltip>
					)}
				</div>
				{showAsCard && (
					<div className="flex w-full justify-between items-center">
						<div className="flex space-x-2 items-center">
							{comments.length > 0 && (
								<div className="flex items-center space-x-1 text-gray-500">
									<FaRegComment />
									<p>{comments.length}</p>
								</div>
							)}
							{dueTime && (
								<Pill size="xs" className="p-1" color={dueByColor}>
									{formatTaskDate(dueTime)}
								</Pill>
							)}
						</div>
						{recruiter && <OrgUserAvatar size="xs" user={recruiter} />}
					</div>
				)}
			</div>
		</div>
	)
})
