import type { IQualityField } from '@touchpoints/requests'
import { makeAutoObservable } from 'mobx'

export class QualityField implements IQualityField {
	fieldName = ''
	customFieldKey = ''
	selected = false

	constructor(data: IQualityField) {
		makeAutoObservable(this)

		this.update(data)
	}

	update(data: IQualityField) {
		this.fieldName = data.fieldName
		this.customFieldKey = data.customFieldKey ?? ''
		this.selected = data.selected ?? false
	}
}
