import { rootStore } from '@store'
import { createSelectOnChange, Select } from '@touchpoints/ui'
import { observer } from 'mobx-react-lite'

type AssigneeSelectProps = {
	assignee?: string
	label?: string
	options?: {
		value: string
		label: string
	}[]
	isDisabled?: boolean
	onChange?: (assignee: string) => void
}
export const AssigneeSelect = observer(function ({
	label,
	assignee,
	options,
	isDisabled,
	onChange,
}: AssigneeSelectProps) {
	const assigneeOptions = [
		// only show unassigned if there is no assignee
		...(!assignee ? [{ value: '', label: 'Unassigned' }] : []),
		...(options ??
			rootStore.organizationUsers.usersForActiveOrg.map((u) => ({
				value: u.id,
				label: u.email,
			}))),
	]
	return (
		<Select
			label={label}
			value={assigneeOptions.find((o) => o.value === (assignee ?? ''))}
			isDisabled={isDisabled}
			options={assigneeOptions}
			onChange={onChange ? createSelectOnChange(onChange) : undefined}
		/>
	)
})
