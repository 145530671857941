import { IMessage } from '@touchpoints/requests'
import type { gmail_v1 } from 'googleapis'

export const IMG_TRACK_REGEX = /<img [^>]* width="1" height="1" \/>/g

export function getMessageId(message: gmail_v1.Schema$Message) {
	// NOTE: this is used for getting the correct messageId for replying
	// to an existing thread
	const id = message?.payload?.headers?.find((h) => h.name === 'Message-Id')?.value
	return id ? id : message?.payload?.headers?.find((h) => h.name === 'Message-ID')?.value
}

export function getMessageSubject(message: gmail_v1.Schema$Message) {
	return message?.payload?.headers?.find((h) => h.name === 'Subject')?.value
}

function extractEmails(text: string) {
	const matches = text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)
	return matches ? matches[0] : ''
}

export function getMessageFrom(message: gmail_v1.Schema$Message) {
	let from = message?.payload?.headers?.find((h) => h.name === 'From')?.value
	if (!from) {
		from = message?.payload?.headers?.find((h) => h.name === 'Return-Path')?.value
	}
	return extractEmails(from || '')
}

export function getMessageTo(message: gmail_v1.Schema$Message | gmail_v1.Schema$MessagePart) {
	const headers =
		'partId' in message
			? message.headers
			: (message as gmail_v1.Schema$Message).payload?.headers
	const to = headers?.find((h) => h.name === 'To')?.value
	return extractEmails(to || '')
}

export function getMessageCc(message: gmail_v1.Schema$Message) {
	const cc = message.payload?.headers?.find((h) => h.name === 'Cc')?.value
	if (cc) {
		return extractEmails(cc)
	}
	return undefined
}

export function getContentType(message: gmail_v1.Schema$Message) {
	return message.payload?.headers?.find((h) => h.name?.toLowerCase() === 'content-type')?.value
}

export function isBounceMessage(message: gmail_v1.Schema$Message) {
	const mimeType = message.payload?.mimeType
	if (mimeType !== 'multipart/report') {
		return false
	}

	// get content type
	const contentType = getContentType(message)
	if (!contentType) {
		return false
	}

	// get report type
	const reportType = contentType
		.split(';')
		.find((s) => s.includes('report-type='))
		?.trim()
	if (!reportType) {
		return false
	}

	// get report type value
	const reportTypeValue = reportType.split('=')[1]?.trim()
	if (reportTypeValue !== 'delivery-status') {
		return false
	}

	return true
}

export function hasFailedRecipients(message: gmail_v1.Schema$Message) {
	const failedRecipients = getFailedRecipients(message)
	return failedRecipients.length > 0
}

export function getFailedRecipients(message: gmail_v1.Schema$Message) {
	const recipients =
		message.payload?.headers?.find((h) => h.name?.toLowerCase() === 'x-failed-recipients')
			?.value ?? ''
	return recipients.split(',').map((r) => r.trim())
}

export function isFailedRecipient(message: gmail_v1.Schema$Message, to: string) {
	const failedRecipients = getFailedRecipients(message)
	return failedRecipients.includes(to)
}

export function getRfc822Message(message: gmail_v1.Schema$Message) {
	const parts = message.payload?.parts ?? []
	const part = parts?.find((p: any) => p.mimeType === 'message/rfc822')
	if (!part) {
		return undefined
	}

	const messageData = part.parts?.find((p: any) => p.mimeType === 'multipart/alternative')
	return messageData
}

export function getDeliveryStatusMessage(message: gmail_v1.Schema$Message) {
	const parts = message.payload?.parts ?? []
	const part = parts?.find((p: any) => p.mimeType === 'message/delivery-status')
	if (!part) {
		return undefined
	}

	const plainData = part.parts?.find((p) => p.mimeType === 'text/plain')
	const plain = decode(plainData?.body?.data ?? '')

	const htmlParts =
		message.payload?.parts
			?.find((p) => p.mimeType === 'multipart/related')
			?.parts?.find((p) => p.mimeType === 'multipart/alternative')?.parts ?? []
	const htmlData = htmlParts?.find((p) => p.mimeType === 'text/html')
	const html = decode(htmlData?.body?.data ?? '')

	return { plain, html }
}

export function getMessageDate(message: gmail_v1.Schema$Message) {
	const date = message.payload?.headers?.find((h) => h.name === 'Date')?.value
	return date ? new Date(date) : Date.now()
}

const decode = (str: string): string => {
	return Buffer.from(str, 'base64').toString('utf-8')
}

export function parseMessage(message: gmail_v1.Schema$Message) {
	const parts = message.payload?.parts

	const textPart = parts?.find((p: any) => p.mimeType === 'text/plain')
	const htmlPart = parts?.find((p: any) => p.mimeType === 'text/html')
	let textContent = textPart ? decode(textPart.body?.data || '') : ''
	let htmlContent = htmlPart ? decode(htmlPart.body?.data || '') : ''
	let bounced = false

	if (!textContent && !htmlContent) {
		const isBounced = isBounceMessage(message)
		if (isBounced || hasFailedRecipients(message)) {
			if (!isBounced) {
				// for some reason not a bounce but we have failed recipients
				// consider it a bounce if the failed recipient is the "to" address
				const rfc822Message = getRfc822Message(message)
				if (rfc822Message) {
					const to = getMessageTo(rfc822Message)
					if (!isFailedRecipient(message, to)) {
						return undefined
					}
				}
			}

			bounced = true
			const { plain, html } = getDeliveryStatusMessage(message) ?? {}
			if (!plain && !html) {
				return undefined
			}

			textContent = plain ?? ''
			htmlContent = html ?? ''
		} else {
			return undefined
		}
	}

	return {
		id: getMessageId(message),
		from: getMessageFrom(message),
		to: getMessageTo(message),
		cc: getMessageCc(message),
		date: getMessageDate(message),
		textContent: textContent.replace(IMG_TRACK_REGEX, ''),
		htmlContent: htmlContent.replace(IMG_TRACK_REGEX, ''),
		bounced,
	} as IMessage
}

export function appendAfter(value: string, extra?: string) {
	if (!extra) {
		return value
	}

	return `${value}\n${extra}`
}
