import ReactDatePicker from 'react-datepicker'
import type { ReactDatePickerProps } from 'react-datepicker'
import { useState, type ReactNode, forwardRef } from 'react'
import clsx from 'clsx'
import { format } from 'date-fns'
import { Pill } from './Pill'
import { Button, Tooltip } from '@nextui-org/react'
import { HiCheck, HiPencil } from 'react-icons/hi'
import { tzLocalShortName } from '@touchpoints/timezone'

interface LabelProps {
	label?: string | ReactNode
	isDark?: boolean
	color?: string
}
function Label({ label, isDark, color }: LabelProps) {
	return (
		<div
			className={clsx({ 'text-gray-800': !color }, color, 'text-sm mb-1.5 ml-1', {
				'dark:text-white': isDark,
			})}
		>
			{label}
		</div>
	)
}

type DatePickerWithConfirmationProps = {
	readOnly?: boolean
	selected?: Date | null | undefined
	dateBgColor?: string
	dateColor?: string
	dateFormat?: string | undefined
	onChange(date: Date | null): void
	showTimeOnHover?: boolean
} & Props &
	ReactDatePickerProps
export function DatePickerWithConfirmation({
	readOnly = false,
	selected,
	disabled,
	label,
	dateColor,
	isDark = false,
	dateFormat = 'EEE, MMM do hh:mm a',
	dateBgColor = 'bg-slate-200',
	onChange,
	showTimeOnHover = false,
	...props
}: DatePickerWithConfirmationProps & { label?: string | ReactNode }) {
	const [editing, setEditing] = useState(false)
	const [date, setDate] = useState(selected)

	const DateView = forwardRef<HTMLSpanElement, any>(({ value }, _ref) => {
		const handleOnClick = () => {
			if (readOnly || disabled) {
				return
			}

			setEditing(!editing)
		}

		if (!value) {
			return (
				<Button auto onClick={handleOnClick}>
					Set
				</Button>
			)
		}

		return (
			<div className="flex space-x-2">
				<Pill
					size="md"
					color={dateBgColor}
					className={clsx('w-full flex items-center', {
						'cursor-pointer': !readOnly && !disabled,
					})}
					onClick={handleOnClick}
				>
					{showTimeOnHover ? (
						<Tooltip
							content={date ? format(date, 'h:mm a') : undefined}
							placement="left"
						>
							<p className={clsx('text-center text-sm h-5', dateColor)}>
								{value} {tzLocalShortName()}
							</p>
							{!readOnly && !disabled && !editing && <HiPencil />}
						</Tooltip>
					) : (
						<>
							<p className={clsx('text-center text-sm h-5', dateColor)}>
								{value} {tzLocalShortName()}
							</p>
							{!readOnly && !disabled && !editing && <HiPencil />}
						</>
					)}
				</Pill>
				{editing && (
					<div className="w-16 flex justify-center mx-[2.5px]">
						<Button
							auto
							light
							size="sm"
							color="success"
							onPress={() => {
								onChange(date ?? new Date())
								setEditing(false)
							}}
						>
							<HiCheck size="1.25em" />
						</Button>
					</div>
				)}
			</div>
		)
	})

	return (
		<div className="flex flex-col w-full">
			{label && <Label label={label} isDark={isDark} />}
			<div className="flex w-full items-center">
				<DatePicker
					open={editing}
					disabled={!editing}
					onCalendarClose={() => setEditing(false)}
					onClickOutside={() => setEditing(false)}
					dateFormat={dateFormat}
					selected={date}
					onChange={(changedDate) => {
						if (changedDate) setDate(changedDate)
					}}
					customInput={<DateView />}
					{...props}
				/>
			</div>
		</div>
	)
}

type Props = {
	isDark?: boolean
} & ReactDatePickerProps
export function DatePicker({
	label,
	isDark = false,
	customInput,
	...props
}: Props & { label?: string | ReactNode }) {
	return (
		<div className="flex flex-col w-full">
			{label && <Label label={label} isDark={isDark} />}
			<div
				className={clsx('flex items-center', {
					'overflow-visible border-2 border-slate-200 rounded-xl p-2 bg-white':
						!customInput,
				})}
			>
				<ReactDatePicker
					{...props}
					popperClassName="z-[1000]"
					className="w-full"
					customInput={customInput}
				/>
			</div>
		</div>
	)
}
