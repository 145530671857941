import { OrgUserAvatar } from '@components/shared/OrgUserAvatar'
import { formatDate } from '@services/time'
import { rootStore } from '@store/index'
import { useReaction } from '@touchpoints/mobx-hooks'
import type { INotification } from '@touchpoints/requests'
import { Popover2 } from '@touchpoints/ui'
import { store } from '../accounts/store'
import { HiBell, HiChatBubbleOvalLeft } from 'react-icons/hi2'
import { useInterval } from 'usehooks-ts'

const POLL_INTERVAL = 1000 * 60 * 1

export function NotificationsPopover() {
	const notifications = useReaction(() => rootStore.notifications.list, 100)
	const unreadCount = useReaction(() => rootStore.notifications.unreadCount, 100)

	useInterval(() => {
		rootStore.notifications.fetchNotifications()
	}, POLL_INTERVAL)

	const handleOpenChange = (isOpen: boolean) => {
		if (!isOpen) {
			return
		}

		rootStore.notifications.markAllAsRead()
	}

	return (
		<Popover2
			trigger={
				<div className="flex items-center justify-center relative mt-1 mr-4">
					<HiBell className="w-7 h-7 text-neutral-800 cursor-pointer hover:text-neutral-600" />
					{unreadCount > 0 && (
						<div className="absolute top-0 right-0 w-2.5 h-2.5 bg-red-500 rounded-full animate-fade-scale-in" />
					)}
				</div>
			}
			placement="bottom-end"
			onOpenChange={handleOpenChange}
		>
			<div className="flex flex-col border overflow-hidden rounded-lg shadow-md shadow-neutral-200 max-h-60 space-y-3 mr-4">
				{notifications.length === 0 ? (
					<NoNotifications />
				) : (
					<div className="flex flex-col divide-y divide-neutral-200 bg-white w-96 overflow-y-auto">
						{notifications.map((notification) => {
							switch (notification.type) {
								case 'comment':
									return (
										<CommentNotification
											key={notification.id}
											notification={notification}
										/>
									)
								default:
									return (
										<UnknownNotification
											key={notification.id}
											notification={notification}
										/>
									)
							}
						})}
					</div>
				)}
			</div>
		</Popover2>
	)
}

type CommentNotificationProps = {
	notification: INotification
}
function CommentNotification({ notification }: CommentNotificationProps) {
	const user = useReaction(() => {
		const fromOrgUserId = notification.payload.comment?.fromOrgUserId
		if (!fromOrgUserId) {
			return undefined
		}
		return rootStore.organizationUsers.users[fromOrgUserId]
	}, 100)

	const handleClick = () => {
		const pcId = notification.payload.comment?.positionCandidateId
		if (!pcId) {
			return
		}

		store.setSelectedPositionCandidateId(pcId)
	}

	return (
		<div
			className="flex flex-row items-center space-x-2 cursor-pointer p-1 hover:bg-neutral-100 h-20"
			onClick={handleClick}
		>
			<div className="flex p-3">
				{user ? (
					<OrgUserAvatar user={user} size="sm" />
				) : (
					<HiChatBubbleOvalLeft className="w-6 h-6 text-neutral-600" />
				)}
			</div>
			<div className="flex flex-col w-72 p-2">
				<p className="text-sm font-medium line-clamp-2 whitespace-break-spaces">
					{notification.payload.content}
				</p>
				<p className="text-xs text-neutral-500">
					on {formatDate(new Date(notification.createdAt))}
				</p>
			</div>
		</div>
	)
}

function UnknownNotification({ notification }: { notification: INotification }) {
	return (
		<div className="flex flex-row items-center space-x-2">
			<div className="flex p-3">{notification.type} notification</div>
		</div>
	)
}

function NoNotifications() {
	return (
		<div className="flex flex-row items-center justify-center p-5 bg-white w-96">
			<p className="text-sm text-neutral-500">No notifications</p>
		</div>
	)
}
