import type { ReactNode } from 'react'
import CommandPalette from './commandPalette/components/CommandPalette'
import { JsonStructure, JsonStructureItem, RenderLink } from './commandPalette/types'
import { filterItems, getItemIndex, useHandleOpenCommandPalette } from './commandPalette/lib/utils'

type Props = {
	onChangeSelected?: (value: number) => void
	onChangeSearch: (search: string) => void
	onChangeOpen: (isOpen: boolean) => void
	renderLink?: RenderLink
	placeholder?: string
	children: ReactNode
	footer?: ReactNode
	selected?: number
	isOpen: boolean
	search: string
	page?: string
}

// This function is a wrapper of react-cmdk project.
// The reason for copying this implementation is to support a toogle dark mode feature:
// https://tailwindcss.com/docs/dark-mode#toggling-dark-mode-manually
export function CustomCommandPalette(props: Props) {
	return <CommandPalette {...props} />
}

CustomCommandPalette.Page = CommandPalette.Page
CustomCommandPalette.List = CommandPalette.List
CustomCommandPalette.ListItem = CommandPalette.ListItem

export { useHandleOpenCommandPalette, filterItems, getItemIndex }
export type { JsonStructure, JsonStructureItem }
