import { makeAutoObservable } from 'mobx'

export class ExtensionStore {
	id = ''

	constructor() {
		makeAutoObservable(this)
	}

	setId(id: string) {
		this.id = id
	}
}
