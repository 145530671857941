import { PostMethodFunc, ISequenceInstance, ICandidate, IPositionCandidate } from '../types'

export type AddCandidateToSequenceOptions = {
	senderId?: string
	senderEmail?: string
	delay?: number
}

export function makeAddCandidateToSequence(
	postMethod: PostMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function addCandidateToSequence(
		orgId: string,
		sequenceId: string,
		candidateId: string,
		positionId: string,
		options?: AddCandidateToSequenceOptions
	) {
		const headers = await authHeader()
		return postMethod<{ sequenceInstance: ISequenceInstance; candidate?: ICandidate }>(
			`/organizations/${orgId}/sequences/${sequenceId}/candidates/${candidateId}`,
			{ ...options, positionId },
			headers
		)
	}
}

export function makeFindCandidateByLinkedinUrl(
	postMethod: PostMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function findCandidateByLinkedinUrl(orgId: string, url: string) {
		const headers = await authHeader()
		return postMethod<{
			candidate: ICandidate
			cards: (IPositionCandidate & { lastActivityAt?: number })[]
		}>(`/organizations/${orgId}/candidates/linkedin`, { linkedinUrl: url }, headers)
	}
}
