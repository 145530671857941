import { TTLCache } from '@touchpoints/ttl-cache'
import cloneDeep from 'lodash/cloneDeep'
import FlatQueue from 'flatqueue'

type Options = {
	maxChannels?: number
	sleepBetween?: number
	cacheTTL?: number
	name?: string
}

const NORMAL_PRIORITY = 1000

export function createAsyncQueue({
	maxChannels = 3,
	sleepBetween = 0,
	cacheTTL = 1000 * 60,
}: Options = {}) {
	const cache = new TTLCache({
		ttl: cacheTTL,
		max: 1000,
	})

	const cachedPriority: Record<string, number> = {}

	// const queue: { resolve: (v: any) => void; action: () => Promise<any> }[] = []
	const queue = new FlatQueue<{
		resolve: (v: any) => void
		action: () => Promise<any>
	}>()
	const uniqueKeys: Record<string, Promise<any>> = {}

	let channelsUsed = 0

	async function executeNext() {
		if (queue.length <= 0) {
			return
		}

		const size = maxChannels - channelsUsed
		if (size <= 0) {
			return
		}

		const tasks = []
		for (let i = 0; i < size; i++) {
			const next = queue.pop()
			if (!next) {
				continue
			}

			tasks.push(next)
		}

		for (const { action, resolve } of tasks) {
			++channelsUsed
			action()
				.then(async (result) => {
					resolve(result)

					if (sleepBetween > 0) {
						await new Promise((resolve) => setTimeout(resolve, sleepBetween))
					}

					--channelsUsed

					executeNext()
				})
				.catch((e) => {
					--channelsUsed
					console.error(e)
				})
		}
	}

	return {
		add<T>(action: () => Promise<T>, priority = NORMAL_PRIORITY) {
			return new Promise<T>((resolve) => {
				queue.push({ action, resolve }, priority)

				if (queue.length === 1) {
					setTimeout(executeNext, 0)
				}
			})
		},
		addUnique<T>(
			key: string,
			action: () => Promise<T>,
			opts?: { cache?: boolean; priority?: number }
		) {
			const { cache: shouldCache = true, priority = NORMAL_PRIORITY } = opts ?? {}

			const existing = uniqueKeys[key] as Promise<T> | undefined
			const oldPriority = cachedPriority[key]
			if (existing && oldPriority === priority) {
				return existing
			}

			if (cache.has(key)) {
				return Promise.resolve<T>(cloneDeep(cache.get(key)))
			}

			const promise = this.add(action, priority)

			uniqueKeys[key] = promise
			cachedPriority[key] = priority

			promise.then((val) => {
				if (shouldCache) {
					cache.set(key, val)
				}
				delete uniqueKeys[key]
				delete cachedPriority[key]
			})
			return promise
		},
		deleteCache(key: string) {
			cache.delete(key)
		},
		updateCache(key: string, value: any) {
			cache.set(key, value)
		},
		clearCache() {
			cache.clear()
		},
		isEmpty() {
			return queue.length <= 0
		},
	}
}
