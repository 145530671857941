'use client'
import { ActionItem } from '@components/accounts/ActionItems'
import { DelaySelect, IDelay } from '@components/shared/DelaySelect'
import { Tooltip } from '@nextui-org/react'
import { rootStore } from '@store/index'
import { useReaction } from '@touchpoints/mobx-hooks'
import { ICandidate, ITask } from '@touchpoints/requests'
import { DateTimePicker } from '@touchpoints/ui'
import Avvvatars from 'avvvatars-react'
import clsx from 'clsx'
import { addDays, format } from 'date-fns'
import type { DeepPartial, FlowbiteDropdownTheme } from 'flowbite-react'
import { Dropdown } from 'flowbite-react'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
import router from 'next/router'
import { useEffect, useState } from 'react'
import { BiErrorCircle } from 'react-icons/bi'
import { HiOutlineClock } from 'react-icons/hi2'
import { TaskActivity } from './TaskActivity'
import { TaskAssignee } from './TaskAssignee'
import { TaskPriority } from './TaskPriority'
import { CloseIcon, DoneIcon, TodoIcon } from './icons'
import { fromMsToUnit } from '@services/time'

export const candidateModal = makeAutoObservable({
	isOpen: false,
	candidateId: '',
	positionId: '',
	open(candidateId: string, positionId?: string) {
		this.candidateId = candidateId
		this.positionId = positionId ?? ''
		this.isOpen = true
	},
	close() {
		this.isOpen = false
	},
})

export const StatusView = ({
	status,
	label,
	onlyIcon = false,
}: {
	status: 'pending' | 'completed' | 'canceled' | 'failed' | 'closed'
	label?: string
	onlyIcon?: boolean
}) => {
	const IconSwitch = ({
		status,
	}: {
		status: 'pending' | 'completed' | 'canceled' | 'failed' | 'closed'
	}) => {
		switch (status) {
			case 'pending':
				return (
					<>
						<TodoIcon />
						&nbsp;&nbsp;
						{!onlyIcon && (
							<span className="whitespace-nowrap">{label ? label : 'To do'}</span>
						)}
					</>
				)
			case 'completed':
				return (
					<>
						<DoneIcon />
						&nbsp;&nbsp;
						{!onlyIcon && (
							<span className="whitespace-nowrap">{label ? label : 'Completed'}</span>
						)}
					</>
				)
			case 'canceled':
				return (
					<>
						<CloseIcon />
						&nbsp;&nbsp;
						{!onlyIcon && (
							<span className="whitespace-nowrap">{label ? label : 'Cancelled'}</span>
						)}
					</>
				)
			case 'failed':
				return (
					<>
						<BiErrorCircle />
						&nbsp;&nbsp;
						{!onlyIcon && (
							<span className="whitespace-nowrap">{label ? label : 'Failed'}</span>
						)}
					</>
				)
			case 'closed':
				return (
					<>
						<CloseIcon />
						&nbsp;&nbsp;
						{!onlyIcon && (
							<span className="whitespace-nowrap">{label ? label : 'Closed'}</span>
						)}
					</>
				)
			default:
				return <></>
		}
	}

	return (
		<div className="">
			<div className="h-[32px] flex flex-row items-center">
				<IconSwitch status={status} />
			</div>
		</div>
	)
}

export const TaskStatus = observer(function ({
	task,
	onComplete,
	onCloseTask,
}: {
	task: ITask
	onComplete?: () => void
	onCloseTask?: () => void
}) {
	const taskOwner = rootStore.organizationUsers.users[task.assignedUserId]
	const [loading, setLoading] = useState({ sequence: false })

	const handleGoToSequence = async () => {
		if (!task.sequenceInstanceId) {
			return
		}

		setLoading((l) => ({ ...l, sequence: true }))

		let instance = rootStore.sequences.getSequenceInstanceById(task.sequenceInstanceId)
		if (!instance) {
			instance = await rootStore.sequences.fetchSequenceInstance(task.sequenceInstanceId)
		}

		setLoading((l) => ({ ...l, sequence: false }))

		if (!instance) {
			return
		}

		router.push(`/sequences/${instance.sequenceId}`)
	}

	const customTheme: DeepPartial<FlowbiteDropdownTheme> = {
		content: 'm-0 w-full',
		floating: {
			base: 'outline-none z-[1001] rounded rounded-l',
		},
	}

	const customThemeItem = {
		container: 'p-1 m-1 rounded hover:bg-slate-100 dark:hover:bg-slate-500',
		base: 'flex items-center justify-start text-sm text-gray-700 cursor-pointer w-full dark:text-white',
		icon: 'mr-2 h-4 w-4',
	}

	return (
		<Dropdown
			label="Task status"
			disabled={!!task.completedAt}
			inline
			theme={customTheme}
			renderTrigger={() => (
				<div
					className={clsx('shadow-sm rounded px-2 border border-slate-100 h-[34px]', {
						'cursor-pointer hover:bg-[#f8f9fa]': !task.completedAt,
						'cursor-not-allowed': !!task.completedAt,
						'pointer-events-none': !!task.completedAt,
						'bg-gray-100': !!task.completedAt,
					})}
				>
					<StatusView status={task.status} />
				</div>
			)}
		>
			<Dropdown.Item theme={customThemeItem}>
				<div onClick={onComplete}>
					<StatusView
						status={'completed'}
						label={
							taskOwner ? `${taskOwner?.firstName} completed this task` : undefined
						}
					/>
				</div>
			</Dropdown.Item>
			<Dropdown.Item theme={customThemeItem}>
				<div onClick={onCloseTask}>
					<StatusView status={'closed'} />
				</div>
			</Dropdown.Item>
		</Dropdown>
	)
})

export interface TaskDueByProps {
	task?: Partial<ITask>
	onChanged?: (dueBy: number) => void
}
export const TaskDueBy = observer(function ({ task, onChanged }: TaskDueByProps) {
	const [dueBy, setDueBy] = useState(task?.dueBy)

	useEffect(() => {
		setDueBy(task?.dueBy)
	}, [task])

	const permissionsForDueDate =
		rootStore.organizationUsers.activeUser?.role === 'admin' ||
		rootStore.organizationUsers.activeUser?.role === 'recruiter'

	const handleUpateDueDate = async (date: Date) => {
		const time = date.getTime()
		setDueBy(time)
		onChanged?.(time)

		if (task?.id) {
			await rootStore.tasks.getTaskById(task.id)?.changeDueDate(time)
		}
	}

	const dueDate = dueBy ? new Date(dueBy) : undefined
	const minDate = dueDate ?? new Date()
	const maxDate = addDays(new Date(), 14)

	return (
		<Tooltip content={dueDate ? format(dueDate, 'EEE, MMM do hh:mm a') : 'No due date'}>
			<DateTimePicker
				selected={dueDate}
				minDate={minDate}
				maxDate={maxDate}
				disabled={!permissionsForDueDate}
				onChange={handleUpateDueDate}
			/>
		</Tooltip>
	)
})

export interface TaskDueInProps {
	task?: Partial<ITask>
	onChanged?: (delay: IDelay) => void
}
export const TaskDueIn = observer(function ({ task, onChanged }: TaskDueInProps) {
	const delayMs = task?.dueInMs ?? 0
	const delayUnit = task?.dueInDelayUnit ?? 'minutes'
	const [delay, setDelay] = useState<IDelay>({
		value: fromMsToUnit(delayMs, delayUnit),
		unit: delayUnit,
	})

	return (
		<DelaySelect
			delay={delay}
			onChange={(delay) => {
				setDelay(delay)
				onChanged?.(delay)
			}}
			trigger={
				<div className="flex items-center space-x-2 h-[34px] p-2 border rounded shadow-sm border-slate-100">
					<div>
						<HiOutlineClock size={20} />
					</div>
					<span className="text-zinc-900 text-sm font-normal">
						Due in {delay.value} {delay.unit}
					</span>
				</div>
			}
		/>
	)
})

export const TaskCandidate = observer(function ({ candidate }: { candidate?: ICandidate }) {
	if (!candidate) {
		return
	}

	return (
		<div className="flex flex-row items-center shadow-sm rounded px-2 border border-slate-100 h-[34px] cursor-not-alloweds">
			<Avvvatars value={candidate.email} size={20} /> &nbsp;&nbsp;
			<span className="whitespace-nowrap">
				{candidate.firstName} {candidate.lastName}
			</span>
		</div>
	)
})
type TaskFooterProps = {
	task: ITask
	onCloseTask?: () => void
	onComplete?: () => void
	candidate?: ICandidate
}
export const TaskFooter = observer(function ({
	task,
	onComplete,
	onCloseTask,
	candidate,
}: TaskFooterProps) {
	const positionCandidate = useReaction(() => {
		const positionId = task.data?.positionId
		const candidateId = task.data?.candidateId
		if (!positionId && !candidateId) {
			return
		}

		return rootStore.positions.getPositionCandidate(candidateId, positionId)
	})

	return (
		<>
			<div className="flex flex-wrap space-x-2 space-y-2 items-top z-10 relative">
				<div>{/* Intended empty div */}</div>
				<TaskPriority task={task} />
				<TaskAssignee task={task} groupByTeams filterable inputPlaceholder="Search..." />
				<TaskStatus task={task} onComplete={onComplete} onCloseTask={onCloseTask} />
				<TaskDueBy task={task} />
				<TaskCandidate candidate={candidate} />
			</div>
			<div className="flex flex-col my-8 flex-grow">
				{positionCandidate && (
					<div className="flex flex-col mb-10">
						{positionCandidate?.actionItems
							?.filter((item) => item.status === 'active')
							.map((item) => (
								<ActionItem
									key={item.id}
									positionCandidate={positionCandidate}
									item={item}
								/>
							))}
					</div>
				)}
				<p className="text-xl font-bold mb-4">Activity</p>
				<TaskActivity
					task={task}
					candidate={candidate}
					positionId={task?.data?.positionId}
				/>
			</div>
		</>
	)
})
