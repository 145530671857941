export function Pencil({ size = 20 }: { size?: number }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 20 21"
			fill="none"
		>
			<g id="edit, pencil, write">
				<path
					id="Icon"
					d="M11.0417 5.87553L13.5775 3.33978C13.9029 3.01434 14.4306 3.01434 14.756 3.33978L17.3275 5.91127C17.6529 6.23671 17.6529 6.76434 17.3275 7.08978L14.7917 9.62553M11.0417 5.87553L2.53583 14.3814C2.37955 14.5377 2.29175 14.7497 2.29175 14.9707V18.3755H5.69657C5.91758 18.3755 6.12955 18.2877 6.28583 18.1314L14.7917 9.62553M11.0417 5.87553L14.7917 9.62553"
					stroke="#687076"
					strokeWidth="1.2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	)
}
