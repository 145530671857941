import { styled } from '@stitches/react'
import clsx from 'clsx'
import { PropsWithChildren } from 'react'

export const Divider = styled('hr', {
	width: '100%',
	marginTop: '20px',
	marginBottom: '20px',
	borderColor: '#eaeaea',
	opacity: 0.5,
})

type DividerWithTextProps = {
	textColor?: 'gray' | 'black'
	textSize?: 'sm' | 'md' | 'lg'
}
export function DividerWithText({
	children,
	textColor = 'gray',
	textSize = 'sm',
}: PropsWithChildren<DividerWithTextProps>) {
	return (
		<div className="relative">
			<div className="absolute inset-0 flex items-center" aria-hidden="true">
				<div className="w-full border-t border-gray-300 z-0" />
			</div>
			<div className="relative flex justify-center">
				<span
					className={clsx(
						'px-2',
						{
							'text-sm': textSize === 'sm',
							'text-base': textSize === 'md',
							'text-lg': textSize === 'lg',
						},
						{
							'text-gray-500': textColor === 'gray',
							'text-black': textColor === 'black',
						}
					)}
				>
					{children}
				</span>
			</div>
		</div>
	)
}
