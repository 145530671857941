import {
	FloatingFocusManager,
	autoUpdate,
	flip,
	offset,
	shift,
	useClick,
	useDismiss,
	useFloating,
	useId,
	useInteractions,
	useRole,
} from '@floating-ui/react'
import { Placement } from '@floating-ui/react-dom'
import { PropsWithChildren, ReactNode, useState } from 'react'

export interface Popover2Props {
	placement: Placement
	trigger: ReactNode
	onOpenChange?: (isOpen: boolean) => void
}
export function Popover2({
	placement,
	trigger,
	children,
	onOpenChange,
}: PropsWithChildren<Popover2Props>) {
	const [isOpen, setIsOpen] = useState(false)

	const { refs, floatingStyles, context } = useFloating({
		placement: placement ? placement : 'bottom-start',
		open: isOpen,
		onOpenChange: (isOpen) => {
			setIsOpen(isOpen)
			onOpenChange?.(isOpen)
		},
		strategy: 'fixed',
		middleware: [offset(4), flip({ fallbackAxisSideDirection: 'end' }), shift()],
		whileElementsMounted: autoUpdate,
	})

	const click = useClick(context)
	const dismiss = useDismiss(context, {
		ancestorScroll: true,
		outsidePress: (event: any) => {
			// Safe guard:
			// Sometimes a dropdown is rendered inside the popover component, and clickking
			// outside the dropdown closes the popover as well. To prevent this we make this hacky check
			return event?.target?.nodeName !== 'HTML'
		},
	})

	const role = useRole(context)

	const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role])

	const headingId = useId()

	return (
		<>
			<div ref={refs.setReference} {...getReferenceProps()}>
				{trigger}
			</div>
			{isOpen && (
				<FloatingFocusManager context={context} modal={true}>
					<div
						ref={refs.setFloating}
						style={{ zIndex: 100, ...floatingStyles, outline: 'none' }}
						aria-labelledby={headingId}
						{...getFloatingProps()}
					>
						{children}
					</div>
				</FloatingFocusManager>
			)}
		</>
	)
}
