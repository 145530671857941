import { observer } from 'mobx-react-lite'

import { rootStore } from '@store'

import { SystemComment } from '@components/comments/CommentsPanel'
import { OrgUserAvatar } from '@components/shared/OrgUserAvatar'
import { Loading, Spacer, Tooltip } from '@nextui-org/react'
import { orgUserDisplay, orgUserNameDisplay } from '@services/format'
import { linkMentions, processMentions } from '@services/mentions'
import {
	ICandidate,
	IResourceComment,
	ITask,
	mapTaskPriority,
	type IOrganizationUser,
	type ITaskActivity,
	type TaskActivityAssignedPayload,
	type TaskActivityClosedPayload,
	type TaskActivityCompletedPayload,
	type TaskActivityCreatedPayload,
	type TaskActivityUpdatedPayload,
} from '@touchpoints/requests'
import { formatTimeDifference } from '@touchpoints/time'
import Autolinker from 'autolinker'
import { format } from 'date-fns'
import { KeyboardEvent, ReactNode, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Mention, MentionsInput } from 'react-mentions'
import { HighPriorityIcon, LowPriorityIcon, MediumPriorityIcon } from './icons'
import { formatTs } from '@services/time'

type TaskActivityProps = {
	task: ITask
	candidate?: ICandidate
	positionId?: string
}

interface ActivityItem {
	type: 'comment' | 'activity'
	item: ITaskActivity | IResourceComment
	ts: number
}

export const TaskActivity = observer(function ({ task, candidate, positionId }: TaskActivityProps) {
	const [addingComment, setAddingComment] = useState(false)

	const positionCandidate =
		positionId && candidate?.id
			? rootStore.positions.getPositionCandidate(candidate.id, positionId)
			: undefined

	const activity: ActivityItem[] = [
		...(task.activity || []).map((a) => {
			return { type: 'activity', item: a, ts: a.ts } as ActivityItem
		}),
		...(positionCandidate
			? positionCandidate.comments.map((c) => {
					return {
						type: 'comment',
						item: c,
						ts: c.createdAt,
					} as ActivityItem
			  })
			: []),
		...(!positionCandidate && task.comments
			? task.comments.map((c) => {
					return {
						type: 'comment',
						item: c,
						ts: c.createdAt,
					} as ActivityItem
			  })
			: []),
	].sort((a, b) => a.ts - b.ts)

	const handleSubmitComment = async (
		message: string,
		plainMessage?: string,
		messageValue?: string
	) => {
		if (!task) {
			return
		}
		setAddingComment(true)

		if (positionCandidate) {
			await rootStore.positions.addCandidateComment(
				positionCandidate.candidateId,
				positionCandidate.positionId,
				message,
				{
					plainComment: plainMessage,
					taskId: task.id,
				}
			)
		} else {
			await rootStore.tasks.addComment(task.id, messageValue ?? plainMessage ?? message)
		}

		setAddingComment(false)
	}

	return (
		<div className="flex flex-grow flex-col w-full">
			{activity.length <= 0 && (
				<div className="w-full h-10 items-start flex text-slate-500">No activity</div>
			)}

			{activity.reverse().map((a, index) => {
				return (
					<div key={index} className="my-2 w-full">
						{a.type === 'activity' && getActivityDetails(a.item as ITaskActivity)}
						{a.type === 'comment' && (
							<TaskComment comment={a.item as IResourceComment} />
						)}
					</div>
				)
			})}
			<div className="sticky bottom-0 py-5 bg-white dark:bg-gray-800">
				<CommentInput onSubmitMessage={handleSubmitComment} loading={addingComment} />
			</div>
		</div>
	)
})

function CommentInput({
	onSubmitMessage,
	loading,
}: {
	onSubmitMessage?: (
		message: string,
		plainMessage?: string,
		messageValue?: string
	) => Promise<void>
	loading?: boolean
}) {
	const [message, setMessage] = useState('')

	const users = rootStore.organizationUsers.usersForActiveOrg.map((u) => ({
		id: u.id,
		display: orgUserDisplay(u),
	}))

	const handleSubmitComment = async () => {
		if (!message) {
			return
		}

		const { value, plainValue } = processMentions(message)

		onSubmitMessage?.(message, plainValue, value)

		setMessage('')
	}

	const handleOnKeydown = (e: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (!onSubmitMessage) {
			return
		}

		if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
			handleSubmitComment()
		}
	}

	return (
		<div className="w-full flex">
			<OrgUserAvatar size={'xs'} user={rootStore.organizationUsers.activeUser} />
			{loading ? (
				<div className="flex grow items-center justify-center">
					<Loading />
				</div>
			) : (
				<div className="flex w-full space-x-2 px-2">
					<div className="w-full min-h-[100px] flex items-end border p-2 rounded-xl shadow-sm border-slate-200 dark:border-slate-600">
						<div className="w-full self-start">
							<MentionsInput
								placeholder="Leave a comment..."
								onKeyDown={handleOnKeydown}
								onChange={(e) => setMessage(e.target.value)}
								value={message}
								autoFocus={false}
								className="w-full rounded-lg dark:bg-slate-800"
								style={{
									control: {
										backgroundColor: 'inherit',
										fontSize: 16,
										fontWeight: 'normal',
									},
									input: {
										border: '0px',
										outlineWidth: '0px',
										padding: '10px',
									},
									'&multiLine': {
										highlighter: {
											padding: '10px',
										},
									},
									suggestions: {
										list: {
											backgroundColor: 'white',
											fontSize: 14,
											marginLeft: '-30px',
											marginTop: '8px',
										},
										item: {
											padding: '5px 15px',
											'&focused': {
												backgroundColor: '#cee4e5',
											},
										},
									},
								}}
							>
								<Mention
									trigger="@"
									displayTransform={(id, display) => {
										const user = rootStore.organizationUsers.users[id]
										if (!user) {
											return display
										}

										if (!user.firstName || !user.lastName) {
											return user.email
										}

										return `${user.firstName} ${user.lastName}`
									}}
									markup="@[__display__]:(__id__)"
									appendSpaceOnAdd
									className="border-0 bg-slate-100 dark:bg-slate-600"
									data={users}
									style={{
										color: '#000000',
										paddingTop: '4px',
										paddingBottom: '4px',
										paddingLeft: '2px',
										paddingRight: '2px',
										marginLeft: '-2px',
									}}
								/>
							</MentionsInput>
						</div>

						<div className="flex items-center h-9 mx-2 px-3 border border-slate-200 dark:border-slate-600 rounded-md shadow-sm cursor-pointer">
							<div onClick={handleSubmitComment}> Comment </div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

type TaskCommentProps = {
	comment: IResourceComment
	markdown?: boolean
}
function TaskComment({ comment, markdown = true }: TaskCommentProps) {
	const content = fixMarkdown(comment.content)
	const orgUser = comment.createdBy
		? rootStore.organizationUsers.users[comment.createdBy]
		: undefined

	if (comment.createdBy === 'system') {
		const customAvatar = (
			<div className="flex flex-row items-top">
				<OrgUserAvatar size={'xs'} text={'SY'} />
			</div>
		)
		return (
			<TaskActivityWrapper customAvatar={customAvatar}>
				<SystemComment comment={comment} />
				<div className="h-12" />
			</TaskActivityWrapper>
		)
	}

	return (
		<TaskActivityWrapper userAvatar={orgUser}>
			<div className="w-full">
				<div className="flex flex-col w-full border shadow-sm p-3 space-y-1 rounded-xl border-slate-200 dark:border-slate-600">
					<div className="flex flex-grow items-center dark:text-slate-300">
						<span className="font-medium">
							{orgUser && orgUserNameDisplay(orgUser)}
						</span>
						<span className="mx-2 w-1 h-1 rounded-full bg-slate-400" />
						<span className="text-sm text-slate-400">
							{formatTimeDifference(new Date(comment.createdAt ?? 0))}
						</span>
					</div>
					<span className="flex-1 prose dark:prose-invert break-all max-w-none">
						{markdown ? (
							<ReactMarkdown skipHtml>
								{Autolinker.link(linkMentions(content, true))}
							</ReactMarkdown>
						) : (
							<div
								dangerouslySetInnerHTML={{
									__html: Autolinker.link(linkMentions(content))
										.split('\n')
										.join('<br/>'),
								}}
							></div>
						)}
					</span>
				</div>
				<div className="h-3" />
			</div>
		</TaskActivityWrapper>
	)
}

const getActivityDetails = (activity: ITaskActivity): ReactNode | undefined => {
	switch (activity.type) {
		case 'created':
			return (
				<TaskCreated
					ts={activity.ts}
					payload={activity.payload as TaskActivityCreatedPayload}
				/>
			)
		case 'updated':
			return (
				<TaskUpdated
					ts={activity.ts}
					payload={activity.payload as TaskActivityUpdatedPayload}
				/>
			)

		case 'assigned':
			return (
				<TaskAssigned
					ts={activity.ts}
					payload={activity.payload as TaskActivityAssignedPayload}
				/>
			)

		case 'completed':
			return (
				<TaskCompleted
					ts={activity.ts}
					payload={activity.payload as TaskActivityCompletedPayload}
				/>
			)

		case 'closed':
			return (
				<TaskClosed
					ts={activity.ts}
					payload={activity.payload as TaskActivityClosedPayload}
				/>
			)
	}

	return undefined
}

const TaskCreated = ({ ts, payload }: { ts: number; payload: TaskActivityCreatedPayload }) => {
	const orgUser = payload.createdByOrgUserId
		? rootStore.organizationUsers.users[payload.createdByOrgUserId]
		: undefined
	return (
		<TaskActivityWrapper userAvatar={orgUser} ts={ts}>
			<span className="text-base">
				{orgUser?.firstName} <span className="text-slate-500"> created this task</span>
			</span>
		</TaskActivityWrapper>
	)
}

const TaskUpdated = ({ ts, payload }: { ts: number; payload: TaskActivityUpdatedPayload }) => {
	const orgUser = rootStore.organizationUsers.users[payload.updatedByOrgUserId]
	const changes = payload.changes
	const knownFields = ['dueBy', 'priority']

	return (
		<div className="flex flex-row items-center">
			{changes.map((change, index) => {
				const field = change.field

				const unknown = !knownFields.find((f) => f === field)
				const customAvatar =
					field !== 'priority' ? undefined : change.newValue === 0 ? (
						<div className="w-[20px] h-[20px] p-1">
							<HighPriorityIcon size={15} />
						</div>
					) : change.newValue === 1 ? (
						<div className="w-[20px] h-[20px] p-1">
							<MediumPriorityIcon size={15} />
						</div>
					) : (
						<div className="w-[20px] h-[20px] p-1">
							<LowPriorityIcon size={15} />
						</div>
					)

				return (
					<TaskActivityWrapper
						key={`change-${index}`}
						userAvatar={orgUser}
						customAvatar={customAvatar}
						ts={ts}
					>
						<span className="text-base">
							{orgUser?.firstName}{' '}
							{unknown && <span className="text-slate-500">changed this task</span>}
							{field === 'priority' && (
								<>
									<span className="text-slate-500">
										changed priority from{' '}
										<span className="text-slate-900 dark:text-white">
											{mapTaskPriority(change.oldValue)}
										</span>{' '}
										to{' '}
										<span className="text-slate-900 dark:text-white">
											{mapTaskPriority(change.newValue)}
										</span>
									</span>
								</>
							)}
							{field === 'dueBy' && (
								<>
									<span className="text-slate-500">
										changed due date from{' '}
										<span className="text-slate-900 dark:text-white">
											<Tooltip content={formatTs(change.oldValue)}>
												{format(new Date(change.oldValue), 'd MMM')}
											</Tooltip>
										</span>{' '}
										to{' '}
										<span className="text-slate-900 dark:text-white">
											<Tooltip content={formatTs(change.newValue)}>
												{format(new Date(change.newValue), 'd MMM')}
											</Tooltip>
										</span>
									</span>
								</>
							)}
						</span>
					</TaskActivityWrapper>
				)
			})}
		</div>
	)
}

const TaskAssigned = ({ ts, payload }: { ts: number; payload: TaskActivityAssignedPayload }) => {
	const userBy = rootStore.organizationUsers.users[payload.assignedByOrgUserId]
	const userTo = rootStore.organizationUsers.users[payload.assignedToOrgUserId]

	return (
		<TaskActivityWrapper userAvatar={userBy} ts={ts}>
			<span className="text-base">
				{userBy?.firstName} <span className="text-slate-500">assigned this task to</span>{' '}
				{userTo?.firstName}
			</span>
		</TaskActivityWrapper>
	)
}

const TaskCompleted = ({ ts, payload }: { ts: number; payload: TaskActivityCompletedPayload }) => {
	const orgUser = rootStore.organizationUsers.users[payload.completedByOrgUserId]
	return (
		<TaskActivityWrapper userAvatar={orgUser} ts={ts}>
			<span className="text-base">
				{orgUser?.firstName} <span className="text-slate-500">completed this task</span>{' '}
			</span>
		</TaskActivityWrapper>
	)
}
const TaskClosed = ({ ts, payload }: { ts: number; payload: TaskActivityClosedPayload }) => {
	const orgUser = rootStore.organizationUsers.users[payload.closedByOrgUserId]
	return (
		<TaskActivityWrapper userAvatar={orgUser} ts={ts}>
			<span className="text-base">
				{orgUser?.firstName} <span className="text-slate-500">closed this task</span>{' '}
			</span>
		</TaskActivityWrapper>
	)
}

function fixMarkdown(text: string) {
	// sometimes bold text comes in as **TEXT ** instead of **TEXT**
	return text.replace(/\b +\*{2}/g, '**')
}

export const TaskActivityWrapper = ({
	userAvatar,
	customAvatar,
	children,
	ts,
}: {
	userAvatar?: IOrganizationUser
	customAvatar?: ReactNode
	ts?: number
	children: ReactNode
}) => {
	return (
		<div className="flex flex-row h-full w-full">
			<div className="flex flex-col">
				<div>
					{customAvatar && customAvatar}
					{!customAvatar && userAvatar && <OrgUserAvatar size={'xs'} user={userAvatar} />}
					{!customAvatar && !userAvatar && <OrgUserAvatar size={'xs'} text={'SY'} />}
				</div>
				<div className="mt-2 border-l-[0.5px] border-slate-200 dark:border-slate-600 h-full self-center"></div>
			</div>
			<Spacer x={1} />
			<div className="w-full">
				<div className="w-full flex flex-row items-center">
					{children}
					<Spacer x={0.3} />
					{ts && (
						<>
							<div className="w-1 h-1 rounded-full bg-[#C1C8CD]" />
							<Spacer x={0.3} />

							<Tooltip content={formatTs(ts)}>
								<span className="text-sm text-slate-400">
									{formatTimeDifference(new Date(ts))}
								</span>
							</Tooltip>
						</>
					)}
				</div>
			</div>

			<div className="h-8 mt-1" />
		</div>
	)
}
