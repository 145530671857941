import { makeAutoObservable } from 'mobx'

import { fetchInvites, inviteEmail } from '@requests/invites'
import type { IInvite } from '@touchpoints/requests'

import type { RootStore } from './root'

class Invite implements IInvite {
	id = ''
	email = ''
	organizationId = ''

	constructor(data: IInvite) {
		makeAutoObservable(this)

		this.id = data.id
		this.email = data.email
		this.organizationId = data.organizationId
	}
}

export class InvitesStore {
	private rootStore: RootStore

	readonly list: Invite[] = []

	constructor(root: RootStore) {
		this.rootStore = root

		makeAutoObservable(this)
	}

	async fetchInvites(orgId?: string) {
		if (!orgId) {
			orgId = this.rootStore.organizations.activeOrganizationId
		}

		if (!orgId) {
			return
		}

		const res = await fetchInvites(orgId)
		const { invites = [] } = res.data ?? {}

		this.clear()
		invites.forEach((i) => this.addInvite(i))
	}

	async sendInvite(email: string, orgId: string) {
		const res = await inviteEmail(email, orgId)
		const { invite } = res.data ?? {}

		if (!invite) {
			return
		}

		this.addInvite(invite)

		return invite
	}

	addInvite(obj: IInvite | Invite) {
		const invite = obj instanceof Invite ? obj : new Invite(obj)
		this.list.push(invite)
	}

	removeInvite(invite: Invite | IInvite) {
		const idx = this.list.findIndex((i) => i.id === invite.id)
		if (idx < 0) {
			return
		}

		this.list.splice(idx, 1)
	}

	clear() {
		while (this.list.length > 0) {
			this.removeInvite(this.list[0])
		}
	}
}
