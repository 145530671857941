import { Button } from '@nextui-org/react'
import noop from 'lodash/noop'
import { createContext, PropsWithChildren, useCallback, useContext, useRef, useState } from 'react'
import { Modal } from './Modal'

type Options = {
	title: string
	message?: string
	content?: JSX.Element
	confirmText?: string
	confirmColor?: 'error' | 'warning' | 'success' | 'default' | 'primary' | 'secondary'
	cancelText?: string
	asAlert?: boolean
}
const ConfirmDialog = createContext<(opts: Options) => Promise<boolean>>(
	noop as () => Promise<boolean>
)

export function ConfirmDialogProvider({ children }: PropsWithChildren<any>) {
	const [state, setState] = useState<Options & { isOpen: boolean }>({
		isOpen: false,
		title: '',
		message: '',
	})
	const onConfirmFunc = useRef<(val: boolean) => void>()

	const confirm = useCallback(
		(data: Options) => {
			return new Promise<boolean>((resolve) => {
				setState({ ...data, isOpen: true })
				onConfirmFunc.current = (val: boolean) => {
					resolve(val)
					setState((prev) => ({ ...prev, isOpen: false }))
				}
			})
		},
		[setState]
	)

	return (
		<ConfirmDialog.Provider value={confirm}>
			{children}
			<Modal
				isOpen={state.isOpen}
				closeButton={false}
				preventClose
				className="mt-[calc(-40vh)]"
			>
				{state.title && (
					<Modal.Header justify="flex-start">
						<p className="text-2xl font-bold">{state.title}</p>
					</Modal.Header>
				)}
				<Modal.Body>
					{state.message && <p className="text-base">{state.message}</p>}
					{state.content && state.content}
				</Modal.Body>
				<Modal.Footer>
					{!state.asAlert && (
						<Button
							auto
							light
							className="z-0"
							onClick={() => onConfirmFunc.current?.(false)}
						>
							{state.cancelText ?? 'Cancel'}
						</Button>
					)}
					<Button
						auto
						color={state.confirmColor ?? 'primary'}
						className="z-0"
						onClick={() => onConfirmFunc.current?.(true)}
					>
						{state.confirmText ?? 'Okay'}
					</Button>
				</Modal.Footer>
			</Modal>
		</ConfirmDialog.Provider>
	)
}

export function useConfirm() {
	return useContext(ConfirmDialog)
}

export function useAlert() {
	const confirm = useConfirm()

	return useCallback(
		(data: Omit<Options, 'asAlert'>) => {
			return confirm({ ...data, asAlert: true })
		},
		[confirm]
	)
}
