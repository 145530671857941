export const createPositionCandidateAttachmentUrl = (
	bucketName: string,
	pcId: string,
	attachmentId: string,
	filename: string
) => {
	const bucket = `${bucketName}.s3.amazonaws.com`
	return `https://${bucket}/attachments/position-candidate/${pcId}/${attachmentId}/${filename}`
}

export const createCandidateAttachmentUrl = (
	bucketName: string,
	candidateId: string,
	attachmentId: string,
	filename: string
) => {
	const bucket = `${bucketName}.s3.amazonaws.com`
	return `https://${bucket}/attachments/candidate/${candidateId}/${attachmentId}/${filename}`
}
