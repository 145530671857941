import { Button, Card, Grid, Text, Tooltip } from '@nextui-org/react'
import {
	HiTrash,
	HiLink,
	HiChevronDown,
	HiChevronRight,
	HiCheckCircle,
	HiCalendar,
	HiExclamationCircle,
	HiMail,
} from 'react-icons/hi'
import { observer } from 'mobx-react-lite'
import { format } from 'date-fns'
import capitalize from 'lodash/capitalize'

import { Select, toast } from '@touchpoints/ui'

import { rootStore } from '@store'

import type {
	CandidateTimeSelectionStatus,
	ICandidate,
	ICandidateTimeSelection,
	ITimeSelection,
} from '@touchpoints/requests'
import { getCandidateScheduleLink } from '@requests/candidates'
import spacetime from 'spacetime'
import { Timezone, tzFriendlyName } from '@touchpoints/timezone'
import { timeSelectionsEqual, timeSelectionsOverlap } from '@touchpoints/time'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

type SelectionCardProps = {
	slot: ITimeSelection
	isSelected: boolean
	isExcluded: boolean
	timezone: Timezone
	onSelect?: () => void
	onDelete?: () => void
}
function SelectionCard({
	slot,
	isSelected,
	isExcluded,
	onSelect,
	onDelete,
	timezone,
}: SelectionCardProps) {
	const s = new Date(slot.start)
	const e = new Date(slot.end)
	const start = spacetime(s).goto(timezone)
	const end = spacetime(e).goto(timezone)

	return (
		<Card variant="bordered" className={clsx({ 'bg-slate-300': isExcluded })}>
			<Card.Header>
				<Text h5>{format(start.toNativeDate(), 'E, MMM do, yyyy')}</Text>
			</Card.Header>
			<Card.Body css={{ px: '$6' }}>
				{start.unixFmt('h:mma')} - {end.unixFmt('h:mma')}
				<span className="italic text-sm">({tzFriendlyName(timezone)})</span>
			</Card.Body>
			<Card.Footer style={{ padding: '10px' }} className="justify-between">
				<Button disabled={isExcluded && !isSelected} onClick={onSelect} ghost={isSelected}>
					{isSelected ? 'Cancel' : 'Select'}
				</Button>
				<Button auto disabled={isExcluded || isSelected} onClick={onDelete} light>
					<HiTrash />
				</Button>
			</Card.Footer>
		</Card>
	)
}

type CandidateSelectionProps = {
	candidate: ICandidate
	selection: ICandidateTimeSelection
	expanded?: boolean
	minimal?: boolean
}
export const CandidateSelection = observer(function ({
	candidate,
	selection,
	expanded,
	minimal = false,
}: CandidateSelectionProps) {
	const [loading, setLoading] = useState(false)
	const [isExpanded, setIsExpanded] = useState(expanded ?? selection.status !== 'complete')

	useEffect(() => {
		if (expanded === undefined) {
			return
		}

		setIsExpanded(expanded)
	}, [expanded])

	const availability = selection.availabilityId
		? rootStore.positionAvailabilities.getAvailabilityById(selection.availabilityId)
		: rootStore.positionAvailabilities.activeAvailabilities[0]

	const baseInterval = availability?.meetingLength ?? 60
	const timezone = availability?.timezone ?? 'America/New_York'
	const excludeSlots = availability?.excludeSlots ?? []

	const name = availability?.name || `${baseInterval} minute meeting`
	const defaultName = name
	const selectedEmails = selection.notifyEmails?.flatMap((email) =>
		email.split(',').map((email) => ({ value: email }))
	)
	const notifyOptions = rootStore.organizationUsers.usersForActiveOrg.map((uo) => ({
		value: uo.email,
	}))
	if (rootStore.positions.settings.defaultCandidateScheduledEmail) {
		const emails = rootStore.positions.settings.defaultCandidateScheduledEmail
			.split(',')
			.filter((e) => e && e.indexOf('{{') < 0)
			.map((e) => e.trim())
		emails.forEach((e) => {
			notifyOptions.push({ value: e })
		})
	}

	const handleGetCalendarLink = async (scheduleId: string) => {
		const position = rootStore.positions.getPositionById(selection.positionId)
		if (!position) {
			// TODO: show error
			return
		}

		// hit API for link
		const res = await getCandidateScheduleLink(
			position.organizationId,
			candidate.id,
			position.id,
			scheduleId
		)
		if (res.success) {
			const { url } = res.data ?? {}
			if (!url) {
				// TODO: show error
			} else {
				navigator.clipboard.writeText(url)
			}
		}

		toast('Link Copied!', {
			hideProgressBar: true,
			closeButton: false,
			closeOnClick: true,
			type: 'success',
			autoClose: 2500,
		})
	}

	const handleUpdateInterval = async (scheduleId: string, interval: number) => {
		const position = rootStore.positions.getPositionById(selection.positionId)
		if (!position) {
			// TODO: show error
			return
		}

		// NOTE: changing interval will also clear out any existing selections
		await rootStore.candidateSelections.updateSchedule(
			position.organizationId,
			position.id,
			candidate.id,
			scheduleId,
			{ interval }
		)
	}

	const handleUpdateNotify = async (scheduleId: string, notifyEmails: string[]) => {
		const position = rootStore.positions.getPositionById(selection.positionId)
		if (!position) {
			// TODO: show error
			return
		}

		// NOTE: changing interval will also clear out any existing selections
		await rootStore.candidateSelections.updateSchedule(
			position.organizationId,
			position.id,
			candidate.id,
			scheduleId,
			{ notifyEmails }
		)
	}

	const handleRemoveSchedule = async (scheduleId: string) => {
		const position = rootStore.positions.getPositionById(selection.positionId)
		if (!position) {
			// TODO: show error
			return
		}

		await rootStore.candidateSelections.deleteSchedule(
			position.organizationId,
			position.id,
			candidate.id,
			scheduleId
		)
	}

	const handleSelectSlot = async (scheduleId: string, slot: ITimeSelection) => {
		const position = rootStore.positions.getPositionById(selection.positionId)
		if (!position) {
			// TODO: show error
			return
		}

		await rootStore.candidateSelections.selectCandidateSelectionSlot(
			position.organizationId,
			position.id,
			candidate.id,
			scheduleId,
			slot
		)
	}

	const handleUnselectSlot = async (scheduleId: string) => {
		const position = rootStore.positions.getPositionById(selection.positionId)
		if (!position) {
			// TODO: show error
			return
		}

		await rootStore.candidateSelections.unselectCandidateSelectionSlot(
			position.organizationId,
			position.id,
			candidate.id,
			scheduleId
		)
	}

	const handleDeleteSlot = async (scheduleId: string, slot: ITimeSelection) => {
		if (loading) {
			return
		}

		const position = rootStore.positions.getPositionById(selection.positionId)
		if (!position) {
			// TODO: show error
			return
		}

		const idx = selection.selections.findIndex((sel) => timeSelectionsEqual(sel, slot))
		if (idx < 0) {
			return
		}

		setLoading(true)
		const newSelections = [...selection.selections]
		newSelections.splice(idx, 1)

		// NOTE: there is a possibility here that recruiter can overwrite candidate
		// selections or vice versa if both try to modify the selection slots at the same time
		// should be extremely rare
		await rootStore.candidateSelections.updateSchedule(
			position.organizationId,
			position.id,
			candidate.id,
			selection.id,
			{
				selections: newSelections,
			}
		)
		setLoading(false)
	}

	const handleMarkComplete = async () => {
		const position = rootStore.positions.getPositionById(selection.positionId)
		if (!position) {
			// TODO: show error
			return
		}

		await rootStore.candidateSelections.updateSchedule(
			position.organizationId,
			position.id,
			candidate.id,
			selection.id,
			{
				status: 'complete',
			}
		)
	}

	return (
		<div className="flex flex-col pt-3 space-y-3" key={selection.id}>
			<div
				className="flex items-center justify-between cursor-pointer"
				onClick={() => setIsExpanded(!isExpanded)}
			>
				<div className="flex flex-initial items-center space-x-2">
					<SelectionStatus status={selection.status} />
					<p className="font-semibold text-lg">{defaultName}</p>
				</div>
				<div className="flex">
					<p className="whitespace-nowrap">Status: {capitalize(selection.status)}</p>
				</div>
				<div className="flex flex-shrink items-center">
					{selection.status !== 'complete' && !minimal && (
						<Tooltip content="Remove">
							<Button light auto onClick={() => handleRemoveSchedule(selection.id)}>
								<HiTrash />
							</Button>
						</Tooltip>
					)}
					{selection.status !== 'complete' && (
						<Tooltip content="Mark Complete">
							<Button auto light onClick={handleMarkComplete}>
								<HiCheckCircle size="1.5em" className="text-green-500" />
							</Button>
						</Tooltip>
					)}
					{!minimal && (
						<Tooltip content="Get Link">
							<Button light auto onClick={() => handleGetCalendarLink(selection.id)}>
								<HiLink />
								&nbsp;Link
							</Button>
						</Tooltip>
					)}
					{isExpanded ? (
						<HiChevronDown fontSize="1.5em" className="cursor-pointer" />
					) : (
						<HiChevronRight fontSize="1.5em" className="cursor-pointer" />
					)}
				</div>
			</div>
			{isExpanded && (
				<div className="flex flex-col space-y-3">
					{!minimal && (
						<div className="flex items-center space-x-3">
							<div className="flex flex-initial items-center space-x-3">
								<p>Interval:</p>
								<div className="w-48">
									<Select
										value={{ value: selection.interval ?? 30 }}
										isClearable={true}
										options={[
											{ value: 30 },
											{ value: 45 },
											{ value: 60 },
											{ value: 90 },
										]}
										getOptionValue={(c) => `${c.value}`}
										getOptionLabel={(c) => `${c.value} mins`}
										onChange={(val) => {
											if (!val) {
												return
											}
											handleUpdateInterval(selection.id, val.value)
										}}
									/>
								</div>
							</div>
							<div className="flex flex-grow items-center space-x-3">
								<Text>Notify: </Text>
								<Select
									value={selectedEmails}
									isMulti
									name="emails"
									getOptionLabel={(item) => item.value}
									getOptionValue={(item) => item.value}
									options={notifyOptions}
									onChange={(val) => {
										if (!val) {
											return
										}
										handleUpdateNotify(
											selection.id,
											val.map((item) => item.value)
										)
									}}
								/>
							</div>
						</div>
					)}
					<Grid.Container gap={1} justify="flex-start">
						{selection.selections.length > 0 ? (
							selection.selections.map((slot, idx) => {
								const isSelected = selection.selectedTime
									? timeSelectionsEqual(selection.selectedTime, slot)
									: false
								const isExcluded = !!excludeSlots.find((s) =>
									timeSelectionsOverlap(s, slot)
								)

								return (
									<Grid key={`${idx}`}>
										<SelectionCard
											timezone={timezone}
											slot={slot}
											isSelected={isSelected}
											isExcluded={isExcluded}
											onSelect={() =>
												isSelected
													? handleUnselectSlot(selection.id)
													: handleSelectSlot(selection.id, slot)
											}
											onDelete={() => {
												handleDeleteSlot(selection.id, slot)
											}}
										/>
									</Grid>
								)
							})
						) : (
							<p className="p-5 text-center">No selections have been made</p>
						)}
					</Grid.Container>
				</div>
			)}
		</div>
	)
})

function SelectionStatus({ status }: { status: CandidateTimeSelectionStatus }) {
	switch (status) {
		case 'complete':
			return <HiCheckCircle fontSize="1.5em" className="text-green-500" />

		case 'scheduled':
			return <HiCalendar fontSize="1.5em" className="text-amber-400" />

		case 'supplied':
			return <HiExclamationCircle fontSize="1.5em" className="text-red-400" />

		case 'requested':
			return <HiMail fontSize="1.5em" className="text-blue-500" />
	}

	return null
}
