import { useCallback, useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'

export function useDraft<T>(data: T) {
	const [draft, setDraft] = useState<Partial<T>>(cloneDeep(data))
	const [hasChanges, setHasChanges] = useState(false)

	useEffect(() => {
		setDraft(cloneDeep(data))
	}, [data])

	useEffect(() => {
		const changes = !isEqual(data ?? {}, draft)
		setHasChanges(changes)
	}, [draft, data])

	const update = useCallback((changes: Partial<T>) => {
		setDraft((obj) => ({ ...obj, ...changes }))
	}, [])

	const clear = useCallback(() => {
		setDraft({})
	}, [])

	const cancel = useCallback(() => {
		setDraft(cloneDeep(data))
	}, [data])

	return { value: draft, update, hasChanges, clear, cancel }
}
