import { Spinner } from '@nextui-org/react'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import { createCandidate } from '@requests/candidates'
import { rootStore } from '@store'

import type { ICandidate } from '@touchpoints/requests'
import { createSelectOnChange, IconHeader, Select2, SelectOption } from '@touchpoints/ui'
import { CandidateForm } from './CandidateForm'

import { AddCandidateIcon } from '@touchpoints/icons'
import { useReaction } from '@touchpoints/mobx-hooks'
import type { PositionExpanded } from '@types'
import { makeAutoObservable } from 'mobx'
import { CandidateStageColorDot } from './CandidateStageColorDot'

type Props = {
	onCreated?: (candidate: ICandidate) => void
	onCancel?: () => void
}

function toPositionOption(position: PositionExpanded & { referenceName: string }): SelectOption {
	return {
		value: position.id,
		label: `${position.referenceName} - ${position.name}`,
		searchString: `${position.referenceName} - ${position.name}`,
	}
}

function emptyPositionOption() {
	return { value: 'none', label: 'None' }
}

const store = makeAutoObservable({
	positionId: '',
	setPositionId(id: string) {
		this.positionId = id
	},
	stageId: '',
	setStageId(id: string) {
		this.stageId = id
	},
})

export const CreateCandidateForm = observer(function ({ onCreated, onCancel }: Props) {
	const [data, setData] = useState<Partial<ICandidate>>({
		sourcer: rootStore.organizationUsers.activeOrgUserId,
	})
	const positionId = useReaction(() => store.positionId)
	const stageId = useReaction(() => store.stageId)
	const [loading, setLoading] = useState(false)

	const handleCreateCandidate = async () => {
		const orgId = rootStore.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const {
			firstName,
			lastName,
			nickname,
			email,
			skills,
			phone,
			timezone,
			notes,
			resubmittable,
			mainContactId,
			linkedinUrl,
			recentEmployer,
			recentJobTitle,
			sourcer,
		} = data

		if (!firstName || !lastName || !email) {
			return
		}

		setLoading(true)

		const res = await createCandidate(orgId, {
			firstName,
			lastName,
			nickname,
			email,
			skills,
			phone,
			timezone,
			addToPositionId: positionId,
			notes,
			resubmittable,
			mainContactId,
			stageId: stageId,
			linkedinUrl,
			recentEmployer,
			recentJobTitle,
			sourcer,
		})

		setLoading(false)

		if (!res.data) {
			return
		}

		const { candidate, positionCandidate } = res.data
		rootStore.candidates.addCandidate(candidate)

		if (positionCandidate) {
			rootStore.positions.addPositionCandidate(positionCandidate)
			const position = rootStore.positions.getPositionById(positionCandidate.positionId)
			if (position) {
				rootStore.positions.addCandidate(position.id, positionCandidate.candidateId)
			}
		}

		setData({})

		onCreated?.(candidate)
	}

	const selectedPosition = positionId
		? rootStore.positions.getPositionById(positionId)
		: undefined

	const positions = [emptyPositionOption(), ...rootStore.positions.list.map(toPositionOption)]

	return (
		<div className="flex h-full flex-col space-y-4 justify-between">
			<div className="p-3 border-b sticky top-0 bg-white rounded-t-md">
				<IconHeader icon={<AddCandidateIcon />} message={'Add new candidate'} />
			</div>
			<div className="grow">
				<CandidateForm
					mode="create"
					candidate={data}
					onFieldChange={(field, val) => {
						setData({
							...data,
							[field]: val,
						})
					}}
				/>
				<div className="p-3 flex space-x-3 items-center justify-end">
					<div className="flex flex-col space-y-4 w-full">
						<div className="flex items-center space-x-2 w-auto justify-start">
							<Select2
								value={
									selectedPosition ? toPositionOption(selectedPosition).label : ''
								}
								label="Add to Position"
								options={positions}
								onChange={createSelectOnChange<string | undefined>((v) =>
									store.setPositionId(v ?? '')
								)}
								filterable
								inputPlaceholder="Search position"
								placeholder={'Select position'}
							/>

							<Select2
								label="Stage"
								value={
									rootStore.stages.candidates.find((s) => s.id === stageId)
										?.name ?? ''
								}
								placeholder={'Select stage'}
								onChange={(v) => store.setStageId(v.value)}
								options={rootStore.stages.candidates.map((u) => {
									return {
										value: u.id,
										label: (
											<span className="flex items-center space-x-1">
												<CandidateStageColorDot color={u.color} />
												&nbsp; {u.name}
											</span>
										),
										searchString: u.name,
									}
								})}
								filterable
								inputPlaceholder="Search stage"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="sticky bottom-0 border-t p-3 space-x-3 rounded-b-md bg-white flex justify-end">
				<button
					className="px-3 py-2 border rounded-md font-light hover:bg-slate-50 cursor-pointer"
					onClick={onCancel}
					disabled={loading}
				>
					Cancel
				</button>
				<button
					className="px-3 py-2 border rounded-md font-light text-white bg-blue-600 hover:bg-blue-500 cursor-pointer"
					onClick={handleCreateCandidate}
					disabled={loading}
				>
					{loading ? <Spinner /> : 'Create'}
				</button>
			</div>
		</div>
	)
})
