import { Loading } from '@nextui-org/react'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import { HiCloudUpload } from 'react-icons/hi'

type DropFileProps = {
	uploading: boolean
	message?: string
	messageWhenDragActive?: string
} & DropzoneOptions

export function Dropzone({
	message = `Drag 'n' drop some files here, or click to select files`,
	messageWhenDragActive = 'Drop the files here ...',
	...options
}: DropFileProps) {
	const { getRootProps, getInputProps, isDragActive } = useDropzone(options)

	return (
		<div
			className="w-full border-2 border-gray-500 border-dashed border-opacity-40 rounded-md"
			{...getRootProps()}
		>
			{options.uploading ? (
				<div className="flex flex-col h-48 p-3">
					<p className="self-center">Uploading files...</p>
					<Loading className="self-center m-4 animate-bounce w-6 h-6" size="sm" />
				</div>
			) : (
				<div>
					<input {...getInputProps()} />
					{isDragActive ? (
						<div className="flex flex-col p-3 h-48">
							<p className="self-center">{messageWhenDragActive}</p>
						</div>
					) : (
						<div className="flex flex-col p-3 h-48">
							{message && <p className="self-center">{message}</p>}
							<HiCloudUpload
								className="self-center m-4 animate-bounce w-6 h-6"
								size={30}
								color="gray"
							></HiCloudUpload>
						</div>
					)}
				</div>
			)}
		</div>
	)
}
