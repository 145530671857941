import { Button, FormElement, Input } from '@nextui-org/react'
import { useEffect, useState } from 'react'
import { HiClipboardCopy, HiPlus } from 'react-icons/hi'
import { toast } from '@touchpoints/ui'

type ListInputProps = {
	label?: string | React.ReactNode
	values: string[]
	onValuesChanged?: (values: string[]) => void
	readOnly?: boolean
}
export function ListInput({ label, values, onValuesChanged, readOnly = false }: ListInputProps) {
	const [list, setList] = useState([''])

	useEffect(() => {
		if (values && values.length > 0) {
			setList(values)
		}
	}, [values])

	const handleAdd = () => {
		values.push('')
		onValuesChanged?.([...values])
	}

	const handleValueChanged = (idx: number, v: string) => {
		values[idx] = v
		onValuesChanged?.([...values])
	}

	const handleEnterKey = (e: React.KeyboardEvent<FormElement>, idx: number) => {
		if (e.key === 'Enter') {
			handleAdd()
		} else if (e.key === 'Backspace' && !values[idx]) {
			values.splice(idx, 1)
			onValuesChanged?.([...values])
		}
	}

	const handleCopy = (value: string) => {
		navigator.clipboard.writeText(value)
		toast.success('Copied to clipboard!')
	}

	return (
		<div className="flex flex-col w-full">
			{label && <div className="text-sm text-gray-800 mb-2 ml-1">{label}</div>}
			<div className="flex space-x-2">
				<div className="flex flex-col flex-grow space-y-2">
					{list.map((v, idx) => (
						<Input
							aria-label={`list number ${idx}`}
							bordered
							key={`${idx}`}
							value={v}
							fullWidth
							readOnly={readOnly}
							placeholder="additional information..."
							onInput={(e) => handleValueChanged(idx, e.currentTarget.value)}
							onKeyUp={(e) => handleEnterKey(e, idx)}
							contentRightStyling={false}
							contentRight={
								<HiClipboardCopy
									className="m-2 cursor-pointer text-xl hover:opacity-60"
									onClick={() => handleCopy(v)}
								/>
							}
						/>
					))}
				</div>
				{!readOnly && (
					<div className="flex flex-shrink items-end">
						<Button auto light onClick={handleAdd}>
							<HiPlus />
						</Button>
					</div>
				)}
			</div>
		</div>
	)
}
