import { CandidateAvatar } from '@components/shared/OrgUserAvatar'
import { rootStore } from '@store'
import { useReaction } from '@touchpoints/mobx-hooks'
import { ICandidate } from '@touchpoints/requests'
import { useEffect } from 'react'
import { store } from '../accounts/store'
import { Tooltip } from '@nextui-org/react'
import { Pill } from '@touchpoints/ui'
import { NotificationsPopover } from './NotificationsPopover'

export function RecentBar() {
	const recentPositionCandidateIds = useReaction(() => rootStore.recent.positionCandidateIds)
	const recentPositionCandidates = useReaction(() => rootStore.recent.positionCandidates)
	const candidatesFromPositionCandidates = useReaction(
		() => {
			return recentPositionCandidates
				.map((pc) => {
					return rootStore.candidates.getCandidateById(pc.candidateId)
				})
				.filter((c) => c) as ICandidate[]
		},
		100,
		[recentPositionCandidates]
	)

	useEffect(() => {
		for (const pcId of recentPositionCandidateIds) {
			rootStore.positions.fetchPositionCandidateById(pcId, { priority: 1 })
		}
	}, [recentPositionCandidateIds])

	const handleOpenPositionCandidate = (idx: number) => {
		const pc = recentPositionCandidates[idx]
		if (!pc) {
			return
		}

		store.setSelectedPositionCandidateId(pc.id)
	}

	return (
		<div className="absolute top-0 right-0 left-0 h-10 z-50">
			<div className="flex w-full justify-end pointer-events-auto">
				<div className="flex p-2">
					{candidatesFromPositionCandidates.map((c, idx) => {
						const pc = recentPositionCandidates[idx]
						const position = rootStore.positions.getPositionById(pc.positionId)
						return (
							<Tooltip
								content={
									<div className="flex items-center whitespace-nowrap">
										{c.firstName} {c.lastName}{' '}
										{position ? (
											<Pill size="xs" className="p-1 px-2">
												{position.referenceName}
											</Pill>
										) : (
											''
										)}
									</div>
								}
								shadow
								placement="bottomEnd"
								key={`${idx}`}
								onClick={() => handleOpenPositionCandidate(idx)}
							>
								<div className="cursor-pointer border-2 border-white hover:border-neutral-800 transition-transform duration-200 ease-in-out rounded-full shadow-md">
									<CandidateAvatar candidate={c} size="sm" />
								</div>
							</Tooltip>
						)
					})}
					<div className="flex items-center justify-center ml-3">
						<NotificationsPopover />
					</div>
				</div>
			</div>
		</div>
	)
}
