import { removeCandidateFromSequence as removeFromSequence } from '../requests/candidates'

import { rootStore } from '../store'

import type { ICandidate, ISequence } from '@touchpoints/requests'

export const removeCandidateFromSequence = async (
	candidate: ICandidate,
	sequence: ISequence,
	instanceId: string
) => {
	const orgId = rootStore.organizations.activeOrganizationId
	if (!orgId) {
		return
	}
	const res = await removeFromSequence(orgId, sequence.id, candidate.id, instanceId)
	if (!res.success) {
		console.error(res.message)
		return
	}
	rootStore.candidates.removeSequenceFromCandidate(candidate.id, sequence.id)

	const instances = rootStore.sequences.getSequenceInstanceByCandidateId(candidate.id)
	const instance = instances.find((i) => i.id === instanceId)
	if (instance) {
		rootStore.sequences.removeSequenceInstance(instance)
	}
}
