export function MeetingsIcon() {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.5 5.50016H12.5M4.16667 2.16683V0.833496M9.83333 2.16683V0.833496M3.5 12.5002H10.5C11.6046 12.5002 12.5 11.6047 12.5 10.5002V4.16683C12.5 3.06226 11.6046 2.16683 10.5 2.16683H3.5C2.39543 2.16683 1.5 3.06226 1.5 4.16683V10.5002C1.5 11.6047 2.39543 12.5002 3.5 12.5002Z"
				stroke="#687076"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
