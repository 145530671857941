export function WarningIcon({ size = 20 }: { size?: number }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 20 21"
			fill="none"
		>
			<g id="error, warning, alert">
				<path
					id="Icon"
					d="M10 8.01597V10.5112M8.56371 3.8253L2.72901 13.8317C2.08238 14.9407 2.88197 16.3333 4.1653 16.3333H15.8347C17.118 16.3333 17.9176 14.9407 17.271 13.8317L11.4363 3.8253C10.7947 2.7249 9.20535 2.7249 8.56371 3.8253Z"
					stroke="#F76808"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<rect
					id="Icon_2"
					x="8.95898"
					y="11.957"
					width="2.08333"
					height="2.08333"
					rx="1.04167"
					fill="#F76808"
				/>
			</g>
		</svg>
	)
}
