import { makeAutoObservable } from 'mobx'
import type { RootStore } from './root'
import { IPositionCandidate } from '@touchpoints/requests'
import * as localData from '../services/localData'

export class RecentStore {
	private readonly root: RootStore

	positionCandidateIds: string[] = []

	constructor(rootStore: RootStore) {
		this.root = rootStore

		this.positionCandidateIds = localData.get().recentPositionCandidateIds ?? []

		makeAutoObservable(this)
	}

	get positionCandidates() {
		return this.positionCandidateIds
			.map((id) => this.root.positions.getPositionCandidateById(id))
			.filter((pc) => pc) as IPositionCandidate[]
	}

	addPositionCandidateId(id: string) {
		if (!id) {
			return
		}

		if (this.positionCandidateIds[0] === id) {
			return
		}

		if (this.positionCandidateIds.includes(id)) {
			// remove and re-add
			const idx = this.positionCandidateIds.indexOf(id)
			this.positionCandidateIds.splice(idx, 1)
		}

		this.positionCandidateIds.unshift(id)

		if (this.positionCandidateIds.length > 3) {
			this.positionCandidateIds.pop()
		}

		localData.store({ recentPositionCandidateIds: [...this.positionCandidateIds] })
	}

	removePositionCandidateId(id: string) {
		const idx = this.positionCandidateIds.indexOf(id)
		if (idx < 0) {
			return
		}

		this.positionCandidateIds.splice(idx, 1)

		localData.store({ recentPositionCandidateIds: [...this.positionCandidateIds] })
	}
}
