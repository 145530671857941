import { Tooltip } from '@nextui-org/react'

import { formatTimeDifference } from '@touchpoints/time'
import { format } from 'date-fns'
import { ReactNode } from 'react'

export interface ActivityItem {
	icon: ReactNode
	message: ReactNode
	ts: number
	after?: ReactNode
}

type ActivityWrapperProps = {
	items: ActivityItem[]
}
export const ActivityWrapper = ({ items }: ActivityWrapperProps) => {
	return (
		<div className="flex flex-col space-y-1 w-full">
			{items.map((item, index) => (
				<div className="flex flex-col" key={`activity-${item.ts}-${index}`}>
					<div className="flex space-x-4">
						<div className="grow flex flex-col items-center min-h-[30px] mt-1">
							<div className="w-5 h-5">{item.icon}</div>
							{index !== items.length - 1 && (
								<div className="grow min-h-12 h-auto mt-1 border-l-[0.5px] border-slate-200 dark:border-slate-600"></div>
							)}
						</div>
						<div className="w-full h-full min-h-[50px] flex space-x-1 items-start pt-0.5">
							<div>{item.message}</div>
							<div className="flex items-center space-x-1 pt-0.5">
								<div className="w-1 h-1 rounded-full bg-[#C1C8CD]" />
								<Tooltip
									content={format(new Date(item.ts), "MMM d, yyyy 'at' h:mm a")}
								>
									<span className="text-sm text-slate-400">
										{formatTimeDifference(new Date(item.ts))}
									</span>
								</Tooltip>
								<div>{item.after}</div>
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	)
}
