import React from 'react'
import DataTable, { TableColumn, TableProps } from 'react-data-table-component'

type Props<RowType> = {
	columns: TableColumn<RowType>[]
	rows?: RowType[]
	selectable?: boolean
	onSelectedRowsChange?: (selected: {
		allSelected: boolean
		selectedCount: number
		selectedRows: RowType[]
	}) => void
	onRowClicked?: (row: RowType, e: React.MouseEvent) => void
	highlightOnHover?: boolean
} & Omit<TableProps<RowType>, 'data'>

export function Table<RowType>({
	columns,
	rows = [],
	selectable = false,
	onSelectedRowsChange,
	onRowClicked,
	highlightOnHover,
	customStyles,
	...props
}: Props<RowType>) {
	return (
		<DataTable
			columns={columns}
			data={rows}
			selectableRows={selectable}
			onSelectedRowsChange={onSelectedRowsChange}
			onRowClicked={onRowClicked}
			highlightOnHover={highlightOnHover ?? !!onRowClicked}
			customStyles={{
				table: {
					style: {
						maxWidth: '100%',
					},
				},
				rows: {
					style: {
						cursor: onRowClicked ? 'pointer' : 'auto',
					},
				},
				...customStyles,
			}}
			{...props}
		/>
	)
}

export type { TableColumn }
