import type { ICandidate } from '@touchpoints/requests'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

type EmailSourceProps = {
	candidate: ICandidate
	email: string
	size?: 'sm' | 'md'
}
export function EmailSource({ candidate, email, size = 'md' }: EmailSourceProps) {
	const [sources, setSources] = useState<{ name: string; color: string }[]>([])

	useEffect(() => {
		const emailSources = candidate._emailSources ?? {}
		const keys = Object.keys(emailSources)
		const sourceNames: { name: string; color: string }[] = []

		for (const key of keys) {
			if (!emailSources[key].includes(email)) {
				continue
			}
			const color = key === 'pdl' ? 'bg-blue-500' : 'bg-amber-500'
			sourceNames.push({ name: key, color: color })
		}

		setSources(sourceNames)
	}, [candidate, email])

	if (Array.from(sources.entries()).length === 0) {
		return null
	}
	return (
		<div className="flex items-end space-x-1 max-h-[66px]">
			{sources.map((source) => (
				<div
					key={source.name}
					className={clsx('rounded-xl px-3 text-white uppercase', source.color, {
						'py-2': size === 'md',
						'py-1': size === 'sm',
					})}
				>
					{source.name}
				</div>
			))}
		</div>
	)
}
