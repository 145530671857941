import * as methods from './methods'
import { result } from './utils'

import type { IApiResponse, JSONObject } from '../types'

function createUrl(apiUrl: string, path: string) {
	return `${apiUrl}/${path.replace('/', '')}`
}

export function makeApiResponse<DataType = JSONObject>(
	status: number,
	success: boolean,
	message?: string,
	data?: DataType
) {
	const res: IApiResponse<DataType | undefined> = {
		status,
		success,
		message,
		data,
	}

	return res
}

export async function responseFrom<DataType = JSONObject>(res: Response) {
	const [data, err] = await result<Partial<IApiResponse<DataType>> | undefined>(res.json())
	if (!data || err) {
		return makeApiResponse<DataType>(res.status, false, 'no response')
	}

	return makeApiResponse<DataType>(res.status, data.success ?? res.ok, data.message, data.data)
}

async function noopAuthHeader() {
	return {}
}

// NOTE: the below can likely be created from a higher order function
// to reduce the repetition in each function

export function makeGet(
	apiUrl: string,
	didRefreshToken?: (res: Response, headers: Record<string, string>) => Promise<boolean>,
	authHeader: () => Promise<Record<string, string>> = noopAuthHeader
) {
	let retries = 0
	return async function get<DataType = JSONObject>(
		path: string,
		params: Record<string, string | number | boolean | undefined | string[]> = {},
		headers: Record<string, string> = {}
	): Promise<IApiResponse<DataType | undefined>> {
		const res = await methods.get(createUrl(apiUrl, path), params, {
			...headers,
			...(await authHeader()),
		})
		if (retries < 3 && didRefreshToken && (await didRefreshToken(res, headers))) {
			++retries
			// retry if refreshed token
			return get(path, params, { ...headers, ...(await authHeader()) })
		}
		return responseFrom<DataType>(res)
	}
}

export function makePost(
	apiUrl: string,
	didRefreshToken?: (res: Response, headers: Record<string, string>) => Promise<boolean>,
	authHeader: () => Promise<Record<string, string>> = noopAuthHeader,
	postFunction: typeof methods.post = methods.post
) {
	let retries = 0
	return async function post<DataType = JSONObject>(
		path: string,
		body: JSONObject | FormData = {},
		headers: Record<string, string> = {}
	): Promise<IApiResponse<DataType | undefined>> {
		const res = await postFunction(createUrl(apiUrl, path), body, {
			...headers,
			...(await authHeader()),
		})
		if (retries < 3 && didRefreshToken && (await didRefreshToken(res, headers))) {
			++retries
			// retry if refreshed token
			return post(path, body, { ...headers, ...(await authHeader()) })
		}
		return responseFrom<DataType>(res)
	}
}

export function makePut(
	apiUrl: string,
	didRefreshToken?: (res: Response, headers: Record<string, string>) => Promise<boolean>,
	authHeader: () => Promise<Record<string, string>> = noopAuthHeader
) {
	let retries = 0
	return async function put<DataType = JSONObject>(
		path: string,
		body: JSONObject = {},
		headers: Record<string, string> = {}
	): Promise<IApiResponse<DataType | undefined>> {
		const res = await methods.put(createUrl(apiUrl, path), body, {
			...headers,
			...(await authHeader()),
		})
		if (retries < 3 && didRefreshToken && (await didRefreshToken(res, headers))) {
			++retries
			// retry if refreshed token
			return put(path, body, { ...headers, ...(await authHeader()) })
		}
		return responseFrom<DataType>(res)
	}
}

export function makePatch(
	apiUrl: string,
	didRefreshToken?: (res: Response, headers: Record<string, string>) => Promise<boolean>,
	authHeader: () => Promise<Record<string, string>> = noopAuthHeader
) {
	let retries = 0
	return async function patch<DataType = JSONObject>(
		path: string,
		body: JSONObject = {},
		headers: Record<string, string> = {}
	): Promise<IApiResponse<DataType | undefined>> {
		const res = await methods.patch(createUrl(apiUrl, path), body, {
			...headers,
			...(await authHeader()),
		})
		if (retries < 3 && didRefreshToken && (await didRefreshToken(res, headers))) {
			++retries
			// retry if refreshed token
			return patch(path, body, { ...headers, ...(await authHeader()) })
		}
		return responseFrom<DataType>(res)
	}
}

export function makeDel(
	apiUrl: string,
	didRefreshToken?: (res: Response, headers: Record<string, string>) => Promise<boolean>,
	authHeader: () => Promise<Record<string, string>> = noopAuthHeader
) {
	let retries = 0
	return async function del<DataType = JSONObject>(
		path: string,
		headers: Record<string, string> = {},
		body: JSONObject = {}
	): Promise<IApiResponse<DataType | undefined>> {
		const res = await methods.del(
			createUrl(apiUrl, path),
			{ ...headers, ...(await authHeader()) },
			body
		)
		if (retries < 3 && didRefreshToken && (await didRefreshToken(res, headers))) {
			++retries
			// retry if refreshed token
			return del(path, { ...headers, ...(await authHeader()) }, body)
		}
		return responseFrom<DataType>(res)
	}
}

export function makeAuthHeader(getToken: () => Promise<string>) {
	return async function authHeader() {
		const accessToken = await getToken()
		return {
			Authorization: `Bearer ${accessToken}`,
		}
	}
}

export function makeRefreshToken(apiUrl: string) {
	return async function refreshToken(id: string, refreshToken: string) {
		const res = await methods.post(`${apiUrl}/auth/refreshToken`, {
			id,
			refreshToken,
		})
		return responseFrom<{ accessToken?: string }>(res)
	}
}

export function makeDidRefreshToken(
	refreshToken: () => Promise<IApiResponse<{ accessToken?: string } | undefined>>,
	onSuccess?: (accessToken: string) => Promise<void>
) {
	return async function didRefreshToken(res: Response, headers: Record<string, string>) {
		if (res.status === 401 && headers['Authorization']) {
			// token may have expired so let's refresh
			const refreshRes = await refreshToken()

			const { accessToken } = refreshRes.data ?? {}
			if (refreshRes.success && accessToken) {
				await onSuccess?.(accessToken)
				return true
			}
		}

		return false
	}
}

export { result }
