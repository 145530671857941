import clsx from 'clsx'

type Props = {
	color?: string
	className?: string
	onClick?: (color: string) => void
	size?: 'sm' | 'md' | 'lg'
	noMargin?: boolean
	noRing?: boolean
}
export function CandidateStageColorDot({
	color = '#000000',
	className,
	onClick,
	size = 'sm',
	noMargin = false,
	noRing = false,
}: Props) {
	return (
		<div
			onClick={() => {
				onClick?.(color)
			}}
			className={clsx(
				{ 'w-3': size === 'sm', 'h-3': size === 'sm' },
				{ 'w-5': size === 'md', 'h-5': size === 'md' },
				{ 'w-8': size === 'lg', 'h-8': size === 'lg' },
				{ 'ml-3': !noMargin },
				{ 'ring-2 ring-white': !noRing },
				'rounded-full min-w-[12px]',
				{ 'cursor-pointer': !!onClick },
				className
			)}
			style={{ background: color }}
		/>
	)
}
