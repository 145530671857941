import {
	IPositionCandidate,
	IPositionCandidateActionItem,
	IPositionCandidateChecklist,
	IPositionCandidateQuestionForm,
	IPositionCandidateQuestionItem,
	PositionCandidateQuestionOption,
} from '@touchpoints/requests'
import { observer } from 'mobx-react-lite'

import { DoneIcon } from '@components/tasks/icons'
import { Button, Checkbox, Loading, Textarea } from '@nextui-org/react'
import { rootStore } from '@store'
import { QuestionIcon } from '@touchpoints/icons'
import { IconHeader, useConfirm } from '@touchpoints/ui'
import { action, makeAutoObservable, runInAction } from 'mobx'
import { useCallback, useEffect, useState } from 'react'
import { HiLightningBolt, HiTrash } from 'react-icons/hi'
import { MdKeyboardCommandKey } from 'react-icons/md'
import { useReaction } from '@touchpoints/mobx-hooks'
import { useRouter } from 'next/router'
import { store as cardDetailedViewStore } from '../accounts/store'

const ChecklistActionItem = observer(function ({
	positionCandidate,
	item,
	onComplete,
	readOnly = false,
	canDelete = false,
	handleDeleteAction,
	handleGoToTriggerAction,
}: {
	positionCandidate: IPositionCandidate
	item: IPositionCandidateChecklist
	onComplete?: () => void
	readOnly?: boolean
	canDelete?: boolean
	handleDeleteAction?: () => void
	handleGoToTriggerAction?: (item: IPositionCandidateChecklist) => void
}) {
	const router = useRouter()
	const isAdmin = useReaction(() => rootStore.organizationUsers.activeUser?.role === 'admin')

	const handleChecklistItemChanged = async (checklistItemId: string, value: boolean) => {
		const checklistItem = item.items.find((i) => i.id === checklistItemId)
		if (!checklistItem) {
			return
		}

		if (checklistItem.tieToTask) {
			router.push(`/tasks/${checklistItem.taskId}`)
			return
		}

		if (checklistItem.completed) {
			// cannot uncheck a completed item
			return
		}

		// optimistically update immediately
		runInAction(() => {
			checklistItem.completed = value
		})

		await rootStore.positions.updateCandidateChecklistItem(
			positionCandidate.candidateId,
			positionCandidate.positionId,
			item.id,
			checklistItemId,
			value
		)

		onComplete?.()
	}

	return (
		<div className="flex flex-col space-y-2">
			<IconHeader icon={<QuestionIcon />} message={item.title}>
				{canDelete && !readOnly && (
					<HiTrash
						className="w-4 h-4 cursor-pointer fill-red-700 hover:fill-black"
						onClick={handleDeleteAction}
					/>
				)}
				{''}
				{isAdmin && !readOnly && handleGoToTriggerAction && (
					<HiLightningBolt
						className="w-4 h-4 cursor-pointer fill-amber-600 hover:fill-black"
						onClick={() => handleGoToTriggerAction?.(item)}
					/>
				)}
			</IconHeader>

			<div className="ml-4 flex flex-col space-y-1 z-0">
				{item.items.map((checklistItem) => (
					<Checkbox
						isReadOnly={readOnly || checklistItem.completed}
						aria-label="Items to complete"
						key={checklistItem.id}
						size="sm"
						lineThrough={checklistItem.completed}
						isSelected={checklistItem.completed}
						onChange={(e) => handleChecklistItemChanged(checklistItem.id, e)}
					>
						<p className="ml-2 text-sm font-normal">{checklistItem.value}</p>

						{!checklistItem.required ? ' (optional)' : ''}
						{checklistItem.tieToTask ? (
							<span className="py-0.5 px-1 text-xs bg-slate-200 rounded-xl mx-2">
								Tied to task
							</span>
						) : null}
					</Checkbox>
				))}
			</div>
		</div>
	)
})

function makeQuestionState() {
	return makeAutoObservable(
		{
			questionIndex: 0,
			questionsCount: 0,
			loading: false,
			setQuestionIndex(idx: number) {
				this.questionIndex = idx
			},
			setQuestionsCount(count: number) {
				this.questionsCount = count
			},
			setLoading(loading: boolean) {
				this.loading = loading
			},
		},
		{
			setQuestionIndex: action,
			setQuestionsCount: action,
		}
	)
}

const questionStateById: Record<string, ReturnType<typeof makeQuestionState>> = {}

type QuestionProgressProps = {
	item: IPositionCandidateQuestionForm
}
const QuestionProgress = observer(function ({ item }: QuestionProgressProps) {
	const questionState = useQuestionState(item)

	return (
		<p className="text-sm h-full items-center flex">
			{questionState.questionIndex + 1} / {questionState.questionsCount}
		</p>
	)
})

type NextButtonProps = {
	disabled?: boolean
	onNext?: () => void
	item: IPositionCandidateQuestionForm
}
const NextButton = observer(function ({ disabled, onNext, item }: NextButtonProps) {
	const questionState = useQuestionState(item)

	const keydownHandler = useCallback(
		(e: any) => {
			if (e && e.metaKey && e.key === 'Enter') {
				onNext?.()
			}
		},
		[onNext]
	)

	useEffect(() => {
		if (typeof document !== 'undefined') {
			document.addEventListener('keydown', keydownHandler)
		}

		return () => {
			if (typeof document !== 'undefined') {
				document.removeEventListener('keydown', keydownHandler)
			}
		}
	}, [keydownHandler, questionState])

	return (
		<div className="flex items-center space-x-2">
			<Button
				aria-label="Next"
				bordered={questionState.loading}
				auto
				disabled={disabled}
				onClick={onNext}
				css={{ background: '#0091FF' }}
			>
				{questionState.loading ? <Loading /> : 'Next'}
			</Button>
			{!disabled && (
				<div className="flex items-center ml-2 text-slate-300">
					<MdKeyboardCommandKey size={20} />
					&nbsp; + Enter
				</div>
			)}
		</div>
	)
})

function useQuestionState(item: IPositionCandidateQuestionForm) {
	if (!questionStateById[item.id]) {
		questionStateById[item.id] = makeQuestionState()
	}

	return questionStateById[item.id]
}

const QuestionActionItem = observer(function ({
	positionCandidate,
	item,
	onComplete,
	canDelete = false,
	handleDeleteAction,
	handleGoToTriggerAction,
}: {
	positionCandidate: IPositionCandidate
	item: IPositionCandidateQuestionForm
	onComplete?: () => void
	canDelete?: boolean
	handleDeleteAction?: () => void
	handleGoToTriggerAction?: (item: IPositionCandidateQuestionForm) => void
}) {
	const [questions, setQuestions] = useState(item.questions.map((q) => ({ ...q })))
	const questionState = useQuestionState(item)
	const isAdmin = useReaction(() => rootStore.organizationUsers.activeUser?.role === 'admin')

	questionState.setQuestionsCount(questions.length)

	useEffect(() => {
		const idx = item.questions.findIndex((q) => !q.completed)
		questionState.setQuestionIndex(idx)
	}, [item.questions, questionState])

	const handleQuestionItemChanged = async (
		questionItemId: string,
		value: { text?: string; options?: PositionCandidateQuestionOption[] }
	) => {
		const question = questions.find((i) => i.id === questionItemId)
		if (!question) {
			return
		}

		question.text = value.text
		question.options = value.options
		setQuestions([...questions])
	}

	const handleNext = async () => {
		const question = questions[questionState.questionIndex]
		if (!question) {
			return
		}

		questionState.setLoading(true)

		// optimistically update immediately
		question.completed = true

		await rootStore.positions.updateCandidateQuestionItem(
			positionCandidate.candidateId,
			positionCandidate.positionId,
			item.id,
			question.id,
			{ text: question.text, options: question.options }
		)

		questionState.setLoading(false)

		onComplete?.()
	}

	const activeQuestionComponent = (question: IPositionCandidateQuestionItem) => {
		if (!question) {
			return null
		}

		switch (question.type) {
			case 'text':
				return (
					<TextQuestionItem
						key={question.id}
						item={item}
						question={question}
						onChange={(v) => handleQuestionItemChanged(question.id, { text: v })}
						onNext={handleNext}
					/>
				)
			case 'single':
				return (
					<SingleQuestionItem
						key={question.id}
						item={item}
						question={question}
						onChange={(options) => handleQuestionItemChanged(question.id, { options })}
						onNext={handleNext}
					/>
				)
			case 'multiple':
				return (
					<MultipleQuestionItem
						key={question.id}
						item={item}
						question={question}
						onChange={(options) => handleQuestionItemChanged(question.id, { options })}
						onNext={handleNext}
					/>
				)
		}
	}

	const currentQuestion = questions[questionState.questionIndex]
	return (
		<div className="flex flex-col">
			<IconHeader icon={<QuestionIcon />} message={currentQuestion?.title}>
				{canDelete && (
					<HiTrash
						className="w-4 h-4 cursor-pointer fill-red-700 hover:fill-black"
						onClick={handleDeleteAction}
					/>
				)}{' '}
				{isAdmin && handleGoToTriggerAction && (
					<HiLightningBolt
						className="w-4 h-4 cursor-pointer fill-amber-600 hover:fill-black"
						onClick={() => handleGoToTriggerAction?.(item)}
					/>
				)}
			</IconHeader>
			{activeQuestionComponent(currentQuestion)}
		</div>
	)
})

const CompletedQuestionActionItem = observer(function ({
	item,
}: {
	item: IPositionCandidateQuestionForm
}) {
	const [questions] = useState(item.questions.map((q) => ({ ...q })))

	const determineQuestionComponent = (question: IPositionCandidateQuestionItem) => {
		switch (question.type) {
			case 'text':
				return (
					<TextQuestionItem key={question.id} item={item} question={question} readOnly />
				)
			case 'single':
				return (
					<SingleQuestionItem
						key={question.id}
						item={item}
						question={question}
						readOnly
					/>
				)
			case 'multiple':
				return (
					<MultipleQuestionItem
						key={question.id}
						item={item}
						question={question}
						readOnly
					/>
				)
		}
	}

	return (
		<div className="flex flex-col">
			<div className="flex flex-col space-y-3 w-full">
				{questions.map((q, idx) => {
					return (
						<div key={`q-${idx}`} className="flex flex-col">
							<IconHeader icon={<QuestionIcon />} message={q?.title} />
							{determineQuestionComponent(q)}
						</div>
					)
				})}
			</div>
		</div>
	)
})

type TextQuestionProps = {
	item: IPositionCandidateQuestionForm
	question: IPositionCandidateQuestionItem
	onChange?: (value: string) => void
	onNext?: () => void
	readOnly?: boolean
}
const TextQuestionItem = observer(function ({
	item,
	question,
	onChange,
	onNext,
	readOnly = false,
}: TextQuestionProps) {
	return (
		<div className="flex flex-col space-y-3 w-full">
			<p className="text-md font-medium leading-6">{question.title}</p>
			<Textarea
				readOnly={readOnly}
				aria-label="Question text"
				value={question.text ?? ''}
				onChange={(e) => onChange?.(e.currentTarget.value)}
			/>
			{onNext && (
				<div className="flex justify-between">
					<QuestionProgress item={item} />
					<NextButton item={item} disabled={!question.text} onNext={onNext} />
				</div>
			)}
		</div>
	)
})

type OptionQuestionProps = {
	item: IPositionCandidateQuestionForm
	question: IPositionCandidateQuestionItem
	onChange?: (options: PositionCandidateQuestionOption[]) => void
	onNext?: () => void
	readOnly?: boolean
}
const SingleQuestionItem = observer(function ({
	item,
	question,
	onChange,
	onNext,
}: OptionQuestionProps) {
	const selected = question.options?.find((o) => o.selected)

	const handleSelectionChanged = (id: string) => {
		const options = question.options?.map((o) => ({
			...o,
			selected: o.id === id,
		}))
		onChange?.(options ?? [])
	}

	return (
		<div className="flex flex-col space-y-3 w-full">
			{question.options?.map((option) => (
				<div
					key={option.id}
					className="flex items-center ml-4 space-x-2 p-1 hover:cursor-pointer"
					onClick={() => handleSelectionChanged(option.id)}
				>
					{selected?.id === option.id && (
						<div className="w-4">
							<DoneIcon size={15} />
						</div>
					)}
					{selected?.id !== option.id && (
						<div className="w-4 h-4 rounded-full bg-slate-50 border" />
					)}
					<p className="ml-2 text-sm font-normal">{option.text}</p>
				</div>
			))}

			{onNext && (
				<div className="flex space-x-2">
					<NextButton item={item} disabled={!selected} onNext={onNext} />
				</div>
			)}
		</div>
	)
})

const MultipleQuestionItem = observer(function ({
	item,
	question,
	onChange,
	onNext,
	readOnly = false,
}: OptionQuestionProps) {
	const selected = question.options?.filter((o) => o.selected).map((o) => o.id)

	const handleSelectedChanged = (ids: string[]) => {
		const options = question.options?.map((o) => ({
			...o,
			selected: ids.includes(o.id),
		}))
		onChange?.(options ?? [])
	}

	return (
		<div className="flex flex-col space-y-3 w-full">
			<IconHeader icon={<QuestionIcon />} message={question.title} />
			<Checkbox.Group
				isReadOnly={readOnly}
				aria-label="Options"
				size="sm"
				value={selected}
				onChange={handleSelectedChanged}
			>
				{question.options?.map((option) => (
					<Checkbox key={option.id} value={option.id}>
						{option.text}
					</Checkbox>
				))}
			</Checkbox.Group>
			{onNext && (
				<div className="flex justify-between">
					<QuestionProgress item={item} />
					<NextButton item={item} disabled={!selected?.length} onNext={onNext} />
				</div>
			)}
		</div>
	)
})

export const ActionItem = observer(function ({
	positionCandidate,
	item,
	onComplete,
	readOnly = false,
}: {
	positionCandidate: IPositionCandidate
	item:
		| IPositionCandidateActionItem
		| IPositionCandidateChecklist
		| IPositionCandidateQuestionForm
	onComplete?: () => void
	readOnly?: boolean
}) {
	const router = useRouter()
	const confirm = useConfirm()
	const determineItemType = () => {
		if ('items' in item) {
			return 'checklist'
		} else if ('questions' in item) {
			return 'question-form'
		}
		return 'unknown'
	}

	const canDelete =
		!readOnly &&
		(rootStore.organizationUsers.activeUser?.ability.can(
			'delete',
			'position-candidate-action-item'
		) ??
			false)

	const handleDelete = async () => {
		if (!canDelete) {
			return
		}

		const type = 'items' in item ? 'Checklist' : 'Questions'
		const shouldContinue = await confirm({
			title: `Delete ${type}?`,
			message: `Are you sure you want to delete? It cannot be undone.`,
			confirmText: 'Delete',
			cancelText: 'Cancel',
			confirmColor: 'error',
		})

		if (!shouldContinue) {
			return
		}

		await rootStore.positions.removeActionItem(
			positionCandidate.candidateId,
			positionCandidate.positionId,
			item.id
		)
	}

	const handleGoToTriggerAction = (
		item: IPositionCandidateChecklist | IPositionCandidateQuestionForm
	) => {
		router.push(`/workflows?t=actions&a=${item.triggerId}`)
		cardDetailedViewStore.close()
	}

	const determineComponentView = () => {
		const type = determineItemType()
		switch (type) {
			case 'checklist':
				return (
					<ChecklistActionItem
						positionCandidate={positionCandidate}
						item={item as IPositionCandidateChecklist}
						onComplete={onComplete}
						readOnly={readOnly}
						canDelete={canDelete}
						handleDeleteAction={handleDelete}
						handleGoToTriggerAction={handleGoToTriggerAction}
					/>
				)
			case 'question-form':
				if (item.status === 'completed') {
					return (
						<CompletedQuestionActionItem
							item={item as IPositionCandidateQuestionForm}
						/>
					)
				} else {
					return (
						<QuestionActionItem
							positionCandidate={positionCandidate}
							item={item as IPositionCandidateQuestionForm}
							onComplete={onComplete}
							canDelete={canDelete}
							handleDeleteAction={handleDelete}
							handleGoToTriggerAction={handleGoToTriggerAction}
						/>
					)
				}

			default:
				return <div>Unknown</div>
		}
	}

	const componentView = determineComponentView()

	return <div className="flex flex-col">{componentView}</div>
})
