import { AiChat } from '@nlux/react'
import { useChatAdapter, ReactChatAdapterOptions } from '@nlux/nlbridge-react'
import { memo, useCallback, useEffect, useMemo } from 'react'
import { authHeader } from '@requests/api'
import { signal, untracked } from '@preact/signals-react'
import { useComputed, useSignal, useSignals } from '@preact/signals-react/runtime'
import { autorun, reaction } from 'mobx'
import { rootStore } from '@store/index'
import { doRefreshToken } from '../../requests/api'
import { useReaction } from '@touchpoints/mobx-hooks'
import { store } from '../accounts/store'

const headers = signal<Record<string, any>>({})

function useAuthHeaders() {
	const updateAuthHeaders = useCallback(() => {
		authHeader().then((result) => {
			headers.value = result
		})
	}, [])

	useEffect(() => {
		// NOTE: this is just to ensure auth token has not expired
		// if the user sits on this page since the API call is being made
		// by an external library
		// normally, we will refresh the token automatically if we get a 401
		doRefreshToken().then(updateAuthHeaders)

		const id = setInterval(() => {
			doRefreshToken().then(updateAuthHeaders)
		}, 1000 * 60 * 25)

		return () => clearInterval(id)
	}, [updateAuthHeaders])
}

type RoleType = 'user' | 'assistant' | 'system'

// NOTE: using this history to keep the chat history; nlux seems to remove it
// once you go to a different tab and come back
const history = signal<{ role: RoleType; message: string }[]>([])

export const CandidateAIChat = memo(function CandidateAIChat() {
	useSignals()
	useAuthHeaders()

	const positionCandidateId = useReaction(() => store.activePositionCandidateId, 100)

	const orgId = useSignal(rootStore.organizations.activeOrganizationId)
	const positionCandidate = useReaction(
		() => rootStore.positions.getPositionCandidateById(positionCandidateId),
		100,
		[positionCandidateId]
	)
	const candidate = useReaction(
		() =>
			positionCandidate
				? rootStore.candidates.getCandidateById(positionCandidate.candidateId)
				: undefined,
		100,
		[positionCandidate]
	)

	useEffect(() => {
		return reaction(
			() => store.activePositionCandidateId,
			() => {
				history.value.length = 0
			}
		)
	}, [positionCandidateId])

	useEffect(() => {
		return autorun(() => {
			orgId.value = rootStore.organizations.activeOrganizationId
		})
	}, [orgId])

	const options = useComputed<ReactChatAdapterOptions>(() => ({
		url: `${process.env.NEXT_PUBLIC_API_URL}/organizations/${orgId.value}/openai/chat/card/${positionCandidateId}`,
		headers: headers.value,
	}))
	const nlbridgeAdapter = useChatAdapter(options.value)

	const events = useMemo(
		() => ({
			messageSent: (data: { uid: string; message: string | string[] }) => {
				history.value = [
					...history.value,
					{
						role: 'user' as RoleType,
						message: Array.isArray(data.message)
							? data.message.join('\n')
							: data.message,
					},
				].slice(-10)
			},
			messageReceived: (data: { uid: string; message: string | string[] }) => {
				history.value = [
					...history.value,
					{
						role: 'assistant' as RoleType,
						message: Array.isArray(data.message)
							? data.message.join('\n')
							: data.message,
					},
				].slice(-10)
			},
		}),
		[]
	)

	return (
		<div className="flex w-full h-full overflow-x-clip">
			<AiChat
				personaOptions={{
					assistant: {
						name: 'Otis',
						avatar: '/images/otis_avatar.png',
					},
				}}
				adapter={nlbridgeAdapter}
				composerOptions={{
					placeholder: candidate
						? `Ask me about ${candidate.firstName}...`
						: 'Ask me about this candidate...',
					autoFocus: true,
				}}
				events={events}
				initialConversation={untracked(() =>
					history.value?.length > 0 ? history.value : undefined
				)}
			/>
		</div>
	)
})
