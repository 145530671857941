import clsx from 'clsx'
import { ReactNode } from 'react'

type IconHeaderProps = {
	icon: ReactNode
	message: string
	children?: ReactNode
	iconClassName?: string
}
export const IconHeader = ({ icon, message, children, iconClassName }: IconHeaderProps) => {
	return (
		<div className="flex items-center space-x-2 my-1">
			<div className={clsx('w-6 h-6 p-1 bg-[#F0F4FF] rounded-md', iconClassName)}>{icon}</div>
			<div className="text-sm font-medium text-zinc-900 leading-tight">{message}</div>
			{children}
		</div>
	)
}
