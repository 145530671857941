import { IUser, Mailbox, makeFetchOrganizationsForUser, makeFetchUser } from '@touchpoints/requests'

import { authHeader, patch, get, del, post } from './api'

export const getUser = makeFetchUser(get, authHeader)

export async function updateUser(changes: Partial<Pick<IUser, 'firstName' | 'lastName'>>) {
	const headers = await authHeader()
	if (!headers.Authorization) {
		return { status: 400, success: false }
	}

	return patch<IUser>('/users/me', changes, headers)
}

export async function updateUserMailbox(email: string, data: Pick<Mailbox, 'signature'>) {
	const headers = await authHeader()
	return patch(
		`/users/me/mailbox/${email}`,
		{
			...data,
		},
		headers
	)
}

export async function removeUserMailbox(email: string) {
	const headers = await authHeader()
	return del(`/users/me/mailbox/${email}`, undefined, headers)
}

export const fetchOrganizationsForUser = makeFetchOrganizationsForUser(get, authHeader)

export async function sendSignaturePreview(email: string) {
	const headers = await authHeader()
	return post(`/users/me/signature/preview`, { email }, headers)
}
