import {
	fetchOrganizationUser,
	fetchOrganizationUsers,
	removeOrganizationUser,
	updateOrganizationUser,
} from '@requests/organizations'
import { abilityFor } from '@touchpoints/access-control'
import type {
	IAvailabilityDay,
	IOrganizationUser,
	IOrganizationUserSettings,
	Mailbox,
	OrganizationUserStatus,
	Role,
} from '@touchpoints/requests'
import { autorun, makeAutoObservable, runInAction } from 'mobx'

import type { RootStore } from './root'

class OrganizationUser implements IOrganizationUser {
	id = ''
	userId = ''
	organizationId = ''
	email = ''
	firstName = ''
	lastName = ''
	mailboxes: Omit<Mailbox, 'permissionId'>[] = []
	role: Role = 'viewer'
	calendarUrl = ''
	slug = ''
	status: OrganizationUserStatus = 'active'

	availability: IAvailabilityDay[] = []

	settings: IOrganizationUserSettings = {}

	get ability() {
		return abilityFor(this)
	}

	constructor(data: IOrganizationUser) {
		makeAutoObservable(this, {
			ability: false,
		})

		this.update(data)
	}

	update(data: IOrganizationUser) {
		this.id = data.id
		this.userId = data.userId
		this.organizationId = data.organizationId
		this.email = data.email
		this.firstName = data.firstName ?? this.firstName
		this.lastName = data.lastName ?? this.lastName
		this.mailboxes = data.mailboxes ?? []
		this.role = data.role
		this.calendarUrl = data.calendarUrl ?? ''
		this.availability = data.availability ?? []
		this.slug = data.slug ?? data.firstName?.toLowerCase() ?? ''
		this.settings = data.settings ?? {}
		this.status = data.status || 'active'
	}
}

export class OrganizationUsersStore {
	activeOrgUserId = ''

	users: Record<string, OrganizationUser | undefined> = {}
	usersByUserId: Record<string, OrganizationUser | undefined> = {}

	readonly rootStore: RootStore

	private _loading = false

	get isLoading() {
		return this._loading
	}

	get activeUser() {
		return this.users[this.activeOrgUserId]
	}

	get activeUserOrgUserId() {
		return this.activeUser?.id
	}

	get usersForActiveOrg() {
		const list: OrganizationUser[] = []

		const orgId = this.rootStore.organizations.activeOrganizationId
		for (const key in this.users) {
			const user = this.users[key]
			if (!user) {
				continue
			}

			if (user.organizationId === orgId) {
				list.push(user)
			}
		}

		return list
	}

	constructor(root: RootStore) {
		this.rootStore = root

		makeAutoObservable(this, {
			rootStore: false,
		})

		autorun(async () => {
			await this.reloadUserOrgData()
		})
	}

	async reloadUserOrgData() {
		// get the org user data (including data only this user can see)
		this.setLoading(true)
		await this.fetchOrganizationUser()
		this.setLoading(false)
		// get all the other users in this org (only public data for those users)
		await this.fetchOrganizationUsers(this.rootStore.organizations.activeOrganizationId)
	}

	getInActiveOrg(userId: string) {
		return this.usersByUserId[userId]
	}

	addOrgUser(obj: IOrganizationUser | OrganizationUser) {
		if (obj.id in this.users) {
			this.users[obj.id]?.update(obj)
			return
		}
		const user = obj instanceof OrganizationUser ? obj : new OrganizationUser(obj)

		this.users[user.id] = user
		this.usersByUserId[user.userId] = user
	}

	async updateRole(id: string, role: Role) {
		const user = this.users[id]
		if (!user) {
			console.error('could not find user')
			return
		}

		const res = await updateOrganizationUser(user.userId, user.organizationId, { role })
		if (!res.success) {
			return
		}

		const { user: newUser } = res.data ?? {}
		if (!newUser) {
			return
		}

		user.update(newUser)
	}

	async updateStatus(id: string, status: OrganizationUserStatus) {
		const user = this.users[id]
		if (!user) {
			console.error('could not find user')
			return
		}

		const res = await updateOrganizationUser(user.userId, user.organizationId, { status })
		if (!res.success) {
			return
		}

		const { user: newUser } = res.data ?? {}
		if (!newUser) {
			return
		}

		user.update(newUser)
	}

	async updateOrgUser(
		id: string,
		changes: Partial<
			Pick<IOrganizationUser, 'calendarUrl' | 'availability' | 'slug' | 'settings'>
		>
	) {
		const user = this.users[id]
		if (!user) {
			console.error('could not find user')
			return false
		}

		const res = await updateOrganizationUser(user.userId, user.organizationId, changes)
		if (!res.success) {
			return false
		}

		const { user: newUser } = res.data ?? {}
		if (!newUser) {
			return false
		}

		user.update(newUser)

		return true
	}

	async removeOrgUser(id: string) {
		const user = this.users[id]
		if (!user) {
			console.error('could not find user')
			return
		}

		const res = await removeOrganizationUser(user.userId, user.organizationId)
		if (!res.success) {
			return
		}

		delete this.users[id]
		delete this.usersByUserId[user.userId]
	}

	private async fetchOrganizationUser() {
		const userId = this.rootStore.users.loggedInUserId
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!userId || !orgId) {
			return
		}

		const res = await fetchOrganizationUser(userId, orgId)

		if (!res.success || !res.data) {
			return
		}

		const { user } = res.data

		runInAction(() => {
			this.activeOrgUserId = user.id
		})

		this.addOrgUser(user)
	}

	private async fetchOrganizationUsers(orgId: string) {
		if (!orgId) {
			return
		}

		const res = await fetchOrganizationUsers(orgId)
		const { users = [] } = res.data ?? {}

		users.forEach((u) => this.addOrgUser(u))
	}
	private setLoading(v: boolean) {
		this._loading = v
	}
}
