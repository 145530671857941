import { authHeader, del, get, patch, post } from './api'

import type {
	ICandidateStage,
	IOrganization,
	IOrganizationUser,
	IStageGroup,
} from '@touchpoints/requests'

type CreateOrgResponse = {
	orgId: string
	userOrg: string
}

type FetchOrgUserResponse = {
	user: IOrganizationUser
}

export async function createOrganization(name: string) {
	const headers = await authHeader()
	return post<CreateOrgResponse>(
		'/organizations',
		{
			organization: {
				name,
			},
		},
		headers
	)
}

export async function addCandidateStage(orgId: string, stages: Omit<ICandidateStage, 'id'>[]) {
	const headers = await authHeader()
	return patch<{ organization: IOrganization }>(
		`/organizations/${orgId}`,
		{
			candidateStage: {
				type: 'add',
				values: stages.map((s) => ({ ...s })),
			},
		},
		headers
	)
}

export async function updateCandidateStage(orgId: string, stages: ICandidateStage[]) {
	const headers = await authHeader()
	return patch<{ organization: IOrganization }>(
		`/organizations/${orgId}`,
		{
			candidateStage: {
				type: 'update',
				values: stages.map((s) => ({ ...s })),
			},
		},
		headers
	)
}

export async function updateStages(orgId: string, stageGroups: IStageGroup[]) {
	const headers = await authHeader()
	return patch<{ organization: IOrganization }>(
		`/organizations/${orgId}`,
		{
			stageGroups,
		},
		headers
	)
}

export async function removeCandidateStage(orgId: string, stages: ICandidateStage[]) {
	const headers = await authHeader()
	return patch<{ organization: IOrganization }>(
		`/organizations/${orgId}`,
		{
			candidateStage: {
				type: 'remove',
				values: stages.map((s) => ({ ...s })),
			},
		},
		headers
	)
}

export async function fetchOrganizationUser(userId: string, orgId: string) {
	const headers = await authHeader()
	return get<FetchOrgUserResponse>(`/organizations/${orgId}/users/${userId}`, undefined, headers)
}

export async function fetchOrganizationUsers(orgId: string) {
	const headers = await authHeader()
	return get<{ users: IOrganizationUser[] }>(`/organizations/${orgId}/users`, undefined, headers)
}

export async function updateOrganizationUser(
	userId: string,
	orgId: string,
	data: Partial<
		Pick<IOrganizationUser, 'role' | 'calendarUrl' | 'slug' | 'availability' | 'status'>
	>
) {
	const headers = await authHeader()
	return patch<FetchOrgUserResponse>(
		`/organizations/${orgId}/users/${userId}`,
		{ ...data },
		headers
	)
}

export async function removeOrganizationUser(userId: string, orgId: string) {
	const headers = await authHeader()
	return del(`/organizations/${orgId}/users/${userId}`, headers)
}
