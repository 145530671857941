import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import { useEffect } from 'react'

export function useLogRocket() {
	useEffect(() => {
		if (process.env.NEXT_PUBLIC_APP_URL !== 'http://localhost:3000') {
			LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_APP_ID)
			setupLogRocketReact(LogRocket)
		}
	}, [])
}

export function identify(uid: string, data: Record<string, string | number | boolean>) {
	LogRocket.identify(uid, data)
}
