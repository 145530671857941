import { PropsWithChildren, ReactNode } from 'react'
import * as RadixPopover from '@radix-ui/react-popover'
import { styled } from '@stitches/react'

const Trigger = styled(RadixPopover.Trigger, {
	background: 'transparent',
	border: '0px',
})

const Arrow = styled(RadixPopover.Arrow, {
	background: 'white',
})

const Content = styled(RadixPopover.Content, {
	background: 'white',
	padding: '0px',
	borderRadius: '10px',
	boxShadow: '0 8px 30px rgba(0, 0, 0, 0.15)',
	zIndex: 100,
})

type PopoverProps = {
	trigger?: ReactNode
}

export function Popover({
	trigger,
	children,
	...props
}: PropsWithChildren<PopoverProps & RadixPopover.PopoverProps & RadixPopover.PopoverContentProps>) {
	return (
		<RadixPopover.Root {...props}>
			<Trigger>{trigger}</Trigger>
			<Content {...props}>{children}</Content>
		</RadixPopover.Root>
	)
}

Popover.Arrow = Arrow
