import { Tooltip } from '@nextui-org/react'
import { formatTs } from '@services/time'
import { rootStore } from '@store/index'
import { ISequenceInstance } from '@touchpoints/requests'
import { observer } from 'mobx-react-lite'
import ms from 'ms'
import { useEffect, useState } from 'react'

export const InstanceRowNextStep = observer(function ({
	instance,
}: {
	instance: ISequenceInstance
}) {
	const [text, setText] = useState(`#${instance.actionStep + 1}`)
	const [timeDiff, setTimeDiff] = useState<number | undefined>(undefined)

	const fastForwarded = rootStore.sequences.isQueuedForFastForward(instance.id)

	useEffect(() => {
		if (instance.status === 'completed') {
			setText('--')
			return
		}

		const step = instance.actionStep + 1

		if (instance.status === 'paused') {
			setText(`#${step} paused`)
			return
		}

		const now = Date.now()
		const sequence = rootStore.sequences.getSequenceById(instance.sequenceId)

		if (fastForwarded) {
			setText(`#${step} queued`)
			return
		}

		if (instance.activeTaskId) {
			return setText(`Waiting for manual action...`)
		}

		if (!instance.lastSendAt || !sequence) {
			return setText(`#${step}`)
		}

		if (instance.stepStatus === 'blocked') {
			if (instance.nextActionAt) {
				const diff = instance.nextActionAt - now
				const formattedDiff = ms(diff, { long: true })
				return setText(`#${step} blocked. Trying again in ${formattedDiff}`)
			} else {
				return setText(`#${step} was blocked`)
			}
		} else if (instance.stepStatus === 'failed') {
			if (instance.nextActionAt) {
				const diff = instance.nextActionAt - now
				const formattedDiff = ms(diff, { long: true })
				return setText(`#${step} failed. Trying again in ${formattedDiff}`)
			} else {
				return setText(`#${step} failed.`)
			}
		}

		if (step <= 1) {
			const action = sequence.actions[instance.actionStep]
			const delay = instance.nextActionAt ? instance.nextActionAt - now : action.delay
			const formattedDiff = delay < ms('1min') ? 'queued' : `in ${ms(delay, { long: true })}`
			setTimeDiff(delay)
			setText(`#${step} ${formattedDiff}`)
			return
		}

		const action = sequence.actions[instance.actionStep]
		const lastAction = sequence.actions[instance.actionStep - 1]
		const diff = instance.nextActionAt
			? instance.nextActionAt - now
			: instance.lastSendAt + ((action?.delay ?? 0) - (lastAction?.delay || 0)) - now
		const formattedDiff = diff < 0 ? 'queued' : `in ${ms(diff, { long: true })}`

		if (diff > 0) {
			setTimeDiff(diff)
		}
		setText(`#${step} ${formattedDiff}`)
	}, [instance, fastForwarded])

	if (timeDiff) {
		return (
			<Tooltip content={<p>{formatTs(Date.now() + timeDiff)}</p>}>
				<p className="text-sm">{text}</p>
			</Tooltip>
		)
	}
	return <p className="text-sm">{text}</p>
})
