export function CursorClickAction({ size = 20 }: { size?: number }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
		>
			<g id="cursor click, arrow">
				<path
					id="Icon"
					d="M9.1665 2.29297V3.7513M14.0889 4.24569L12.9967 5.3379M5.33634 12.9981L4.24413 14.0903M3.74984 9.16797H2.2915M5.33634 5.33781L4.24413 4.24559M11.9259 17.4925L8.70403 8.77441C8.64145 8.60507 8.8092 8.44171 8.97682 8.50876L17.5096 11.9219C17.6726 11.9871 17.6869 12.2122 17.5335 12.2974L14.2185 14.1391C14.1845 14.158 14.1565 14.186 14.1376 14.22L12.3035 17.5214C12.217 17.6771 11.9877 17.6595 11.9259 17.4925Z"
					stroke="#889096"
					strokeWidth="1.25"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	)
}
