import { autorun, makeAutoObservable } from 'mobx'

import {
	type IStageGroup,
	type IStageInstance,
	type IStageTemplate,
	type ITriggerAction,
	ChecklistItem,
	IMoveToQuestionTriggerAction,
	IOrgStage,
	IOrgStageProps,
	ISmarformTriggerAction,
	IStage,
	QuestionItem,
	TriggerActionName,
} from '@touchpoints/requests'

import {
	createStageTemplate,
	fetchStageTemplateById,
	fetchStageTemplates,
	removeStageTemplate,
	testTriggerAction,
	updateStageTemplate,
} from '@requests/stageTemplates'
import { IStageTemplateUpdateProps } from '@touchpoints/service-types'
import { nanoid } from 'nanoid'
import { rootStore } from '.'
import type { RootStore } from './root'

export class OrgStage implements IOrgStage {
	id = ''
	name = ''
	description = ''

	constructor(data: IOrgStageProps) {
		makeAutoObservable(this)
		this.update(data)
	}

	update(data: IOrgStageProps) {
		this.id = data.id
		this.name = data.name
		this.description = data.description
	}

	setName(name: string) {
		this.name = name
	}

	setDescription(description: string) {
		this.description = description
	}

	getStageGroups() {
		return rootStore.organizations.activeOrganization?.stages.groups || []
	}
}

export class StageInstance implements IStageInstance {
	id = ''
	stageReferenceId = ''
	actions = [] as ITriggerAction[]

	constructor(data: Partial<IStageInstance>) {
		makeAutoObservable(this)
		this.update(data)
	}

	update(data: Partial<IStageInstance>) {
		this.id = data.id ?? ''
		this.stageReferenceId = data.stageReferenceId ?? ''
		this.actions = data.actions ?? []
	}

	insertAction(action: ITriggerAction, index: number): void {
		this.actions = [
			...this.actions.slice(0, index),
			action,
			...this.actions.slice(index, this.actions.length),
		]

		rootStore.stageTemplates.markAsDirty()
	}

	getStageGroups() {
		return rootStore.stageTemplates.getActiveStageTemplate()?.stageGroups ?? []
	}
}

export class StageGroup implements IStageGroup {
	id = ''
	name = ''
	color = ''
	stages = [] as IStage[]

	constructor(data: Partial<IStageGroup>) {
		makeAutoObservable(this)
		this.update(data)
	}

	update(data: Partial<IStageGroup>) {
		this.id = data.id ?? ''
		this.name = data.name ?? ''
		this.color = data.color ?? ''
		this.stages = (data.stages ?? []).map(this.createStageInstance)
	}

	removeStage(index: number) {
		if (this.stages[index]) {
			this.stages.splice(index, 1)
			rootStore.stageTemplates.markAsDirty()
		}
	}

	addStage(stage: IStageInstance) {
		this.stages.push(this.createStageInstance(stage))
		rootStore.stageTemplates.markAsDirty()
	}

	addStageAfter(stage: IStage, stageId: string): void {
		const index = this.stages.findIndex((s) => s.id === stageId)
		if (index >= 0) {
			this.stages.splice(index + 1, 0, this.createStageInstance(stage))
		}

		rootStore.stageTemplates.markAsDirty()
	}

	insertStage(stage: IStage, index: number): void {
		this.stages = [
			...this.stages.slice(0, index),
			this.createStageInstance(stage),
			...this.stages.slice(index, this.stages.length),
		]

		rootStore.stageTemplates.markAsDirty()
	}

	private createStageInstance(s: IStage) {
		return (isStageInstance(s) ? new StageInstance(s) : new OrgStage(s)) as IStageInstance
	}
}

export class StageTemplate implements IStageTemplate {
	id = ''
	name = ''
	organizationId = ''

	stageGroups = [] as IStageGroup[]

	createdBy = ''
	createdAt = 0
	lastUpdateBy = ''
	lastUpdateAt = 0

	constructor(data: IStageTemplate) {
		makeAutoObservable(this)
		this.update(data)
	}

	update(data: IStageTemplate) {
		this.id = data.id
		this.name = data.name
		this.organizationId = data.organizationId

		this.stageGroups = data.stageGroups.map((sg) => new StageGroup(sg))

		this.createdBy = data.createdBy
		this.createdAt = data.createdAt
		this.lastUpdateBy = data.lastUpdateBy
		this.lastUpdateAt = data.lastUpdateAt
	}

	setName(name: string) {
		this.name = name
		rootStore.stageTemplates.markAsDirty()
	}
}

export class StageTemplateStore {
	readonly rootStore: RootStore
	readonly stageTemplates: Record<string, StageTemplate> = {}
	readonly dirtyTemplates: Record<string, boolean> = {}

	activeStageTemplateId = ''
	activeStageId = ''

	constructor(root: RootStore) {
		this.rootStore = root

		makeAutoObservable(this, {
			rootStore: false,
		})

		autorun(async () => {
			await this.fetchStageTemplates()
		})

		autorun(async () => {
			if (!this.activeStageTemplateId) {
				return
			}

			// only try fetching if we didn't already get it
			if (this.activeStageTemplateId !== 'new')
				await this.fetchStageTemplate(this.activeStageTemplateId)
		})
	}

	createNewStageTemplate() {
		const stageGroups = rootStore.organizations.activeOrganization?.stages.groups ?? []
		const stageTemplate = new StageTemplate({
			id: 'new',
			name: 'New stage template',
			stageGroups: stageGroups.map((sg) => {
				return new StageGroup({
					...sg,
					stages: sg.stages.map(createStageInstance),
				})
			}),
		} as unknown as IStageTemplate)

		this.addStageTemplate(stageTemplate)
		this.setActiveStageTemplateId(stageTemplate.id)

		return stageTemplate
	}

	async createStageTemplate(stageTemplate: IStageTemplateUpdateProps) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await createStageTemplate(orgId, stageTemplate)

		if (!res.data) {
			return
		}

		this.markAsClean()
		return this.addStageTemplate(res.data)
	}

	async updateStageTemplate(stageTemplateId: string, stageTemplate: IStageTemplateUpdateProps) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await updateStageTemplate(orgId, stageTemplateId, stageTemplate)

		if (!res.data) {
			return
		}
		this.markAsClean()
		return this.addStageTemplate(res.data)
	}

	async removeStageTemplate(stageTemplateId: string) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await removeStageTemplate(orgId, stageTemplateId)

		if (res.success) {
			delete this.stageTemplates[stageTemplateId]
		}
	}

	async fetchStageTemplates() {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await fetchStageTemplates(orgId)

		if (!res.data) {
			return
		}

		const stageTemplates = res.data
		stageTemplates.forEach((t) => this.addStageTemplate(t))
	}

	async fetchStageTemplate(stageTemplateId: string) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await fetchStageTemplateById(orgId, stageTemplateId)

		if (!res.data || !res.success) {
			return
		}

		const template = res.data
		this.addStageTemplate(template)
	}

	async testAction(actionId: string, options: { positionId: string; candidateId: string }) {
		const orgId = this.rootStore.organizations.activeOrganizationId
		if (!orgId) {
			return { success: false, message: 'No active organization' }
		}

		const res = await testTriggerAction(orgId, this.activeStageTemplateId, actionId, options)
		if (!res) {
			return { success: false, message: 'No response' }
		}

		const success = res.success ?? false

		if (!success) {
			return { success: false, message: res.message ?? 'Failed to test action' }
		}

		return { success }
	}

	getStageTemplates() {
		return Object.values(this.stageTemplates)
	}

	getStageTemplateById(id: string) {
		return this.stageTemplates[id]
	}

	setActiveStageTemplateId(stageTemplateId: string) {
		this.activeStageTemplateId = stageTemplateId
	}

	setActiveStageId(stageId: string) {
		this.activeStageId = stageId
	}

	getActiveStageTemplate() {
		return this.stageTemplates[this.activeStageTemplateId]
	}

	getActiveStage() {
		return this.getActiveStageTemplate()
			?.stageGroups.flatMap((group) => group.stages)
			.find((stage) => stage.id === this.activeStageId)
	}

	getStages() {
		return this.getActiveStageTemplate()?.stageGroups.flatMap((group) => group.stages)
	}

	addStageTemplate(obj: IStageTemplate | StageTemplate) {
		if (obj.id in this.stageTemplates) {
			this.stageTemplates[obj.id]?.update(obj)
			return this.stageTemplates[obj.id]
		}

		const stageTemplate = obj instanceof StageTemplate ? obj : new StageTemplate(obj)
		this.stageTemplates[stageTemplate.id] = stageTemplate

		return this.stageTemplates[stageTemplate.id]
	}

	markAsDirty() {
		this.dirtyTemplates[this.activeStageTemplateId] = true
	}

	markAsClean() {
		this.dirtyTemplates[this.activeStageTemplateId] = false
	}

	private clearStageTemplates() {
		for (const key in this.stageTemplates) {
			delete this.stageTemplates[key]
		}
	}
}

function isQuestionItem(item: QuestionItem | ChecklistItem): item is QuestionItem {
	return (item as QuestionItem).type !== undefined
}

export function isTriggerAction(data: Partial<ITriggerAction>): data is ITriggerAction {
	if (!data.id || !data.createdAt || !data.actionName) {
		return false
	}
	return true
}

export function isStageInstance(data: Partial<IStageInstance>): data is IStageInstance {
	if (!data.id || !data.actions || !Array.isArray(data.actions)) {
		return false
	}

	return data.actions.every(isTriggerAction)
}

export function canAddStage(stage: IStage): boolean {
	const stageId = isStageInstance(stage) ? stage.stageReferenceId : stage.id
	const stages = rootStore.stageTemplates
		.getActiveStageTemplate()
		?.stageGroups.flatMap((sg) => sg.stages as IStageInstance[])

	return stages.findIndex((s) => s.stageReferenceId === stageId) === -1
}

export function emptyStage(): IStage {
	return new StageInstance({
		id: nanoid(),
		stageReferenceId: '',
		actions: [],
	})
}

export function createStageInstance(stage: IStage): IStageInstance {
	return new StageInstance({
		id: nanoid(),
		stageReferenceId: stage.id,
		actions: [],
	})
}

export function getStageReference(stage: IStageInstance | IOrgStage): IOrgStage | undefined {
	if (isStageInstance(stage)) {
		const stageGroups = rootStore.organizations.activeOrganization?.stages.groups
		return stageGroups
			?.flatMap((sg) => sg.stages as IOrgStage[])
			.find((s) => s.id === stage.stageReferenceId)
	} else {
		return stage
	}
}

// Copies and replace all ids in the given stage
export function cloneStage(stage: IStageInstance): IStageInstance {
	const stageId = nanoid()
	return {
		...stage,
		id: stageId,
		actions: stage.actions.map((action: ITriggerAction) => cloneAction(action, stageId)),
	}
}

export function cloneAction(action: ITriggerAction, stageId: string): ITriggerAction {
	if (action.actionName === TriggerActionName.CreateSmarform) {
		const smartform = action as ISmarformTriggerAction
		const itemIds: Record<string, string> = {}

		smartform.items.forEach((item) => {
			itemIds[item.id] = nanoid()
		})

		return {
			...smartform,
			id: nanoid(),
			items: smartform.items.map((item) => {
				return {
					...item,
					id: itemIds[item.id],
					options: (item.options ?? []).map((option) => {
						return {
							...option,
							id: nanoid(),
							actions: (option.actions ?? []).map((a) => {
								if (a.actionName === TriggerActionName.MoveToQuestion) {
									const moveToQuestionAction = a as IMoveToQuestionTriggerAction
									return {
										...moveToQuestionAction,
										id: nanoid(),
										createdAt: Date.now(),
										stageId,
										smartformItemId:
											itemIds[moveToQuestionAction.smartformItemId],
										moveToItemId: itemIds[moveToQuestionAction.moveToItemId],
									}
								}
								return {
									...a,
									id: nanoid(),
								}
							}),
						}
					}),
				}
			}),
		}
	}
	return { ...action, id: nanoid(), createdAt: Date.now() }
}

export function getStageData(stage: IStage): {
	stageGroupColor: string
	stageNumber: string
	stageIndex: number
} {
	const stageGroups = stage.getStageGroups?.() ?? []
	const stageGroupIndex = stageGroups.findIndex((sg) => {
		return sg.stages.findIndex((s) => s.id === stage.id) !== -1
	})
	const stageIndex = stageGroups[stageGroupIndex]?.stages.findIndex((s) => s.id === stage.id)

	return {
		stageGroupColor: stageGroups[stageGroupIndex]?.color ?? '#000000',
		stageNumber: `${stageGroupIndex + 1}.${stageIndex + 1}`,
		stageIndex,
	}
}
