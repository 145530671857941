export function SystemUser({ size = 20 }: { size?: number }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
		>
			<rect width="20" height="20" rx="10" fill="#FDE5E6" />
			<g clipPath="url(#clip0_2259_27242)">
				<path
					d="M16.5922 11.1944H12.8108L15.4847 13.8683L13.7948 15.5582L11.1209 12.8843V16.6657H9.51465V3.33203H11.1209V7.11423L13.7191 4.51681L13.7948 4.44032L15.4847 6.13023L12.2156 8.80414H16.5922V11.1944Z"
					fill="#EB6224"
				/>
				<path
					d="M3.40771 11.1944H7.18911L4.5152 13.8683L6.20512 15.5582L8.87903 12.8843V16.6657H10.4853V3.33203H8.87903V7.11423L6.28081 4.51681L6.20512 4.44032L4.5152 6.13023L7.18911 8.80414H3.40771V11.1944Z"
					fill="#EB6224"
				/>
				<g clipPath="url(#clip1_2259_27242)">
					<path
						d="M7.41699 10.0588C9.37373 10.4344 9.67835 10.739 10.0539 12.6958C10.4295 10.739 10.7341 10.4344 12.6909 10.0588C10.7334 9.68323 10.4295 9.37861 10.0539 7.42188C9.67835 9.37861 9.37373 9.68323 7.41699 10.0588Z"
						fill="#FDE5E6"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_2259_27242">
					<rect
						width="13.1847"
						height="13.3337"
						fill="white"
						transform="translate(3.40771 3.33203)"
					/>
				</clipPath>
				<clipPath id="clip1_2259_27242">
					<rect
						width="6.05533"
						height="6.05534"
						fill="white"
						transform="translate(6.75391 6.99609)"
					/>
				</clipPath>
			</defs>
		</svg>
	)
}
