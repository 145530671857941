import { TextProps } from '@nextui-org/react'
import { PropsWithChildren } from 'react'

export function Label({ children, ...props }: PropsWithChildren<Partial<TextProps>>) {
	return (
		<div className="mb-1">
			<span className="text-zinc-900 text-sm font-normal leading-[19px]" {...props}>
				{children}
			</span>
		</div>
	)
}
