import { Select, SelectComponents, SelectOptionProps, SelectControlProps } from '@touchpoints/ui'
import { CandidateStageColorDot } from './CandidateStageColorDot'
import { Loading } from '@nextui-org/react'
import { ValueContainerProps } from 'react-select'

type Props = {
	options: {
		value: string
		label: string
		color?: string
	}[]
	stage: string
	label?: string
	onChange: (stage: string) => void
	readOnly?: boolean
	isLoading?: boolean
	menuPlacement?: 'top' | 'bottom'
	placeholder?: string
}
export function CandidateStageSelect({
	options,
	label,
	stage,
	onChange,
	readOnly,
	isLoading,
	menuPlacement,
	placeholder,
}: Props) {
	return (
		<div className="flex w-full items-center">
			{isLoading && <Loading type="points-opacity" />}
			{!isLoading && (
				<Select
					label={label}
					placeholder={placeholder}
					isMulti={false}
					value={options.find((v) => v.value === stage ?? '')}
					components={{ Option, Control }}
					options={options}
					fullWidth
					isDisabled={readOnly}
					getOptionLabel={(d) => d.label}
					getOptionValue={(d) => d.value}
					isLoading={isLoading}
					menuPlacement={menuPlacement}
					onChange={(stage) => {
						if (!stage) {
							return
						}
						if (stage.value === undefined || stage.value === null) {
							return
						}

						onChange?.(stage.value)
					}}
				/>
			)}
		</div>
	)
}

type MultiProps = {
	options: {
		value: string
		label: string
		color?: string
	}[]
	stages: string[]
	label?: string
	onChange: (stages: string[]) => void
	readOnly?: boolean
}
export function CandidateStageMultiSelect({
	options,
	label,
	stages,
	onChange,
	readOnly,
}: MultiProps) {
	return (
		<div className="flex w-full">
			<Select
				label={label}
				isMulti
				value={options.filter((v) => stages.includes(v.value))}
				components={{ Option, Control, ValueContainer }}
				options={options}
				fullWidth
				isDisabled={readOnly}
				getOptionLabel={(d) => d.label}
				getOptionValue={(d) => d.value}
				hideSelectedOptions={false}
				onChange={(stage) => {
					if (!stage) {
						return
					}
					onChange?.(stage.map((v) => v.value))
				}}
			/>
		</div>
	)
}

function ValueContainer(
	props: ValueContainerProps<{ value: string; label: string; color?: string }>
) {
	return (
		<SelectComponents.ValueContainer {...props}>
			<div className="flex items-center w-full flex-nowrap">{props.children}</div>
		</SelectComponents.ValueContainer>
	)
}

export function Control<isMulti extends boolean>(
	props: SelectControlProps<{ value: string; label: string; color?: string }, isMulti>
) {
	const data = props.getValue()[0]

	return (
		<SelectComponents.Control {...props}>
			<div className="flex items-center w-full">
				{data && <CandidateStageColorDot color={data.color} />}
				{props.children}
			</div>
		</SelectComponents.Control>
	)
}

export function Option<isMulti extends boolean>(
	props: SelectOptionProps<{ value: string; label: string; color?: string }, isMulti>
) {
	const { data } = props
	return (
		<SelectComponents.Option {...props}>
			<div className="flex items-center">
				{data.color && <CandidateStageColorDot color={data.color} />}
				<div className="ml-2 overflow-x-">{data.label}</div>
			</div>
		</SelectComponents.Option>
	)
}
