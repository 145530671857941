export function SearchIcon() {
	return (
		<svg
			width="16"
			height="15"
			viewBox="0 0 16 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.6667 14.1668L11.375 10.8752M13 6.66683C13 9.88849 10.3884 12.5002 7.16671 12.5002C3.94505 12.5002 1.33337 9.88849 1.33337 6.66683C1.33337 3.44517 3.94505 0.833496 7.16671 0.833496C10.3884 0.833496 13 3.44517 13 6.66683Z"
				stroke="#687076"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	)
}
