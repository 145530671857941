export function SequencesIcon() {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5 2.50019L5.58579 1.9144C6.36684 1.13335 7.63317 1.13335 8.41422 1.9144L9 2.50019M2.5 5.00019L1.91422 5.58597C1.13317 6.36702 1.13317 7.63335 1.91422 8.4144L2.5 9.00019M11.5 5.00019L12.0858 5.58597C12.8668 6.36702 12.8668 7.63335 12.0858 8.4144L11.5 9.00019M9 11.5002L8.41422 12.086C7.63317 12.867 6.36684 12.867 5.58579 12.086L5 11.5002"
				stroke="#687076"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
