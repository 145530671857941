import type { ICandidate, IPosition } from '@touchpoints/requests'

export function nameDisplayForCandidate(candidate?: ICandidate) {
	if (!candidate) {
		return 'Unknown Candidate'
	}

	return `${candidate.firstName} ${candidate.lastName}`
}

export function cardTitleFromCandidate(candidate?: ICandidate) {
	if (!candidate) {
		return 'Unknown Candidate'
	}

	const parts = [nameDisplayForCandidate(candidate), candidate.phone, candidate.email]
	return parts.filter((part) => !!part).join(' | ')
}

export function columnTitleFromPosition(position?: IPosition & { referenceName: string }) {
	if (!position) {
		return 'Unknown Position'
	}

	const parts = [position.referenceName, position.name, position.managerName, position.location]
	return parts.filter((part) => !!part).join(' | ')
}
