import { rootStore } from '@store/index'
import { useReaction } from '@touchpoints/mobx-hooks'
import { ICandidate, IPositionCandidate } from '@touchpoints/requests'
import { uniqBy } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useState, useEffect } from 'react'
import { CandidateStageEntry } from './CandidateStageEntry'
import clsx from 'clsx'

type CandidateStageListProps = {
	candidate: ICandidate
	showJobDescription?: boolean
	displayFlex?: boolean
}
export const CandidateStageList = observer(function ({
	candidate,
	showJobDescription = false,
	displayFlex = false,
}: CandidateStageListProps) {
	const [positionCandidates, setPositionCandidates] = useState<IPositionCandidate[]>([])
	const orgId = useReaction(() => rootStore.organizations.activeOrganizationId)

	useEffect(() => {
		const fetchPositionCandidate = async (candidateId: string) => {
			let pcs = rootStore.positions.getPositionCandidatesForCandidate(candidate.id)
			if (pcs.length === 0) {
				await rootStore.positions.fetchPositionCandidatesForCandidate(candidateId)
				pcs = rootStore.positions.getPositionCandidatesForCandidate(candidate.id)
			}
			setPositionCandidates(uniqBy(pcs, (pc) => pc.positionId))
		}
		if (candidate.id && orgId) {
			fetchPositionCandidate(candidate.id)
		}
	}, [candidate.id, orgId])

	return (
		<div
			className={clsx('overflow-hidden', {
				'flex items-center flex-wrap justify-start': !!displayFlex,
			})}
		>
			{positionCandidates.map((positionCandidate) => {
				const position = rootStore.positions.getPositionById(positionCandidate.positionId)
				if (!position) {
					return null
				}

				const stage = rootStore.stages.candidatesStageById[positionCandidate.stage ?? '']

				return (
					<CandidateStageEntry
						key={position.id}
						position={position}
						stage={stage}
						showJobDescription={showJobDescription}
					/>
				)
			})}
		</div>
	)
})
