import { FormEvent, useState } from 'react'
import { Text, Card, Grid, Input, Button, PressEvent } from '@nextui-org/react'

import { Divider } from '../core'

type Props = {
	title?: string
	isLogin?: boolean
	signupText?: string
	loginText?: string
	onSignIn: (email: string, password: string) => void
	onGoogleSignIn?: () => void
}

export function Authentication({
	title = 'Welcome',
	isLogin = false,
	signupText = 'Signup',
	loginText = 'Login',
	onSignIn,
	onGoogleSignIn,
}: Props) {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [loading, setLoading] = useState(false)

	const handleSignIn = async (_e: PressEvent | FormEvent) => {
		setLoading(true)

		onSignIn(email, password)
	}

	const handleGoogleSignIn = () => {
		setLoading(true)

		onGoogleSignIn?.()
	}

	return (
		<Card css={{ paddingBottom: '20px' }}>
			<Text
				size={48}
				css={{
					textGradient: '45deg, $blue500 -20%, $pink500 50%',
				}}
				weight="bold"
			>
				{title}
			</Text>
			<form onSubmit={handleSignIn}>
				<Grid.Container justify="center" gap={2} style={{ padding: 0 }}>
					<Grid xs={12}>
						<Input
							label="Email"
							placeholder="amit@touchpoints.io"
							size="xl"
							bordered
							fullWidth
							value={email}
							onChange={(e) => setEmail(e.currentTarget.value)}
						/>
					</Grid>
					<Grid xs={12}>
						<Input.Password
							label="Password"
							size="xl"
							bordered
							fullWidth
							value={password}
							onChange={(e) => setPassword(e.currentTarget.value)}
						/>
					</Grid>
					<Grid xs={12}>
						<Button
							auto
							shadow
							size="xl"
							style={{ width: '100%' }}
							onClick={handleSignIn}
							disabled={loading}
						>
							{isLogin ? loginText : signupText}
						</Button>
					</Grid>
				</Grid.Container>
			</form>
			{!!onGoogleSignIn && (
				<>
					<Divider />
					<Button
						auto
						shadow
						size="xl"
						onClick={handleGoogleSignIn}
						disabled={loading}
						bordered
					>
						{isLogin ? loginText : signupText} with Google
					</Button>
				</>
			)}
		</Card>
	)
}
