export function DashboardIcon() {
	return (
		<svg
			width="12"
			height="14"
			viewBox="0 0 12 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.666687 5.83333L6.00002 1.5L11.3334 5.83333V13.3333H0.666687V5.83333Z"
				stroke="#687076"
				strokeWidth="1.2"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
