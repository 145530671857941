import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'

import { rootStore } from '@store'
import { IPositionCandidate } from '@touchpoints/requests'
import { useEffect, useState } from 'react'

import clsx from 'clsx'
import { HiChevronLeft, HiChevronRight, HiX } from 'react-icons/hi'
import { BoardColumn, positionCandidateToBoardEntry } from './BoardColumn'
import { store } from './store'

export const PositionCandidateNavigator = observer(function ({
	positionCandidate,
	positionCandidates,
}: {
	positionCandidate: IPositionCandidate
	positionCandidates: IPositionCandidate[]
}) {
	const router = useRouter()

	const index = positionCandidates.findIndex((pc) => pc.id === positionCandidate.id)

	const canGoLeft = index > 0
	const canGoRight = index < positionCandidates.length - 1

	const handleLeft = () => {
		if (!canGoLeft) {
			return
		}

		// router.push(`/tasks/${positionCandidates[index - 1].id}`)
	}

	const handleRight = () => {
		if (!canGoRight) {
			return
		}

		// router.push(`/tasks/${positionCandidates[index + 1].id}`)
	}

	if (positionCandidates.length === 0) {
		return <></>
	}

	return (
		<div className="flex flex-row justify-between items-center min-h-[48px] border-b border-gray-100 dark:bg-gray-800 dark:border-gray-600">
			<div className="flex items-center dark:text-white">
				<div className="mx-3 w-full">
					<span className="text-slate-700 dark:text-slate-400">{index + 1}</span>
					{' / '}
					<span className="text-slate-400 dark:text-slate-200">
						{positionCandidates.length}
					</span>
				</div>
				<button
					type="button"
					className={clsx(
						'm-1 rounded-sm outline outline-1 outline-gray-100 dark:text-white dark:bg-gray-800 shadow-sm',
						{
							'bg-slate-200 dark:hover:bg-gray-700 cursor-not-allowed': !canGoLeft,
							'hover:bg-slate-100 dark:hover:bg-slate-600': canGoLeft,
						}
					)}
					onClick={handleRight}
					disabled={!canGoLeft}
				>
					<HiChevronLeft size={'1.5em'} />
				</button>
				<button
					type="button"
					className={clsx(
						'm-1 rounded-sm outline outline-1 outline-gray-100 dark:text-white dark:bg-gray-700 shadow-sm ',
						{
							'bg-slate-200 dark:hover:bg-gray-700 cursor-not-allowed': !canGoRight,
							'hover:bg-slate-100 dark:hover:bg-slate-600': canGoRight,
						}
					)}
					onClick={handleLeft}
					disabled={!canGoRight}
				>
					<HiChevronRight size={'1.5em'} />
				</button>
			</div>
			<button
				type="button"
				className={clsx(
					'mr-6 rounded-sm outline outline-1 outline-gray-100 dark:text-white dark:bg-gray-700 shadow-sm '
				)}
				onClick={() => router.push(`/tasks`)}
			>
				<HiX size={'1.5em'} />
			</button>
		</div>
	)
})

type CardDetailedViewColumnProps = {
	positionCandidate: IPositionCandidate
	loadingCandidates?: boolean
}

export const CardDetailedViewColumn = observer(function ({
	positionCandidate,
	loadingCandidates,
}: CardDetailedViewColumnProps) {
	const [boardEntries, setBoardEntries] = useState(store.boardEntries)

	useEffect(() => {
		if (!boardEntries || boardEntries.length === 0) {
			rootStore.positions
				.fetchPositionCandidates(positionCandidate.positionId)
				.then((positionCandidates) => {
					if (positionCandidates)
						setBoardEntries(positionCandidates.map(positionCandidateToBoardEntry))
				})
		}
	}, [positionCandidate, boardEntries])

	return (
		<div className="hidden xl:flex overflow-x-hidden w-96 overflow-y-hidden">
			<BoardColumn
				key={positionCandidate.positionId}
				positionId={positionCandidate.positionId}
				boardEntries={boardEntries}
				showAsCard={false}
				useIntersectionRenderer={false}
				loadingCandidates={!!loadingCandidates}
			/>
		</div>
	)
})
