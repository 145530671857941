import { ObservableMap, makeAutoObservable } from 'mobx'
import type { RootStore } from './root'
import {
	addTentativeCandidate,
	deleteTentativeCandidate,
	fetchPositionsWithTentativeCandidates,
	fetchTentativeCandidate,
	fetchTentativeCandidates,
	fetchUpdatedTentativeCandidates,
	rejectTentativeCandidate,
	sourceTentativeCandidate,
} from '@requests/candidates'
import type { ITentativeCandidate } from '@touchpoints/requests'

export class TentativeCandidatesStore {
	private readonly root: RootStore

	readonly tentativeCandidatesByPositionId = new ObservableMap<string, ITentativeCandidate[]>()

	readonly positionIds: string[] = []

	private lastUpdateFetchTime = Date.now()

	get positions() {
		return this.positionIds
			.map((id) => this.root.positions.getPositionById(id))
			.filter((p) => !!p)
	}

	constructor(root: RootStore) {
		this.root = root

		makeAutoObservable(this)
	}

	async fetchTentativeCandidates(positionId: string) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		this.lastUpdateFetchTime = Date.now()
		const res = await fetchTentativeCandidates(orgId, positionId)
		if (!res.success) {
			return
		}

		const { tentativeCandidates = [] } = res.data ?? {}

		this.tentativeCandidatesByPositionId.set(
			positionId,
			tentativeCandidates.sort(() => Math.random() - 0.5)
		)
	}

	async fetchTentativeCandidate(id: string) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await fetchTentativeCandidate(orgId, id)
		if (!res.success) {
			return
		}

		const { tentativeCandidate } = res.data ?? {}
		if (!tentativeCandidate) {
			return
		}

		if (!tentativeCandidate.positionId) {
			return
		}

		const currentTentativeCandidates =
			this.tentativeCandidatesByPositionId.get(tentativeCandidate.positionId) ?? []

		// replace if exists
		const idx = currentTentativeCandidates.findIndex((c) => c.id === id)
		if (idx >= 0) {
			currentTentativeCandidates[idx] = tentativeCandidate
		} else {
			// add if not exists
			currentTentativeCandidates.push(tentativeCandidate)
		}

		this.tentativeCandidatesByPositionId.set(tentativeCandidate.positionId, [
			...currentTentativeCandidates,
		])
	}

	async fetchPositionsWithTentativeCandidates() {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return []
		}

		const res = await fetchPositionsWithTentativeCandidates(orgId)
		if (!res.success) {
			return []
		}

		const { positionIds = [] } = res.data ?? {}

		this.positionIds.length = 0
		this.positionIds.push(...positionIds)
	}

	async fetchRecentUpdates() {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await fetchUpdatedTentativeCandidates(orgId, this.lastUpdateFetchTime)
		if (!res.success) {
			return
		}

		const { tentativeCandidates = [], lastUpdate = Date.now() } = res.data ?? {}

		this.lastUpdateFetchTime = lastUpdate

		const sourcedOrRejectedCandidates: ITentativeCandidate[] = []

		for (const candidate of tentativeCandidates) {
			if (!candidate.positionId) {
				return
			}

			const currentTentativeCandidates =
				this.tentativeCandidatesByPositionId.get(candidate.positionId) ?? []

			const idx = currentTentativeCandidates.findIndex((c) => c.id === candidate.id)

			if (candidate.sourcedBy) {
				// remove if exists
				if (idx >= 0) {
					currentTentativeCandidates.splice(idx, 1)
				}
				sourcedOrRejectedCandidates.push(candidate)
			} else if (candidate.rejectedBy) {
				// remove if exists
				if (idx >= 0) {
					currentTentativeCandidates.splice(idx, 1)
				}
				sourcedOrRejectedCandidates.push(candidate)
			} else {
				// recently created or otherwise updated so add or replace
				if (idx >= 0) {
					currentTentativeCandidates[idx] = candidate
				} else {
					// add if not exists
					currentTentativeCandidates.push(candidate)
				}
			}

			this.tentativeCandidatesByPositionId.set(candidate.positionId, [
				...currentTentativeCandidates,
			])
		}

		return sourcedOrRejectedCandidates
	}

	async addTentativeCandidate(positionId: string, data: Partial<ITentativeCandidate>) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await addTentativeCandidate(orgId, {
			...data,
			positionId,
		})

		if (!res.success) {
			return
		}

		const { tentativeCandidate } = res.data ?? {}
		if (!tentativeCandidate) {
			return
		}

		const currentTentativeCandidates =
			this.tentativeCandidatesByPositionId.get(positionId) ?? []

		this.tentativeCandidatesByPositionId.set(positionId, [
			...currentTentativeCandidates,
			tentativeCandidate,
		])

		return tentativeCandidate
	}

	async deleteTentativeCandidate(positionId: string, id: string) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return false
		}

		const list = this.tentativeCandidatesByPositionId.get(positionId) ?? []
		const candidate = list.find((c) => c.id === id)
		if (!candidate) {
			return false
		}

		const res = await deleteTentativeCandidate(orgId, id)
		if (!res.success) {
			return false
		}

		const currentTentativeCandidates =
			this.tentativeCandidatesByPositionId.get(positionId) ?? []
		this.tentativeCandidatesByPositionId.set(
			positionId,
			currentTentativeCandidates.filter((c) => c.id !== id)
		)

		return true
	}

	async reject(id: string) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return false
		}

		const positionId = this.root.positions.activePositionId
		if (!positionId) {
			return false
		}

		const [res] = await Promise.all([
			rejectTentativeCandidate(orgId, id),
			// sim a delay so that the rejection is visible
			new Promise((resolve) => setTimeout(resolve, 1000)),
		])

		if (!res.success) {
			return false
		}

		const currentTentativeCandidates =
			this.tentativeCandidatesByPositionId.get(positionId) ?? []
		this.tentativeCandidatesByPositionId.set(
			positionId,
			currentTentativeCandidates.filter((c) => c.id !== id)
		)

		return true
	}

	async accept(id: string) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return false
		}

		const positionId = this.root.positions.activePositionId
		if (!positionId) {
			return false
		}

		const res = await sourceTentativeCandidate(orgId, id)
		if (!res.success) {
			return false
		}

		const currentTentativeCandidates =
			this.tentativeCandidatesByPositionId.get(positionId) ?? []
		this.tentativeCandidatesByPositionId.set(
			positionId,
			currentTentativeCandidates.filter((c) => c.id !== id)
		)

		return true
	}
}
