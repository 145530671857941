import clsx from 'clsx'
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { HiChevronDown, HiChevronUp } from 'react-icons/hi'
import { Dropdown } from './Dropdown'
import { Label } from './Label'
import { useCustomFilterableInput } from '../hooks'
import Fuse from 'fuse.js'

export interface SelectOption {
	value: string
	label: string | ReactNode
	searchString?: string
}
type SelectProps = {
	options: SelectOption[]
	value: string | ReactNode
	onChange: (option: SelectOption) => void
	label?: string | JSX.Element
	filterable?: boolean
	placeholder?: string | JSX.Element
	tabIndex?: number
	inputPlaceholder?: string
	readOnly?: boolean
	triggerClassName?: string
}

export function Select2({
	label,
	value,
	options,
	onChange,
	placeholder,
	tabIndex,
	filterable = false,
	inputPlaceholder,
	readOnly,
	triggerClassName,
}: SelectProps) {
	const [open, setOpen] = useState(false)
	const [dropdownOptions, setDropdownOptions] = useState(options)
	const originalOptions = useMemo(() => [...options], [options])
	const [search, setSearch] = useState(
		filterable
			? new Fuse<SelectOption>(originalOptions, {
					keys: ['searchString'],
			  })
			: null
	)
	const ref = useRef<HTMLInputElement>(null)
	const { query, wrapperRef, onFocus, onBlur } = useCustomFilterableInput()

	useEffect(() => {
		search?.setCollection(originalOptions)
	}, [originalOptions, search])

	useEffect(() => {
		if (!filterable) {
			setSearch(null)
		}
		setSearch(
			new Fuse<SelectOption>(originalOptions, {
				keys: ['searchString'],
			})
		)
	}, [filterable, originalOptions])

	useEffect(() => {
		if (!query) {
			setDropdownOptions([...originalOptions])
			return
		}

		const id = setTimeout(() => {
			if (!search) {
				return
			}

			const res = search.search(query)
			const items = res.map((r) => r.item)
			setDropdownOptions(items)
			ref.current?.focus()
		}, 300)

		return () => {
			clearTimeout(id)
		}
	}, [search, query, originalOptions])
	return (
		<div className="w-full">
			{label && <Label>{label}</Label>}
			<Dropdown
				side="bottom"
				align="start"
				open={open}
				onCloseAutoFocus={() => setOpen(false)}
				onEscapeKeyDown={() => setOpen(false)}
				onPointerDownOutside={() => setOpen(false)}
				onFocusOutside={() => setOpen(false)}
				onInteractOutside={() => setOpen(false)}
				trigger={
					<div
						className={clsx(
							`flex justify-between w-full items-center border rounded-md shadow-xs px-3 py-2 hover:cursor-pointer`,
							'cursor-pointer font-light text-[#11181C] focus:outline focus:outline-[#0091FF]',
							{ 'border-[#0091FF]': open, 'bg-slate-50': !!readOnly },
							triggerClassName
						)}
						onClick={() => setOpen(!readOnly && !open)}
						tabIndex={tabIndex}
					>
						<div className="w-full flex truncate">
							{value ? (
								<Dropdown.Label className="truncate">{value}</Dropdown.Label>
							) : (
								<Dropdown.Label className="truncate text-slate-400 font-light">
									{placeholder}
								</Dropdown.Label>
							)}
						</div>
						{!open && (
							<div className="w-[16px]">
								<HiChevronDown />
							</div>
						)}
						{open && (
							<div className="w-[16px]">
								<HiChevronUp />
							</div>
						)}
					</div>
				}
			>
				{filterable && (
					<div className="flex flex-col bg-inherit sticky top-0">
						<input
							ref={wrapperRef}
							type="text"
							className={clsx(
								'w-full text-gray-900 text-sm my-1 p-2 focus:ring-blue-500 focus:border-blue-500 block dark:placeholder-gray-400 dark:text-white border-0 ring-0'
							)}
							aria-label="filter members"
							placeholder={inputPlaceholder}
							value={query}
							onFocus={onFocus}
							onBlur={onBlur}
							onChange={() => {
								//do nothing...
							}}
						/>
						<hr className="h-0 w-full border-t border-slate-100"></hr>
					</div>
				)}
				<div className="px-2 w-full border">
					{dropdownOptions.map((option, index) => {
						const isString = typeof option.label === 'string'
						return (
							<Dropdown.Item
								key={`${option.value}-${index}`}
								onSelect={() => {
									onChange(option)
									setOpen(false)
								}}
							>
								{isString && (
									<p className="font-light text-sm leading-4 p-3 hover:bg-slate-50 truncate">
										{option.label}
									</p>
								)}
								{!isString && <div className="px-2 py-1">{option.label}</div>}
							</Dropdown.Item>
						)
					})}
				</div>
			</Dropdown>
		</div>
	)
}
