import { UsersStore } from './users'
import { OrganizationsStore } from './organizations'
import { OrganizationUsersStore } from './organizationUsers'
import { SequencesStore } from './sequences'
import { CandidatesStore } from './candidates'
import { PositionsStore } from './positions'
import { AccountsStore } from './accounts'
import { OnboardingStore } from './onboarding'
import { TemplateStore } from './templates'
import { SnippetStore } from './snippets'
import { ExtensionStore } from './extension'
import { StagesStore } from './stages'
import { InvitesStore } from './invites'
import { PositionAvailabilities, CandidateSelectionsStore } from './schedules'
import { Settings } from './settings'
import { TeamsStore } from './teams'
import { MeetingsStore } from './meetings'
import { TasksStore } from './tasks'
import { TriggerActionsStore } from './triggers'
import { BlueprintsStore } from './blueprints'
import { QuickSearchStore } from './quickSearch'
import { NotificationsStore } from './notifications'
import { UIStore } from './ui'
import { FavoriteBoardsStore } from './favoriteBoards'
import { StatsStore } from './stats'
import { CleaningStore } from './cleaning'
import { RecentStore } from './recent'
import { StageTemplateStore } from './stageTemplates'
import { TentativeCandidatesStore } from './tentativeCandidates'
import { FunnelsStore } from './funnels'
import { QuestionsStore } from './questions'
import { CallScriptsStore } from './callScripts'

export class RootStore {
	readonly users: UsersStore
	readonly organizations: OrganizationsStore
	readonly organizationUsers: OrganizationUsersStore
	readonly sequences: SequencesStore
	readonly candidates: CandidatesStore
	readonly candidateSelections: CandidateSelectionsStore
	readonly positions: PositionsStore
	readonly positionAvailabilities: PositionAvailabilities
	readonly accounts: AccountsStore
	readonly onboarding: OnboardingStore
	readonly templates: TemplateStore
	readonly snippets: SnippetStore
	readonly stages: StagesStore
	readonly invites: InvitesStore
	readonly teams: TeamsStore
	readonly meetings: MeetingsStore
	readonly tasks: TasksStore
	readonly triggerActions: TriggerActionsStore
	readonly blueprints: BlueprintsStore
	readonly favoriteBoards: FavoriteBoardsStore
	readonly cleaning: CleaningStore
	readonly stageTemplates: StageTemplateStore
	readonly tentativeCandidates: TentativeCandidatesStore
	readonly funnels: FunnelsStore
	readonly questions: QuestionsStore
	readonly callScripts: CallScriptsStore
	readonly notifications: NotificationsStore

	readonly settings: Settings
	readonly extension: ExtensionStore

	readonly quickSearch: QuickSearchStore
	readonly stats: StatsStore

	readonly recent: RecentStore

	readonly ui = new UIStore()

	get isLoading() {
		return this.users.isLoading || this.organizations.isLoading
	}

	constructor() {
		this.users = new UsersStore()
		this.organizations = new OrganizationsStore()
		this.organizationUsers = new OrganizationUsersStore(this)
		this.tasks = new TasksStore(this)
		this.candidates = new CandidatesStore(this)
		this.positions = new PositionsStore(this)
		this.settings = new Settings(this)
		this.sequences = new SequencesStore(this)
		this.positionAvailabilities = new PositionAvailabilities(this)
		this.candidateSelections = new CandidateSelectionsStore(this)
		this.accounts = new AccountsStore(this)
		this.onboarding = new OnboardingStore(this)
		this.templates = new TemplateStore(this)
		this.snippets = new SnippetStore(this)
		this.stages = new StagesStore(this)
		this.teams = new TeamsStore(this)
		this.meetings = new MeetingsStore(this)
		this.triggerActions = new TriggerActionsStore(this)
		this.blueprints = new BlueprintsStore(this)
		this.invites = new InvitesStore(this)
		this.favoriteBoards = new FavoriteBoardsStore(this)
		this.cleaning = new CleaningStore(this)
		this.stageTemplates = new StageTemplateStore(this)
		this.tentativeCandidates = new TentativeCandidatesStore(this)
		this.funnels = new FunnelsStore(this)
		this.questions = new QuestionsStore(this)
		this.callScripts = new CallScriptsStore(this)
		this.notifications = new NotificationsStore(this)
		this.extension = new ExtensionStore()

		this.quickSearch = new QuickSearchStore()
		this.stats = new StatsStore(this)

		this.recent = new RecentStore(this)
	}
}
