'use client'
import { rootStore } from '@store/index'
import { PositionsIcon } from '@touchpoints/icons'
import { useReaction } from '@touchpoints/mobx-hooks'
import { ITask } from '@touchpoints/requests'
import { Pill, useCustomFilterableInput } from '@touchpoints/ui'
import type { PositionExpanded } from '@types'
import clsx from 'clsx'
import type { DeepPartial, FlowbiteDropdownTheme } from 'flowbite-react'
import { Dropdown } from 'flowbite-react'
import Fuse from 'fuse.js'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'

const toPositionOption = (position: (PositionExpanded & { referenceName: string }) | undefined) => {
	if (position) {
		return {
			value: position.id,
			label: position.name,
			refName: position.referenceName,
		}
	}
	return emptyPositionOption()
}

const emptyPositionOption = () => {
	return { value: 'noposition', label: 'No position', refName: '--' }
}

const CUSTOM_THEME: DeepPartial<FlowbiteDropdownTheme> = {
	content: 'm-0 w-full',
	floating: {
		base: 'outline-none z-[1001] rounded rounded-l',
	},
}

const CUSTOM_ITEM_THEME = {
	container: 'p-1 m-1 rounded hover:bg-slate-100 dark:hover:bg-slate-500',
	base: 'flex items-center justify-start text-sm text-gray-700 cursor-pointer w-full focus:outline-none dark:text-white',
	icon: 'mr-2 h-4 w-4',
}

type TaskCandidateProps = {
	task?: Partial<ITask>
	value?: string
	onChanged?: (positionId: string) => void
	positionsForCandidateId?: string // Only show positions for this candidate
	rightIcon?: React.ReactNode
	itemClassName?: string
	filterable?: boolean
}
export const TaskPosition = observer(function TaskPosition({
	task,
	value,
	onChanged,
	rightIcon,
	itemClassName,
	filterable,
	positionsForCandidateId,
}: TaskCandidateProps) {
	const [positionId, setPositionId] = useState<string>(value ?? task?.data?.positionId ?? '')
	const [position, setPosition] = useState<
		(PositionExpanded & { referenceName: string }) | undefined
	>(undefined)
	const [positionOptions, setPositionOptions] = useState<
		{ value: string; label: string; refName: string }[]
	>([])

	const { query, wrapperRef, onFocus, onBlur } = useCustomFilterableInput()

	const rootStoreOptions = useReaction(
		() => [emptyPositionOption(), ...rootStore.positions.list.map(toPositionOption)],
		150,
		[rootStore.positions.list]
	)

	const [search, setSearch] = useState(
		filterable
			? new Fuse<{ value: string; label: string; refName: string }>(rootStoreOptions, {
					keys: ['label', 'value', 'refName'],
			  })
			: null
	)

	const handleUpdatePosition = async (positionId: string) => {
		onChanged?.(positionId)
		setPositionId(positionId)
	}

	function labelForPosition(positionId: string) {
		if (!positionId || positionId === 'noposition') {
			return <span className="text-slate-500">No position</span>
		}
		return position?.referenceName
	}

	useEffect(() => {
		if (!value) {
			return
		}
		setPositionId(value)
	}, [value])

	useEffect(() => {
		if (!filterable) {
			setSearch(null)
		}
		setSearch(
			new Fuse<{ value: string; label: string; refName: string }>(rootStoreOptions, {
				keys: ['label', 'value', 'refName'],
			})
		)
	}, [filterable, rootStoreOptions])

	useEffect(() => {
		search?.setCollection(rootStoreOptions)
	}, [search, rootStoreOptions])

	useEffect(() => {
		if (positionId) {
			setPosition(rootStore.positions.getPositionById(positionId))
		}
	}, [positionId])

	useEffect(() => {
		if (!query) {
			setPositionOptions(rootStoreOptions.slice(0, 10))
			return
		}

		const id = setTimeout(() => {
			if (!search) {
				return
			}

			const res = search.search(query)
			const items = res.map((i) => i.item)
			setPositionOptions(items.slice(0, 10))
		}, 300)

		return () => {
			clearTimeout(id)
		}
	}, [search, query, rootStoreOptions])

	// Fetch positions for this candidate
	useEffect(() => {
		if (!positionsForCandidateId || positionsForCandidateId === 'nocandidate') {
			setPositionOptions(rootStoreOptions.slice(0, 10))

			return
		}

		const positionCandidates =
			rootStore.positions.getPositionCandidatesForCandidate(positionsForCandidateId)
		const result = positionCandidates
			.map((p) => rootStore.positions.getPositionById(p.positionId))
			.filter((p) => !!p) as PositionExpanded[]

		const options = result
			? [emptyPositionOption(), ...result.map(toPositionOption)]
			: rootStoreOptions
		setPositionOptions(options.slice(0, 10))
	}, [positionsForCandidateId, rootStoreOptions])

	return (
		<div ref={wrapperRef}>
			<Dropdown
				label="Candidate"
				disabled={task ? !!task.completedAt : false}
				inline
				theme={CUSTOM_THEME}
				renderTrigger={() => (
					<div
						className={clsx(
							'shadow-sm rounded px-2 border border-slate-100 h-[34px] w-full min-w-fit flex flex-row items-center justify-between dark:bg-slate-700 dark:border-slate-700',
							{
								'cursor-pointer': task ? !task.completedAt : true,
								'cursor-not-allowed': task ? !!task.completedAt : false,
								'pointer-events-none': task ? !!task.completedAt : false,
								'bg-gray-50': task ? !!task.completedAt : false,
							}
						)}
					>
						<PositionsIcon />
						&nbsp;&nbsp;
						<span className="whitespace-nowrap flex flex-grow">
							{labelForPosition(positionId)}
						</span>
						{rightIcon}
					</div>
				)}
			>
				{filterable && (
					<>
						<div className="flex p-1">
							<input
								type="text"
								className={clsx(
									'w-full text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block p-1 dark:placeholder-gray-400 dark:text-white border-0 ring-0'
								)}
								aria-label="Add position"
								placeholder="Add position"
								value={query}
								onFocus={onFocus}
								onBlur={onBlur}
								onChange={() => {
									// Do nothing
								}}
							/>
						</div>
						<hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
					</>
				)}
				{positionOptions.map((ao, index) => {
					return (
						<Dropdown.Item
							key={`${index}`}
							theme={CUSTOM_ITEM_THEME}
							onClick={() => handleUpdatePosition(ao.value)}
							className={itemClassName}
						>
							<Pill size="xs" color="bg-slate-100">
								{ao.refName}
							</Pill>
							&nbsp;{ao.label}
						</Dropdown.Item>
					)
				})}
			</Dropdown>
		</div>
	)
})
