import { useRef, useState } from 'react'
import { Editor } from '@tinymce/tinymce-react'

import type { Editor as TinyMCEEditor } from 'tinymce'
import { makeAutoObservable } from 'mobx'
import { useReaction } from '@touchpoints/mobx-hooks'

function TextEditor({
	setInstance,
	onDirty,
	readonly = false,
	toolbar = true,
}: {
	setInstance: (instance: TinyMCEEditor) => void
	onDirty?: () => void
} & Options) {
	return (
		<>
			<Editor
				apiKey={process.env.NEXT_PUBLIC_TINYMCE_API_KEY}
				disabled={readonly}
				init={{
					width: '100%',
					height: '100%',
					menubar: false,
					force_br_newlines: false,
					force_p_newlines: true,
					forced_root_block: false,
					remove_trailing_brs: true,
					resize: false,
					relative_urls: false,
					convert_urls: false,
					plugins: [
						'advlist autolink lists link image',
						// 'charmap print preview anchor help',
						// 'searchreplace visualblocks code',
						'insertdatetime media table paste wordcount',
						'code',
					],
					toolbar: toolbar
						? 'code | fontselect | fontsizeselect | forecolor backcolor | bold italic underline | link | \
						undo redo | alignleft aligncenter alignright | \
            bullist numlist outdent indent image | help'
						: false,
				}}
				onInit={(_evt, editor) => setInstance(editor)}
				onEditorChange={() => onDirty?.()}
				// onDirty={onDirty}
			/>
			<style global jsx>{`
				.tox-tinymce-aux {
					z-index: 10000 !important;
				}
			`}</style>
		</>
	)
}

const store = makeAutoObservable({
	dirty: false,
	setDirty(dirty: boolean) {
		this.dirty = dirty
	},
})

type Options = {
	readonly?: boolean
	toolbar?: boolean
}
export function useEditor(options: Options = {}) {
	const [instance, setInstance] = useState<TinyMCEEditor>()
	const dirty = useReaction(() => store.dirty, 100)
	const component = useRef(function () {
		return (
			<TextEditor
				{...options}
				setInstance={setInstance}
				onDirty={() => {
					store.setDirty(true)
				}}
			/>
		)
	})

	return {
		instance,
		dirty,
		component: component.current,
		setClean() {
			store.setDirty(false)
			instance?.setDirty(false)
		},
	}
}
