import type { IResourceComment } from './comments'
import { ITriggerDelayUnit } from './triggers'

export const mapTaskPriority = (priority: number) => {
	return priority === 0 ? 'High' : priority === 1 ? 'Medium' : 'Low'
}

export type TaskType =
	| 'manual-email'
	| 'manual-text-message'
	| 'manual-linkedin-message'
	| 'manual-generic'

export type TaskStatus = 'pending' | 'completed' | 'canceled' | 'failed' | 'closed'

export interface ITask {
	id: string
	referenceNumber: string
	createdAt: number
	organizationId: string
	assignedUserId: string
	createdByOrgUserId?: string
	type: TaskType
	status: TaskStatus
	sequenceInstanceId?: string
	dueBy?: number
	dueInMs?: number
	dueInDelayUnit?: ITriggerDelayUnit
	completedAt?: number | null
	data?: Record<string, any>
	priority: number
	isPrivate?: boolean

	comments?: IResourceComment[]
	activity?: ITaskActivity[]

	candidateStageId?: string
	candidateStageTriggerId?: string

	// this is temp while we are migrating Recruitful systems to Touchpoints
	// tasks that are coming from an Airtable will have this field
	airtableRecordId?: string
}

export type TaskProps = Pick<
	ITask,
	| 'type'
	| 'status'
	| 'organizationId'
	| 'assignedUserId'
	| 'sequenceInstanceId'
	| 'priority'
	| 'isPrivate'
	| 'dueBy'
	| 'createdByOrgUserId'
	| 'airtableRecordId'
> & { data?: Record<string, any> }

export type TaskActivityType = 'created' | 'updated' | 'assigned' | 'completed' | 'closed'
export interface TaskActivityCreatedPayload {
	createdByOrgUserId?: string
}

export interface TaskActivityUpdatedPayload {
	updatedByOrgUserId: string
	changes: {
		field: string
		oldValue: any
		newValue: any
	}[]
}

export interface TaskActivityAssignedPayload {
	assignedByOrgUserId: string
	assignedToOrgUserId: string
}

export interface TaskActivityCompletedPayload {
	completedByOrgUserId: string
}

export interface TaskActivityClosedPayload {
	closedByOrgUserId: string
}

export type TaskActivityPayload =
	| TaskActivityCreatedPayload
	| TaskActivityUpdatedPayload
	| TaskActivityAssignedPayload
	| TaskActivityCompletedPayload
	| TaskActivityClosedPayload

export interface ITaskActivity {
	ts: number
	type: TaskActivityType
	payload: TaskActivityPayload

	[key: string]: any
}
