import { orgUserDisplay } from '@services/format'
import { rootStore } from '@store'
import flatten from 'lodash/flatten'
import { useReaction } from './mobx'
import { useEffect, useState } from 'react'

export function useOrgUserOptions(includeNone = false, includeInactive = false) {
	const users = rootStore.organizationUsers.usersForActiveOrg
		.filter((u) => {
			if (includeInactive) {
				return true
			}
			return u.status === 'active'
		})
		.map((u) => ({
			value: u.id,
			label: orgUserDisplay(u),
		}))

	if (includeNone) {
		users.unshift({ value: '', label: 'None' })
	}

	return users
}

export function useRecruiterOptions(mailboxId?: string) {
	const recruiterMailboxes = useReaction(() =>
		flatten(
			rootStore.organizationUsers.usersForActiveOrg
				.filter((user) => user.mailboxes.length > 0)
				.map((user) =>
					user.mailboxes.map((m) => ({
						id: user.userId,
						firstName: user.firstName,
						lastName: user.lastName,
						email: m.email,
						signature: m.signature,
					}))
				)
		)
	)

	const recruiterOptions = recruiterMailboxes.map((r, idx) => ({
		id: r.id,
		email: r.email,
		label: `${r.email} (${r.firstName || 'No'} ${r.lastName || 'Name'})`,
		value: `${idx}`,
	}))

	const selectedOption = recruiterOptions.find((m) => m.value === mailboxId)
	const recruiterMailbox = selectedOption
		? recruiterMailboxes.find((m) => m.email === selectedOption.email)
		: undefined

	return [recruiterOptions, recruiterMailbox] as const
}

export function useMissingSignatureMessage(mailbox?: { email: string }) {
	const [data, setData] = useState<{
		title: string
		message: string
		confirmColor: 'error' | 'warning' | 'success' | 'default' | 'primary' | 'secondary'
		confirmText: string
		cancelText: string
	}>({
		title: 'Missing Signature',
		message: '',
		confirmColor: 'success',
		confirmText: 'Add candidate anyway',
		cancelText: 'Cancel',
	})

	useEffect(() => {
		if (!mailbox) {
			return
		}

		setData((prev) => ({
			...prev,
			message: `You are missing the email signature for the following account: ${mailbox?.email}. Please add it in your settings.`,
		}))
	}, [mailbox])

	return data
}
