import { PropsWithChildren, ReactNode } from 'react'
import * as RadixDropdown from '@radix-ui/react-dropdown-menu'
import { styled } from '@stitches/react'

type DropdownProps = {
	trigger?: ReactNode
}

const Trigger = styled(RadixDropdown.Trigger, {
	background: 'transparent',
	border: '0px',
	outline: '1px solid transparent',
	width: '100%',
})

const TriggerItem = styled(RadixDropdown.SubTrigger, {
	background: 'transparent',
	border: '0px',
	outline: 'none',
	width: '100%',
})

const Content = styled(RadixDropdown.Content, {
	background: 'white',
	zIndex: 100,
	borderRadius: '10px',
	boxShadow: '0 8px 30px rgba(0, 0, 0, 0.15)',
	maxHeight: '400px',
	overflow: 'hidden',
	overflowY: 'auto',
	width: '100%',
})

const Label = styled(RadixDropdown.Label, {
	display: 'flex',
	alignItems: 'center',
})

const Separator = styled(RadixDropdown.Separator, {
	marginTop: '10px',
	marginBottom: '10px',
	border: '1px solid #eaeaea',
	opacity: 0.5,
})

const Item = styled(RadixDropdown.Item, {
	outline: 'none',
	marginTop: '5px',
	marginBottom: '5px',
	padding: '2px',
	cursor: 'pointer',
	'&:hover': { background: '#f7f7f7' },
})

const CheckboxItem = styled(RadixDropdown.CheckboxItem, {
	outline: 'none',
	marginTop: '5px',
	marginBottom: '5px',
	padding: '2px',
	cursor: 'pointer',
	'&:hover': { background: '#f7f7f7' },
})

function Child({
	children,
	trigger,
	open,
	defaultOpen,
	onOpenChange,
	...props
}: PropsWithChildren<
	DropdownProps & RadixDropdown.DropdownMenuContentProps & RadixDropdown.DropdownMenuProps
>) {
	return (
		<RadixDropdown.Sub open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
			<TriggerItem>{trigger}</TriggerItem>
			<RadixDropdown.SubContent {...props}>{children}</RadixDropdown.SubContent>
		</RadixDropdown.Sub>
	)
}

export function Dropdown({
	children,
	trigger,
	open,
	dir,
	defaultOpen,
	onOpenChange,
	modal,
	...props
}: PropsWithChildren<
	DropdownProps & RadixDropdown.DropdownMenuContentProps & RadixDropdown.DropdownMenuProps
>) {
	return (
		<RadixDropdown.Root
			open={open}
			dir={dir}
			defaultOpen={defaultOpen}
			onOpenChange={onOpenChange}
			modal={modal}
		>
			<Trigger>{trigger}</Trigger>
			<Content {...props}>{children}</Content>
		</RadixDropdown.Root>
	)
}

Dropdown.Label = Label
Dropdown.Separator = Separator
Dropdown.Item = Item
Dropdown.CheckboxItem = CheckboxItem
Dropdown.ItemIndicator = RadixDropdown.ItemIndicator
Dropdown.Child = Child
