import { CandidateStageAssigneeField, ICandidateStageTrigger, QuestionItem } from './organization'
import { IPositionCandidateChecklistItem } from './positions'
import { TaskType } from './tasks'

export enum TriggerActionName {
	AddCandidateToSequence = 'add-candidate-to-sequence',
	RemoveCandidateFromSequence = 'remove-candidate-from-sequence',
	CompleteSequenceForCandidate = 'complete-sequence-for-candidate',
	PauseSequenceForCandidate = 'pause-sequence-for-candidate',
	ResumeSequenceForCandidate = 'resume-sequence-for-candidate',
	ChangeCandidateStage = 'change-candidate-stage',
	CreateManualTask = 'create-manual-task',
	CreateSmarform = 'create-smartform',
	MoveToQuestion = 'move-to-question',
	CreateCandidateChecklist = 'create-candidate-checklist',
	CreateCandidateQuestionForm = 'create-candidate-question-form',
	ClearActiveActionItems = 'clear-active-action-items',
	AddEventToCandidate = 'add-event-to-candidate',
	RespondToTimedEvent = 'respond-to-timed-event',
	AddCommentToCandidate = 'add-comment-to-candidate',

	CreateStageAlert = 'create-stage-alert',

	UseTriggerTemplate = 'use-trigger-template',
}

export enum TriggerType {
	Extension = 'extension',
	Sequence = 'sequence',
	CandidateStage = 'candidate-stage',
}

export enum TriggerEvent {
	CandidateStageChanged = 'candidate-stage-changed',
	CandidateOpenedEmail = 'candidate-opened-email',

	CandidateRepliedToSequence = 'candidate-replied-to-sequence',
	CandidateBookedMeeting = 'candidate-booted-meeting',
	CandidateAddedToSequence = 'candidate-added-to-sequence',
	CandidateFinishesSequence = 'candidate-finishes-sequence',

	CandidateChecklistOptionCreated = 'candidate-checklist-option-created',

	CandidateActionItemCompleted = 'candidate-action-item-completed',
	CandidateChecklistOptionCompleted = 'candidate-checklist-option-completed',
	CandidateQuestionOptionSelected = 'candidate-question-option-selected',

	FirstSequenceStepCompleted = 'first-sequence-step-completed',

	TimedEventFired = 'timed-event-fired',
	StageAlertFired = 'stage-alert-fired',
	DelayedTriggerAction = 'delayed-trigger-action',

	SnippetSentInEmail = 'snippet-sent-in-email',

	VettingCallBooked = 'vetting-call-booked',

	PositionCandidateChanged = 'position-candidate-changed',
	PositionOpened = 'position-opened',
	PositionClosed = 'position-closed',

	TestAction = 'test-action',

	None = 'none',
}

export type TriggerResponse = {
	success: boolean
	message?: string
	data?: Record<string, any>
}

export type TriggerOptions = {
	_id?: string
	userId?: string
	organizationId?: string
	candidateId?: string
	sequenceId?: string
	sequenceInstanceId?: string
	sequenceName?: string
	threadId?: string

	positionId?: string
	candidateEmail?: string
	accountId?: string
	candidateStageId?: string

	snippetId?: string
	taskPriority?: number
	taskDueIn?: number

	from?: string
	title?: string
	subject?: string
	html?: string
	trackingId?: string

	actionItemId?: string
	checklistItemId?: string
	questionItemId?: string
	questionItemOptionId?: string

	positionCandidateId?: string
	timedEventId?: string
	timedEventDelay?: number
	meetingTime?: number
	meetingInstanceId?: string

	actionId?: string

	meetingId?: string

	jobId?: string

	ignoreDelay?: boolean
	delayedTriggerAction?: {
		actionName: TriggerActionName
		triggerList: (ICandidateStageTrigger | AnyTriggerAction)[]
		options: TriggerOptions
	}
}

/**
 * @deprecated use ITriggerAction instead
 */
export interface ITrigger {
	id: string
	type: TriggerType
	event: TriggerEvent
	action: TriggerActionName

	templateId?: string

	triggers?: ITrigger[]

	[key: string]: any
}

export interface ITriggerActionReference {
	id: string
	event: TriggerEvent
	referenceId: string
	stageTemplateId?: string

	[key: string]: any
}

export type ITriggerDelayUnit = 'milliseconds' | 'seconds' | 'minutes' | 'hours' | 'days' | 'weeks'
export interface ITriggerAction {
	id: string
	event: TriggerEvent
	actionName: TriggerActionName

	displayName?: string

	organizationId: string
	createdBy: string
	createdAt: number

	triggerActions: ITriggerActionReference[]

	delayMs?: number
	delayDisplayUnit?: ITriggerDelayUnit

	[key: string]: any
}

export interface ICandidateStageTriggerAction extends ITriggerAction {
	candidateStageId?: string
}

export interface ITaskTriggerAction extends ITriggerAction {
	taskType: TaskType
	taskPriority: number
	taskDueIn?: number
	taskDueInDelayUnit?: ITriggerDelayUnit

	// NOTE: can be a direct orgUserId or a CandidateStageAssigneeField
	assigneeField?: CandidateStageAssigneeField | string

	title?: string
	subject?: string
	cc?: string
	html?: string
	linkedTemplateId?: string
}

export interface IChecklistTriggerAction extends ITriggerAction {
	checklistItems?: IPositionCandidateChecklistItem[]
	groupedActions?: GroupedAction[]
}

export interface IQuestionFormTriggerAction extends ITriggerAction {
	questionItems?: QuestionItem[]
}

export interface GroupedAction {
	// Actions triggered when multiple items are completed
	condition: string[]
	action: ITriggerActionReference | ITriggerAction
}

export type ChecklistItem = {
	id: string
	title: string
	options?: IPositionCandidateChecklistItem[]

	groupedActions?: GroupedAction[]
}

export interface ISmarformTriggerAction extends ITriggerAction {
	items: (QuestionItem | ChecklistItem)[]
}

export interface IMoveToQuestionTriggerAction extends ITriggerAction {
	stageId: string
	smartformItemId: string
	moveToItemId: string
}

export interface IAddCandidateEventTriggerAction extends ITriggerAction {
	eventTypeId: string

	timedEvents?: {
		id: string
		name: string
		timeOffset: number
	}[]
}

export interface ITimedEventFiredTriggerAction extends ITriggerAction {
	actionId: string
	timedEventId: string
	ignorePastEvents?: boolean
}

export interface IAddCandidateToSequenceTriggerAction extends ITriggerAction {
	sequenceId: string
	positionId?: string

	senderField?: CandidateStageAssigneeField | 'defaultMailbox'
	delayMs?: number
}

export interface IRemoveCandidateFromSequenceTriggerAction extends ITriggerAction {
	sequenceId?: string
}

export interface ICompleteSequenceForCandidateTriggerAction extends ITriggerAction {
	sequenceId?: string
}

export interface IPauseSequenceTriggerAction extends ITriggerAction {
	sequenceId?: string
}

export interface IResumeSequenceTriggerAction extends ITriggerAction {
	sequenceId?: string
}

export interface ICreateStageAlertTriggerAction extends ITriggerAction {
	duration: number
}

export interface IAddCommentToCandidateTriggerAction extends ITriggerAction {
	actionName: TriggerActionName.AddCommentToCandidate
	comment: string
	postAs?: string
}

export interface ISnippetTriggerAction extends ITriggerAction {
	sequenceId?: string
	positionId?: string
	candidateStageId?: string
}

export interface IDelayedTriggerAction extends ITriggerAction {
	delayedAction: {
		actionName: string
		trigger: ITriggerAction
		options: TriggerOptions
	}
}

export type AnyTriggerAction = ITriggerAction &
	Partial<ICandidateStageTriggerAction> &
	Partial<ITaskTriggerAction> &
	Partial<IChecklistTriggerAction> &
	Partial<ISmarformTriggerAction> &
	Partial<IQuestionFormTriggerAction> &
	Partial<IMoveToQuestionTriggerAction> &
	Partial<ITriggerActionReference> &
	Partial<IAddCandidateEventTriggerAction> &
	Partial<ITimedEventFiredTriggerAction> &
	Partial<IAddCandidateToSequenceTriggerAction> &
	Partial<ICreateStageAlertTriggerAction> &
	Partial<Omit<IAddCommentToCandidateTriggerAction, 'actionName'>> &
	Partial<ISnippetTriggerAction>
