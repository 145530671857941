'use client'
import { Loading } from '@nextui-org/react'
import { rootStore } from '@store/index'
import { UnassignedIcon } from '@touchpoints/icons'
import { ICandidate, ITask } from '@touchpoints/requests'
import { useCustomFilterableInput } from '@touchpoints/ui'
import Avvvatars from 'avvvatars-react'
import clsx from 'clsx'
import type { DeepPartial, FlowbiteDropdownTheme } from 'flowbite-react'
import { Dropdown } from 'flowbite-react'
import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'

const candidateToOption = (c: ICandidate): { value: string; label: string; email: string } => {
	return { value: c.id, label: `${c.firstName} ${c.lastName}`, email: c.email }
}
const CUSTOM_THEME: DeepPartial<FlowbiteDropdownTheme> = {
	content: 'm-0 w-full',
	floating: {
		base: 'outline-none z-[1001] rounded rounded-l',
	},
}

const CUSTOM_ITEM_THEME = {
	container: 'p-1 m-1 rounded hover:bg-slate-100 dark:hover:bg-slate-500',
	base: 'flex items-center justify-start text-sm text-gray-700 cursor-pointer w-full focus:outline-none dark:text-white',
	icon: 'mr-2 h-4 w-4',
}

type TaskCandidateProps = {
	task?: Partial<ITask>
	value?: string
	onChanged?: (candidateId: string) => void
	rightIcon?: React.ReactNode
	itemClassName?: string
	filterable?: boolean
}
export const TaskCandidate = observer(function ({
	task,
	value,
	onChanged,
	rightIcon,
	itemClassName,
	filterable,
}: TaskCandidateProps) {
	const [candidateId, setCandidateId] = useState<string>(value ?? task?.data?.candidateId ?? '')
	const [candidate, setCandidate] = useState<ICandidate | undefined>(undefined)
	const [loading, setLoading] = useState(false)

	const baseOptions = rootStore.candidates.list
		.filter((c) => !rootStore.positions.activeCandidatesSet.has(c.id))
		.splice(0, 10)
		.map((u) => candidateToOption(u))

	const originalCandidateOptions = useMemo(
		() => [
			// only show unassigned if there is no assignee
			...(!candidateId
				? [{ value: 'nocandidate', label: 'No candidate', email: undefined }]
				: []),
			...baseOptions,
		],
		[candidateId, baseOptions]
	)
	const [candidateOptions, setCandidateOptions] = useState([...originalCandidateOptions])
	const { query, wrapperRef, onFocus, onBlur } = useCustomFilterableInput()

	useEffect(() => {
		if (!value) {
			return
		}

		setCandidateId(value)
	}, [value])

	const handleUpdateCandidate = async (candidateId: string) => {
		onChanged?.(candidateId)
		setCandidateId(candidateId)
	}

	useEffect(() => {
		if (candidateId) {
			setCandidate(rootStore.candidates.getCandidateById(candidateId))
		}
	}, [candidateId])

	useEffect(() => {
		if (!query) {
			setCandidateOptions([...originalCandidateOptions])
			return
		}

		const loadCandidates = debounce(async (query: string): Promise<void> => {
			setLoading(true)
			const pageResult = await rootStore.candidates.fetchCandidatesPage({
				page: 1,
				rows: 10,
				filter: { searchTerm: query },
			})
			if (pageResult?.items) {
				setCandidateOptions(pageResult.items?.map((c) => candidateToOption(c)))
			} else {
				setCandidateOptions([])
			}
			setLoading(false)
		}, 300)

		const id = setTimeout(() => {
			loadCandidates(query)
		}, 300)

		return () => {
			clearTimeout(id)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query])

	function iconForCandidate(candidateId: string) {
		if (!candidateId) return <UnassignedIcon />
		return (
			<Avvvatars
				value={`${candidate?.firstName ?? 'Unknown'} ${candidate?.lastName ?? ''}`}
				size={20}
			/>
		)
	}

	function labelForCandidate(candidateId: string) {
		if (!candidateId) {
			return <span className="text-slate-500">No candidate</span>
		}
		if (candidateId === 'nocandidate') {
			return 'No candidate'
		}
		return `${candidate?.firstName ?? 'Unknown'} ${candidate?.lastName ?? ''}`
	}

	return (
		<div>
			<Dropdown
				label="Candidate"
				disabled={task ? !!task.completedAt : false}
				inline
				theme={CUSTOM_THEME}
				renderTrigger={() => (
					<div
						className={clsx(
							'shadow-sm rounded px-2 border border-slate-100 h-[34px] min-w-fit flex flex-row items-center justify-between dark:bg-slate-700 dark:border-slate-700',
							{
								'cursor-pointer': task ? !task.completedAt : true,
								'cursor-not-allowed': task ? !!task.completedAt : false,
								'pointer-events-none': task ? !!task.completedAt : false,
								'bg-gray-50': task ? !!task.completedAt : false,
							}
						)}
					>
						{iconForCandidate(candidateId)} &nbsp;&nbsp;
						<span className="whitespace-nowrap flex flex-grow">
							{labelForCandidate(candidateId)}
						</span>
						{rightIcon}
					</div>
				)}
			>
				{filterable && (
					<>
						<div className="flex p-1">
							<input
								ref={wrapperRef}
								type="text"
								className={clsx(
									'w-full text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block p-1 dark:placeholder-gray-400 dark:text-white border-0 ring-0'
								)}
								aria-label="Add candidates"
								placeholder="Add candidate"
								value={query}
								onFocus={onFocus}
								onBlur={onBlur}
								onChange={() => ''}
							/>
						</div>
						<hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
					</>
				)}
				{!loading &&
					candidateOptions.map((ao, index) => {
						return (
							<Dropdown.Item
								key={`${index}`}
								theme={CUSTOM_ITEM_THEME}
								onClick={() => handleUpdateCandidate(ao.value)}
								className={itemClassName}
							>
								{ao.value === 'nocandidate' && <UnassignedIcon />}
								{ao.value !== 'nocandidate' && (
									<Avvvatars value={ao.label} size={20} />
								)}
								&nbsp;{ao.label} {ao.email && `| ${ao.email}`}
							</Dropdown.Item>
						)
					})}
				{loading && (
					<div className="flex flex-row justify-center my-2">
						<Loading size="sm"></Loading>
					</div>
				)}
			</Dropdown>
		</div>
	)
})
