import { authHeader, get } from './api'

export type Stats = { all: number; today: number; tomorrow: number }

export async function getEmailStats(organizationId: string) {
	const headers = await authHeader()
	return get<{
		queuedByEmail: Record<string, Stats>
		queuedByPositionByEmail: Record<string, Record<string, Stats>>
		queuedBySequenceByEmail: Record<string, Record<string, Stats>>
	}>(`/organizations/${organizationId}/stats/emails`, undefined, headers)
}
