import { authHeader, get, patch, post, del } from '@requests/api'
import { AirtableRecord } from './types'

export enum ClientRecordField {
	Name = 'Name',
	ShortName = 'Short Name',
	Status = 'Client Status',
	Benefits = 'Benefits',
	Hook = 'Company Hook',
}

export async function getClientRecords(orgId: string) {
	const headers = await authHeader()
	return get<{ records: AirtableRecord[] }>(
		`/organizations/${orgId}/airtable/clients`,
		undefined,
		headers
	)
}

export async function addClientRecord(
	orgId: string,
	fields: Partial<Record<ClientRecordField, any>>
) {
	const headers = await authHeader()
	return post<{ record: AirtableRecord }>(
		`/organizations/${orgId}/airtable/clients`,
		{
			fields: fields as Record<string, any>,
		},
		headers
	)
}

export async function updateClientRecord(
	orgId: string,
	clientId: string,
	changes: Record<string, any>
) {
	const headers = await authHeader()
	return patch<{ record: AirtableRecord }>(
		`/organizations/${orgId}/airtable/clients/${clientId}`,
		{
			record: changes,
		},
		headers
	)
}

export async function deleteClientRecord(orgId: string, clientId: string) {
	const headers = await authHeader()
	return del<{ record: AirtableRecord }>(
		`/organizations/${orgId}/airtable/clients/${clientId}`,
		headers,
		undefined
	)
}
