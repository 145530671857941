import { Text, Dropdown, Button } from '@nextui-org/react'
import { HiLink, HiPlus } from 'react-icons/hi'
import { observer } from 'mobx-react-lite'

import { SlidePanel, SlidePanelProps, toast } from '@touchpoints/ui'

import { rootStore } from '@store'

import type { ICandidate } from '@touchpoints/requests'
import { CandidateSelection } from './CandidateSelection'
import { useEffect } from 'react'
import { getCandidateScheduleMasterLink } from '@requests/candidates'

type CandidateOptionsProps = Omit<SlidePanelProps, 'children'> & {
	candidate: ICandidate
}
export const CandidateInterviews = observer(function ({
	candidate,
	...props
}: CandidateOptionsProps) {
	const positionId = rootStore.positions.activePositionId

	useEffect(() => {
		if (!positionId) {
			return
		}
		// try to get latest info when the component mounts
		rootStore.candidateSelections.fetchOneCandidateSelections(positionId, candidate.id)
	}, [positionId, candidate.id])

	const handleAddSchedule = async (key: string) => {
		const position = rootStore.positions.activePosition
		if (!position) {
			// TODO: show error
			return
		}

		if (key === 'next-round') {
			await rootStore.candidateSelections.createNextRound(
				position.organizationId,
				candidate.id,
				position.id
			)
		} else {
			// key is availabilityId otherwise
			await rootStore.candidateSelections.createSchedule(
				position.organizationId,
				candidate.id,
				position.id,
				key
			)
		}
	}

	const handleGetMasterLink = async () => {
		const position = rootStore.positions.activePosition
		if (!position) {
			// TODO: show error
			return
		}

		// hit API for link
		const res = await getCandidateScheduleMasterLink(
			position.organizationId,
			candidate.id,
			position.id
		)
		if (res.success) {
			const { url } = res.data ?? {}
			if (!url) {
				// TODO: show error
			} else {
				navigator.clipboard.writeText(url)
			}
		}

		toast('Link Copied!', {
			hideProgressBar: true,
			closeButton: false,
			closeOnClick: true,
			type: 'success',
			autoClose: 2500,
		})
	}

	const selections =
		rootStore.candidateSelections.selectionsForCandidate
			.get(candidate.id)
			?.filter((s) => {
				if (!s.availabilityId) {
					return false
				}
				return rootStore.positionAvailabilities.getAvailabilityById(s.availabilityId)
			})
			?.map((s) => ({ ...s })) ?? []
	const interviews = [...rootStore.positionAvailabilities.activeAvailabilities]

	return (
		<SlidePanel
			title={
				<div className="flex justify-between items-center">
					<Text h4>
						Time Selections for {candidate.firstName} {candidate.lastName}
					</Text>
					<div className="flex items-center space-x-3">
						<Dropdown>
							<Dropdown.Button flat>
								<HiPlus /> Add
							</Dropdown.Button>
							<Dropdown.Menu onAction={(key) => handleAddSchedule(key as string)}>
								{interviews.map((interview) => {
									return (
										<Dropdown.Item key={interview.id}>
											{interview.name ||
												`${interview.meetingLength} min meeting`}
										</Dropdown.Item>
									)
								})}
							</Dropdown.Menu>
						</Dropdown>
						<Button auto color="primary" onClick={handleGetMasterLink}>
							<HiLink />
							&nbsp;Link
						</Button>
					</div>
				</div>
			}
			{...props}
		>
			{selections.length > 0 ? (
				<div className="flex flex-col space-y-3 divide-y">
					{selections.map((sel) => (
						<CandidateSelection
							key={sel.id}
							candidate={candidate}
							selection={sel}
							expanded={sel.status !== 'complete'}
						/>
					))}
				</div>
			) : (
				<div className="flex justify-center mt-10">
					<p className="italic">Create a new time selection using the button above.</p>
				</div>
			)}
		</SlidePanel>
	)
})
