import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { TaskFooter } from '@components/tasks/TasksFooter'
import { Button, Loading } from '@nextui-org/react'
import { ensureHttpInUrl } from '@services/url'
import { ICandidate, ITask } from '@touchpoints/requests'
import { Pill, toast } from '@touchpoints/ui'
import { HiClipboardCopy, HiExternalLink } from 'react-icons/hi'
import { useData } from './useTaskData'

import clsx from 'clsx'

const TaskTitle = observer(function ({ title, size }: { title: string; size?: 'sm' | 'xl' }) {
	return (
		<p
			className={clsx('leading-tight', {
				'text-2xl': size === 'xl',
				'text-lg': size === 'sm',
			})}
		>
			{title}
		</p>
	)
})

const TaskHTML = observer(function ({ html, size }: { html: string; size?: 'sm' | 'xl' }) {
	return (
		<div
			id="message-preview"
			className={clsx(
				'flex flex-col w-full py-5 text-[#11181c] leading-[21px] rounded-md max-h-96 overflow-y-auto max-w-full prose prose:w-full prose-a:text-blue-600 prose-p:my-1 prose-pre:bg-transparent prose-pre:text-black dark:bg-gray-800 dark:text-slate-400',
				{
					'text-base': size === 'xl',
					'text-sm': size === 'sm',
				}
			)}
			dangerouslySetInnerHTML={{ __html: html }}
		></div>
	)
})

interface IInnerTaskContentProps {
	task: ITask
	size?: 'sm' | 'xl'
}
const ManualEmailContent = observer(function ({ task, size }: IInnerTaskContentProps) {
	const { candidate, sender, subject, cc, html, loading } = useData(task, 'manual-email')
	return (
		<>
			<TaskTitle
				title={candidate ? `Send email to ${candidate?.email}` : 'Send Manual Email'}
			/>
			{sender && (
				<div className="flex w-full justify-start border dark:border-slate-600 rounded-md p-3 my-2">
					<b>From:&nbsp;</b>
					<p className="text-base">{sender.email}</p>
				</div>
			)}
			{candidate && (
				<div className="flex w-full justify-start border dark:border-slate-600 rounded-md p-3 my-2">
					<b>To:&nbsp;</b>
					<p className="text-base">{candidate.email}</p>
				</div>
			)}
			{cc.length > 0 && (
				<div className="flex w-full justify-start border dark:border-slate-600 rounded-md p-3 my-2">
					<b>CC:&nbsp;</b>
					{cc.map((email) => {
						return (
							<Pill size="sm" key={email} className="mr-2">
								{email}
							</Pill>
						)
					})}
				</div>
			)}
			{subject && (
				<div className="flex w-full justify-start border dark:border-slate-600 rounded-md p-3 my-2">
					<b>Subject:&nbsp;</b>
					<p className="text-base" dangerouslySetInnerHTML={{ __html: subject }}></p>
				</div>
			)}
			{loading ? (
				<div className="flex w-full p-5 justify-center items-center">
					<Loading />
				</div>
			) : (
				<TaskHTML html={html} size={size} />
			)}
		</>
	)
})

const ManualTextMessageContent = observer(function ({ task, size }: IInnerTaskContentProps) {
	const { candidate, html } = useData(task, 'manual-text-message')

	const handleCopyValue = (value: string) => {
		navigator.clipboard.writeText(value)
		toast.success('Copied to clipboard')
	}

	return (
		<>
			<TaskTitle
				title={
					candidate
						? `Send Text to ${candidate.firstName} ${candidate.lastName}`
						: 'Send Manual Text'
				}
			/>
			{candidate && size === 'xl' && (
				<div className="flex w-full justify-start items-center rounded-md p-3 border border-gray-100 dark:bg-gray-800 dark:border-gray-600">
					<b>To:&nbsp;</b>
					<p className="text-base">{candidate.phone}</p>
					<Button
						size="sm"
						auto
						light
						onClick={() => handleCopyValue(candidate.phone ?? '')}
					>
						<HiClipboardCopy className="dark:text-white" />
					</Button>
				</div>
			)}

			<div className="flex relative">
				<TaskHTML html={html} size={size} />
				{size === 'xl' && (
					<div className="absolute top-0 right-0 p-2">
						<Button
							auto
							light
							onClick={() =>
								handleCopyValue(
									document.getElementById('message-preview')?.innerText ?? ''
								)
							}
						>
							<HiClipboardCopy className="dark:text-white" />
						</Button>
					</div>
				)}
			</div>
		</>
	)
})

const ManualLinkedInMessageConetnt = observer(function ({ task, size }: IInnerTaskContentProps) {
	const { candidate, html, subject } = useData(task, 'manual-linkedin-message')

	const handleCopyValue = (value: string) => {
		navigator.clipboard.writeText(value)
		toast.success('Copied to clipboard')
	}

	return (
		<>
			<TaskTitle
				title={
					candidate
						? `Send LinkedIn Message to ${candidate.firstName} ${candidate.lastName}`
						: 'Send LinkedIn Message'
				}
			/>
			{size === 'xl' && (
				<>
					{candidate && (
						<div className="flex w-full justify-start items-center border-2 rounded-md p-3">
							<b>To:&nbsp;</b>
							<p className="text-base">{candidate.email}</p>
							<Button
								size="sm"
								auto
								light
								onClick={() => handleCopyValue(candidate.email ?? '')}
							>
								<HiClipboardCopy />
							</Button>
						</div>
					)}
					{candidate?.linkedinUrl && (
						<div className="flex w-full justify-start items-center border-2 rounded-md p-3">
							<HiExternalLink size="1.25em" />
							&nbsp;
							<a
								href={ensureHttpInUrl(candidate.linkedinUrl ?? '')}
								target="_blank"
								rel="noreferrer"
							>
								<p className="text-base">Go to Profile</p>
							</a>
						</div>
					)}
					{subject && (
						<div className="flex w-full justify-start border-2 rounded-md p-3">
							<b>Subject:&nbsp;</b>
							<p
								className="text-base w-full"
								dangerouslySetInnerHTML={{ __html: subject }}
							></p>
						</div>
					)}
				</>
			)}
			<div className="flex relative">
				<TaskHTML html={html} size={size} />
				{size === 'xl' && (
					<div className="absolute top-0 right-0 p-2">
						<Button
							auto
							light
							onClick={() =>
								handleCopyValue(
									document.getElementById('message-preview')?.innerText ?? ''
								)
							}
						>
							<HiClipboardCopy />
						</Button>
					</div>
				)}
			</div>
		</>
	)
})

const ManualGenericContent = observer(function ({ task, size }: IInnerTaskContentProps) {
	const { candidate, html } = useData(task, 'manual-generic')

	const handleCopyValue = (value: string) => {
		navigator.clipboard.writeText(value)
		toast.success('Copied to clipboard')
	}

	const title = `${task.data?.title ?? 'Generic Task'}`
	return (
		<>
			<TaskTitle
				title={
					candidate ? `${title} for ${candidate.firstName} ${candidate.lastName}` : title
				}
			/>
			<div className="flex relative">
				<TaskHTML html={html} size={size} />
				{size === 'xl' && (
					<div className="absolute top-0 right-0 p-2 z-0">
						<Button
							auto
							light
							size={'xl'}
							onClick={() =>
								handleCopyValue(
									document.getElementById('message-preview')?.innerText ?? ''
								)
							}
						>
							<HiClipboardCopy className="dark:text-white" />
						</Button>
					</div>
				)}
			</div>
		</>
	)
})

export function useInnerTaskContent(task: ITask, size: 'sm' | 'xl' = 'xl') {
	return useMemo(() => {
		switch (task.type) {
			case 'manual-email':
				return <ManualEmailContent task={task} size={size} />
			case 'manual-text-message':
				return <ManualTextMessageContent task={task} size={size} />
			case 'manual-linkedin-message':
				return <ManualLinkedInMessageConetnt task={task} size={size} />
			case 'manual-generic':
				return <ManualGenericContent task={task} size={size} />
		}
	}, [task, size])
}

type InnerTaskOoptions = {
	candidate?: ICandidate
	onComplete?: () => void
	onCloseTask?: () => void
}
export function useInnerTaskView(
	task: ITask,
	{ candidate, onComplete, onCloseTask }: InnerTaskOoptions = {}
) {
	const content = useInnerTaskContent(task)

	return useMemo(() => {
		return (
			<>
				{content}
				<div className="py-14">
					<TaskFooter
						task={task}
						onComplete={onComplete}
						onCloseTask={onCloseTask}
						candidate={candidate}
					/>
				</div>
			</>
		)
	}, [task, onComplete, onCloseTask, candidate, content])
}
