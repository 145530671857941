import { authHeader, get, patch, post, del } from './api'

import type { ICallScript, IQuestion, toJSONObject } from '@touchpoints/requests'

export async function getQuestion(organizationId: string, questionId: string) {
	const headers = await authHeader()
	return get<{ question: IQuestion }>(
		`/organizations/${organizationId}/questions/${questionId}`,
		undefined,
		headers
	)
}

export async function getAllQuestions(organizationId: string) {
	const headers = await authHeader()
	return get<{ questions: IQuestion[] }>(
		`/organizations/${organizationId}/questions`,
		undefined,
		headers
	)
}

export async function addQuestion(
	organizationId: string,
	question: Pick<IQuestion, 'name' | 'question'>
) {
	const headers = await authHeader()
	return post<{ question: IQuestion }>(
		`/organizations/${organizationId}/questions`,
		{
			...question,
		},
		headers
	)
}

export async function updateQuestion(
	organizationId: string,
	questionId: string,
	data: Partial<Pick<IQuestion, 'name' | 'question'>>
) {
	const headers = await authHeader()
	return patch<{ question: IQuestion }>(
		`/organizations/${organizationId}/questions/${questionId}`,
		{
			...data,
		},
		headers
	)
}

export async function deleteQuestion(organizationId: string, questionId: string) {
	const headers = await authHeader()
	return del(`/organizations/${organizationId}/questions/${questionId}`, headers)
}

export async function previewQuestion(
	organizationId: string,
	questionId: string,
	candidateId: string,
	positionId: string
) {
	const headers = await authHeader()
	return post<{ question: string; description: string; context: object }>(
		`/organizations/${organizationId}/questions/${questionId}/preview`,
		{ candidateId, positionId },
		headers
	)
}

export async function getCallScript(organizationId: string, callScriptId: string) {
	const headers = await authHeader()
	return get<{ script: ICallScript }>(
		`/organizations/${organizationId}/call-scripts/${callScriptId}`,
		undefined,
		headers
	)
}

export async function getAllCallScripts(organizationId: string) {
	const headers = await authHeader()
	return get<{ scripts: ICallScript[] }>(
		`/organizations/${organizationId}/call-scripts`,
		undefined,
		headers
	)
}

export async function addCallScript(organizationId: string, script: Pick<ICallScript, 'name'>) {
	const headers = await authHeader()
	return post<{ script: ICallScript }>(
		`/organizations/${organizationId}/call-scripts`,
		{
			...script,
		},
		headers
	)
}

export async function updateCallScript(
	organizationId: string,
	callScriptId: string,
	data: Partial<Pick<ICallScript, 'name' | 'questions'>>
) {
	const headers = await authHeader()
	return patch<{ script: ICallScript }>(
		`/organizations/${organizationId}/call-scripts/${callScriptId}`,
		{
			...data,
		},
		headers
	)
}

export async function deleteCallScript(organizationId: string, callScriptId: string) {
	const headers = await authHeader()
	return del(`/organizations/${organizationId}/call-scripts/${callScriptId}`, headers)
}

export async function getCallScriptInstance(
	organizationId: string,
	callScriptId: string,
	positionId: string,
	candidateId: string
) {
	const headers = await authHeader()
	return get<{ script: ICallScript; questions: IQuestion[] }>(
		`/organizations/${organizationId}/call-scripts/${callScriptId}/instance`,
		{ positionId, candidateId },
		headers
	)
}

export async function submitScriptResponse(
	organizationId: string,
	callScriptId: string,
	positionId: string,
	candidateId: string,
	questions: toJSONObject<IQuestion>[],
	responses: Record<string, string>
) {
	const headers = await authHeader()
	return post(
		`/organizations/${organizationId}/call-scripts/${callScriptId}/submit`,
		{ positionId, candidateId, questions, responses },
		headers
	)
}
