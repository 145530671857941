import Autolinker from 'autolinker'
import { useEffect, useMemo, useState } from 'react'
import { rootStore } from '@store'
import {
	ICandidate,
	IOrganizationUser,
	IPosition,
	ISequenceInstance,
	ITask,
	SequenceActionType,
} from '@touchpoints/requests'
import { autorun, reaction } from 'mobx'

export function useData(task: ITask, type: SequenceActionType) {
	const [data, setData] = useState<Record<string, any> | undefined>({ ...task.data })
	const [sender, setSender] = useState<IOrganizationUser>()
	const [candidate, setCandidate] = useState<ICandidate>()
	const [position, setPosition] = useState<IPosition>()
	const [instance, setInstance] = useState<ISequenceInstance>()

	const [subject, setSubject] = useState('')
	const [cc, setCC] = useState<string[]>([])
	const [html, setHtml] = useState('')
	const [title, setTitle] = useState('')

	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setData({ ...task.data })
		if (!task.sequenceInstanceId) {
			return
		}

		rootStore.sequences.fetchSequenceInstance(task.sequenceInstanceId)
	}, [task])

	useEffect(() => {
		const fetchCandidate = async (id: string) => {
			const candidate = await rootStore.candidates.getCandidateByIdAsync(id)
			if (candidate) {
				setCandidate(candidate)
			}
		}
		if (instance) fetchCandidate(instance.candidateId)
	}, [instance])

	useEffect(() => {
		if (!task.sequenceInstanceId) {
			return
		}

		return autorun(() => {
			const instance = rootStore.sequences.activeInstances.find(
				(i) => i.id === task.sequenceInstanceId
			)
			if (!instance) {
				return
			}

			setInstance(instance)

			if (instance.senderId) {
				const orgUser = rootStore.organizationUsers.getInActiveOrg(instance.senderId)
				if (orgUser) {
					setSender({ ...orgUser })
				}
			}

			const sequence = rootStore.sequences.getSequenceById(instance.sequenceId)
			if (!sequence) {
				return
			}

			const manualAction = sequence.actions.find((a) => a.type === type)
			setData({ ...manualAction?.data })
		})
	}, [task, type])

	useEffect(() => {
		if (!data) {
			return
		}

		const {
			linkedTemplateId,
			candidateId,
			positionId,
			subject = '',
			html = '',
			title = '',
			cc = [],
		} = data as {
			linkedTemplateId?: string
			candidateId?: string
			positionId?: string
			subject?: string
			html?: string
			title?: string
			cc?: string[]
		}

		if (linkedTemplateId) {
			setLoading(true)
			rootStore.templates
				.preview(linkedTemplateId, {
					candidateId: candidateId ?? candidate?.id,
					positionId: positionId ?? instance?.positionId,
				})
				.then((val) => {
					if (!val) {
						return
					}

					setSubject(Autolinker.link(val.subject || subject))
					setHtml(Autolinker.link(val.html))
					setLoading(false)
				})
		} else {
			setSubject(Autolinker.link(subject))
			setHtml(Autolinker.link(html))
		}

		setCC(cc)
		setTitle(title)
	}, [data, candidate, instance])

	useEffect(() => {
		const getCandidate = async (id?: string) => {
			if (!id) return undefined
			let candidate
			candidate = rootStore.candidates.getCandidateById(id)
			if (!candidate) {
				candidate = await rootStore.candidates.getCandidateByIdAsync(id)
			}
			return candidate
		}

		const getPosition = async (id?: string) => {
			if (!id) return undefined
			let position
			position = rootStore.positions.getPositionById(id)
			if (!position) {
				position = await rootStore.positions.fetchPosition(id)
			}
			return position
		}

		const fetchData = async (candidateId?: string, positionId?: string) => {
			const [candidate, position] = await Promise.all([
				getCandidate(candidateId),
				getPosition(positionId),
			])
			setCandidate(candidate)
			setPosition(position)
		}

		const { candidateId, positionId } = data as {
			candidateId?: string
			positionId?: string
		}

		fetchData(candidateId, positionId)
	}, [data])

	return useMemo(
		() => ({
			candidate,
			position,
			instance,
			html,
			sender,
			subject,
			title,
			cc,
			loading,
		}),
		[candidate, position, instance, html, sender, subject, title, cc, loading]
	)
}
