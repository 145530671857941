import { styled } from '@stitches/react'

export const Flex = styled('div', {
	display: 'flex',
	alignItems: 'center',
})

export const FlexStacked = styled('div', {
	display: 'flex',
	flexDirection: 'column',
})
