import type { ICalendarEvent, ITimeSelection } from '@touchpoints/requests'
import {
	addMilliseconds,
	differenceInDays,
	differenceInHours,
	differenceInMilliseconds,
	differenceInMinutes,
	format,
	isAfter,
	isBefore,
	isEqual,
} from 'date-fns'
import spacetime from 'spacetime'

export const DEFAULT_TIMEZONE = 'America/New_York'

export function timeOverlapsAny(
	selection: ITimeSelection,
	list: Partial<ICalendarEvent>[],
	bufferInMS = 0
) {
	return !!list.find((s) => {
		if (!s.start || !s.end) {
			return false
		}

		const tz = s.timezone || DEFAULT_TIMEZONE

		let startDate = spacetime(new Date(s.start)).goto(tz).toNativeDate()
		let endDate = spacetime(new Date(s.end)).goto(tz).toNativeDate()

		if (bufferInMS > 0) {
			startDate = addMilliseconds(startDate, -bufferInMS)
			endDate = addMilliseconds(endDate, bufferInMS)
		}

		return timeSelectionsOverlap({ start: startDate, end: endDate }, selection)
	})
}

export function timeSelectionsEqual(left: ITimeSelection, right: ITimeSelection) {
	const leftStart = new Date(left.start)
	const leftEnd = new Date(left.end)

	const rightStart = new Date(right.start)
	const rightEnd = new Date(right.end)

	return leftStart.getTime() === rightStart.getTime() && leftEnd.getTime() === rightEnd.getTime()
}

export function timeSelectionInside(needle: ITimeSelection, haystack: ITimeSelection) {
	console.log('needle', needle)
	console.log('haystack', haystack)
	const needleStart = new Date(needle.start).getTime()
	const needleEnd = new Date(needle.end).getTime()

	const haystackStart = new Date(haystack.start).getTime()
	const haystackEnd = new Date(haystack.end).getTime()

	const res = needleStart >= haystackStart && needleEnd <= haystackEnd
	console.log(res, `${needleStart} >= ${haystackStart} && ${needleEnd} <= ${haystackEnd}`)
	return res
}

export function timeSelectionsOverlap(left: ITimeSelection, right: ITimeSelection) {
	const leftStart = new Date(left.start)
	const leftEnd = new Date(left.end)

	const rightStart = new Date(right.start)
	const rightEnd = new Date(right.end)

	if (isAfter(rightStart, leftEnd)) {
		// right start is after left end so cannot overlap
		return false
	}

	if (isAfter(leftStart, rightEnd)) {
		// left start is after right end so cannot overlap
		return false
	}

	if (isEqual(rightEnd, leftStart) || isBefore(rightEnd, leftStart)) {
		// right end is before left start so cannot overlap
		return false
	}

	if (isEqual(leftEnd, rightStart) || isBefore(leftEnd, rightStart)) {
		// left end is before right start so cannot overlap
		return false
	}

	return true
}

export function formatTimeDifference(fromDate: Date) {
	const d = new Date(fromDate)
	const now = Date.now()
	const past = differenceInMilliseconds(d, now) < 0
	let diff = Math.abs(differenceInMinutes(now, d))

	if (diff <= 1) return 'just now'
	if (diff <= 60) return `${diff}m ${past ? 'ago' : ''}`

	diff = Math.abs(differenceInHours(d, now))
	if (diff <= 24) return `${diff}h ${past ? 'ago' : ''}`

	diff = Math.abs(differenceInDays(d, now))
	if (diff <= 4) return `${diff}d ${past ? 'ago' : ''}`

	if (diff <= 50) return format(d, 'MMM d')

	return format(d, 'MMM d, yyyy')
}
