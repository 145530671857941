import { ICandidate } from '@touchpoints/requests'
import { observer } from 'mobx-react-lite'
import { PropsWithChildren } from 'react'
import { FaLinkedin } from 'react-icons/fa'
import { HiOutlineMail, HiPhone } from 'react-icons/hi'
import { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'

export const CandidateEmail = observer(function ({ email }: { email?: string }) {
	if (!email) return
	return (
		<span className="flex items-center space-x-4 text-zinc-500 text-xs font-medium">
			<HiOutlineMail />
			&nbsp;
			{email}
		</span>
	)
})

type Props = {
	candidate: ICandidate
}
export const CandidateInfoHeader = observer(function ({
	candidate,
	children,
}: PropsWithChildren<Props>) {
	const firstLetter = candidate.firstName[0]
	const handleLinkedIn = () => {
		const linkedinUrl = candidate?.linkedinUrl
		if (!linkedinUrl) {
			return
		}

		window.open(linkedinUrl, '_blank')
	}
	return (
		<div className="flex justify-between">
			<div className="grow flex w-full flex-col space-y-3">
				{firstLetter && (
					<span className="w-10 h-10 leading-10 mr-3 text-center text-zinc-900 dark:text-black rounded-full bg-slate-100">
						{firstLetter.toLocaleUpperCase()}
					</span>
				)}
				<div className="flex items-center flex-wrap space-x-3">
					<span className="text-xl text-zinc-900 dark:text-white font-medium leading-7">
						{candidate.firstName} {candidate.lastName}
					</span>
					{candidate && (
						<>
							{candidate.phone && isValidPhoneNumber(candidate.phone) && (
								<span className="flex items-center space-x-4 text-zinc-500 text-xs font-medium">
									<HiPhone />
									&nbsp;
									{formatPhoneNumber(candidate.phone)}
								</span>
							)}
							<CandidateEmail email={candidate.email} />

							{candidate.linkedinUrl && (
								<span
									className="flex items-center space-x-4 text-zinc-500 text-xs font-medium hover:cursor-pointer"
									onClick={handleLinkedIn}
								>
									<FaLinkedin /> &nbsp; Linkedin Profile
								</span>
							)}
						</>
					)}
				</div>
			</div>
			{children}
		</div>
	)
})
