import type {
	CustomField,
	PositionStatus,
	PositionType,
	ICandidateStage,
	IAccount,
	IPosition,
	PositionExpanded,
} from '@touchpoints/requests'
import { type AnnotationsMap, action } from 'mobx'

export function createRefrenceName(position: PositionExpanded): string {
	const shortName = position.account?.shortName || position.account?.name || '???'
	return `${shortName}${position.referenceNumber}`
}

export abstract class BasePosition implements PositionExpanded {
	id = ''
	organizationId = ''
	accountId = ''
	name = ''
	type: PositionType = 'direct-hire'
	accountTeam = ''
	managerName = ''
	managerEmail = ''
	status: PositionStatus = 'open'
	clientReqId = ''
	location = ''
	teamId = ''
	defaultMailbox = ''
	defaultSequenceId = ''
	defaultMeetingId = ''
	accountManagerId = ''
	engagementContactId = ''
	supportContactId = ''

	callScript1Id = ''
	callScript2Id = ''

	highlights = ''
	intakeDetails = ''
	description = ''
	skillsAndQualifications = ''
	eligibility = ''
	compensation = ''
	expectations = ''

	linkedBlueprintId = ''
	stageTemplateId = ''
	candidateStageOverrides?: Record<string, Omit<ICandidateStage, 'id' | 'name'>> | null = null
	customFields: Record<string, CustomField> = {}

	referenceNumber = 0

	account?: IAccount

	_typeformId = ''
	knowledgeDBLink = ''

	get referenceName(): string {
		return createRefrenceName(this)
	}

	constructor(data: IPosition, account?: IAccount) {
		this.account = account

		this.update(data)
	}

	update(data: IPosition) {
		this.id = data.id
		this.organizationId = data.organizationId
		this.accountId = data.accountId
		this.name = data.name
		this.type = data.type
		this.accountTeam = data.accountTeam ?? ''
		this.managerName = data.managerName ?? ''
		this.managerEmail = data.managerEmail ?? ''
		this.status = data.status
		this.clientReqId = data.clientReqId
		this.location = data.location
		this.teamId = data.teamId ?? ''
		this.defaultMailbox = data.defaultMailbox ?? ''
		this.defaultSequenceId = data.defaultSequenceId ?? ''
		this.defaultMeetingId = data.defaultMeetingId ?? ''
		this.accountManagerId = data.accountManagerId ?? ''
		this.engagementContactId = data.engagementContactId ?? ''
		this.supportContactId = data.supportContactId ?? ''
		this.customFields = data.customFields ?? {}

		this.callScript1Id = data.callScript1Id ?? ''
		this.callScript2Id = data.callScript2Id ?? ''

		this.highlights = data.highlights ?? ''
		this.intakeDetails = data.intakeDetails ?? ''
		this.description = data.description ?? ''
		this.skillsAndQualifications = data.skillsAndQualifications ?? ''
		this.eligibility = data.eligibility ?? ''
		this.compensation = data.compensation ?? ''
		this.expectations = data.expectations ?? ''

		this.linkedBlueprintId = data.linkedBlueprintId ?? ''
		this.stageTemplateId = data.stageTemplateId ?? ''
		this.candidateStageOverrides = data.candidateStageOverrides ?? null

		// Convert dates to Date objects
		for (const key in this.customFields) {
			if (this.customFields[key].type === 'date') {
				this.customFields[key].value = new Date(this.customFields[key].value as string)
			}
		}
		this.referenceNumber = data.referenceNumber ?? 0

		this._typeformId = data._typeformId ?? ''
		this.knowledgeDBLink = data.knowledgeDBLink ?? ''
	}

	setAccount(account?: IAccount) {
		this.account = account ?? this.account
	}
}

export function getBasePositionAnnotationMap() {
	return {
		id: true,
		organizationId: true,
		accountId: true,
		name: true,
		type: true,
		accountTeam: true,
		managerName: true,
		managerEmail: true,
		status: true,
		clientReqId: true,
		location: true,
		teamId: true,
		defaultMailbox: true,
		defaultSequenceId: true,
		accountManagerId: true,
		engagementContactId: true,
		supportContactId: true,

		callScript1Id: true,

		linkedBlueprintId: true,
		candidateStageOverrides: true,
		customFields: true,

		referenceNumber: true,
		setAccount: action,
	} as AnnotationsMap<BasePosition, PropertyKey>
}
