export function toUniformLinkedinUrl(_url: string) {
	_url = _url.trim()
	if (!_url) {
		return _url
	}

	try {
		const url = _url.startsWith('http') ? _url : `https://${_url}`
		const u = new URL(url)
		return `https://www.linkedin.com${u.pathname.replace(/\/$/, '')}`
	} catch (e) {
		console.log(e)
	}

	return _url
}
