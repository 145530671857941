export function SmartFormChecklist({ size = 17 }: { size?: number }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 17 17"
			fill="none"
		>
			<g id="search, page, find">
				<path
					id="Icon"
					d="M13.195 7.63759V2.08203H2.77832V14.582H7.29221M5.5561 10.4154H6.25054M5.5561 4.85981H10.4172M5.5561 7.63759H7.63943"
					stroke="#D6409F"
					strokeWidth="1.25"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<g id="Rectangle 1745">
					<mask id="path-2-inside-1_2105_59977" fill="white">
						<rect x="9" y="9.83594" width="5" height="5" rx="1" />
					</mask>
					<rect
						x="9"
						y="9.83594"
						width="5"
						height="5"
						rx="1"
						stroke="#D6409F"
						strokeWidth="2.5"
						strokeLinejoin="round"
						mask="url(#path-2-inside-1_2105_59977)"
					/>
				</g>
			</g>
		</svg>
	)
}
