import { DateTimePicker } from '@touchpoints/ui'

import { useReaction } from '@hooks/mobx'
import { Button, Tooltip } from '@nextui-org/react'
import { formatTimeDifference } from '@services/date'
import { formatDate, formatHour } from '@services/time'
import { rootStore } from '@store'
import { IPositionCandidateScheduledEvent } from '@touchpoints/requests'
import clsx from 'clsx'
import { differenceInMilliseconds, format, add, sub } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { HiOutlineCalendar, HiTrash } from 'react-icons/hi'
import { store } from './store'

type EventTimeLabelProps = {
	time: Date | null
	completedAt?: number | null
}
function EventTimeLabel({ time, completedAt }: EventTimeLabelProps) {
	if (!time) return <p className="text-sm font-normal text-[#6E56CF]">No date</p>

	const completed = !!completedAt
	const eventTime = new Date(time)
	const completedTime = completedAt ? new Date(completedAt) : new Date()
	const isPast = differenceInMilliseconds(eventTime, Date.now()) < 0

	return (
		<Tooltip content={formatDate(eventTime)}>
			<div
				className={clsx('flex items-center space-x-2 text-sm font-normal', {
					'text-slate-500': completed,
					'text-[#6E56CF]': !completed && !isPast,
					'text-red-500': !completed && isPast,
				})}
			>
				<p>
					{!completed && isPast && `${formatTimeDifference(eventTime)}`}
					{!completed && !isPast && `Starts in ${formatTimeDifference(eventTime)}`}
					{completed && `${formatHour(eventTime)} - ${formatHour(completedTime)}`}
				</p>

				<div className="w-1 h-1 rounded-full bg-slate-400" />

				<p className="text-slate-500">{format(new Date(time), 'EEE d MMM')}</p>
			</div>
		</Tooltip>
	)
}

type EventsRowProps = {
	event: IPositionCandidateScheduledEvent
	onDelete?: (id: string) => void
	deleting?: boolean
	readOnly?: boolean
}
export function EventsRow({ event: evt, onDelete, deleting, readOnly }: EventsRowProps) {
	const [selectedDate, setSelectedDate] = useState<Date | null>(
		evt.time ? new Date(evt.time) : null
	)
	const [completed, setCompleted] = useState(!!evt.completedAt)

	const positionCandidate = useReaction(() => {
		const positionCandidate = rootStore.positions.getPositionCandidateById(
			store.activePositionCandidateId
		)
		return positionCandidate
	})

	const candidate = useReaction(() => {
		if (!positionCandidate) return
		return rootStore.candidates.getCandidateById(positionCandidate?.candidateId)
	})

	const allEvents = useReaction(() => {
		const positionCandidate = rootStore.positions.getPositionCandidateById(
			store.activePositionCandidateId
		)
		return positionCandidate?.scheduledEvents?.map((e) => ({ ...e })) ?? []
	})

	useEffect(() => {
		setCompleted(!!evt.completedAt)
	}, [evt.completedAt])

	const handleUpdateDate = async (date: Date) => {
		setSelectedDate(date)

		if (!positionCandidate || !date) {
			return
		}

		const newEvents = [...allEvents]
		const index = newEvents.findIndex((e) => e.id === evt.id)
		const e = newEvents[index]
		e.time = date.getTime()

		await rootStore.positions.updateCandidateScheduledEvent(
			positionCandidate.candidateId,
			positionCandidate.positionId,
			e.id,
			{
				...e,
			}
		)
	}

	const today = useMemo(() => new Date(), [])
	const minDate = useMemo(() => sub(today, { years: 10 }), [today])
	const maxDate = useMemo(() => add(today, { years: 1 }), [today])

	return (
		<div key={evt.id} className="flex space-x-3 items-center justify-between py-3">
			<div className="flex space-x-3 items-center">
				<div
					className={clsx('w-8 rounded-full p-2', {
						'bg-[#EDE9FE]': !completed,
						'bg-slate-50': completed,
					})}
				>
					<HiOutlineCalendar
						className={clsx({
							'text-[#8E4EC6]': !completed,
							'text-slate-500': completed,
						})}
					/>
				</div>
				<div className="flex flex-col space-y-0">
					<p className="text-sm text-[#11181C]">
						{candidate?.firstName} {candidate?.lastName} - {evt.name}
					</p>
					<EventTimeLabel time={selectedDate} completedAt={evt.completedAt} />
				</div>
			</div>
			<div className="flex flex-initial items-center">
				<DateTimePicker
					selected={selectedDate ?? today}
					disabled={completed}
					timeSelect={true}
					onChange={handleUpdateDate}
					placement="left-start"
					minDate={minDate}
					maxDate={maxDate}
					withConfirmation={true}
				/>
				{!readOnly && onDelete && (
					<Button
						auto
						color="error"
						light
						size="sm"
						onClick={() => onDelete?.(evt.id)}
						disabled={deleting}
					>
						<HiTrash size="1.25em" />
					</Button>
				)}
			</div>
		</div>
	)
}
