import { action, autorun, makeAutoObservable, ObservableMap, reaction, runInAction } from 'mobx'
import flatten from 'lodash/flatten'
import orderBy from 'lodash/orderBy'

import type { RootStore } from './root'

import type {
	CandidateTimeSelectionStatus,
	IAvailability,
	IAvailabilityBlock,
	IAvailabilityDay,
	IAvailabilityEvent,
	ICandidateTimeSelection,
	ITimeSelection,
} from '@touchpoints/requests'
import {
	createPositionAvailability,
	fetchPositionAvailability,
	removePositionAvailability,
	updatePositionAvailability,
} from '@requests/positions'
import {
	addCandidateNextRoundInterview,
	addCandidateSchedule,
	deleteCandidateSchedule,
	getBulkCandidateSchedules,
	getCandidateSchedules,
	getScheduledCandidateSchedules,
	getSuppliedCandidateSchedules,
	selectCandidateSelectionSlot,
	unselectCandidateSelectionSlot,
	updateCandidateSchedule,
} from '@requests/candidates'
import { Timezone } from '@touchpoints/timezone'
import { timeSelectionsEqual } from '@touchpoints/time'
import { debounce } from 'lodash'
import { createAsyncQueue } from '@services/queue'

class AvailabilityBlock implements IAvailabilityBlock {
	readonly start: string
	readonly end: string

	private _note = ''

	get note() {
		return this._note
	}

	constructor(start = '9:00am', end = '10:00am', note = '') {
		makeAutoObservable(this)

		this.start = start
		this.end = end
		this._note = note
	}
}

class AvailabilityDay implements IAvailabilityDay {
	readonly blocks: AvailabilityBlock[] = []

	constructor(blocks: IAvailabilityBlock[] = []) {
		makeAutoObservable(this)

		blocks.forEach((b) => {
			this.addBlock(b)
		})
	}

	toObject() {
		return {
			blocks: this.blocks.map((b) => ({ ...b })),
		}
	}

	addBlock(block: AvailabilityBlock | IAvailabilityBlock) {
		this.blocks.push(
			block instanceof AvailabilityBlock
				? block
				: new AvailabilityBlock(block.start, block.end, block.note)
		)
	}
}

class AvailabilityEvent implements IAvailabilityEvent {
	readonly blocks: AvailabilityBlock[] = []
	readonly date = new Date()

	constructor(date: Date, blocks: IAvailabilityBlock[] = []) {
		blocks.forEach((b) => {
			this.addBlock(b)
		})

		this.date = date

		makeAutoObservable(this)
	}

	toObject() {
		return {
			blocks: this.blocks.map((b) => ({ ...b })),
			date: new Date(this.date),
		}
	}

	addBlock(block: AvailabilityBlock | IAvailabilityBlock) {
		this.blocks.push(
			block instanceof AvailabilityBlock
				? block
				: new AvailabilityBlock(block.start, block.end, block.note)
		)
	}
}

class Availability implements IAvailability {
	id = ''
	name = ''
	positionId = ''
	timezone: Timezone = 'America/New_York'
	interval = 30
	meetingLength = 30
	readonly days: AvailabilityDay[] = []
	readonly events: AvailabilityEvent[] = []
	readonly excludeSlots: ITimeSelection[] = []
	stage = ''

	constructor(data: Partial<IAvailability>) {
		this.update(data)

		makeAutoObservable(this, {
			addDay: action,
			clearDays: action,
		})
	}

	updateDays(days: IAvailabilityDay[] = []) {
		this.clearDays()

		for (const d of days) {
			this.addDay(d)
		}
	}

	updateEvents(events: IAvailabilityEvent[] = []) {
		this.clearEvents()

		const orderedEvents = orderBy(events, ['date'])
		for (const e of orderedEvents) {
			this.addEvent(e)
		}
	}

	updateExcludes(slots: ITimeSelection[] = []) {
		this.clearExcludes()

		for (const slot of slots) {
			this.addExclude(slot)
		}
	}

	update(data: Partial<IAvailability>) {
		this.id = data.id ?? this.id
		this.positionId = data.positionId ?? this.positionId
		this.name = data.name ?? ''
		this.timezone = data.timezone ?? this.timezone
		this.interval = data.interval ?? this.interval
		this.meetingLength = data.meetingLength ?? this.meetingLength
		if (data.days) {
			this.updateDays(data.days)
		}
		if (data.events) {
			this.updateEvents(data.events)
		}
		if (data.excludeSlots) {
			this.updateExcludes(data.excludeSlots)
		}
		this.stage = data.stage ?? ''
	}

	toObject(): IAvailability {
		return {
			id: this.id,
			name: this.name,
			positionId: this.positionId,
			timezone: this.timezone,
			interval: this.interval,
			meetingLength: this.meetingLength,
			days: this.days.map((d) => d.toObject()),
			events: this.events.map((e) => e.toObject()),
			stage: this.stage,
		}
	}

	addExclude(slot: ITimeSelection) {
		this.excludeSlots.push(slot)
	}

	addDay(day: AvailabilityDay | IAvailabilityDay) {
		this.days.push(day instanceof AvailabilityDay ? day : new AvailabilityDay(day.blocks))
	}

	addEvent(event: AvailabilityEvent | IAvailabilityEvent) {
		this.events.push(
			event instanceof AvailabilityEvent
				? event
				: new AvailabilityEvent(event.date, event.blocks)
		)
	}

	clearDays() {
		while (this.days.length > 0) {
			this.days.pop()
		}
	}

	clearEvents() {
		while (this.events.length > 0) {
			this.events.pop()
		}
	}

	clearExcludes() {
		while (this.excludeSlots.length > 0) {
			this.excludeSlots.pop()
		}
	}
}

export class PositionAvailabilities {
	private rootStore: RootStore

	readonly availabilitiesByPositionId = new ObservableMap<string, Availability[]>()
	readonly availabilitiesById = new ObservableMap<string, Availability>()

	private readonly requestsQueue = createAsyncQueue({ maxChannels: 1 })

	get activeAvailabilities() {
		const id = this.rootStore.positions.activePositionId
		if (!id) {
			return []
		}

		return this.availabilitiesByPositionId.get(id) ?? []
	}

	constructor(root: RootStore) {
		this.rootStore = root

		makeAutoObservable(this)

		autorun(() => {
			const id = this.rootStore.positions.activePositionId
			if (!id) {
				return
			}

			this.fetchAvailabilities(id)
		})
	}

	async fetchAvailabilities(positionId: string) {
		const position = this.rootStore.positions.getPositionById(positionId)
		if (!position) {
			return
		}

		if (!this.availabilitiesByPositionId.has(positionId)) {
			this.availabilitiesByPositionId.set(positionId, [])
		}

		// get from API
		const res = await this.requestsQueue.addUnique(positionId, () =>
			fetchPositionAvailability(position.organizationId, position.accountId, position.id)
		)
		if (!res.success) {
			return
		}

		const { interviews } = res.data ?? {}

		if (!interviews) {
			// TODO: show error
			return
		}

		const availabilities = this.availabilitiesByPositionId.get(positionId) ?? []
		runInAction(() => {
			availabilities.length = 0
		})
		for (const availability of interviews) {
			const hasExisting = this.availabilitiesById.has(availability.id)
			const a = hasExisting
				? this.availabilitiesById.get(availability.id)
				: new Availability(availability)

			if (hasExisting) {
				a?.update(availability)
			}

			if (!a) {
				continue
			}

			runInAction(() => {
				availabilities.push(a)
				this.availabilitiesById.set(availability.id, a)
			})
		}

		runInAction(() => {
			this.availabilitiesByPositionId.set(positionId, availabilities)
		})
	}

	async create() {
		const positionId = this.rootStore.positions.activePositionId ?? undefined

		if (!positionId) {
			return
		}

		const position = this.rootStore.positions.getPositionById(positionId)
		if (!position) {
			return
		}

		const res = await createPositionAvailability(
			position.organizationId,
			position.accountId,
			position.id
		)
		if (!res.success) {
			return
		}

		const { availability } = res.data ?? {}
		if (!availability) {
			return
		}

		const a = new Availability(availability)

		const availabilities = this.availabilitiesByPositionId.get(positionId) ?? []
		runInAction(() => {
			availabilities.push(a)
			this.availabilitiesByPositionId.set(positionId, availabilities)

			this.availabilitiesById.set(a.id, a)
		})

		return a
	}

	async saveChanges(
		newData: Partial<Pick<IAvailability, 'days' | 'events' | 'stage'>>,
		availabilityId: string,
		positionId?: string
	) {
		if (!positionId) {
			positionId = this.rootStore.positions.activePositionId ?? undefined
		}

		if (!positionId) {
			return
		}

		const position = this.rootStore.positions.getPositionById(positionId)
		if (!position) {
			return
		}

		// update optimistically
		let availability = this.availabilitiesById.get(availabilityId)
		const previousAvailability = availability?.toObject()
		availability?.update(newData)

		// save to API
		const res = await updatePositionAvailability(
			position.organizationId,
			position.accountId,
			position.id,
			availabilityId,
			newData
		)
		if (!res.success) {
			// revert changes
			if (previousAvailability) {
				availability?.update(previousAvailability)
			}
			return
		}

		const { availability: data } = res.data ?? {}

		if (!data) {
			// TODO: show error
			if (previousAvailability) {
				availability?.update(previousAvailability)
			}
			return
		}

		availability = this.availabilitiesById.get(availabilityId)
		if (!availability) {
			return
		}

		availability.update(data)
	}

	async remove(positionId: string, ...ids: string[]) {
		const position = this.rootStore.positions.getPositionById(positionId)
		if (!position) {
			return
		}

		const res = await removePositionAvailability(
			position.organizationId,
			position.accountId,
			position.id,
			{
				ids,
			}
		)

		if (!res.success) {
			return
		}

		const { deletedIds } = res.data ?? {}
		if (!deletedIds) {
			return
		}

		for (const id of deletedIds) {
			this.availabilitiesById.delete(id)
		}

		const availabilities = this.availabilitiesByPositionId.get(positionId) ?? []
		const remaining = availabilities.filter((a) => !deletedIds.find((id) => id === a.id))
		this.availabilitiesByPositionId.set(positionId, remaining)
	}

	getAvailabilityById(id: string) {
		return this.availabilitiesById.get(id)
	}
}

export class CandidateSelectionsStore {
	private readonly rootStore: RootStore
	readonly selectionsForCandidate = new ObservableMap<string, ICandidateTimeSelection[]>()

	get list() {
		const values = [...this.selectionsForCandidate.values()]
		return flatten(values)
	}

	get suppliedList() {
		return this.list.filter((sel) => sel.status === 'supplied')
	}

	get scheduledList() {
		return this.list.filter((sel) => sel.status === 'scheduled')
	}

	constructor(root: RootStore) {
		this.rootStore = root

		makeAutoObservable(this)

		reaction(
			() => root.positions.activeCandidates?.map((id) => id) ?? [],
			debounce((candidateIds) => {
				const positionId = this.rootStore.positions.activePositionId
				if (!positionId) {
					return
				}

				this.fetchCandidatesSelections(positionId, candidateIds)
			}, 500)
		)
	}

	async fetchCandidatesSelections(positionId: string, candidateIds: string[]) {
		const orgId = this.rootStore.organizations.activeOrganizationId
		if (!orgId) {
			return
		}
		const res = await getBulkCandidateSchedules(orgId, candidateIds, positionId)
		if (!res.success) {
			return
		}

		const { selections = [] } = res.data ?? {}

		runInAction(() => {
			for (const selection of selections) {
				const candidateId = selection.candidateId
				this.clear(candidateId)
				this.addSelection(candidateId, selection)
			}
		})
	}

	async fetchOneCandidateSelections(positionId: string, candidateId: string) {
		const orgId = this.rootStore.organizations.activeOrganizationId
		if (!orgId) {
			return
		}
		const res = await getCandidateSchedules(orgId, candidateId, positionId)
		if (!res.success) {
			return
		}

		this.clear(candidateId)
		const { selections = [] } = res.data ?? {}
		for (const selection of selections) {
			this.addSelection(candidateId, selection)
		}
	}

	// NOTE: may want a more optimized version that gets both
	// 'supplied' and 'scheduled' candidate selections
	async fetchSuppliedCandidateSelections() {
		const orgId = this.rootStore.organizations.activeOrganizationId
		if (!orgId) {
			return
		}
		const res = await getSuppliedCandidateSchedules(orgId)
		if (!res.success) {
			return
		}

		const { selections = [] } = res.data ?? {}
		for (const selection of selections) {
			this.addSelection(selection.candidateId, selection)
		}
	}

	async fetchScheduledCandidateSelections() {
		const orgId = this.rootStore.organizations.activeOrganizationId
		if (!orgId) {
			return
		}
		const res = await getScheduledCandidateSchedules(orgId)
		if (!res.success) {
			return
		}

		const { selections = [] } = res.data ?? {}
		for (const selection of selections) {
			this.addSelection(selection.candidateId, selection)
		}
	}

	async createNextRound(orgId: string, candidateId: string, positionId: string) {
		const res = await addCandidateNextRoundInterview(orgId, candidateId, positionId)
		if (!res.success) {
			return
		}

		const { selection } = res.data ?? {}
		if (!selection) {
			return
		}

		this.addSelection(candidateId, selection)
	}

	async createSchedule(
		orgId: string,
		candidateId: string,
		positionId: string,
		availabilityId: string
	) {
		const res = await addCandidateSchedule(orgId, candidateId, positionId, availabilityId)
		if (!res.success) {
			return
		}

		const { selection } = res.data ?? {}
		if (!selection) {
			return
		}

		this.addSelection(candidateId, selection)
	}

	async updateSchedule(
		orgId: string,
		positionId: string,
		candidateId: string,
		scheduleId: string,
		changes: {
			interval?: number
			notifyEmails?: string[]
			selections?: ITimeSelection[]
			status?: CandidateTimeSelectionStatus
		}
	) {
		const res = await updateCandidateSchedule(
			orgId,
			candidateId,
			positionId,
			scheduleId,
			changes
		)
		if (!res.success) {
			return
		}

		const sel = this.selectionsForCandidate
			.get(candidateId)
			?.find((sel) => sel.id === scheduleId)
		if (!sel) {
			return
		}
		this.updateSelection(sel, changes)
	}

	async deleteSchedule(
		orgId: string,
		positionId: string,
		candidateId: string,
		scheduleId: string
	) {
		const res = await deleteCandidateSchedule(orgId, candidateId, positionId, scheduleId)
		if (!res.success) {
			return
		}

		const { selection } = res.data ?? {}
		if (!selection) {
			return
		}

		this.removeSelection(scheduleId, candidateId)
	}

	addSelection(candidateId: string, selection: ICandidateTimeSelection) {
		if (!this.selectionsForCandidate.has(candidateId)) {
			this.selectionsForCandidate.set(candidateId, [])
		}

		// remove existing (if it exists)
		this.removeSelection(selection.id, candidateId)

		this.selectionsForCandidate.get(candidateId)?.push(selection)
	}

	updateSelection(selection: ICandidateTimeSelection, data: Partial<ICandidateTimeSelection>) {
		Object.assign(selection, data)

		// specifically clear this out
		if (!data.selectedTime) {
			selection.selectedTime = undefined
		}
	}

	removeSelection(id: string, candidateId: string) {
		const list = this.selectionsForCandidate.get(candidateId)
		if (!list) {
			return
		}

		const idx = list.findIndex((sel) => sel.id === id)
		if (idx < 0) {
			return
		}

		list.splice(idx, 1)
	}

	async selectCandidateSelectionSlot(
		orgId: string,
		positionId: string,
		candidateId: string,
		scheduleId: string,
		slot: ITimeSelection
	) {
		const res = await selectCandidateSelectionSlot(
			orgId,
			candidateId,
			positionId,
			scheduleId,
			slot
		)
		if (!res.success) {
			return
		}

		const { selection } = res.data ?? {}
		if (!selection) {
			return
		}

		this.onSelectedTimeChanged(candidateId, scheduleId, selection)
	}

	async unselectCandidateSelectionSlot(
		orgId: string,
		positionId: string,
		candidateId: string,
		scheduleId: string
	) {
		const res = await unselectCandidateSelectionSlot(orgId, candidateId, positionId, scheduleId)
		if (!res.success) {
			return
		}

		const { selection } = res.data ?? {}
		if (!selection) {
			return
		}

		this.onSelectedTimeChanged(candidateId, scheduleId, selection)
	}

	private onSelectedTimeChanged(
		candidateId: string,
		scheduleId: string,
		selection: ICandidateTimeSelection
	) {
		const list = this.selectionsForCandidate.get(candidateId)
		if (!list) {
			return
		}

		const idx = list.findIndex((sel) => sel.id === scheduleId)
		if (idx < 0) {
			return
		}

		const existingSelection = list[idx]
		const availability = selection.availabilityId
			? this.rootStore.positionAvailabilities.getAvailabilityById(selection.availabilityId)
			: this.rootStore.positionAvailabilities.activeAvailabilities[0]
		if (availability && existingSelection.selectedTime) {
			const excludeSlots = [...availability.excludeSlots].filter(
				(slot) =>
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					!timeSelectionsEqual(slot, existingSelection.selectedTime!)
			)

			if (selection.selectedTime) {
				excludeSlots.push(selection.selectedTime)
			}

			availability.updateExcludes(excludeSlots)
		} else if (selection.selectedTime) {
			// in the case where existingSelection doesn't have a selectedTime
			availability?.addExclude(selection.selectedTime)
		}
		this.updateSelection(existingSelection, selection)
	}

	clear(candidateId: string) {
		this.selectionsForCandidate.set(candidateId, [])
	}
}
