import { type ReactNode } from 'react'

type Props = {
	checked?: boolean
	onChange?: (e: boolean) => void
	label?: ReactNode | string
}
export function Switch({ checked, onChange, label }: Props) {
	return (
		<label className="relative inline-flex items-center cursor-pointer">
			<input
				type="checkbox"
				// value={checked ? '' : undefined}
				checked={checked}
				className="sr-only peer"
				onChange={() => onChange?.(!checked)}
			/>
			<div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
			<span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
				{label}
			</span>
		</label>
	)
}
