import { rootStore } from '@store'
import type { ICandidateEvent, IPositionCandidateEvent } from '@touchpoints/requests'
import { autorun } from 'mobx'
import { useEffect, useState } from 'react'

export function useCandidateEvents(candidateId: string) {
	const [events, setEvents] = useState<(ICandidateEvent | IPositionCandidateEvent)[]>([])

	useEffect(() => {
		if (!candidateId) {
			setEvents([])
			return
		}
		rootStore.candidates.events.fetchAllEventsForCandidate(candidateId)

		return autorun(() => {
			const allEvents = rootStore.candidates.events.getEventsForCandidate(candidateId)
			setEvents(
				[...allEvents].sort((a, b) => {
					return b.ts - a.ts
				})
			)
		})
	}, [candidateId])

	return events
}
