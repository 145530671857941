import { ReactNode } from 'react'
import { Label } from './Label'
import clsx from 'clsx'

type TextAreaProps = {
	value?: string
	onChange?: (val: string) => void
	rows?: number
	label?: string | ReactNode
	placeholder?: string
	tabIndex?: number
	readOnly?: boolean
}

export function TextArea({
	value,
	onChange,
	label,
	placeholder,
	rows = 3,
	tabIndex,
	readOnly,
}: TextAreaProps) {
	const isString = typeof label === 'string'
	return (
		<div className="w-full">
			{label && isString && <Label>{label}</Label>}
			{label && !isString && <div>{label}</div>}
			<textarea
				value={value ?? ''}
				rows={rows}
				onChange={(e) => onChange?.(e.currentTarget.value)}
				placeholder={placeholder}
				tabIndex={tabIndex}
				disabled={!!readOnly}
				className={clsx(
					'border border-slate-200 rounded-md w-full py-2 px-3 placeholder:text-sm placeholder:text-slate-400',
					{
						'bg-slate-50': !!readOnly,
						'focus:outline focus:outline-[#0091FF]': !readOnly,
					}
				)}
			/>
		</div>
	)
}
